import { CONTRACTPROPOSALALREADYREALIZEDSchema } from './CONTRACTPROPOSALALREADYREALIZEDSchema.ts'
import { PROVIDERSIGNATUREMISSINGSchema } from './PROVIDERSIGNATUREMISSINGSchema.ts'
import { TENANTSIGNATUREMISSINGSchema } from './TENANTSIGNATUREMISSINGSchema.ts'
import { z } from 'zod'

export const multiplePublicErrorsOutUnionCONTRACTPROPOSALALREADYREALIZEDTENANTSIGNATUREMISSINGPROVIDERSIGNATUREMISSINGSchema = z.object({
  errors: z.array(
    z.union([z.lazy(() => CONTRACTPROPOSALALREADYREALIZEDSchema), z.lazy(() => TENANTSIGNATUREMISSINGSchema), z.lazy(() => PROVIDERSIGNATUREMISSINGSchema)]),
  ),
  title: z.string().optional(),
  type: z.literal('https://metergrid.de/api-errors/public-errors').default('https://metergrid.de/api-errors/public-errors'),
})