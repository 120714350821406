import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { MGTooltip } from '../MGTooltip';

export type MGEllipsizedValueProps = {
  children: ReactNode;
};

export const MGEllipsizedValue = ({ children }: MGEllipsizedValueProps) => {
  return (
    <MGTooltip title={children}>
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography>{children}</Typography>
      </Box>
    </MGTooltip>
  );
};
