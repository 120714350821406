// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  DismissNotificationMutationResponse,
  DismissNotificationPathParams,
  DismissNotification404,
  DismissNotification422,
} from '../types/DismissNotification.ts'

export function getDismissNotificationUrl(id: DismissNotificationPathParams['id']) {
  return `/api-internal/notifications/${id}/dismiss` as const
}

/**
 * @summary Dismiss Notification
 * {@link /api-internal/notifications/:id/dismiss}
 */
export async function dismissNotification(id: DismissNotificationPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<DismissNotificationMutationResponse, ResponseErrorConfig<DismissNotification404 | DismissNotification422>, unknown>({
    method: 'POST',
    url: getDismissNotificationUrl(id).toString(),
    ...requestConfig,
  })
  return res.data
}