import { multiplePublicErrorsOutALREADYSIGNEDSchema } from './multiplePublicErrorsOutALREADYSIGNEDSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const deleteContractProposalPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description No Content
 */
export const deleteContractProposal204Schema = z.unknown()

/**
 * @description Not Found
 */
export const deleteContractProposal404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const deleteContractProposal422Schema = z.union([z.lazy(() => paramValidationErrorOutSchema), z.lazy(() => multiplePublicErrorsOutALREADYSIGNEDSchema)])

export const deleteContractProposalMutationResponseSchema = z.lazy(() => deleteContractProposal204Schema)