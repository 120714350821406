import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetPaymentPlanPdfQueryResponse,
  GetPaymentPlanPdfPathParams,
  GetPaymentPlanPdf400,
  GetPaymentPlanPdf404,
  GetPaymentPlanPdf422,
} from '../types/GetPaymentPlanPdf.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getPaymentPlanPdf } from '../clients/getPaymentPlanPdf.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getPaymentPlanPdfQueryKey = (id: GetPaymentPlanPdfPathParams['id']) =>
  [{ url: '/api-internal/payment_plans/:id/pdf', params: { id: id } }] as const

export type GetPaymentPlanPdfQueryKey = ReturnType<typeof getPaymentPlanPdfQueryKey>

export function getPaymentPlanPdfQueryOptions(id: GetPaymentPlanPdfPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getPaymentPlanPdfQueryKey(id)
  return queryOptions<
    GetPaymentPlanPdfQueryResponse,
    ResponseErrorConfig<GetPaymentPlanPdf400 | GetPaymentPlanPdf404 | GetPaymentPlanPdf422>,
    GetPaymentPlanPdfQueryResponse,
    typeof queryKey
  >({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getPaymentPlanPdf(id, config)
    },
  })
}

/**
 * @summary Get Payment Plan Pdf
 * {@link /api-internal/payment_plans/:id/pdf}
 */
export function useGetPaymentPlanPdf<
  TData = GetPaymentPlanPdfQueryResponse,
  TQueryData = GetPaymentPlanPdfQueryResponse,
  TQueryKey extends QueryKey = GetPaymentPlanPdfQueryKey,
>(
  id: GetPaymentPlanPdfPathParams['id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetPaymentPlanPdfQueryResponse,
        ResponseErrorConfig<GetPaymentPlanPdf400 | GetPaymentPlanPdf404 | GetPaymentPlanPdf422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getPaymentPlanPdfQueryKey(id)

  const query = useQuery({
    ...(getPaymentPlanPdfQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetPaymentPlanPdf400 | GetPaymentPlanPdf404 | GetPaymentPlanPdf422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}