import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListSepaMandatesQueryResponse, ListSepaMandatesQueryParams, ListSepaMandates404, ListSepaMandates422 } from '../types/ListSepaMandates.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { listSepaMandates } from '../clients/listSepaMandates.ts'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listSepaMandatesInfiniteQueryKey = (params: ListSepaMandatesQueryParams) =>
  [{ url: '/api-internal/sepa_mandates/' }, ...(params ? [params] : [])] as const

export type ListSepaMandatesInfiniteQueryKey = ReturnType<typeof listSepaMandatesInfiniteQueryKey>

export function listSepaMandatesInfiniteQueryOptions(params: ListSepaMandatesQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listSepaMandatesInfiniteQueryKey(params)
  return infiniteQueryOptions<
    ListSepaMandatesQueryResponse,
    ResponseErrorConfig<ListSepaMandates404 | ListSepaMandates422>,
    ListSepaMandatesQueryResponse,
    typeof queryKey,
    number
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListSepaMandatesQueryParams['page']
      }
      return listSepaMandates(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Sepa Mandates
 * {@link /api-internal/sepa_mandates/}
 */
export function useListSepaMandatesInfinite<
  TData = InfiniteData<ListSepaMandatesQueryResponse>,
  TQueryData = ListSepaMandatesQueryResponse,
  TQueryKey extends QueryKey = ListSepaMandatesInfiniteQueryKey,
>(
  params: ListSepaMandatesQueryParams,
  options: {
    query?: Partial<
      InfiniteQueryObserverOptions<ListSepaMandatesQueryResponse, ResponseErrorConfig<ListSepaMandates404 | ListSepaMandates422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listSepaMandatesInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listSepaMandatesInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ResponseErrorConfig<ListSepaMandates404 | ListSepaMandates422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}