// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetLetterPdfsAsZipQueryResponse,
  GetLetterPdfsAsZipQueryParams,
  GetLetterPdfsAsZip400,
  GetLetterPdfsAsZip404,
  GetLetterPdfsAsZip422,
} from '../types/GetLetterPdfsAsZip.ts'

export function getGetLetterPdfsAsZipUrl() {
  return `/api-internal/letters/pdfs` as const
}

/**
 * @summary Get Letter Pdfs As Zip
 * {@link /api-internal/letters/pdfs}
 */
export async function getLetterPdfsAsZip(params: GetLetterPdfsAsZipQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetLetterPdfsAsZipQueryResponse,
    ResponseErrorConfig<GetLetterPdfsAsZip400 | GetLetterPdfsAsZip404 | GetLetterPdfsAsZip422>,
    unknown
  >({ method: 'GET', url: getGetLetterPdfsAsZipUrl().toString(), params, ...requestConfig })
  return res.data
}