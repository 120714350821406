// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { CountCustomersQueryResponse, CountCustomersQueryParams, CountCustomers404, CountCustomers422 } from '../types/CountCustomers.ts'

export function getCountCustomersUrl() {
  return `/api-internal/customers/count` as const
}

/**
 * @summary Count Customers
 * {@link /api-internal/customers/count}
 */
export async function countCustomers(params?: CountCustomersQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CountCustomersQueryResponse, ResponseErrorConfig<CountCustomers404 | CountCustomers422>, unknown>({
    method: 'GET',
    url: getCountCustomersUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}