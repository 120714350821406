import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListPlansQueryResponse, ListPlansQueryParams, ListPlans404, ListPlans422 } from '../types/ListPlans.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listPlans } from '../clients/listPlans.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listPlansQueryKey = (params: ListPlansQueryParams) => [{ url: '/api-internal/plans/' }, ...(params ? [params] : [])] as const

export type ListPlansQueryKey = ReturnType<typeof listPlansQueryKey>

export function listPlansQueryOptions(params: ListPlansQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listPlansQueryKey(params)
  return queryOptions<ListPlansQueryResponse, ResponseErrorConfig<ListPlans404 | ListPlans422>, ListPlansQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listPlans(params, config)
    },
  })
}

/**
 * @summary List Plans
 * {@link /api-internal/plans/}
 */
export function useListPlans<TData = ListPlansQueryResponse, TQueryData = ListPlansQueryResponse, TQueryKey extends QueryKey = ListPlansQueryKey>(
  params: ListPlansQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<ListPlansQueryResponse, ResponseErrorConfig<ListPlans404 | ListPlans422>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listPlansQueryKey(params)

  const query = useQuery({
    ...(listPlansQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListPlans404 | ListPlans422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}