// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  RealizeContractProposalMutationRequest,
  RealizeContractProposalMutationResponse,
  RealizeContractProposalPathParams,
  RealizeContractProposal404,
  RealizeContractProposal422,
} from '../types/RealizeContractProposal.ts'

export function getRealizeContractProposalUrl(id: RealizeContractProposalPathParams['id']) {
  return `/api-internal/contract_proposals/${id}/realize` as const
}

/**
 * @summary Realize Contract Proposal
 * {@link /api-internal/contract_proposals/:id/realize}
 */
export async function realizeContractProposal(
  id: RealizeContractProposalPathParams['id'],
  data: RealizeContractProposalMutationRequest,
  config: Partial<RequestConfig<RealizeContractProposalMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    RealizeContractProposalMutationResponse,
    ResponseErrorConfig<RealizeContractProposal404 | RealizeContractProposal422>,
    RealizeContractProposalMutationRequest
  >({ method: 'POST', url: getRealizeContractProposalUrl(id).toString(), data, ...requestConfig })
  return res.data
}