import { s3FileOutSchema } from './s3FileOutSchema.ts'
import { z } from 'zod'

export const projectTenantAcquisitionConfigurationOutSchema = z.object({
  enable_email_delivery: z.boolean().default(false),
  enable_postal_delivery: z.boolean().default(false),
  energy_mix_direct_consumption: z.union([z.string(), z.number()]).default(0.4),
  logo: z.union([z.lazy(() => s3FileOutSchema), z.null()]).optional(),
  panel_selection_helper_image: z.union([z.lazy(() => s3FileOutSchema), z.null()]).optional(),
  payment_plan_amount_maximum: z.union([z.string(), z.number()]).default(500),
  payment_plan_amount_minimum: z.union([z.string(), z.number()]).default(30),
})