import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListPaymentPlansQueryResponse, ListPaymentPlansQueryParams, ListPaymentPlans404, ListPaymentPlans422 } from '../types/ListPaymentPlans.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listPaymentPlans } from '../clients/listPaymentPlans.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listPaymentPlansQueryKey = (params: ListPaymentPlansQueryParams) => [{ url: '/api-internal/payment_plans/' }, ...(params ? [params] : [])] as const

export type ListPaymentPlansQueryKey = ReturnType<typeof listPaymentPlansQueryKey>

export function listPaymentPlansQueryOptions(params: ListPaymentPlansQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listPaymentPlansQueryKey(params)
  return queryOptions<
    ListPaymentPlansQueryResponse,
    ResponseErrorConfig<ListPaymentPlans404 | ListPaymentPlans422>,
    ListPaymentPlansQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listPaymentPlans(params, config)
    },
  })
}

/**
 * @summary List Payment Plans
 * {@link /api-internal/payment_plans/}
 */
export function useListPaymentPlans<
  TData = ListPaymentPlansQueryResponse,
  TQueryData = ListPaymentPlansQueryResponse,
  TQueryKey extends QueryKey = ListPaymentPlansQueryKey,
>(
  params: ListPaymentPlansQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ListPaymentPlansQueryResponse, ResponseErrorConfig<ListPaymentPlans404 | ListPaymentPlans422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listPaymentPlansQueryKey(params)

  const query = useQuery({
    ...(listPaymentPlansQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListPaymentPlans404 | ListPaymentPlans422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}