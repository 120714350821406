import { useGetProject } from '@mg/api-wrappers/src/api-internal';
import { Box, Chip } from '@mui/material';
import { LifeBuoy, Settings, Sparkles } from 'lucide-react';
import { useState } from 'react';
import { useUserInfo } from '../../../helpers/userInfo.ts';
import { useCurrentProject } from '../../../hooks/useCurrentProject.ts';
import { useTranslation } from '../../../i18n';
import { FeedbackLinkButton } from '../../shared/FeedbackLinkButton';
import { SidebarItemLink } from './Sidebar.Item.Link';
import { SidebarSettingsMenu } from './Sidebar.Settings.Menu';

export const SidebarSettings = () => {
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const { t } = useTranslation('sidebar');
  const currentProjectId = useCurrentProject();
  const projectQuery = useGetProject(currentProjectId);
  const currentProject = projectQuery.data;
  const [user] = useUserInfo();
  const userEmail = user?.email || '';
  const projectName = currentProject?.name || currentProject?.location || '';
  return (
    <Box width={'100%'}>
      <FeedbackLinkButton />
      <SidebarItemLink
        icon={<Sparkles size={20} />}
        text={t('route_release_notes')}
        href="https://www.metergrid.de/produkte/release-notes"
        target="_blank"
      />
      <SidebarItemLink
        icon={<LifeBuoy size={20} />}
        text={
          <>
            {t('sidebar_help')}
            <Box component="span" sx={{ ml: 1 }}>
              <Chip
                size={'small'}
                variant={'soft'}
                color={'primary'}
                label={t('new_capital')}
                sx={{ borderRadius: '8px', fontSize: '0.66rem', height: '18px' }}
              />
            </Box>
          </>
        }
        href={`https://www.metergrid.de/help-center/kundensupport?project_id=${encodeURIComponent(currentProjectId)}&user_email=${encodeURIComponent(userEmail)}&project_name=${encodeURIComponent(projectName)}`}
        target="_blank"
      ></SidebarItemLink>
      <SidebarItemLink
        id={'settings-menu-anchor'}
        icon={<Settings size={20} />}
        text={t('settings')}
        onClick={() => setSettingsMenuOpen(true)}
      />

      <SidebarSettingsMenu open={settingsMenuOpen} onToggle={() => setSettingsMenuOpen(false)} />
    </Box>
  );
};
