import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { BillingMutationRequest, BillingMutationResponse, Billing400, Billing404, Billing422 } from '../types/Billing.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { billing } from '../clients/billing.ts'
import { useMutation } from '@tanstack/react-query'

export const billingMutationKey = () => [{ url: '/api-internal/billing/billing' }] as const

export type BillingMutationKey = ReturnType<typeof billingMutationKey>

/**
 * @summary Billing
 * {@link /api-internal/billing/billing}
 */
export function useBilling(
  options: {
    mutation?: UseMutationOptions<BillingMutationResponse, ResponseErrorConfig<Billing400 | Billing404 | Billing422>, { data?: BillingMutationRequest }>
    client?: Partial<RequestConfig<BillingMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? billingMutationKey()

  return useMutation<BillingMutationResponse, ResponseErrorConfig<Billing400 | Billing404 | Billing422>, { data?: BillingMutationRequest }>({
    mutationFn: async ({ data }) => {
      return billing(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}