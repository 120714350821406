import { levyStructureYearOutSchema } from './levyStructureYearOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const getDefaultLevyYears200Schema = z.lazy(() => levyStructureYearOutSchema)

/**
 * @description Not Found
 */
export const getDefaultLevyYears404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getDefaultLevyYears422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getDefaultLevyYearsQueryResponseSchema = z.lazy(() => getDefaultLevyYears200Schema)