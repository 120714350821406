import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ExportTransactionsQueryResponse, ExportTransactions400, ExportTransactions404, ExportTransactions422 } from '../types/ExportTransactions.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { exportTransactions } from '../clients/exportTransactions.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const exportTransactionsQueryKey = () => [{ url: '/api-internal/transactions/export' }] as const

export type ExportTransactionsQueryKey = ReturnType<typeof exportTransactionsQueryKey>

export function exportTransactionsQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = exportTransactionsQueryKey()
  return queryOptions<
    ExportTransactionsQueryResponse,
    ResponseErrorConfig<ExportTransactions400 | ExportTransactions404 | ExportTransactions422>,
    ExportTransactionsQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return exportTransactions(config)
    },
  })
}

/**
 * @description Export transaction data
 * @summary Export Transactions
 * {@link /api-internal/transactions/export}
 */
export function useExportTransactions<
  TData = ExportTransactionsQueryResponse,
  TQueryData = ExportTransactionsQueryResponse,
  TQueryKey extends QueryKey = ExportTransactionsQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        ExportTransactionsQueryResponse,
        ResponseErrorConfig<ExportTransactions400 | ExportTransactions404 | ExportTransactions422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? exportTransactionsQueryKey()

  const query = useQuery({
    ...(exportTransactionsQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ExportTransactions400 | ExportTransactions404 | ExportTransactions422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}