import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetProjectToOnboardingCompleteMutationResponse,
  SetProjectToOnboardingCompletePathParams,
  SetProjectToOnboardingCompleteQueryParams,
  SetProjectToOnboardingComplete400,
  SetProjectToOnboardingComplete404,
  SetProjectToOnboardingComplete422,
} from '../types/SetProjectToOnboardingComplete.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { setProjectToOnboardingComplete } from '../clients/setProjectToOnboardingComplete.ts'
import { useMutation } from '@tanstack/react-query'

export const setProjectToOnboardingCompleteMutationKey = () => [{ url: '/api-internal/projects/{id}/complete_onboarding' }] as const

export type SetProjectToOnboardingCompleteMutationKey = ReturnType<typeof setProjectToOnboardingCompleteMutationKey>

/**
 * @summary Set Project To Onboarding Complete
 * {@link /api-internal/projects/:id/complete_onboarding}
 */
export function useSetProjectToOnboardingComplete(
  options: {
    mutation?: UseMutationOptions<
      SetProjectToOnboardingCompleteMutationResponse,
      ResponseErrorConfig<SetProjectToOnboardingComplete400 | SetProjectToOnboardingComplete404 | SetProjectToOnboardingComplete422>,
      { id: SetProjectToOnboardingCompletePathParams['id']; params?: SetProjectToOnboardingCompleteQueryParams }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? setProjectToOnboardingCompleteMutationKey()

  return useMutation<
    SetProjectToOnboardingCompleteMutationResponse,
    ResponseErrorConfig<SetProjectToOnboardingComplete400 | SetProjectToOnboardingComplete404 | SetProjectToOnboardingComplete422>,
    { id: SetProjectToOnboardingCompletePathParams['id']; params?: SetProjectToOnboardingCompleteQueryParams }
  >({
    mutationFn: async ({ id, params }) => {
      return setProjectToOnboardingComplete(id, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}