import { MeasuringPointSubscriptionSchema } from '@mg/api-wrappers/src/api-internal';
import { Grid2, Stack } from '@mui/material';
import { MeteringDetailsMeasuringPoint } from './MeteringDetails.MeasuringPoint';
import { MeteringDetailsMeasuringPointData } from './MeteringDetails.MeasuringPointData';
import { MeteringDetailsMeasuringPointPanel } from './MeteringDetails.MeasuringPointPanel';

type Props = {
  panelId: number;
  subscription?: MeasuringPointSubscriptionSchema | null;
};

export const MeteringDetailsOverview = ({ panelId, subscription }: Props) => {
  return (
    <Grid2 container spacing={2} alignItems={'stretch'} justifyContent={'stretch'}>
      <Grid2 size={5}>
        <Stack spacing={2} justifyContent={'space-between'}>
          <MeteringDetailsMeasuringPointPanel panelId={panelId} />
          <MeteringDetailsMeasuringPoint panelId={panelId} />
        </Stack>
      </Grid2>
      <Grid2 size={7}>
        <MeteringDetailsMeasuringPointData panelId={panelId} subscription={subscription} />
      </Grid2>
    </Grid2>
  );
};
