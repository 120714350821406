import { useQueryState } from 'nuqs';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSwitchProjectHook } from '../../../hooks/useSwitchProject.ts';

export const ProjectSwitch = () => {
  const params = useParams();
  const switchProject = useSwitchProjectHook();
  const navigate = useNavigate();
  const [next] = useQueryState('next');

  useEffect(() => {
    if (params.id) {
      switchProject(parseInt(params.id), { redirectTo: next || '/' });
    } else {
      navigate(next || '/', { replace: true });
    }
  }, []);

  return null;
};
