// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { BulkEmailSendMutationRequest, BulkEmailSendMutationResponse, BulkEmailSend404, BulkEmailSend422 } from '../types/BulkEmailSend.ts'

export function getBulkEmailSendUrl() {
  return `/api-internal/document_email/bulk_send` as const
}

/**
 * @summary Bulk Email Send
 * {@link /api-internal/document_email/bulk_send}
 */
export async function bulkEmailSend(
  data: BulkEmailSendMutationRequest,
  config: Partial<RequestConfig<BulkEmailSendMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<BulkEmailSendMutationResponse, ResponseErrorConfig<BulkEmailSend404 | BulkEmailSend422>, BulkEmailSendMutationRequest>({
    method: 'POST',
    url: getBulkEmailSendUrl().toString(),
    data,
    ...requestConfig,
  })
  return res.data
}