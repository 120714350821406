import { multiplePublicErrorsOutUnionCONTRACTPROPOSALALREADYREALIZEDTENANTSIGNATUREMISSINGPROVIDERSIGNATUREMISSINGSchema } from './multiplePublicErrorsOutUnionCONTRACTPROPOSALALREADYREALIZEDTENANTSIGNATUREMISSINGPROVIDERSIGNATUREMISSINGSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { realizeContractProposalInSchema } from './realizeContractProposalInSchema.ts'
import { z } from 'zod'

export const realizeContractProposalPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description No Content
 */
export const realizeContractProposal204Schema = z.unknown()

/**
 * @description Not Found
 */
export const realizeContractProposal404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const realizeContractProposal422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.lazy(() => multiplePublicErrorsOutUnionCONTRACTPROPOSALALREADYREALIZEDTENANTSIGNATUREMISSINGPROVIDERSIGNATUREMISSINGSchema),
])

export const realizeContractProposalMutationRequestSchema = z.lazy(() => realizeContractProposalInSchema)

export const realizeContractProposalMutationResponseSchema = z.lazy(() => realizeContractProposal204Schema)