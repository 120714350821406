import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { renderContractInSchema } from './renderContractInSchema.ts'
import { renderContractMissingDataErrorOutSchema } from './renderContractMissingDataErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const renderContract200Schema = z.unknown()

/**
 * @description Bad Request
 */
export const renderContract400Schema = z.lazy(() => renderContractMissingDataErrorOutSchema)

/**
 * @description Not Found
 */
export const renderContract404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const renderContract422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const renderContractMutationRequestSchema = z.lazy(() => renderContractInSchema)

export const renderContractMutationResponseSchema = z.lazy(() => renderContract200Schema)