// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListLettersQueryResponse, ListLettersQueryParams, ListLetters404, ListLetters422 } from '../types/ListLetters.ts'

export function getListLettersUrl() {
  return `/api-internal/letters/` as const
}

/**
 * @summary List Letters
 * {@link /api-internal/letters/}
 */
export async function listLetters(params: ListLettersQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListLettersQueryResponse, ResponseErrorConfig<ListLetters404 | ListLetters422>, unknown>({
    method: 'GET',
    url: getListLettersUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}