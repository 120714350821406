// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetBillingPreflightDataQueryResponse,
  GetBillingPreflightDataQueryParams,
  GetBillingPreflightData400,
  GetBillingPreflightData404,
  GetBillingPreflightData422,
} from '../types/GetBillingPreflightData.ts'

export function getGetBillingPreflightDataUrl() {
  return `/api-internal/billing/preflight_data` as const
}

/**
 * @summary Get Billing Preflight Data
 * {@link /api-internal/billing/preflight_data}
 */
export async function getBillingPreflightData(params: GetBillingPreflightDataQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetBillingPreflightDataQueryResponse,
    ResponseErrorConfig<GetBillingPreflightData400 | GetBillingPreflightData404 | GetBillingPreflightData422>,
    unknown
  >({ method: 'GET', url: getGetBillingPreflightDataUrl().toString(), params, ...requestConfig })
  return res.data
}