// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListResidualPowerPlansQueryResponse,
  ListResidualPowerPlansQueryParams,
  ListResidualPowerPlans404,
  ListResidualPowerPlans422,
} from '../types/ListResidualPowerPlans.ts'

export function getListResidualPowerPlansUrl() {
  return `/api-internal/residual_power_plans/` as const
}

/**
 * @summary List Residual Power Plans
 * {@link /api-internal/residual_power_plans/}
 */
export async function listResidualPowerPlans(params: ListResidualPowerPlansQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListResidualPowerPlansQueryResponse, ResponseErrorConfig<ListResidualPowerPlans404 | ListResidualPowerPlans422>, unknown>({
    method: 'GET',
    url: getListResidualPowerPlansUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}