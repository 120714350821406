import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getPlanChangeDocumentPdfPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const getPlanChangeDocumentPdf200Schema = z.unknown()

/**
 * @description Bad Request
 */
export const getPlanChangeDocumentPdf400Schema = z.string()

/**
 * @description Not Found
 */
export const getPlanChangeDocumentPdf404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getPlanChangeDocumentPdf422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getPlanChangeDocumentPdfQueryResponseSchema = z.lazy(() => getPlanChangeDocumentPdf200Schema)