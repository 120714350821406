import { Box, TextField as MUITextField, Stack, TextFieldProps, Typography } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { ValidationError } from '@tanstack/react-form';
import { ReactNode } from 'react';
import { palette } from '../../../styles';
import { MGFieldError } from './MGField.Error';
import { getHighlightOutlineInputSxForColor } from './MGField.HighlightOutlinedInputSx';

export type MGFieldTextProps = {
  name: string;
  value: string;
  label?: ReactNode;
  errors: ValidationError[];
  highlight?: boolean;
  TextFieldProps?: TextFieldProps;
  onChange: InputBaseProps['onChange'];
  onBlur: InputBaseProps['onBlur'];
};

export const MGFieldText = ({
  name,
  label,
  errors = [],
  value,
  highlight,
  TextFieldProps,
  onChange,
  onBlur,
}: MGFieldTextProps) => {
  return (
    <Stack spacing={1}>
      {label && (
        <Typography variant={'small'} fontWeight={300}>
          {label}
        </Typography>
      )}
      <Stack sx={highlight ? getHighlightOutlineInputSxForColor(palette.primary) : undefined}>
        <MUITextField
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={errors.length > 0}
          {...TextFieldProps}
        />

        <Box minHeight={'12px'}>
          <MGFieldError errors={errors} />
        </Box>
      </Stack>
    </Stack>
  );
};
