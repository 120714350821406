// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  BulkEmailPreflightMutationRequest,
  BulkEmailPreflightMutationResponse,
  BulkEmailPreflightQueryParams,
  BulkEmailPreflight404,
  BulkEmailPreflight422,
} from '../types/BulkEmailPreflight.ts'

export function getBulkEmailPreflightUrl() {
  return `/api-internal/document_email/bulk_preflight` as const
}

/**
 * @summary Bulk Email Preflight
 * {@link /api-internal/document_email/bulk_preflight}
 */
export async function bulkEmailPreflight(
  data?: BulkEmailPreflightMutationRequest,
  params?: BulkEmailPreflightQueryParams,
  config: Partial<RequestConfig<BulkEmailPreflightMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    BulkEmailPreflightMutationResponse,
    ResponseErrorConfig<BulkEmailPreflight404 | BulkEmailPreflight422>,
    BulkEmailPreflightMutationRequest
  >({ method: 'POST', url: getBulkEmailPreflightUrl().toString(), params, data, ...requestConfig })
  return res.data
}