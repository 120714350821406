import { envVars } from '@mg/ui/src/envVars';
import { axiosInstance } from '@mg/ui/src/kubbClient.ts';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { useAtom, useAtomValue } from 'jotai/index';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, currentProjectIdAtom, refreshMessagesAtom } from '../../jotai/atoms';
import { Loader } from './Loader/Loader';

type Props = {
  pathToDjangoForm: string;
  redirectPath?: string;
  prefill?: { id: string; value: string | number }[];
  hideTitle?: boolean;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
};

export const DjangoForm = ({ pathToDjangoForm, onSuccess, onError, prefill = [], hideTitle }: Props) => {
  const [loading, setLoading] = useState(true);
  const [innerHTML, setInnerHTML] = useState<{ __html: string }>({ __html: '' });
  const [messageRefreshCounter, setMessageRefreshCounter] = useAtom(refreshMessagesAtom);
  const token = useAtomValue(accessTokenAtom);
  const submitHandlerRegistered = useRef(false);

  const navigate = useNavigate();

  const currentProjectId = useAtomValue(currentProjectIdAtom);

  const iframeUrl = useMemo(() => {
    const djangoUrl = new URL(pathToDjangoForm, window.location.origin);
    djangoUrl.searchParams.set('__project_id__', currentProjectId!.toString());
    djangoUrl.searchParams.set('__no_redirect__', 'true');
    return `${envVars.VITE_BACKEND_HOST}/iframe${djangoUrl.pathname}?${djangoUrl.searchParams.toString()}`;
  }, [pathToDjangoForm, currentProjectId]);

  useEffect(() => {
    axiosInstance
      .get(iframeUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        createAndFormatElement(res.data);
      });
  }, [iframeUrl]);

  // Prefill form with data
  useEffect(() => {
    const form = document.getElementsByTagName('form')[0];
    if (form) {
      for (const _prefillValue of prefill) {
        const input = form.querySelector(`[id="${_prefillValue.id}"]`) as HTMLInputElement;
        if (input) {
          if (input.type === 'select-one') {
            const option = input.querySelector(`option[value="${_prefillValue.value}"]`) as HTMLOptionElement;
            if (option) option.selected = true;
          } else {
            input.value = `${_prefillValue.value}`;
          }
        }
      }
    }
  }, [prefill, innerHTML]);

  const createAndFormatElement = (htmlAsString: string) => {
    const element = document.createElement('html');
    element.innerHTML = htmlAsString;

    if (element.getElementsByTagName('form').length > 0) {
      element.getElementsByClassName('btn-link hover-danger')[0].remove();
      if (hideTitle) {
        element.getElementsByClassName('main_title')[0].remove();
      }
      const newElement = element.getElementsByClassName('react-usable-content')[0];
      setInnerHTML({ __html: newElement.innerHTML });
    } else {
      onSuccess ? onSuccess() : navigate(-1);
    }
    setLoading(false);
  };

  const addOnSubmitHandlerToForm = useCallback((element: Element) => {
    if (element && !submitHandlerRegistered.current) {
      const form = element.getElementsByTagName('form')[0];
      if (!form) return;
      form.method = '';
      element.addEventListener('submit', (e) => {
        e.preventDefault();
        if (e.target === null) return;

        setLoading(true);
        const data = Object.fromEntries(new FormData(e.target as HTMLFormElement).entries());
        axiosInstance
          .postForm(iframeUrl, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            createAndFormatElement(res.data);
          })
          .catch(onError)
          .finally(() => setMessageRefreshCounter(messageRefreshCounter + 1));
      });
      submitHandlerRegistered.current = true;
    }
  }, []);

  return (
    <Box sx={{ height: 'calc(100% - 50px)' }}>
      {!innerHTML.__html || loading ? (
        <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </Box>
      ) : (
        <Box paddingBottom={'20px'} ref={addOnSubmitHandlerToForm} dangerouslySetInnerHTML={innerHTML} />
      )}
    </Box>
  );
};
