import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListTransactionsQueryResponse, ListTransactionsQueryParams, ListTransactions404, ListTransactions422 } from '../types/ListTransactions.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listTransactions } from '../clients/listTransactions.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listTransactionsQueryKey = (params?: ListTransactionsQueryParams) => [{ url: '/api-internal/transactions/' }, ...(params ? [params] : [])] as const

export type ListTransactionsQueryKey = ReturnType<typeof listTransactionsQueryKey>

export function listTransactionsQueryOptions(params?: ListTransactionsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listTransactionsQueryKey(params)
  return queryOptions<
    ListTransactionsQueryResponse,
    ResponseErrorConfig<ListTransactions404 | ListTransactions422>,
    ListTransactionsQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listTransactions(params, config)
    },
  })
}

/**
 * @summary List Transactions
 * {@link /api-internal/transactions/}
 */
export function useListTransactions<
  TData = ListTransactionsQueryResponse,
  TQueryData = ListTransactionsQueryResponse,
  TQueryKey extends QueryKey = ListTransactionsQueryKey,
>(
  params?: ListTransactionsQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ListTransactionsQueryResponse, ResponseErrorConfig<ListTransactions404 | ListTransactions422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listTransactionsQueryKey(params)

  const query = useQuery({
    ...(listTransactionsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListTransactions404 | ListTransactions422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}