import { customerSchemaSchema } from './customerSchemaSchema.ts'
import { measuringPointDataNestedSchemaSchema } from './measuringPointDataNestedSchemaSchema.ts'
import { measuringPointDataSchemaSchema } from './measuringPointDataSchemaSchema.ts'
import { measuringPointPanelNestedSchemaSchema } from './measuringPointPanelNestedSchemaSchema.ts'
import { OBISCODESSchema } from './OBISCODESSchema.ts'
import { PANELTYPESchema } from './PANELTYPESchema.ts'
import { remoteReadingStatusSchema } from './remoteReadingStatusSchema.ts'
import { z } from 'zod'

export const measuringPointSchemaSchema = z.object({
  conversion_factor: z.number().int(),
  customer: z.union([z.lazy(() => customerSchemaSchema), z.null()]).optional(),
  data: z
    .union([
      z
        .object({})
        .strict()
        .catchall(z.array(z.lazy(() => measuringPointDataNestedSchemaSchema))),
      z.null(),
    ])
    .optional(),
  id: z.number().int(),
  installation_datetime: z.union([z.string().datetime(), z.null()]).describe('The timestamp when the measuring point was installed').optional(),
  is_read_remotely: z.boolean(),
  last_reading: z.union([z.array(z.lazy(() => measuringPointDataNestedSchemaSchema)), z.null()]).optional(),
  manual_data: z.union([z.array(z.lazy(() => measuringPointDataSchemaSchema)), z.null()]).optional(),
  measuring_point_panel: z.union([z.lazy(() => measuringPointPanelNestedSchemaSchema), z.null()]).optional(),
  obis_codes: z.array(z.lazy(() => OBISCODESSchema)),
  owner_id: z.union([z.number().int(), z.null()]),
  panel_id: z.number().int(),
  remote_reading_status: z.array(z.lazy(() => remoteReadingStatusSchema)),
  removal_datetime: z.union([z.string().datetime(), z.null()]).describe('The timestamp when the measuring point was removed').optional(),
  serial: z.string(),
  type: z.lazy(() => PANELTYPESchema),
})