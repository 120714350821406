// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetDefaultLevyYearsQueryResponse, GetDefaultLevyYears404, GetDefaultLevyYears422 } from '../types/GetDefaultLevyYears.ts'

export function getGetDefaultLevyYearsUrl() {
  return `/api-internal/levy_structures/available_default_levy_years` as const
}

/**
 * @summary Get Default Levy Years
 * {@link /api-internal/levy_structures/available_default_levy_years}
 */
export async function getDefaultLevyYears(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetDefaultLevyYearsQueryResponse, ResponseErrorConfig<GetDefaultLevyYears404 | GetDefaultLevyYears422>, unknown>({
    method: 'GET',
    url: getGetDefaultLevyYearsUrl().toString(),
    ...requestConfig,
  })
  return res.data
}