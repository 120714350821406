import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetProjectQueryResponse, GetProjectPathParams, GetProjectQueryParams, GetProject404, GetProject422 } from '../types/GetProject.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getProject } from '../clients/getProject.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getProjectQueryKey = (id: GetProjectPathParams['id'], params?: GetProjectQueryParams) =>
  [{ url: '/api-internal/projects/:id', params: { id: id } }, ...(params ? [params] : [])] as const

export type GetProjectQueryKey = ReturnType<typeof getProjectQueryKey>

export function getProjectQueryOptions(
  id: GetProjectPathParams['id'],
  params?: GetProjectQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getProjectQueryKey(id, params)
  return queryOptions<GetProjectQueryResponse, ResponseErrorConfig<GetProject404 | GetProject422>, GetProjectQueryResponse, typeof queryKey>({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getProject(id, params, config)
    },
  })
}

/**
 * @summary Get Project
 * {@link /api-internal/projects/:id}
 */
export function useGetProject<TData = GetProjectQueryResponse, TQueryData = GetProjectQueryResponse, TQueryKey extends QueryKey = GetProjectQueryKey>(
  id: GetProjectPathParams['id'],
  params?: GetProjectQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetProjectQueryResponse, ResponseErrorConfig<GetProject404 | GetProject422>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getProjectQueryKey(id, params)

  const query = useQuery({
    ...(getProjectQueryOptions(id, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetProject404 | GetProject422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}