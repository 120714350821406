import { PaletteColor } from '@mui/material';

export const getHighlightOutlineInputSxForColor = (paletteColor: PaletteColor) => {
  return {
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '11px',
      border: `1px solid ${paletteColor.main}`,
      boxShadow: `0px 0px 0px 3px ${paletteColor.light}`,
    },
  };
};
