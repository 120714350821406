import { ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from '@mui/material';
import { useMolecule } from 'bunshi/react';
import { useAtom } from 'jotai/index';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { palette } from '../../styles/palette';
import { mgActionMenuMolecule } from './mgActionMenuMolecule.tsx';

export type MGActionMenuItemProps = {
  type?: 'action' | 'header';
  text: ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  disabledHint?: ReactNode;
  onClick?: () => void;
  href?: string;
  hidden?: boolean;
};
export const MGActionMenuItem = (props: MGActionMenuItemProps) => {
  const { openAtom } = useMolecule(mgActionMenuMolecule);
  const [menuOpen, setMenuOpen] = useAtom(openAtom);

  if (props.type === 'header') {
    return (
      <MenuItem
        component="span"
        sx={{
          width: '90%',
          textAlign: 'left',
          ':hover': { background: 'initial' },
          cursor: 'default',
          paddingBottom: 0,
          marginBottom: 0,
          marginTop: 2.5,
        }}
        disableRipple
      >
        <Typography variant={'extraSmall'} color={palette.primary.main}>
          {props.text}
        </Typography>
      </MenuItem>
    );
  }

  return (
    <Tooltip title={props.disabled ? props.disabledHint : ''} arrow>
      <span>
        <MenuItem
          sx={{ width: '90%', textAlign: 'left' }}
          to={props.href}
          component={props.href ? Link : 'button'}
          onClick={() => {
            setMenuOpen(false);
            if (props.onClick) {
              props.onClick();
            }
          }}
          disabled={props.disabled}
        >
          <ListItemIcon>{props.icon}</ListItemIcon>
          <ListItemText>{props.text}</ListItemText>
        </MenuItem>
      </span>
    </Tooltip>
  );
};
