import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { CountLettersQueryResponse, CountLettersQueryParams, CountLetters404, CountLetters422 } from '../types/CountLetters.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { countLetters } from '../clients/countLetters.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countLettersQueryKey = (params: CountLettersQueryParams) => [{ url: '/api-internal/letters/count' }, ...(params ? [params] : [])] as const

export type CountLettersQueryKey = ReturnType<typeof countLettersQueryKey>

export function countLettersQueryOptions(params: CountLettersQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = countLettersQueryKey(params)
  return queryOptions<CountLettersQueryResponse, ResponseErrorConfig<CountLetters404 | CountLetters422>, CountLettersQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countLetters(params, config)
    },
  })
}

/**
 * @summary Count Letters
 * {@link /api-internal/letters/count}
 */
export function useCountLetters<TData = CountLettersQueryResponse, TQueryData = CountLettersQueryResponse, TQueryKey extends QueryKey = CountLettersQueryKey>(
  params: CountLettersQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<CountLettersQueryResponse, ResponseErrorConfig<CountLetters404 | CountLetters422>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countLettersQueryKey(params)

  const query = useQuery({
    ...(countLettersQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<CountLetters404 | CountLetters422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}