import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ExportMeasuringPointDataQueryResponse,
  ExportMeasuringPointDataQueryParams,
  ExportMeasuringPointData400,
  ExportMeasuringPointData404,
  ExportMeasuringPointData422,
} from '../types/ExportMeasuringPointData.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { exportMeasuringPointData } from '../clients/exportMeasuringPointData.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const exportMeasuringPointDataQueryKey = (params: ExportMeasuringPointDataQueryParams) =>
  [{ url: '/api-internal/measuring_point_data/export' }, ...(params ? [params] : [])] as const

export type ExportMeasuringPointDataQueryKey = ReturnType<typeof exportMeasuringPointDataQueryKey>

export function exportMeasuringPointDataQueryOptions(
  params: ExportMeasuringPointDataQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = exportMeasuringPointDataQueryKey(params)
  return queryOptions<
    ExportMeasuringPointDataQueryResponse,
    ResponseErrorConfig<ExportMeasuringPointData400 | ExportMeasuringPointData404 | ExportMeasuringPointData422>,
    ExportMeasuringPointDataQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return exportMeasuringPointData(params, config)
    },
  })
}

/**
 * @description Export customer data
 * @summary Export Measuring Point Data
 * {@link /api-internal/measuring_point_data/export}
 */
export function useExportMeasuringPointData<
  TData = ExportMeasuringPointDataQueryResponse,
  TQueryData = ExportMeasuringPointDataQueryResponse,
  TQueryKey extends QueryKey = ExportMeasuringPointDataQueryKey,
>(
  params: ExportMeasuringPointDataQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ExportMeasuringPointDataQueryResponse,
        ResponseErrorConfig<ExportMeasuringPointData400 | ExportMeasuringPointData404 | ExportMeasuringPointData422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? exportMeasuringPointDataQueryKey(params)

  const query = useQuery({
    ...(exportMeasuringPointDataQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ExportMeasuringPointData400 | ExportMeasuringPointData404 | ExportMeasuringPointData422>> & {
    queryKey: TQueryKey
  }

  query.queryKey = queryKey as TQueryKey

  return query
}