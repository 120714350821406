// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListPlanChangeDocumentsQueryResponse,
  ListPlanChangeDocumentsQueryParams,
  ListPlanChangeDocuments404,
  ListPlanChangeDocuments422,
} from '../types/ListPlanChangeDocuments.ts'

export function getListPlanChangeDocumentsUrl() {
  return `/api-internal/plan_change_documents/` as const
}

/**
 * @summary List Plan Change Documents
 * {@link /api-internal/plan_change_documents/}
 */
export async function listPlanChangeDocuments(params: ListPlanChangeDocumentsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListPlanChangeDocumentsQueryResponse, ResponseErrorConfig<ListPlanChangeDocuments404 | ListPlanChangeDocuments422>, unknown>({
    method: 'GET',
    url: getListPlanChangeDocumentsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}