import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListSepaTransactionsQueryResponse,
  ListSepaTransactionsQueryParams,
  ListSepaTransactions404,
  ListSepaTransactions422,
} from '../types/ListSepaTransactions.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listSepaTransactions } from '../clients/listSepaTransactions.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listSepaTransactionsQueryKey = (params?: ListSepaTransactionsQueryParams) =>
  [{ url: '/api-internal/transactions/sepa_transactions' }, ...(params ? [params] : [])] as const

export type ListSepaTransactionsQueryKey = ReturnType<typeof listSepaTransactionsQueryKey>

export function listSepaTransactionsQueryOptions(params?: ListSepaTransactionsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listSepaTransactionsQueryKey(params)
  return queryOptions<
    ListSepaTransactionsQueryResponse,
    ResponseErrorConfig<ListSepaTransactions404 | ListSepaTransactions422>,
    ListSepaTransactionsQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listSepaTransactions(params, config)
    },
  })
}

/**
 * @summary List Sepa Transactions
 * {@link /api-internal/transactions/sepa_transactions}
 */
export function useListSepaTransactions<
  TData = ListSepaTransactionsQueryResponse,
  TQueryData = ListSepaTransactionsQueryResponse,
  TQueryKey extends QueryKey = ListSepaTransactionsQueryKey,
>(
  params?: ListSepaTransactionsQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ListSepaTransactionsQueryResponse,
        ResponseErrorConfig<ListSepaTransactions404 | ListSepaTransactions422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listSepaTransactionsQueryKey(params)

  const query = useQuery({
    ...(listSepaTransactionsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListSepaTransactions404 | ListSepaTransactions422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}