import { useListCustomers } from '@mg/api-wrappers/src/api-internal';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useAtom } from 'jotai/index';
import { useEffect, useState } from 'react';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterModalOpenAtom, filterValueAtom } from '../../../jotai/actionBar';
import { AccountingCustomFilters } from './Accounting.types';

const FORM_CUSTOMER_SELECT_BLANK_VALUE = '_blank';

export const AccountingFilterForm = () => {
  const [selectValue, setSelectValue] = useState<string>(FORM_CUSTOMER_SELECT_BLANK_VALUE);
  const [startDateValue, setStartDateValue] = useState<dayjs.Dayjs | null>(null);
  const [endDateValue, setEndDateValue] = useState<dayjs.Dayjs | null>(null);

  const [filter, setFilterValue] = useAtom(filterValueAtom);
  const [, setFilterModalOpen] = useAtom(filterModalOpenAtom);
  const projectFilter = useSelectedProjectsFilter();
  const customersQuery = useListCustomers({ ...projectFilter, skip_pagination: true });
  const { t } = useTranslation('transactions');

  /**
   * Transfers form state into a filter object
   */
  const serialize: () => AccountingCustomFilters | Record<string, never> = () => {
    const customerId = parseInt(selectValue);

    const filter: AccountingCustomFilters = {};
    if (customerId) filter.customer = customerId;
    if (startDateValue) filter.time_range_from = startDateValue.toDate().toISOString();
    if (endDateValue) filter.time_range_to = endDateValue.toDate().toISOString();

    return filter;
  };

  /**
   * Transfers a filter object into the form state
   * @param filter
   */
  const deserialize: (filter?: AccountingCustomFilters) => void = (filter) => {
    if (filter?.customer) setSelectValue(filter.customer + '');
    if (filter?.time_range_from) setStartDateValue(dayjs(new Date(filter.time_range_from)));
    if (filter?.time_range_to) setEndDateValue(dayjs(new Date(filter.time_range_to)));
  };

  const handleSubmitButtonClick = () => {
    setFilterModalOpen(false);
    setFilterValue(serialize());
  };

  /**
   * Restore form state from filter when existing
   */
  useEffect(() => {
    deserialize(filter);
  }, [filter]);

  const createElementId = (id: string) => `accounting-filter-modal-${id}`;

  return (
    <Stack direction="column" gap={4} justifyContent="space-between" height="100%">
      <Box>
        <Typography variant="h2" fontWeight="500" mb={2}>
          {t('filter_modal_title')}
        </Typography>
        <Stack gap={2}>
          <Stack gap={2}>
            <Typography>{t('filter_modal_customer_title')}</Typography>
            <FormControl fullWidth>
              <InputLabel id={createElementId('select-customer-label')}>
                {t('filter_modal_customer_input_label')}
              </InputLabel>
              <Select
                labelId={createElementId('select-customer-label')}
                id={createElementId('select-customer')}
                value={selectValue}
                label={t('filter_modal_customer_input_label')}
                onChange={(e) => setSelectValue(e.target.value)}
              >
                <MenuItem value={FORM_CUSTOMER_SELECT_BLANK_VALUE}>
                  {t('filter_modal_customer_blank_option_label')}
                </MenuItem>
                {customersQuery?.data?.items
                  .sort((a, b) => {
                    if (!a.display_name && !b.display_name) return 0;
                    if (!a.display_name && b.display_name) return -1;
                    if (a.display_name && !b.display_name) return 1;

                    // When both items have .display_name
                    if (a.display_name! < b.display_name!) return -1;
                    if (a.display_name! > b.display_name!) return 1;
                    return 0;
                  })
                  .map(({ id, display_name }, index) => (
                    <MenuItem key={id} value={id ?? index}>
                      {display_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack gap={2}>
            <Typography mt={1}>{t('filter_modal_date_range_title')}</Typography>
            <Stack direction="row" gap={1} alignItems="center">
              <FormControl fullWidth>
                <DatePicker
                  value={startDateValue}
                  label={t('filter_modal_date_range_start')}
                  onChange={(value) => setStartDateValue(value)}
                />
              </FormControl>
              <Typography>-</Typography>
              <FormControl fullWidth>
                <DatePicker
                  value={endDateValue}
                  label={t('filter_modal_date_range_end')}
                  onChange={(value) => setEndDateValue(value)}
                />
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Stack flexDirection="row" gap={2} justifyContent="end">
        <Button
          variant="outlined"
          color="gray"
          onClick={(e) => {
            e.preventDefault();
            setFilterModalOpen(false);
          }}
        >
          {t('filter_modal_cancel_button')}
        </Button>
        <Button variant={'contained'} onClick={handleSubmitButtonClick}>
          {t('filter_modal_apply_filter_button')}
        </Button>
      </Stack>
    </Stack>
  );
};
