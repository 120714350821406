import { messageSchemaSchema } from './messageSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const listMessages200Schema = z.array(z.lazy(() => messageSchemaSchema))

/**
 * @description Not Found
 */
export const listMessages404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const listMessages422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listMessagesQueryResponseSchema = z.lazy(() => listMessages200Schema)