import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListMeasuringPointDataForGraphQueryResponse,
  ListMeasuringPointDataForGraphQueryParams,
  ListMeasuringPointDataForGraph404,
  ListMeasuringPointDataForGraph422,
} from '../types/ListMeasuringPointDataForGraph.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listMeasuringPointDataForGraph } from '../clients/listMeasuringPointDataForGraph.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listMeasuringPointDataForGraphQueryKey = (params?: ListMeasuringPointDataForGraphQueryParams) =>
  [{ url: '/api-internal/measuring_point_data/graph' }, ...(params ? [params] : [])] as const

export type ListMeasuringPointDataForGraphQueryKey = ReturnType<typeof listMeasuringPointDataForGraphQueryKey>

export function listMeasuringPointDataForGraphQueryOptions(
  params?: ListMeasuringPointDataForGraphQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = listMeasuringPointDataForGraphQueryKey(params)
  return queryOptions<
    ListMeasuringPointDataForGraphQueryResponse,
    ResponseErrorConfig<ListMeasuringPointDataForGraph404 | ListMeasuringPointDataForGraph422>,
    ListMeasuringPointDataForGraphQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listMeasuringPointDataForGraph(params, config)
    },
  })
}

/**
 * @summary List Measuring Point Data For Graph
 * {@link /api-internal/measuring_point_data/graph}
 */
export function useListMeasuringPointDataForGraph<
  TData = ListMeasuringPointDataForGraphQueryResponse,
  TQueryData = ListMeasuringPointDataForGraphQueryResponse,
  TQueryKey extends QueryKey = ListMeasuringPointDataForGraphQueryKey,
>(
  params?: ListMeasuringPointDataForGraphQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ListMeasuringPointDataForGraphQueryResponse,
        ResponseErrorConfig<ListMeasuringPointDataForGraph404 | ListMeasuringPointDataForGraph422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listMeasuringPointDataForGraphQueryKey(params)

  const query = useQuery({
    ...(listMeasuringPointDataForGraphQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListMeasuringPointDataForGraph404 | ListMeasuringPointDataForGraph422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}