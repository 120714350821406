import { measuringPointDataNestedSchemaSchema } from './measuringPointDataNestedSchemaSchema.ts'
import { OBISCODESSchema } from './OBISCODESSchema.ts'
import { PANELTYPESchema } from './PANELTYPESchema.ts'
import { remoteReadingStatusSchema } from './remoteReadingStatusSchema.ts'
import { z } from 'zod'

export const measuringPointNestedSchemaSchema = z.object({
  conversion_factor: z.number().int(),
  id: z.number().int(),
  installation_datetime: z.union([z.string().datetime(), z.null()]).describe('The timestamp when the measuring point was installed').optional(),
  is_read_remotely: z.boolean(),
  last_reading: z.union([z.array(z.lazy(() => measuringPointDataNestedSchemaSchema)), z.null()]).optional(),
  obis_codes: z.array(z.lazy(() => OBISCODESSchema)),
  owner_id: z.union([z.number().int(), z.null()]),
  panel_id: z.number().int(),
  remote_reading_status: z.array(z.lazy(() => remoteReadingStatusSchema)),
  removal_datetime: z.union([z.string().datetime(), z.null()]).describe('The timestamp when the measuring point was removed').optional(),
  serial: z.string(),
  type: z.lazy(() => PANELTYPESchema),
})