import { useGetProject } from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { Box, Button, Stack } from '@mui/material';
import { useMolecule } from 'bunshi/react';
import { useAtom, useSetAtom } from 'jotai';
import { ArrowRight, InfoIcon } from 'lucide-react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from '../../../../../feature-flags';
import { useSelectedProjectsFilter } from '../../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../../i18n';
import { ProjectsCurrentProjectCard } from '../../../Projects/Projects.CurrentProjectCard';
import { billingMolecule } from '../../Billing.Atoms';
import { BillingBillingPeriodCard } from './Billing.BillingPeriodCard';

export const BillingBillingPeriod = () => {
  const location = useLocation();
  const { isInitializedAtom } = useMolecule(billingMolecule);
  const setIsInitialized = useSetAtom(isInitializedAtom);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const { billingDebug: isDebug } = useFlags();
  const { t } = useTranslation('billing');
  const navigate = useNavigate();

  const projectFilter = useSelectedProjectsFilter();
  const projectQuery = useGetProject(projectFilter.project_id);

  const { billingCustomersAtom } = useMolecule(billingMolecule);
  const [billingCustomers] = useAtom(billingCustomersAtom);

  isDebug && console.log('[BillingBillingPeriod] billingCustomers', billingCustomers);

  return (
    <Stack spacing={3} minHeight={'calc(100vh - 280px)'} justifyContent={'space-between'}>
      <Stack spacing={3}>
        <ProjectsCurrentProjectCard hideInput />
        {projectQuery.data?.provider?.invoice_type === 'BGB_USTG_2021_COMPLIANT' && (
          <MGAlert
            severity="info"
            icon={<InfoIcon />}
            title={t('hint')}
            subtitle={t('billing_period_easy_billing_process_alert_subtitle')}
          />
        )}
        <BillingBillingPeriodCard />
      </Stack>
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant={'contained'} color={'gray'} onClick={() => navigate(-1)}>
            {t('billing_period_cancel_button')}
          </Button>
          <Button
            variant={'contained'}
            component={Link}
            to={'/customers/billing/metering-data' + (location.search ? location.search : '')}
            endIcon={<ArrowRight size={20} />}
            disabled={!isDebug && !(billingCustomers.length > 0)}
          >
            {t('billing_period_continue_button')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
