// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetTransactionStatisticsQueryResponse,
  GetTransactionStatisticsQueryParams,
  GetTransactionStatistics404,
  GetTransactionStatistics422,
} from '../types/GetTransactionStatistics.ts'

export function getGetTransactionStatisticsUrl() {
  return `/api-internal/transactions/statistics` as const
}

/**
 * @summary Get Transaction Statistics
 * {@link /api-internal/transactions/statistics}
 */
export async function getTransactionStatistics(params?: GetTransactionStatisticsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetTransactionStatisticsQueryResponse, ResponseErrorConfig<GetTransactionStatistics404 | GetTransactionStatistics422>, unknown>({
    method: 'GET',
    url: getGetTransactionStatisticsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}