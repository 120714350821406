import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  RemoveTenantAcquisitionConfigurationPanelSelectionHelperImageMutationResponse,
  RemoveTenantAcquisitionConfigurationPanelSelectionHelperImagePathParams,
  RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage404,
  RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage422,
} from '../types/RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { removeTenantAcquisitionConfigurationPanelSelectionHelperImage } from '../clients/removeTenantAcquisitionConfigurationPanelSelectionHelperImage.ts'
import { useMutation } from '@tanstack/react-query'

export const removeTenantAcquisitionConfigurationPanelSelectionHelperImageMutationKey = () =>
  [{ url: '/api-internal/projects/{project_id}/tenant_acquisition_configuration/panel_selection_helper_image/remove' }] as const

export type RemoveTenantAcquisitionConfigurationPanelSelectionHelperImageMutationKey = ReturnType<
  typeof removeTenantAcquisitionConfigurationPanelSelectionHelperImageMutationKey
>

/**
 * @summary Remove Tenant Acquisition Configuration Panel Selection Helper Image
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration/panel_selection_helper_image/remove}
 */
export function useRemoveTenantAcquisitionConfigurationPanelSelectionHelperImage(
  options: {
    mutation?: UseMutationOptions<
      RemoveTenantAcquisitionConfigurationPanelSelectionHelperImageMutationResponse,
      ResponseErrorConfig<RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage404 | RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage422>,
      { project_id: RemoveTenantAcquisitionConfigurationPanelSelectionHelperImagePathParams['project_id'] }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? removeTenantAcquisitionConfigurationPanelSelectionHelperImageMutationKey()

  return useMutation<
    RemoveTenantAcquisitionConfigurationPanelSelectionHelperImageMutationResponse,
    ResponseErrorConfig<RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage404 | RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage422>,
    { project_id: RemoveTenantAcquisitionConfigurationPanelSelectionHelperImagePathParams['project_id'] }
  >({
    mutationFn: async ({ project_id }) => {
      return removeTenantAcquisitionConfigurationPanelSelectionHelperImage(project_id, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}