// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetSepaMandatePdfsAsZipQueryResponse,
  GetSepaMandatePdfsAsZipQueryParams,
  GetSepaMandatePdfsAsZip400,
  GetSepaMandatePdfsAsZip404,
  GetSepaMandatePdfsAsZip422,
} from '../types/GetSepaMandatePdfsAsZip.ts'

export function getGetSepaMandatePdfsAsZipUrl() {
  return `/api-internal/sepa_mandates/pdfs` as const
}

/**
 * @summary Get Sepa Mandate Pdfs As Zip
 * {@link /api-internal/sepa_mandates/pdfs}
 */
export async function getSepaMandatePdfsAsZip(params: GetSepaMandatePdfsAsZipQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetSepaMandatePdfsAsZipQueryResponse,
    ResponseErrorConfig<GetSepaMandatePdfsAsZip400 | GetSepaMandatePdfsAsZip404 | GetSepaMandatePdfsAsZip422>,
    unknown
  >({ method: 'GET', url: getGetSepaMandatePdfsAsZipUrl().toString(), params, ...requestConfig })
  return res.data
}