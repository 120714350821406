import { addressSchemaSchema } from './addressSchemaSchema.ts'
import { bankAccountSchemaSchema } from './bankAccountSchemaSchema.ts'
import { customerStatusSchema } from './customerStatusSchema.ts'
import { measuringPointPanelNestedSchemaSchema } from './measuringPointPanelNestedSchemaSchema.ts'
import { measuringPointSubscriptionNestedSchemaSchema } from './measuringPointSubscriptionNestedSchemaSchema.ts'
import { planSchemaSchema } from './planSchemaSchema.ts'
import { postalAddressSchemaSchema } from './postalAddressSchemaSchema.ts'
import { projectSchemaSchema } from './projectSchemaSchema.ts'
import { z } from 'zod'

export const customerSchemaSchema = z.object({
  active_sepa_mandate_id: z.union([z.number().int(), z.null()]).optional(),
  address: z.union([z.lazy(() => addressSchemaSchema), z.null()]).optional(),
  bank_account: z.union([z.lazy(() => bankAccountSchemaSchema), z.null()]).optional(),
  company: z.union([z.string(), z.null()]).default(''),
  current_plan: z.union([z.lazy(() => planSchemaSchema), z.null()]).optional(),
  display_name: z.string().optional(),
  email: z.union([z.string(), z.null()]).optional(),
  first_name: z.union([z.string(), z.null()]).optional(),
  has_active_sepa_mandate: z.union([z.boolean(), z.null()]).optional(),
  has_not_cancelled_sepa: z.union([z.boolean(), z.null()]).optional(),
  id: z.union([z.number().int(), z.null()]).optional(),
  is_active: z.boolean().optional(),
  is_deletable: z.boolean().optional(),
  is_editable: z.boolean().optional(),
  is_in_fiscal_unity_with_provider_company: z.boolean(),
  last_billing_date: z.union([z.string().date(), z.null()]).optional(),
  last_name: z.union([z.string(), z.null()]).optional(),
  measuring_point_panel: z.union([z.lazy(() => measuringPointPanelNestedSchemaSchema), z.null()]).optional(),
  notes: z.union([z.string(), z.null()]).optional(),
  payment_method: z.string().optional(),
  phone_number: z.union([z.string(), z.null()]).optional(),
  plans: z.union([z.array(z.lazy(() => planSchemaSchema)), z.null()]).optional(),
  postal_address: z.union([z.lazy(() => postalAddressSchemaSchema), z.null()]).optional(),
  project: z.union([z.lazy(() => projectSchemaSchema), z.null()]).optional(),
  salutation: z.string().max(128).default('NONE'),
  salutation_full: z.string().optional(),
  status: z.union([z.lazy(() => customerStatusSchema), z.null()]).optional(),
  subscription: z.union([z.lazy(() => measuringPointSubscriptionNestedSchemaSchema), z.null()]).optional(),
  total_open_amount: z.union([z.number(), z.null()]).optional(),
  total_settled_amount: z.union([z.number(), z.null()]).optional(),
})