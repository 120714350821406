// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CreateLetterMutationRequest,
  CreateLetterMutationResponse,
  CreateLetterQueryParams,
  CreateLetter400,
  CreateLetter404,
  CreateLetter422,
} from '../types/CreateLetter.ts'

export function getCreateLetterUrl() {
  return `/api-internal/letters/` as const
}

/**
 * @summary Create Letter
 * {@link /api-internal/letters/}
 */
export async function createLetter(
  data: CreateLetterMutationRequest,
  params?: CreateLetterQueryParams,
  config: Partial<RequestConfig<CreateLetterMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateLetterMutationResponse,
    ResponseErrorConfig<CreateLetter400 | CreateLetter404 | CreateLetter422>,
    CreateLetterMutationRequest
  >({ method: 'POST', url: getCreateLetterUrl().toString(), params, data, ...requestConfig })
  return res.data
}