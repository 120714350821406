import { MeasuringPointDataSchema, MeasuringPointSchema, ObisCodes } from '@mg/api-wrappers/src/api-internal';
import { presentDate, presentKwh } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { HtmlLabel } from '@visx/annotation';
import { ParentSize } from '@visx/responsive';
import { Annotation, AnnotationLineSubject } from '@visx/xychart';
import dayjs from 'dayjs';
import { getMeasuringPointDataLabel } from '../../../helpers/getMeasuringPointDataLabel';
import { useTranslation } from '../../../i18n';
import { LineChartData, LineType, MGAreaChart } from '../../shared/Charts/MGAreaChart';

type Props = {
  start_date?: dayjs.Dayjs;
  end_date?: dayjs.Dayjs;
  measuring_point_informations: MeasuringPointSchema[];
  measuring_point_data: Record<number, Record<ObisCodes, MeasuringPointDataSchema[]>> | undefined;
  currentMeasuringPointId: number | undefined | null;
};
export const MeteringDetailsMeasuringPointDataGraph = ({
  start_date,
  end_date,
  measuring_point_informations,
  measuring_point_data,
  currentMeasuringPointId,
}: Props) => {
  const theme = useTheme();
  const { t, tString } = useTranslation('meteringDetails');
  const accessors = {
    xAccessor: (d) => new Date(d?.x || 0),
    yAccessor: (d) => d?.y,
  };
  const chartData: LineChartData[] =
    measuring_point_informations && measuring_point_data
      ? measuring_point_informations
          .map((point) => {
            const data = measuring_point_data[point.id!];
            if (!data) {
              return [];
            }
            const obisCodes = Object.keys(data);
            return obisCodes.map((obisCode) => {
              const isConsumption = obisCode === '1-0:1.8.0';
              const isActive = point.id === currentMeasuringPointId;
              return {
                id: `${point.id}-${obisCode}-${point.type}-active:<${isActive}>`,
                active: isActive,
                color: !isActive
                  ? palette.gray.main
                  : isConsumption
                    ? palette.primary.dark
                    : palette.secondary.main,
                lineType: LineType.Solid,
                fill: true,
                unit: 'kWh',
                data: data[obisCode].map(({ timestamp, value }) => ({
                  x: dayjs(timestamp).toDate(),
                  y: value * point.conversion_factor!,
                })),
              };
            });
          })
          .flat()
      : [];

  const maxY = chartData.reduce((acc, serie) => {
    for (const point of serie.data) {
      if (point.y > acc) acc = point.y;
    }
    return acc;
  }, 0);
  return (
    <ParentSize>
      {({ width, height }) => (
        <MGAreaChart
          dataSet={chartData}
          height={height}
          width={width}
          renderTooltip={({ tooltipData }) => {
            if (!tooltipData?.nearestDatum) return;
            return (
              <Paper sx={{ borderRadius: theme.spacing(1), padding: theme.spacing(1, 2) }}>
                <Stack sx={{ gap: theme.spacing(1) }}>
                  <Typography variant={'small'} fontWeight={'bold'}>
                    {getMeasuringPointDataLabel(tooltipData.nearestDatum.key)}
                    {tooltipData.nearestDatum.key.includes('active:<true>') ? '' : ` (${t('inactive')})`}
                  </Typography>
                  <Typography variant={'extraSmall'}>
                    {presentDate(tooltipData.nearestDatum.datum.x)}
                  </Typography>
                  <Typography fontWeight={'bold'} variant={'small'}>
                    {presentKwh(tooltipData.nearestDatum.datum.y)}
                  </Typography>
                </Stack>
              </Paper>
            );
          }}
        >
          {start_date && start_date.isAfter(dayjs(chartData[0]?.data[0]?.x)) && (
            <Annotation datum={{ x: start_date.toDate(), y: maxY * 0.9 }} dx={5} {...accessors}>
              <AnnotationLineSubject
                y2={height - 20}
                stroke={palette.primary.main}
                strokeDasharray="4,4"
              ></AnnotationLineSubject>
              <HtmlLabel horizontalAnchor={'start'} showAnchorLine={false}>
                <div className="bg-white border-2 border-blue-500 rounded-xl px-2 py-1 shadow-md">
                  {tString('subscription_start_date')}
                </div>
              </HtmlLabel>
            </Annotation>
          )}
          {end_date && end_date.isBefore(dayjs(chartData[0]?.data[chartData[0]?.data.length - 1]?.x)) && (
            <Annotation datum={{ x: end_date.toDate(), y: maxY * 0.9 }} dx={-5} {...accessors}>
              <AnnotationLineSubject
                y1={0}
                y2={height - 20}
                stroke={palette.primary.main}
                strokeDasharray="4,4"
              ></AnnotationLineSubject>
              <HtmlLabel horizontalAnchor={'end'} showAnchorLine={false}>
                <div className="bg-white border-2 border-blue-500 rounded-xl px-2 py-1 shadow-md">
                  {tString('subscription_end_date')}
                </div>
              </HtmlLabel>
            </Annotation>
          )}
        </MGAreaChart>
      )}
    </ParentSize>
  );
};
