import { useExportTransactions, useGetCustomerDataExport } from '@mg/api-wrappers/src/api-internal';
import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { Button } from '@mui/material';
import { ArrowBigDownDash, ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { downloadFile } from '../../../helpers/downloadFile';
import { useTranslation } from '../../../i18n';
import { SepaXmlGenerationModal } from '../../modals/SepaXmlGenerationModal/SepaXmlGenerationModal';

export const ProjectsActionsMenu = () => {
  const { t } = useTranslation('projects');
  const [sepaXmlGenerationModalOpen, setSepaXmlGenerationModalOpen] = useState(false);

  const useDownloadCustomerExport = useGetCustomerDataExport({ query: { enabled: false } });
  const useExportTransactionData = useExportTransactions({ query: { enabled: false } });
  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('export_customer_data') as string,
      icon: <ArrowBigDownDash />,
      onClick: () => downloadFile(useDownloadCustomerExport),
    },
    {
      text: t('export_transaction_data') as string,
      icon: <ArrowBigDownDash />,
      onClick: () => downloadFile(useExportTransactionData),
    },
    {
      text: t('generate_sepa_xml') as string,
      icon: <ArrowBigDownDash />,
      onClick: () => setSepaXmlGenerationModalOpen(true),
    },
  ];

  return (
    <>
      <MGActionMenu
        menuItems={menuItems}
        buttonComponent={
          <Button endIcon={<ChevronDown />} variant={'contained'} size={'large'}>
            {t('export_all_projects')}
          </Button>
        }
        spacing={10}
      />
      <SepaXmlGenerationModal
        open={sepaXmlGenerationModalOpen}
        handleToggle={setSepaXmlGenerationModalOpen}
      />
    </>
  );
};
