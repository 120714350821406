// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ChangePasswordMutationRequest, ChangePasswordMutationResponse, ChangePassword404, ChangePassword422 } from '../types/ChangePassword.ts'

export function getChangePasswordUrl() {
  return `/api-internal/users/current/change-password` as const
}

/**
 * @summary Change Password
 * {@link /api-internal/users/current/change-password}
 */
export async function changePassword(
  data: ChangePasswordMutationRequest,
  config: Partial<RequestConfig<ChangePasswordMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ChangePasswordMutationResponse, ResponseErrorConfig<ChangePassword404 | ChangePassword422>, ChangePasswordMutationRequest>({
    method: 'POST',
    url: getChangePasswordUrl().toString(),
    data,
    ...requestConfig,
  })
  return res.data
}