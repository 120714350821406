import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetTenantAcquisitionConfigurationMutationRequest,
  SetTenantAcquisitionConfigurationMutationResponse,
  SetTenantAcquisitionConfigurationPathParams,
  SetTenantAcquisitionConfiguration404,
  SetTenantAcquisitionConfiguration422,
} from '../types/SetTenantAcquisitionConfiguration.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { setTenantAcquisitionConfiguration } from '../clients/setTenantAcquisitionConfiguration.ts'
import { useMutation } from '@tanstack/react-query'

export const setTenantAcquisitionConfigurationMutationKey = () => [{ url: '/api-internal/projects/{project_id}/tenant_acquisition_configuration' }] as const

export type SetTenantAcquisitionConfigurationMutationKey = ReturnType<typeof setTenantAcquisitionConfigurationMutationKey>

/**
 * @summary Set Tenant Acquisition Configuration
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration}
 */
export function useSetTenantAcquisitionConfiguration(
  options: {
    mutation?: UseMutationOptions<
      SetTenantAcquisitionConfigurationMutationResponse,
      ResponseErrorConfig<SetTenantAcquisitionConfiguration404 | SetTenantAcquisitionConfiguration422>,
      { project_id: SetTenantAcquisitionConfigurationPathParams['project_id']; data?: SetTenantAcquisitionConfigurationMutationRequest }
    >
    client?: Partial<RequestConfig<SetTenantAcquisitionConfigurationMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? setTenantAcquisitionConfigurationMutationKey()

  return useMutation<
    SetTenantAcquisitionConfigurationMutationResponse,
    ResponseErrorConfig<SetTenantAcquisitionConfiguration404 | SetTenantAcquisitionConfiguration422>,
    { project_id: SetTenantAcquisitionConfigurationPathParams['project_id']; data?: SetTenantAcquisitionConfigurationMutationRequest }
  >({
    mutationFn: async ({ project_id, data }) => {
      return setTenantAcquisitionConfiguration(project_id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}