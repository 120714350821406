import { MGFieldSelect, MGFieldSelectProps } from './MGField/MGField.Select';
import { MGFormFieldBaseProps } from './MGForm.Field';

interface Props
  extends MGFormFieldBaseProps,
    Pick<MGFieldSelectProps, 'options' | 'SelectProps' | 'highlight'> {
  options: MGFieldSelectProps['options'];
  disabled?: boolean;
  placeholderText?: string;
  SelectProps?: MGFieldSelectProps['SelectProps'];
}

export const MGFormFieldSelect = ({
  label = '',
  options,
  form,
  name,
  highlight,
  disabled,
  placeholderText,
  FieldProps,
  SelectProps,
}: Props) => {
  return (
    <form.Field name={name} {...FieldProps}>
      {(field) => (
        <MGFieldSelect
          name={field.name}
          label={label}
          errors={field.state.meta.errors}
          value={field.state.value || ''}
          options={options}
          highlight={highlight}
          disabled={disabled}
          placeholderText={placeholderText}
          SelectProps={SelectProps}
          onChange={(e) => field.handleChange(e.target.value)}
          onBlur={field.handleBlur}
        />
      )}
    </form.Field>
  );
};
