import { axiosInstance } from '@mg/api-wrappers/src/client';
import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { palette } from '@mg/ui/src/styles';
import { EditOutlined } from '@mui/icons-material';
import { FileCheck, Trash2, UserRoundPlus } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';

type Props = {
  planId: number;
  isDeletable: boolean;
  isEditable: boolean;
};
export const PlansTenantPlansRowActionMenu = ({ planId, isDeletable, isEditable }: Props) => {
  const [djangoFormUrl, setDjangoFormUrl] = useState('');
  const [djangoFormOpen, setDjangoFormOpen] = useState(false);

  const { t } = useTranslation('plans');

  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('contract_document'),
      icon: <FileCheck />,
      href: `/contract/?plan=${planId}`,
    },
    {
      text: t('assign_customer'),
      icon: <UserRoundPlus />,
      href: `/plans/${planId}/subscribe`,
    },
    {
      text: t('edit_plan'),
      icon: <EditOutlined />,
      disabled: !isEditable,
      onClick: () => {
        setDjangoFormUrl(`/plans/${planId}/edit`);
        setDjangoFormOpen(true);
      },
    },
    {
      text: t('delete_plan'),
      disabled: !isDeletable,
      icon: <Trash2 size={18} color={palette.error.main} />,
      onClick: () => {
        axiosInstance
          .get(`/plans/${planId}/delete`)
          .then(() => {
            setDjangoFormOpen(false);
            toast.success(t('plan_deleted_success'));
            invalidateAllQueries();
          })
          .catch(() => {
            toast.error(t('plan_deleted_error'));
          });
      },
    },
  ];

  return (
    <>
      <MGActionMenu menuItems={menuItems} />
      <DjangoFormModal
        pathToDjangoForm={djangoFormUrl}
        title={t('Aktionen') as string}
        open={djangoFormOpen}
        handleToggle={() => {
          setDjangoFormOpen(!djangoFormOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setDjangoFormOpen(false);
        }}
      />
    </>
  );
};
