import { MeasuringPointDataSchema, MissingDataOut } from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert';
import { presentDate } from '@mg/ui/src/presenters';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from '../../../../../i18n';
import { BillingMeteringDataData } from './Billing.MeteringData.Data';
import { BillingMeteringDataMissingData } from './Billing.MeteringData.MissingData';

export const BillingMeteringDataCardTable = ({ meteringData }: { meteringData: MissingDataOut[] }) => {
  const { t } = useTranslation('latestReadings');
  const { t: tBilling } = useTranslation('billing');

  const columns: GridColDef<MissingDataOut>[] = [
    {
      field: 'serial',
      headerName: 'Zählernummer',
      valueGetter: (_, row) => row.measuring_point?.serial,
      flex: 0.7,
    },
    {
      field: 'type',
      headerName: 'Typ',
      valueGetter: (_, row) => t(row.measuring_point?.type + '_' + row.obis_code),
      flex: 0.7,
    },
    {
      field: 'start_data',
      headerName: 'Ablesung Anfang',
      renderCell: (params) => {
        if (params.value) {
          return <BillingMeteringDataData data={params.value} />;
        }
        return (
          <BillingMeteringDataMissingData missingData={params.row} timestamp={params.row.start_datetime} />
        );
      },
      flex: 1,
    } satisfies GridColDef<MissingDataOut, MeasuringPointDataSchema>,
    {
      field: 'end_data',
      headerName: 'Ablesung Ende',
      renderCell: (params) => {
        if (params.value) {
          return <BillingMeteringDataData data={params.value} />;
        }
        return (
          <BillingMeteringDataMissingData missingData={params.row} timestamp={params.row.end_datetime} />
        );
      },
      flex: 1,
    },
  ];

  // Datagrid requires measuring_point.id and obis_code to be present
  const displayableMeteringData = meteringData.filter((data) => data.measuring_point?.id && data.obis_code);

  return (
    <>
      {meteringData.map((data) =>
        data.errors?.map((error) => {
          if (
            ['MEASURING_POINT_MISSING', 'INOUT_PANEL_MISSING', 'SOURCE_PANEL_MISSING'].includes(error.code)
          ) {
            return (
              <MGAlert
                severity={'error'}
                title={t('error')}
                subtitle={tBilling(error.code, {
                  panel_name: data.measuring_point_panel?.name,
                  start_datetime: presentDate(data.start_datetime),
                  end_datetime: presentDate(data.end_datetime),
                })}
              />
            );
          }
        }),
      )}

      {displayableMeteringData.length > 0 && (
        <DataGridPro
          autoHeight
          columns={columns}
          rows={displayableMeteringData}
          getRowId={(row) => row.measuring_point!.id + row.obis_code!}
        />
      )}
    </>
  );
};
