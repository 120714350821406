import { MGCallToActionCard } from '@mg/ui/src/components/MGCallToActionCard';
import { useState } from 'react';
import { useTranslation } from '../../../i18n';
import { CalendlyModal } from '../../modals/CalendlyModal';

export const DashboardNewProjectCard = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation('dashboard');
  const { tString: tStringProjects } = useTranslation('projects');

  return (
    <>
      <MGCallToActionCard
        title={t('book_appointment_card_title')}
        buttonTitle={t('book_appointment_button')}
        color={'dark'}
        onButtonClick={() => setModalOpen(!modalOpen)}
      />
      <CalendlyModal
        calendlyLink={tStringProjects('url_calendly_new_project')}
        open={modalOpen}
        handleToggle={() => setModalOpen(!modalOpen)}
        translationPrefix={'book_appointment'}
      />
    </>
  );
};
