/*
 We're using a hack where we set environment variables to a placholder string during build and replace those
 with the values set during runtime at container startup (see docker/entrypoint.sh).

 An expression like `import.meta.env.VITE_BLA == "yes"` will look like `"__PLACEHOLDER__VITE_BLA__" == "yes"`
 during build, which Vite optimizes to `false` in the bundle.

 This is prevented by setting Vite's correctVarValueBeforeDeclaration option to true
 (see libs/ui/vite.base-config.ts) and using `envVars` instead of `import.meta.env`, as it is defined using
 `var`.
 */

// !!! IMPORTART !!! Don't change the `var` to `const` or `let` - see above.
// eslint-disable-next-line no-var
export var envVars = import.meta.env;
