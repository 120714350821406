import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { BulkMarkAsSentMutationRequest, BulkMarkAsSentMutationResponse, BulkMarkAsSent404, BulkMarkAsSent422 } from '../types/BulkMarkAsSent.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { bulkMarkAsSent } from '../clients/bulkMarkAsSent.ts'
import { useMutation } from '@tanstack/react-query'

export const bulkMarkAsSentMutationKey = () => [{ url: '/api-internal/document_email/bulk_mark_as_sent' }] as const

export type BulkMarkAsSentMutationKey = ReturnType<typeof bulkMarkAsSentMutationKey>

/**
 * @summary Bulk Mark As Sent
 * {@link /api-internal/document_email/bulk_mark_as_sent}
 */
export function useBulkMarkAsSent(
  options: {
    mutation?: UseMutationOptions<
      BulkMarkAsSentMutationResponse,
      ResponseErrorConfig<BulkMarkAsSent404 | BulkMarkAsSent422>,
      { data: BulkMarkAsSentMutationRequest }
    >
    client?: Partial<RequestConfig<BulkMarkAsSentMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkMarkAsSentMutationKey()

  return useMutation<BulkMarkAsSentMutationResponse, ResponseErrorConfig<BulkMarkAsSent404 | BulkMarkAsSent422>, { data: BulkMarkAsSentMutationRequest }>({
    mutationFn: async ({ data }) => {
      return bulkMarkAsSent(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}