import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import '../../styles/theme';

type Props = {
  label: ReactNode;
  children: ReactNode;
};
export const MGCardListLayoutItem = ({ label, children }: Props) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
      <Typography variant={'small'} fontWeight={400}>
        {label}
      </Typography>
      {typeof children === 'string' ? <Typography variant={'small'}>{children}</Typography> : children}
    </Stack>
  );
};
