import { envVars } from '@mg/ui/src/envVars';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { accessTokenAtom, currentProjectIdAtom } from '../../jotai/atoms.ts';

export const DjangoPageIFrame = () => {
  const location = useLocation();
  const token = useAtomValue(accessTokenAtom);
  const currentProjectId = useAtomValue(currentProjectIdAtom);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  if (!token) {
    return null;
  }

  const searchParams = new URLSearchParams(location.search);
  searchParams.set('__project_id__', currentProjectId!.toString());
  searchParams.set('__token__', token);

  const src = location.pathname.startsWith('/api/')
    ? `${envVars.VITE_BACKEND_HOST}/${location.pathname}?${searchParams.toString()}`
    : `${envVars.VITE_BACKEND_HOST}/iframe${location.pathname}?${searchParams.toString()}`;

  return (
    <>
      <Stack sx={{ height: '100vh', width: '100%' }}>
        <iframe
          ref={iframeRef}
          style={{
            marginTop: '0',
            height: '100%',
            width: '100%',
            border: 'none',
          }}
          src={src}
        />
      </Stack>
    </>
  );
};
