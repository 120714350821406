// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListMeasuringPointDataForGraphQueryResponse,
  ListMeasuringPointDataForGraphQueryParams,
  ListMeasuringPointDataForGraph404,
  ListMeasuringPointDataForGraph422,
} from '../types/ListMeasuringPointDataForGraph.ts'

export function getListMeasuringPointDataForGraphUrl() {
  return `/api-internal/measuring_point_data/graph` as const
}

/**
 * @summary List Measuring Point Data For Graph
 * {@link /api-internal/measuring_point_data/graph}
 */
export async function listMeasuringPointDataForGraph(
  params?: ListMeasuringPointDataForGraphQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    ListMeasuringPointDataForGraphQueryResponse,
    ResponseErrorConfig<ListMeasuringPointDataForGraph404 | ListMeasuringPointDataForGraph422>,
    unknown
  >({ method: 'GET', url: getListMeasuringPointDataForGraphUrl().toString(), params, ...requestConfig })
  return res.data
}