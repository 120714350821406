import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetSepaMandatePdfQueryResponse,
  GetSepaMandatePdfPathParams,
  GetSepaMandatePdf400,
  GetSepaMandatePdf404,
  GetSepaMandatePdf422,
} from '../types/GetSepaMandatePdf.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getSepaMandatePdf } from '../clients/getSepaMandatePdf.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSepaMandatePdfQueryKey = (id: GetSepaMandatePdfPathParams['id']) =>
  [{ url: '/api-internal/sepa_mandates/:id/pdf', params: { id: id } }] as const

export type GetSepaMandatePdfQueryKey = ReturnType<typeof getSepaMandatePdfQueryKey>

export function getSepaMandatePdfQueryOptions(id: GetSepaMandatePdfPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getSepaMandatePdfQueryKey(id)
  return queryOptions<
    GetSepaMandatePdfQueryResponse,
    ResponseErrorConfig<GetSepaMandatePdf400 | GetSepaMandatePdf404 | GetSepaMandatePdf422>,
    GetSepaMandatePdfQueryResponse,
    typeof queryKey
  >({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSepaMandatePdf(id, config)
    },
  })
}

/**
 * @summary Get Sepa Mandate Pdf
 * {@link /api-internal/sepa_mandates/:id/pdf}
 */
export function useGetSepaMandatePdf<
  TData = GetSepaMandatePdfQueryResponse,
  TQueryData = GetSepaMandatePdfQueryResponse,
  TQueryKey extends QueryKey = GetSepaMandatePdfQueryKey,
>(
  id: GetSepaMandatePdfPathParams['id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetSepaMandatePdfQueryResponse,
        ResponseErrorConfig<GetSepaMandatePdf400 | GetSepaMandatePdf404 | GetSepaMandatePdf422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSepaMandatePdfQueryKey(id)

  const query = useQuery({
    ...(getSepaMandatePdfQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetSepaMandatePdf400 | GetSepaMandatePdf404 | GetSepaMandatePdf422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}