// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetTenantAcquisitionConfigurationPanelSelectionHelperImageMutationRequest,
  SetTenantAcquisitionConfigurationPanelSelectionHelperImageMutationResponse,
  SetTenantAcquisitionConfigurationPanelSelectionHelperImagePathParams,
  SetTenantAcquisitionConfigurationPanelSelectionHelperImage404,
  SetTenantAcquisitionConfigurationPanelSelectionHelperImage422,
} from '../types/SetTenantAcquisitionConfigurationPanelSelectionHelperImage.ts'

export function getSetTenantAcquisitionConfigurationPanelSelectionHelperImageUrl(
  project_id: SetTenantAcquisitionConfigurationPanelSelectionHelperImagePathParams['project_id'],
) {
  return `/api-internal/projects/${project_id}/tenant_acquisition_configuration/panel_selection_helper_image` as const
}

/**
 * @summary Set Tenant Acquisition Configuration Panel Selection Helper Image
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration/panel_selection_helper_image}
 */
export async function setTenantAcquisitionConfigurationPanelSelectionHelperImage(
  project_id: SetTenantAcquisitionConfigurationPanelSelectionHelperImagePathParams['project_id'],
  data: SetTenantAcquisitionConfigurationPanelSelectionHelperImageMutationRequest,
  config: Partial<RequestConfig<SetTenantAcquisitionConfigurationPanelSelectionHelperImageMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const formData = new FormData()
  if (data) {
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof typeof data]
      if (typeof key === 'string' && (typeof value === 'string' || value instanceof Blob)) {
        formData.append(key, value)
      }
    })
  }
  const res = await request<
    SetTenantAcquisitionConfigurationPanelSelectionHelperImageMutationResponse,
    ResponseErrorConfig<SetTenantAcquisitionConfigurationPanelSelectionHelperImage404 | SetTenantAcquisitionConfigurationPanelSelectionHelperImage422>,
    SetTenantAcquisitionConfigurationPanelSelectionHelperImageMutationRequest
  >({
    method: 'POST',
    url: getSetTenantAcquisitionConfigurationPanelSelectionHelperImageUrl(project_id).toString(),
    data: formData,
    ...requestConfig,
    headers: { 'Content-Type': 'multipart/form-data', ...requestConfig.headers },
  })
  return res.data
}