import { z } from 'zod'

/**
 * @description Error response for multiple public errors
 */
export const multiplePublicErrorsOutSchema = z
  .object({
    errors: z.array(z.unknown()),
    title: z.string().optional(),
    type: z.literal('https://metergrid.de/api-errors/public-errors').default('https://metergrid.de/api-errors/public-errors'),
  })
  .describe('Error response for multiple public errors')