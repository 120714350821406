// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetContractDocumentsZipQueryResponse,
  GetContractDocumentsZipQueryParams,
  GetContractDocumentsZip404,
  GetContractDocumentsZip422,
} from '../types/GetContractDocumentsZip.ts'

export function getGetContractDocumentsZipUrl() {
  return `/api-internal/contract_proposals/contract_documents_zip` as const
}

/**
 * @description Returns a zip file containing all contract-related documents for a contract proposal.
 * @summary Get Contract Documents Zip
 * {@link /api-internal/contract_proposals/contract_documents_zip}
 */
export async function getContractDocumentsZip(params: GetContractDocumentsZipQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetContractDocumentsZipQueryResponse, ResponseErrorConfig<GetContractDocumentsZip404 | GetContractDocumentsZip422>, unknown>({
    method: 'GET',
    url: getGetContractDocumentsZipUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}