import {
  Addchart,
  CompareArrows,
  DeleteForeverOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';

import { MeasuringPointPanelSchema, PanelType } from '@mg/api-wrappers/src/api-internal';
import { axiosInstance } from '@mg/api-wrappers/src/client.ts';
import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { palette } from '@mg/ui/src/styles';
import { useState } from 'react';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter.ts';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';
import { FormModal } from '../../modals/FormModal.tsx';
import { fireSweetalert } from '../../modals/SweetalertModal';
import { PanelForm } from './MeteringForms/Metering.PanelForm.tsx';

type Props = {
  rowId: number;
  customerId?: number | null;
  row: MeasuringPointPanelSchema;
};
export const MeteringActionsMenu = ({ rowId, customerId, row }: Props) => {
  const projectFilter = useSelectedProjectsFilter();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addDataModalOpen, setAddDataModalOpen] = useState(false);
  const [addMeterModalOpen, setAddMeterModalOpen] = useState(false);

  const { t } = useTranslation('metering');

  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('change_name'),
      icon: <EditOutlined />,
      onClick: () => setEditModalOpen(!editModalOpen),
    },
    {
      text: t('add_data'),
      icon: <Addchart />,
      onClick: () => setAddDataModalOpen(!addDataModalOpen),
      disabled: !row.current_measuring_point,
    },
    {
      text: t('switch_meter'),
      icon: <CompareArrows />,
      onClick: () => setAddMeterModalOpen(!addMeterModalOpen),
    },
    {
      text: t('delete'),
      disabled: !row.is_deletable,
      icon: <DeleteForeverOutlined color={'error'} />,
      disabledHint: t('disabled_hint'),
      onClick: () => {
        fireSweetalert({
          title: t('delete_modal_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('delete_modal_text'),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axiosInstance.get(`/metering/panels/${rowId}/delete`).then(() => {
              invalidateAllQueries();
            });
          }
        });
      },
    },
  ];

  if (customerId) {
    menuItems.unshift({
      text: t('show_customer'),
      icon: <VisibilityOutlined />,
      href: `/customers/${customerId}`,
    });
  }

  return (
    <>
      <MGActionMenu menuItems={menuItems} />
      <FormModal
        open={editModalOpen}
        handleToggle={() => {
          setEditModalOpen(!editModalOpen);
        }}
        title={t('tit_edit_panel')}
        subtitle={t('lbl_edit_panel_label')}
        width="760px"
      >
        <PanelForm
          projectId={projectFilter.project_id}
          handleClose={() => {
            setEditModalOpen(!editModalOpen);
          }}
          panelId={rowId}
          defaultValues={{
            name: row.name,
            type: row.type !== null ? (row.type as PanelType) : undefined,
            malo_id: row.malo_id ?? undefined,
            melo_id: row.melo_id ?? undefined,
          }}
        />
      </FormModal>
      <DjangoFormModal
        pathToDjangoForm={`/metering/data/create?panel_id=${rowId}`}
        title={t('add_data')}
        open={addDataModalOpen}
        handleToggle={() => {
          setAddDataModalOpen(!addDataModalOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddDataModalOpen(false);
        }}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/panels/${rowId}/measuring_point_switch`}
        title={t('switch_meter')}
        open={addMeterModalOpen}
        handleToggle={() => {
          setAddMeterModalOpen(!addMeterModalOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddMeterModalOpen(false);
        }}
        prefill={[{ id: 'id_panel', value: rowId }]}
      />
    </>
  );
};
