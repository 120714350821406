import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const countProjects200Schema = z.number().int()

/**
 * @description Not Found
 */
export const countProjects404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const countProjects422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const countProjectsQueryResponseSchema = z.lazy(() => countProjects200Schema)