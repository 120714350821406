// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  UpdateLetterMutationRequest,
  UpdateLetterMutationResponse,
  UpdateLetterPathParams,
  UpdateLetterQueryParams,
  UpdateLetter400,
  UpdateLetter404,
  UpdateLetter422,
} from '../types/UpdateLetter.ts'

export function getUpdateLetterUrl(id: UpdateLetterPathParams['id']) {
  return `/api-internal/letters/${id}/` as const
}

/**
 * @summary Update Letter
 * {@link /api-internal/letters/:id/}
 */
export async function updateLetter(
  id: UpdateLetterPathParams['id'],
  data?: UpdateLetterMutationRequest,
  params?: UpdateLetterQueryParams,
  config: Partial<RequestConfig<UpdateLetterMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateLetterMutationResponse,
    ResponseErrorConfig<UpdateLetter400 | UpdateLetter404 | UpdateLetter422>,
    UpdateLetterMutationRequest
  >({ method: 'PATCH', url: getUpdateLetterUrl(id).toString(), params, data, ...requestConfig })
  return res.data
}