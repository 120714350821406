import { axiosInstance } from '@mg/api-wrappers/src/client.ts';
import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { palette } from '@mg/ui/src/styles';
import { Trash2 } from 'lucide-react';
import { useState } from 'react';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';
import { fireSweetalert } from '../../modals/SweetalertModal';

type Props = {
  levyStructureId: number;
  isDeletable?: boolean;
};
export const LevyStructureRowActionMenu = ({ levyStructureId, isDeletable }: Props) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { t } = useTranslation('levies');

  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('delete'),
      icon: <Trash2 color={palette.error.main} size={'18'} />,
      disabled: !isDeletable,
      onClick: () => {
        fireSweetalert({
          title: t('delete_levy_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('delete_levy_text'),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axiosInstance.get(`/levystructures/${levyStructureId}/delete/`).then(() => {
              invalidateAllQueries();
            });
          }
        });
      },
    },
  ];

  return (
    <>
      <MGActionMenu menuItems={menuItems} />
      <DjangoFormModal
        pathToDjangoForm={``}
        title={t('edit_project') as string}
        open={editModalOpen}
        handleToggle={() => {
          setEditModalOpen(!editModalOpen);
        }}
        onSuccess={() => {
          setEditModalOpen(false);
          invalidateAllQueries();
        }}
      />
    </>
  );
};
