import {
  measuringPointDataGranularity,
  MeasuringPointDataSchema,
  MeasuringPointSubscriptionSchema,
  ObisCodes,
  useGetMeasuringPointPanel,
  useListMeasuringPointDataForGraph,
  useListMeasuringPoints,
} from '@mg/api-wrappers/src/api-internal';
import { MGCard } from '@mg/ui/src/components/MGCard';
import { Button, Grid2, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { LineChart } from 'lucide-react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { MeteringDetailsMeasuringPointDataGraph } from './MeteringDetails.MeasuringPointData.Graph';

type Props = {
  panelId: number;
  subscription?: MeasuringPointSubscriptionSchema | null;
};

export const MeteringDetailsMeasuringPointData = ({ panelId, subscription }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('meteringDetails');
  const projectIdFilter = useSelectedProjectsFilter();

  const panelQuery = useGetMeasuringPointPanel(panelId, {
    include_current_measuring_point: true,
    include_project: true,
  });

  const currentMeasuringPointId = panelQuery.data?.current_measuring_point?.id;
  const pointQuery = useListMeasuringPoints({
    panel_id: panelId,
    ...projectIdFilter,
  });
  const measuringPointDataQuery = useListMeasuringPointDataForGraph({
    panel_ids: [panelId],
    granularity: measuringPointDataGranularity.day,
    always_first: true,
  });

  const groupedMeasuringPointData: undefined | Record<number, Record<ObisCodes, MeasuringPointDataSchema[]>> =
    useMemo(() => {
      return measuringPointDataQuery.data?.reduce((acc, dataPoint) => {
        const { measuring_point_id, obis_code } = dataPoint;
        if (!acc[measuring_point_id]) {
          acc[measuring_point_id] = {};
        }
        if (!acc[measuring_point_id][obis_code]) {
          acc[measuring_point_id][obis_code] = [];
        }
        acc[measuring_point_id][obis_code].push(dataPoint);
        return acc;
      }, {});
    }, [measuringPointDataQuery.data]);
  const isLoading = panelQuery.isLoading || pointQuery.isLoading || measuringPointDataQuery.isLoading;
  const isError = panelQuery.isError || pointQuery.isError;
  const data = pointQuery.data;
  const noData =
    groupedMeasuringPointData === undefined || Object.keys(groupedMeasuringPointData).length === 0;
  return (
    <MGCard isLoading={isLoading} isError={isError} noData={noData} sx={{ height: '100%' }}>
      <Grid2 container>
        <Grid2 size={12}>
          <LineChart color={theme.palette.primary.main} />
        </Grid2>
        <Grid2 size={7} marginTop={'10px'}>
          <Typography>{t('data_card_title')}</Typography>
        </Grid2>
        <Grid2 size={5} display={'flex'} justifyContent={'flex-end'}>
          {panelQuery.data?.project?.is_read_remotely === false && (
            <Button component={Link} to="/metering/data">
              {t('edit_data_button')}
            </Button>
          )}
        </Grid2>
        <Grid2 size={12} marginTop={'20px'} height={'350px'}>
          <MeteringDetailsMeasuringPointDataGraph
            start_date={subscription?.start_date ? dayjs(subscription.start_date) : undefined}
            end_date={subscription?.ended_at ? dayjs(subscription.ended_at) : undefined}
            measuring_point_informations={data || []}
            measuring_point_data={groupedMeasuringPointData}
            currentMeasuringPointId={currentMeasuringPointId}
          />
        </Grid2>
      </Grid2>
    </MGCard>
  );
};
