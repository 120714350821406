import { MoreHoriz } from '@mui/icons-material';
import { Box, ButtonProps, Divider, IconButton, Menu, Typography } from '@mui/material';
import { ScopeProvider, useMolecule } from 'bunshi/react';
import { useAtom } from 'jotai';
import { cloneElement, ReactElement, ReactNode, useId, useRef } from 'react';
import { MGActionMenuItem, MGActionMenuItemProps } from './MGActionMenu.Item';
import { mgActionMenuMolecule, mgActionMenuScope } from './mgActionMenuMolecule.tsx';

type Props = {
  title?: ReactNode;
  menuItems?: MGActionMenuItemProps[];
  children?: ReactNode;
  onToggle?: () => void;
  buttonComponent?: ReactElement<ButtonProps>;
  spacing?: number;
};

export const MGActionMenu = (props: Props) => {
  const value = useId();

  return (
    <ScopeProvider scope={mgActionMenuScope} value={value}>
      <MGActionMenuInner {...props} />
    </ScopeProvider>
  );
};

const MGActionMenuInner = ({
  title,
  menuItems = [],
  onToggle,
  buttonComponent,
  spacing,
  children,
}: Props) => {
  const { openAtom } = useMolecule(mgActionMenuMolecule);
  const [menuOpen, setMenuOpen] = useAtom(openAtom);
  const ref = useRef(null);

  const handleToggle = (e) => {
    if (onToggle) {
      onToggle();
    } else {
      setMenuOpen(!menuOpen);
    }
    e.stopPropagation();
  };

  return (
    <>
      {buttonComponent ? (
        cloneElement(buttonComponent, { ref: ref, onClick: handleToggle })
      ) : (
        <IconButton ref={ref} onClick={handleToggle}>
          <MoreHoriz />
        </IconButton>
      )}

      <Menu
        open={menuOpen}
        onClose={handleToggle}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ width: '500px' }}
        slotProps={{
          paper: {
            sx: {
              minWidth: '200px',
              overflow: 'visible',
              marginTop: spacing ? `${spacing}px` : '0px',
            },
          },
        }}
      >
        <Box paddingX={'20px'} paddingBottom={'10px'} paddingTop={'5px'}>
          <Typography variant={'small'}>{title || 'Aktionen'}</Typography>
        </Box>
        <Divider />

        {menuItems
          .filter((item) => !item.hidden)
          .map((item, i) => (
            <MGActionMenuItem key={`action-menu-item${i}`} {...item} />
          ))}
        {children}
      </Menu>
    </>
  );
};
