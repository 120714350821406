import 'dayjs/locale/de';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import 'vite/modulepreload-polyfill';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import './api/interceptors';
import { App } from './App';
import './i18n';
import './index.css';

z.setErrorMap(zodI18nMap);

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
