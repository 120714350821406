import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListMeasuringPointsQueryResponse,
  ListMeasuringPointsQueryParams,
  ListMeasuringPoints404,
  ListMeasuringPoints422,
} from '../types/ListMeasuringPoints.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listMeasuringPoints } from '../clients/listMeasuringPoints.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listMeasuringPointsQueryKey = (params: ListMeasuringPointsQueryParams) =>
  [{ url: '/api-internal/measuring_points/' }, ...(params ? [params] : [])] as const

export type ListMeasuringPointsQueryKey = ReturnType<typeof listMeasuringPointsQueryKey>

export function listMeasuringPointsQueryOptions(params: ListMeasuringPointsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listMeasuringPointsQueryKey(params)
  return queryOptions<
    ListMeasuringPointsQueryResponse,
    ResponseErrorConfig<ListMeasuringPoints404 | ListMeasuringPoints422>,
    ListMeasuringPointsQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listMeasuringPoints(params, config)
    },
  })
}

/**
 * @summary List Measuring Points
 * {@link /api-internal/measuring_points/}
 */
export function useListMeasuringPoints<
  TData = ListMeasuringPointsQueryResponse,
  TQueryData = ListMeasuringPointsQueryResponse,
  TQueryKey extends QueryKey = ListMeasuringPointsQueryKey,
>(
  params: ListMeasuringPointsQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ListMeasuringPointsQueryResponse, ResponseErrorConfig<ListMeasuringPoints404 | ListMeasuringPoints422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listMeasuringPointsQueryKey(params)

  const query = useQuery({
    ...(listMeasuringPointsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListMeasuringPoints404 | ListMeasuringPoints422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}