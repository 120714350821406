// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SendDocumentByEmailMutationResponse,
  SendDocumentByEmailQueryParams,
  SendDocumentByEmail404,
  SendDocumentByEmail422,
} from '../types/SendDocumentByEmail.ts'

export function getSendDocumentByEmailUrl() {
  return `/api-internal/document_email/send_email` as const
}

/**
 * @summary Send Document By Email
 * {@link /api-internal/document_email/send_email}
 */
export async function sendDocumentByEmail(params: SendDocumentByEmailQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SendDocumentByEmailMutationResponse, ResponseErrorConfig<SendDocumentByEmail404 | SendDocumentByEmail422>, unknown>({
    method: 'POST',
    url: getSendDocumentByEmailUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}