import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  RequestPasswordResetMutationRequest,
  RequestPasswordResetMutationResponse,
  RequestPasswordReset404,
  RequestPasswordReset422,
} from '../types/RequestPasswordReset.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { requestPasswordReset } from '../clients/requestPasswordReset.ts'
import { useMutation } from '@tanstack/react-query'

export const requestPasswordResetMutationKey = () => [{ url: '/api-internal/auth/request_password_reset' }] as const

export type RequestPasswordResetMutationKey = ReturnType<typeof requestPasswordResetMutationKey>

/**
 * @summary Request Password Reset
 * {@link /api-internal/auth/request_password_reset}
 */
export function useRequestPasswordReset(
  options: {
    mutation?: UseMutationOptions<
      RequestPasswordResetMutationResponse,
      ResponseErrorConfig<RequestPasswordReset404 | RequestPasswordReset422>,
      { data: RequestPasswordResetMutationRequest }
    >
    client?: Partial<RequestConfig<RequestPasswordResetMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? requestPasswordResetMutationKey()

  return useMutation<
    RequestPasswordResetMutationResponse,
    ResponseErrorConfig<RequestPasswordReset404 | RequestPasswordReset422>,
    { data: RequestPasswordResetMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return requestPasswordReset(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}