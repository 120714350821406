import { measuringPointDataNestedSchemaSchema } from './measuringPointDataNestedSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { OBISCODESSchema } from './OBISCODESSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listMeasuringPointDataForGraphQueryParamsSchema = z
  .object({
    obis_codes: z.union([z.array(z.lazy(() => OBISCODESSchema)), z.null()]).optional(),
    measuring_point_ids: z.union([z.array(z.number().int()), z.null()]).optional(),
    panel_ids: z.union([z.array(z.number().int()), z.null()]).optional(),
    timestamp_from: z.union([z.string().datetime(), z.null()]).optional(),
    timestamp_to: z.union([z.string().datetime(), z.null()]).optional(),
    project_ids: z.union([z.array(z.number().int()), z.null()]).optional(),
    granularity: z.enum(['day', 'week', 'month', 'year', 'all']).default('all'),
    always_first: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const listMeasuringPointDataForGraph200Schema = z.array(z.lazy(() => measuringPointDataNestedSchemaSchema))

/**
 * @description Not Found
 */
export const listMeasuringPointDataForGraph404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const listMeasuringPointDataForGraph422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listMeasuringPointDataForGraphQueryResponseSchema = z.lazy(() => listMeasuringPointDataForGraph200Schema)