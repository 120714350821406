import { Button, Radio, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { palette } from '../../styles';

export interface MGTextRadioButtonProps {
  label?: ReactNode;
  selected: boolean;
  textOnly?: boolean;
  onClick: () => void;
  children?: ReactNode;
}

// Note: Having the Radio inside a Button can lead to unexpected behavior
export const MGTextRadioButton = ({
  label,
  selected,
  textOnly,
  onClick,
  children,
}: MGTextRadioButtonProps) => {
  return (
    <Button
      variant={'outlined'}
      fullWidth
      onClick={onClick}
      sx={{
        border: `1px solid ${selected ? palette.primary.main : palette.border.main}`,
        borderRadius: '10px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: textOnly ? 'center' : 'flex-start',
        backgroundColor: selected ? palette.primary.light : '',
      }}
    >
      <Radio checked={selected} sx={textOnly ? { display: 'none' } : null} />
      {label && (
        <Typography sx={{ color: `${palette.gray.dark} !important` }} variant={'small'}>
          {label}
        </Typography>
      )}
      {children}
    </Button>
  );
};
