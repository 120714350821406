import { presentKwh } from '@mg/ui/src/presenters';
import { Box, Typography } from '@mui/material';
import { curveMonotoneX } from '@visx/curve';
import { GlyphCircle } from '@visx/glyph';
import { AnimatedAreaSeries, AnimatedAxis, AnimatedGrid, Tooltip, XYChart } from '@visx/xychart';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';
import Lottie from 'lottie-react';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from '../../../i18n';
import noDataLottie from '../../../lottie/no-data.json';

export const MGAreaChart = ({ dataSet, renderTooltip, width, height, children }: Props) => {
  const { t } = useTranslation('shared');
  const accessors = {
    xAccessor: (d) => new Date(d?.x || 0),
    yAccessor: (d) => d.y,
  };

  if (dataSet.length === 0) {
    return (
      <Box
        height={'100%'}
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Box sx={{ height: '100px', width: '100px' }}>
          <Lottie animationData={noDataLottie} loop={false} />
        </Box>
        <Typography variant={'extraSmall'}>{t('no_data_available')}</Typography>
      </Box>
    );
  }
  return (
    <XYChart
      height={height}
      width={width}
      xScale={{ type: 'time' }}
      yScale={{ type: 'linear' }}
      margin={{ top: 20, left: 50, right: 10, bottom: 20 }}
    >
      <AnimatedAxis orientation="bottom" numTicks={6} animationTrajectory={'min'} />
      <AnimatedAxis
        orientation="left"
        tickFormat={(value) => presentKwh(value)}
        labelOffset={20}
        animationTrajectory={'min'}
      />
      <AnimatedGrid columns={false} numTicks={8} />

      {dataSet.map((serie) => (
        <AnimatedAreaSeries
          fillOpacity={0.4}
          strokeWidth={0}
          strokeDasharray={serie.lineType === LineType.Dashed ? 4 : undefined}
          curve={curveMonotoneX}
          key={serie.id}
          dataKey={serie.id!}
          data={serie.data.map((d) => ({
            ...d,
            color: serie.color,
          }))} // Mapping color into data to be able to use it in Tooltip
          {...accessors}
          fill={serie.color}
          lineProps={{ stroke: serie.color }}
        ></AnimatedAreaSeries>
      ))}
      {children}
      <Tooltip
        showDatumGlyph
        renderGlyph={() => <GlyphCircle />}
        unstyled
        applyPositionStyle
        showHorizontalCrosshair
        horizontalCrosshairStyle={{ strokeDasharray: 5, width: '10px' }}
        verticalCrosshairStyle={{ strokeDasharray: 5, strokeWidth: 20 }}
        snapTooltipToDatumY
        detectBounds={true}
        renderTooltip={renderTooltip}
      />
    </XYChart>
  );
};

type Props = {
  dataSet: LineChartData[];
  children: ReactNode;
  renderTooltip: ({
    tooltipData,
  }: RenderTooltipParams<{ x: string | Date; y: string }>) => ReactElement | undefined;
  height: number;
  width: number;
};

export type LineChartData = {
  data: DataItem[];
  color: string;
  lineType: LineType;
  id?: string;
  active?: boolean;
} & { [otherOptions: string]: unknown };

type DataItem = {
  x: number | Date;
  y: number;
};

export type VerticalLine = {
  x: Date;
  label: string;
  color: string;
};

export enum LineType {
  Dashed = 'dashed',
  Solid = 'solid',
}
