import { axiosInstance } from '@mg/api-wrappers/src/client.ts';
import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { palette } from '@mg/ui/src/styles';
import { Trash2 } from 'lucide-react';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../i18n';
import { fireSweetalert } from '../../modals/SweetalertModal';

type Props = {
  levyId: number;
  levyStructureId: number;
  isDeletable?: boolean;
};
export const LevyStructureLeviesRowActionMenu = ({ levyId, levyStructureId, isDeletable }: Props) => {
  const { t } = useTranslation('levies');

  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('delete'),
      icon: <Trash2 color={palette.error.main} size={'18'} />,
      disabled: !isDeletable,
      onClick: () => {
        fireSweetalert({
          title: t('delete_levy_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('delete_levy_text'),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axiosInstance.get(`/levystructures/${levyStructureId}/levies/${levyId}/delete/`).then(() => {
              invalidateAllQueries();
            });
          }
        });
      },
    },
  ];

  return (
    <>
      <MGActionMenu menuItems={menuItems} />
    </>
  );
};
