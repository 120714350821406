import { CircleHelp } from 'lucide-react';
import { AnchorHTMLAttributes } from 'react';

export type MGHelpButtonProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export const MGHelpButton = ({
  href,
  target = '_blank',
  rel = 'noopener noreferrer',
  tabIndex = -1,
  ...props
}: MGHelpButtonProps) => {
  return (
    <a href={href} target={target} rel={rel} tabIndex={tabIndex} {...props} style={{ marginLeft: 5 }}>
      <CircleHelp />
    </a>
  );
};
