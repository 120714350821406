import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { pagedMeasuringPointPanelSchemaSchema } from './pagedMeasuringPointPanelSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listMeasuringPointPanelsQueryParamsSchema = z.object({
  include_project: z.boolean().default(false),
  include_customer: z.boolean().default(false),
  include_measuring_points: z.boolean().default(false),
  include_current_measuring_point: z.boolean().default(false),
  include_last_reading: z.boolean().default(false),
  include_status: z.boolean().default(false),
  include_subscriptions: z.boolean().default(false),
  search: z.union([z.string(), z.null()]).optional(),
  project_id: z.number().int(),
  page: z.union([z.number().int(), z.null()]).default(1),
  page_size: z.union([z.number().int(), z.null()]).default(25),
  order_by: z.union([z.string(), z.null()]).optional(),
  skip_pagination: z.union([z.boolean(), z.null()]).default(false),
  single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const listMeasuringPointPanels200Schema = z.lazy(() => pagedMeasuringPointPanelSchemaSchema)

/**
 * @description Not Found
 */
export const listMeasuringPointPanels404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const listMeasuringPointPanels422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listMeasuringPointPanelsQueryResponseSchema = z.lazy(() => listMeasuringPointPanels200Schema)