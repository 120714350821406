// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { CountInvoicesQueryResponse, CountInvoicesQueryParams, CountInvoices404, CountInvoices422 } from '../types/CountInvoices.ts'

export function getCountInvoicesUrl() {
  return `/api-internal/invoices/count` as const
}

/**
 * @summary Count Invoices
 * {@link /api-internal/invoices/count}
 */
export async function countInvoices(params: CountInvoicesQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CountInvoicesQueryResponse, ResponseErrorConfig<CountInvoices404 | CountInvoices422>, unknown>({
    method: 'GET',
    url: getCountInvoicesUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}