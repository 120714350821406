// noinspection AllyJsxHardcodedStringInspection

import { useGetProject } from '@mg/api-wrappers/src/api-internal';
import { Avatar, AvatarGroup, Button, Stack, Typography } from '@mui/material';
import { CircleHelp, MessageCircle } from 'lucide-react';
import avatarAlan from '../../../assets/avatar_alan.png';
import avatarHannes from '../../../assets/avatar_hannes.png';
import avatarJulian from '../../../assets/avatar_julian.png';
import { useUserInfo } from '../../../helpers/userInfo.ts';
import { useCurrentProject } from '../../../hooks/useCurrentProject.ts';
import { useTranslation } from '../../../i18n';

export const DashboardSupportCard = () => {
  const { t } = useTranslation('dashboard');
  const currentProjectId = useCurrentProject();
  const projectQuery = useGetProject(currentProjectId);
  const currentProject = projectQuery.data;
  const [user] = useUserInfo();
  const userEmail = user?.email || '';
  const projectName = currentProject?.name || currentProject?.location || '';
  return (
    <Stack alignItems={'center'} justifyContent={'center'} spacing={2} paddingX={15} paddingY={5}>
      <AvatarGroup sx={{ alignItems: 'flex-end', '.MuiAvatarGroup-avatar': { borderColor: '#F6F5F3' } }}>
        <Avatar sx={{ width: 60, height: 60 }} alt="Alan" src={avatarAlan} />
        <Avatar sx={{ width: 72, height: 72, zIndex: 1 }} alt="Julian" src={avatarJulian} />
        <Avatar sx={{ width: 60, height: 60 }} alt="Hannes" src={avatarHannes} />
      </AvatarGroup>

      <Typography variant={'h3'}>{t('support_card_title')}</Typography>
      <Typography variant={'small'} textAlign={'center'}>
        {t('support_card_subtitle')}
      </Typography>

      <Stack direction={'row'} spacing={1}>
        <Button
          variant={'contained'}
          startIcon={<CircleHelp size={18} />}
          href={'https://www.metergrid.de/help-center/faq'}
          target={'_blank'}
        >
          {t('support_card_faq_button')}
        </Button>
        <Button
          variant={'contained'}
          color={'white'}
          startIcon={<MessageCircle size={18} />}
          href={`https://www.metergrid.de/help-center/kundensupport?project_id=${encodeURIComponent(currentProjectId)}&user_email=${encodeURIComponent(userEmail)}&project_name=${encodeURIComponent(projectName)}`}
          target={'_blank'}
        >
          {t('support_card_contact_support_button')}
        </Button>
      </Stack>
    </Stack>
  );
};
