import { MeasuringPointDataSchema } from '@mg/api-wrappers/src/api-internal';
import { presentDate, presentKwh } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../../../i18n.tsx';

export const BillingMeteringDataData = ({ data }: { data: MeasuringPointDataSchema }) => {
  const { t } = useTranslation('billing');

  return (
    <Stack direction={'column'} justifyContent={'center'} height={'100%'}>
      <Typography variant={'small'}>{presentKwh(data.value)}</Typography>
      <Typography variant="extraSmall" color={palette.gray.dark}>
        {data.origin === 'CALCULATED' ? (
          <>{t('interpolated_measuring_point_data', { date: presentDate(new Date(data.timestamp)) })}</>
        ) : (
          <>{t('existing_measuring_point_data', { date: presentDate(new Date(data.timestamp)) })}</>
        )}
      </Typography>
    </Stack>
  );
};
