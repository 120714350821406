import { ALREADYSIGNEDBYPROVIDERSchema } from './ALREADYSIGNEDBYPROVIDERSchema.ts'
import { CREDITORIDENTFIERNEEDEDFORSEPASchema } from './CREDITORIDENTFIERNEEDEDFORSEPASchema.ts'
import { MEASURINGPOINTMISSINGSchema } from './MEASURINGPOINTMISSINGSchema.ts'
import { NOTSIGNEDBYTENANTSchema } from './NOTSIGNEDBYTENANTSchema.ts'
import { z } from 'zod'

export const multiplePublicErrorsOutUnionALREADYSIGNEDBYPROVIDERNOTSIGNEDBYTENANTCREDITORIDENTFIERNEEDEDFORSEPAMEASURINGPOINTMISSINGSchema = z.object({
  errors: z.array(
    z.union([
      z.lazy(() => ALREADYSIGNEDBYPROVIDERSchema),
      z.lazy(() => NOTSIGNEDBYTENANTSchema),
      z.lazy(() => CREDITORIDENTFIERNEEDEDFORSEPASchema),
      z.lazy(() => MEASURINGPOINTMISSINGSchema),
    ]),
  ),
  title: z.string().optional(),
  type: z.literal('https://metergrid.de/api-errors/public-errors').default('https://metergrid.de/api-errors/public-errors'),
})