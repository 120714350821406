import { contractProposalStateSchema } from './contractProposalStateSchema.ts'
import { ESTIMATEDSTARTDATETYPESchema } from './ESTIMATEDSTARTDATETYPESchema.ts'
import { measuringPointPanelNestedSchemaSchema } from './measuringPointPanelNestedSchemaSchema.ts'
import { planNestedSchemaSchema } from './planNestedSchemaSchema.ts'
import { z } from 'zod'

export const contractProposalOutSchema = z.object({
  accept_contract_only_via_email: z.boolean().default(false).describe('Accepts that the contract is sent only via email.'),
  accept_policies: z.boolean().default(false).describe('The customer accepts the privacy policy and cancellation policy.'),
  accept_sepa_mandate: z.boolean().default(false).describe('The customer accepts the SEPA mandate.'),
  address_address_2: z.union([z.string(), z.null()]).optional(),
  address_city: z.union([z.string(), z.null()]).optional(),
  address_company: z.union([z.string(), z.null()]).default(''),
  address_country: z.union([z.string(), z.null()]).default('DE'),
  address_first_name: z.union([z.string(), z.null()]).default(''),
  address_last_name: z.union([z.string(), z.null()]).default(''),
  address_salutation: z.union([z.string(), z.null()]).default('NONE'),
  address_street: z.union([z.string(), z.null()]).optional(),
  address_street_number: z.union([z.string(), z.null()]).optional(),
  address_zip_code: z.union([z.string(), z.null()]).optional(),
  bank_account_bic: z.union([z.string(), z.null()]).default('').describe('Business Identifier Code with 8 or 11 characters'),
  bank_account_currency: z.string().max(4).default('EUR').describe('Used to enforce a certain currency when making transactions.'),
  bank_account_iban: z.union([z.string(), z.null()]).default('').describe('International Bank Account Number with 34 alphanumeric characters'),
  bank_account_owner: z.union([z.string(), z.null()]).default('').describe("Bank account owner's name"),
  created_at: z.union([z.string().datetime(), z.null()]).optional(),
  edit_link: z.string(),
  email: z.union([z.string(), z.null()]).optional(),
  email_sent_at: z.union([z.string().datetime(), z.null()]).describe('The date and time when the contract was delivered via e-mail.').optional(),
  estimated_start_date_type: z.union([z.lazy(() => ESTIMATEDSTARTDATETYPESchema), z.null()]).optional(),
  final_cancellation_policy_pdf: z.union([z.string(), z.null()]).optional(),
  final_contract_pdf: z.union([z.string(), z.null()]).optional(),
  final_overview_pdf: z.union([z.string(), z.null()]).optional(),
  final_power_of_attorney_pdf: z.union([z.string(), z.null()]).optional(),
  final_sepa_mandate_pdf: z.union([z.string(), z.null()]).optional(),
  final_start_date: z.union([z.string().date(), z.null()]).describe('The final, confirmed start date for the subscription.').optional(),
  household_energy_use_per_year_kwh: z.union([z.number().int(), z.null()]).describe('The amount of energy used by the household per year in kWh.').optional(),
  household_number_of_people: z.union([z.number().int(), z.null()]).describe('The number of people living in the household.').optional(),
  id: z.number().int(),
  invoice_address_address_2: z.union([z.string(), z.null()]).optional(),
  invoice_address_city: z.union([z.string(), z.null()]).optional(),
  invoice_address_company: z.union([z.string(), z.null()]).default(''),
  invoice_address_country: z.union([z.string(), z.null()]).default('DE'),
  invoice_address_first_name: z.union([z.string(), z.null()]).default(''),
  invoice_address_last_name: z.union([z.string(), z.null()]).default(''),
  invoice_address_salutation: z.union([z.string(), z.null()]).default('NONE'),
  invoice_address_street: z.union([z.string(), z.null()]).optional(),
  invoice_address_street_number: z.union([z.string(), z.null()]).optional(),
  invoice_address_zip_code: z.union([z.string(), z.null()]).optional(),
  is_deletable: z.boolean(),
  issue_switching_order: z.boolean().default(false).describe('The customer issues the order to swith their electricity contract for them.'),
  letter_sent_at: z.union([z.string().datetime(), z.null()]).describe('The date and time when the contract was delivered via postal service.').optional(),
  measuring_point_panel: z.union([z.lazy(() => measuringPointPanelNestedSchemaSchema), z.null()]).optional(),
  measuring_point_serial: z.union([z.string(), z.null()]).optional(),
  payment_plan_amount_gross: z.union([z.string(), z.number(), z.null()]).optional(),
  payment_plan_currency: z.string().max(4).default('EUR').describe('The currency in which the subscription will be charged.'),
  payment_type: z.string().max(32).default('unset'),
  phone_number: z.union([z.string(), z.null()]),
  plan: z.union([z.lazy(() => planNestedSchemaSchema), z.null()]).optional(),
  project: z.number().int(),
  promoter_activity: z.union([z.string(), z.null()]).optional(),
  provider_signed_at: z.union([z.string().datetime(), z.null()]).describe('The date and time when the provider signed the contract.').optional(),
  realized_at: z.union([z.string().datetime(), z.null()]).describe('The date and time when the contract proposal was realized.').optional(),
  resulting_customer: z.union([z.number().int(), z.null()]).optional(),
  resulting_measuring_point_subscription: z.union([z.number().int(), z.null()]).optional(),
  resulting_payment_plan: z.union([z.number().int(), z.null()]).optional(),
  resulting_sepa_mandate: z.union([z.number().int(), z.null()]).optional(),
  separate_invoice_address: z.boolean().default(false).describe('The invoice address differs from the main address.'),
  signed_with_promoter: z.union([z.number().int(), z.null()]).optional(),
  specific_estimated_start_date: z.union([z.string().date(), z.null()]).describe('The specific estimated start date for the subscription.').optional(),
  started_with_promoter: z.union([z.number().int(), z.null()]).optional(),
  state: z.lazy(() => contractProposalStateSchema),
  tenant_sign_by_provider_user: z.union([z.number().int(), z.null()]).optional(),
  tenant_signed_at: z.union([z.string().datetime(), z.null()]).describe('The date and time when the tenant signed the contract.').optional(),
  updated_at: z.union([z.string().datetime(), z.null()]).optional(),
})