import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SignContractProposalByProviderMutationResponse,
  SignContractProposalByProviderPathParams,
  SignContractProposalByProvider404,
  SignContractProposalByProvider422,
} from '../types/SignContractProposalByProvider.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { signContractProposalByProvider } from '../clients/signContractProposalByProvider.ts'
import { useMutation } from '@tanstack/react-query'

export const signContractProposalByProviderMutationKey = () => [{ url: '/api-internal/contract_proposals/{id}/sign_by_provider' }] as const

export type SignContractProposalByProviderMutationKey = ReturnType<typeof signContractProposalByProviderMutationKey>

/**
 * @summary Sign Contract Proposal By Provider
 * {@link /api-internal/contract_proposals/:id/sign_by_provider}
 */
export function useSignContractProposalByProvider(
  options: {
    mutation?: UseMutationOptions<
      SignContractProposalByProviderMutationResponse,
      ResponseErrorConfig<SignContractProposalByProvider404 | SignContractProposalByProvider422>,
      { id: SignContractProposalByProviderPathParams['id'] }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? signContractProposalByProviderMutationKey()

  return useMutation<
    SignContractProposalByProviderMutationResponse,
    ResponseErrorConfig<SignContractProposalByProvider404 | SignContractProposalByProvider422>,
    { id: SignContractProposalByProviderPathParams['id'] }
  >({
    mutationFn: async ({ id }) => {
      return signContractProposalByProvider(id, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}