import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  AcceptSepaMandateMutationRequest,
  AcceptSepaMandateMutationResponse,
  AcceptSepaMandatePathParams,
  AcceptSepaMandate404,
  AcceptSepaMandate422,
} from '../types/AcceptSepaMandate.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { acceptSepaMandate } from '../clients/acceptSepaMandate.ts'
import { useMutation } from '@tanstack/react-query'

export const acceptSepaMandateMutationKey = () => [{ url: '/api-internal/sepa_mandates/{id}/accept' }] as const

export type AcceptSepaMandateMutationKey = ReturnType<typeof acceptSepaMandateMutationKey>

/**
 * @summary Accept Sepa Mandate
 * {@link /api-internal/sepa_mandates/:id/accept}
 */
export function useAcceptSepaMandate(
  options: {
    mutation?: UseMutationOptions<
      AcceptSepaMandateMutationResponse,
      ResponseErrorConfig<AcceptSepaMandate404 | AcceptSepaMandate422>,
      { id: AcceptSepaMandatePathParams['id']; data: AcceptSepaMandateMutationRequest }
    >
    client?: Partial<RequestConfig<AcceptSepaMandateMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? acceptSepaMandateMutationKey()

  return useMutation<
    AcceptSepaMandateMutationResponse,
    ResponseErrorConfig<AcceptSepaMandate404 | AcceptSepaMandate422>,
    { id: AcceptSepaMandatePathParams['id']; data: AcceptSepaMandateMutationRequest }
  >({
    mutationFn: async ({ id, data }) => {
      return acceptSepaMandate(id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}