// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CountPlanChangeDocumentsQueryResponse,
  CountPlanChangeDocumentsQueryParams,
  CountPlanChangeDocuments404,
  CountPlanChangeDocuments422,
} from '../types/CountPlanChangeDocuments.ts'

export function getCountPlanChangeDocumentsUrl() {
  return `/api-internal/plan_change_documents/count` as const
}

/**
 * @summary Count Plan Change Documents
 * {@link /api-internal/plan_change_documents/count}
 */
export async function countPlanChangeDocuments(params: CountPlanChangeDocumentsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CountPlanChangeDocumentsQueryResponse, ResponseErrorConfig<CountPlanChangeDocuments404 | CountPlanChangeDocuments422>, unknown>({
    method: 'GET',
    url: getCountPlanChangeDocumentsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}