import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { transactionFilterSchema } from './transactionFilterSchema.ts'
import { transactionSettleSchemaSchema } from './transactionSettleSchemaSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const settleTransactions200Schema = z.lazy(() => transactionSettleSchemaSchema)

/**
 * @description Not Found
 */
export const settleTransactions404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const settleTransactions422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const settleTransactionsMutationRequestSchema = z.lazy(() => transactionFilterSchema)

export const settleTransactionsMutationResponseSchema = z.lazy(() => settleTransactions200Schema)