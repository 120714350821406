import { ContractProposalOut, useRealizeContractProposal } from '@mg/api-wrappers/src/api-internal/index.ts';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert.tsx';
import { MGCard } from '@mg/ui/src/components/MGCard/MGCard.tsx';
import { MGFormFieldDate } from '@mg/ui/src/components/MGForm/MGForm.Field.Date.tsx';
import { MGFormSubmitButton } from '@mg/ui/src/components/MGForm/MGForm.SubmitButton.tsx';
import { MGForm } from '@mg/ui/src/components/MGForm/MGForm.tsx';
import { MGModal, MGModalBody, MGModalHandle, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { customerFullName } from '@mg/ui/src/presenters/customer.ts';
import { palette } from '@mg/ui/src/styles/palette.ts';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import dayjs, { type Dayjs } from 'dayjs';
import { ArrowRight, ReceiptEuro } from 'lucide-react';
import { ReactNode, useRef } from 'react';
import { toast } from 'react-toastify';
import IconHouseSvg from '../../../assets/icon_house.svg';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries.ts';
import { useTranslation } from '../../../i18n.tsx';

export interface ContractsCreateCustomerModalProps {
  contractProposal: ContractProposalOut;
}

export const ContractsCreateCustomerModal = ({ contractProposal }: ContractsCreateCustomerModalProps) => {
  const { t, tString } = useTranslation('contracts');

  const modalHandle = useRef<MGModalHandle>(null);

  const realizeContractProposal = useRealizeContractProposal({
    mutation: {
      async onSuccess() {
        await invalidateAllQueries();
        modalHandle.current?.close();
        toast.success(t('toast_create_customer_modal_success'));
      },
    },
  });

  const form = useForm<{ startDate: Dayjs | null }>({
    onSubmit: async ({ value }) => {
      await realizeContractProposal.mutateAsync({
        id: (contractProposal.id && +contractProposal.id) || 0,
        data: {
          final_start_date: dayjs(value.startDate).format('YYYY-MM-DD'),
        },
      });
    },
    validators: {
      onChange: ({ value }) => {
        if (!dayjs(value.startDate).isValid()) {
          return { fields: { startDate: tString('err_create_customer_modal_start_date_invalid') } };
        }
        return;
      },
    },
    defaultValues: {
      startDate: null,
    },
  });

  return (
    <MGModal
      ref={modalHandle}
      handleToggle={() => {
        form.reset();
      }}
      width={'800px'}
      preventAutoClose={realizeContractProposal.isPending}
      buttonComponent={
        <Button variant={'contained'} color={'primary'}>
          Bewohner anlegen
        </Button>
      }
    >
      <MGModalTitle>{t('tit_create_customer_modal')}</MGModalTitle>
      <Typography variant="small">{t('lbl_create_customer_modal_subtitle')}</Typography>
      <MGModalBody>
        <Stack direction={'row'} gap={2.5} marginTop={2.5} justifyContent={'stretch'} alignItems={'center'}>
          <StepCard
            avatar={'JS'}
            title={customerFullName({
              first_name: contractProposal.address_first_name,
              last_name: contractProposal.address_last_name,
            })}
            subtitle={t('lbl_create_customer_modal_customer')}
          />
          <Box>
            <ArrowRight size={24} color={palette.primary.main} />
          </Box>
          <StepCard
            avatar={<ReceiptEuro size={24} color={palette.primary.main} />}
            title={contractProposal.plan?.name ?? 'Nicht verfügbar'}
            subtitle={t('lbl_create_customer_modal_plan')}
          />
          <Box>
            <ArrowRight size={24} color={palette.primary.main} />
          </Box>
          <StepCard
            avatar={<img src={IconHouseSvg} alt="House" />}
            title={contractProposal.address_street + ' ' + contractProposal.address_street_number}
            subtitle={t('lbl_create_customer_modal_object')}
          />
        </Stack>

        <Box marginTop={5}>
          <MGForm form={form}>
            <MGFormFieldDate
              form={form}
              label={t('lbl_create_customer_modal_start_date')}
              name={'startDate'}
              FieldProps={{
                required: true,
              }}
            />

            {realizeContractProposal.isError && (
              <MGAlert severity="error" title={t('err_create_customer_modal_unknown_alert_title')}>
                {t('err_create_customer_modal_unknown_alert_text')}
              </MGAlert>
            )}

            <Stack direction={'row'} gap={2} justifyContent={'flex-end'} paddingTop={2}>
              <Button
                variant="outlined"
                disabled={realizeContractProposal.isPending}
                onClick={(e) => {
                  e.preventDefault();
                  modalHandle.current?.close();
                }}
              >
                {t('btn_create_customer_modal_cancel')}
              </Button>
              <MGFormSubmitButton
                icon={null}
                buttonLabel={t('btn_create_customer_modal_confirm')}
                form={form}
              />
            </Stack>
          </MGForm>
        </Box>
      </MGModalBody>
    </MGModal>
  );
};

const StepCard = ({ avatar, title, subtitle }: { avatar: ReactNode; title: string; subtitle: ReactNode }) => {
  return (
    <MGCard sx={{ padding: 2, flex: 1 }}>
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        <Avatar
          sx={{
            backgroundColor: palette.primary.light,
            color: palette.primary.main,
            width: '35px',
            height: '35px',
            fontSize: '12px',
          }}
        >
          {avatar}
        </Avatar>
        <Box>
          <Typography
            title={title}
            component={'p'}
            variant="regular"
            fontWeight={500}
            fontSize={'14px'}
            color={palette.text.black}
          >
            {title}
          </Typography>
          <Typography component={'p'} variant="small" fontWeight={300}>
            {subtitle}
          </Typography>
        </Box>
      </Stack>
    </MGCard>
  );
};
