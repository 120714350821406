import { CustomerSchema, useExportMeasuringPointData } from '@mg/api-wrappers/src/api-internal';
import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { Button } from '@mui/material';
import { ChevronDown, Download, FilePlus, Plus } from 'lucide-react';
import { useState } from 'react';
import { downloadFile } from '../../../../../helpers/downloadFile';
import { invalidateAllQueries } from '../../../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../../../i18n';
import { DjangoFormModal } from '../../../../modals/DjangoFormModal';

type Props = {
  customer: CustomerSchema | undefined;
};
export const CustomerDetailsMeteringActionMenu = ({ customer }: Props) => {
  const [addDataModalOpen, setAddDataModalOpen] = useState(false);
  const [addMeterModalOpen, setAddMeterModalOpen] = useState(false);
  const { t } = useTranslation('customerDetails');
  const exportMeteringDataQuery = useExportMeasuringPointData(
    {
      project_id: customer?.measuring_point_panel?.project || -1,
    },
    { query: { enabled: false } },
  );

  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('metering_menu_add_data'),
      icon: <FilePlus size={18} />,
      onClick: () => setAddDataModalOpen(!addDataModalOpen),
    },
    {
      text: t('metering_menu_export_data'),
      icon: <Download size={18} />,
      onClick: () =>
        downloadFile(exportMeteringDataQuery, { project_id: customer?.measuring_point_panel?.project }),
    },
    {
      text: t('metering_menu_add_meter'),
      icon: <Plus size={18} />,
      onClick: () => setAddMeterModalOpen(!addMeterModalOpen),
    },
  ];

  return (
    <>
      <MGActionMenu
        buttonComponent={
          <Button variant={'contained'} endIcon={<ChevronDown />}>
            {t('metering_menu_actions')}
          </Button>
        }
        menuItems={menuItems}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/data/create?panel_id=${customer?.measuring_point_panel?.id}`}
        title={t('metering_menu_add_data')}
        open={addDataModalOpen}
        handleToggle={() => {
          setAddDataModalOpen(!addDataModalOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddDataModalOpen(false);
        }}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/panels/${customer?.measuring_point_panel?.id}/measuring_point_switch`}
        title={t('metering_menu_add_meter')}
        open={addMeterModalOpen}
        handleToggle={() => {
          setAddMeterModalOpen(!addMeterModalOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddMeterModalOpen(false);
        }}
        prefill={[{ id: 'id_panel', value: customer?.measuring_point_panel?.id as number }]}
      />
    </>
  );
};
