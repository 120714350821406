import {
  deliverableDocumentType,
  SepaMandateSchema,
  useAcceptSepaMandate,
  useCancelSepaMandate,
  useGetSepaMandatePdf,
} from '@mg/api-wrappers/src/api-internal';
import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { MGConfirmModal } from '@mg/ui/src/components/MGConfirmModal';
import { palette } from '@mg/ui/src/styles';
import { FileCheck, Undo2 } from 'lucide-react';
import { toast } from 'react-toastify';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../i18n';
import { useDocumentContext } from '../../features/documents/document-context/document-context';
import { useCommonActionMenuItems } from './useCommonActionMenuItems';

export const DocumentsSepaTableRowActionMenu = () => {
  const { document } = useDocumentContext<SepaMandateSchema>();
  const { tString: t } = useTranslation('documents');

  const getSepaPdfQuery = useGetSepaMandatePdf(document.id, { query: { enabled: false } });
  const commonItems = useCommonActionMenuItems({
    documentType: deliverableDocumentType.sepa_mandate,
    downloadPdfQuery: getSepaPdfQuery,
  });

  const acceptSepaMutation = useAcceptSepaMandate();
  const cancelSepaMutation = useCancelSepaMandate();

  const menuItems: MGActionMenuItemProps[] = [
    ...commonItems,
    {
      text: t('accept_sepa'),
      hidden: document.state !== 'CREATED',
      icon: <FileCheck color={palette.success.main} size={'18'} />,
      onClick: () => {
        MGConfirmModal.fire({
          title: t('accept_sepa_title'),
          type: 'info',
          text: t('accept_sepa_text'),
          inputType: 'date',
        }).then(({ isConfirmed, value }) => {
          if (isConfirmed) {
            toast
              .promise(
                acceptSepaMutation.mutateAsync({ id: document.id, data: { accepted_date: value as string } }),
                {
                  pending: t('accept_sepa_loading'),
                  success: t('accept_sepa_success'),
                  error: t('oops_something_went_wrong'),
                },
              )
              .then(() => {
                invalidateAllQueries();
              });
          }
        });
      },
    },
    {
      text: t('revert_sepa'),
      hidden: document.state !== 'ACCEPTED',
      icon: <Undo2 color={palette.error.main} size={'18'} />,
      onClick: () => {
        MGConfirmModal.fire({
          title: t('revert_sepa_title'),
          type: 'error',
          text: t('revert_sepa_text', { reference: document.reference }),
          inputType: 'date',
          inputLabel: t('revert_sepa_cancelled_date'),
        }).then(({ isConfirmed, value }) => {
          if (isConfirmed) {
            toast
              .promise(
                cancelSepaMutation.mutateAsync({
                  id: document.id,
                  data: { cancelled_date: value as string },
                }),
                {
                  pending: t('cancel_sepa_loading'),
                  success: t('cancel_sepa_success'),
                  error: t('oops_something_went_wrong'),
                },
              )
              .then(() => {
                invalidateAllQueries();
              });
          }
        });
      },
    },
  ];

  return (
    <>
      <MGActionMenu menuItems={menuItems} />
    </>
  );
};
