// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  DeleteContractProposalMutationResponse,
  DeleteContractProposalPathParams,
  DeleteContractProposal404,
  DeleteContractProposal422,
} from '../types/DeleteContractProposal.ts'

export function getDeleteContractProposalUrl(id: DeleteContractProposalPathParams['id']) {
  return `/api-internal/contract_proposals/${id}/` as const
}

/**
 * @summary Delete Contract Proposal
 * {@link /api-internal/contract_proposals/:id/}
 */
export async function deleteContractProposal(id: DeleteContractProposalPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<DeleteContractProposalMutationResponse, ResponseErrorConfig<DeleteContractProposal404 | DeleteContractProposal422>, unknown>({
    method: 'DELETE',
    url: getDeleteContractProposalUrl(id).toString(),
    ...requestConfig,
  })
  return res.data
}