import { useCountPlans, useCountResidualPowerPlans } from '@mg/api-wrappers/src/api-internal';
import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout';
import { useQueryState } from 'nuqs';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { CreatePlanModal } from '../../modals/CreatePlanModal';
import { FilterProvider } from '../../shared/DataGridCard/DataGridCard.FilterProvider';
import { MGTabProps } from '../../shared/MetergridTabs';
import { PlansResidualPlans } from './Plans.ResidualPlans';
import { PlansTenantPlans } from './Plans.TenantPlans';

export const Plans = () => {
  const [currentTab, setCurrentTab] = useQueryState('tab', { defaultValue: 'plan' });
  const { t, tString } = useTranslation('plans');
  const projectFilter = useSelectedProjectsFilter();
  const { data: planCount } = useCountPlans({ ...projectFilter });
  const { data: residualPlanCount } = useCountResidualPowerPlans({ ...projectFilter });

  const tabs: MGTabProps[] = [
    {
      label: tString('tab_tenant_plan'),
      value: 'plan',
      count: planCount,
      selected: currentTab === 'plan',
      showError: planCount === 0,
    },
    {
      label: tString('tab_residual_plan'),
      value: 'residual_plan',
      count: residualPlanCount,
      selected: currentTab === 'residual_plan',
      showError: residualPlanCount === 0,
    },
  ];

  return (
    <FilterProvider>
      <MGPageLayout title={t('page_title')} subtitle={t('page_subtitle')} rightSection={<CreatePlanModal />}>
        {currentTab === 'plan' ? (
          <PlansTenantPlans setCurrentTab={setCurrentTab} tabs={tabs} />
        ) : (
          <PlansResidualPlans setCurrentTab={setCurrentTab} tabs={tabs} />
        )}
      </MGPageLayout>
    </FilterProvider>
  );
};
