import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  UpdateLetterMutationRequest,
  UpdateLetterMutationResponse,
  UpdateLetterPathParams,
  UpdateLetterQueryParams,
  UpdateLetter400,
  UpdateLetter404,
  UpdateLetter422,
} from '../types/UpdateLetter.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { updateLetter } from '../clients/updateLetter.ts'
import { useMutation } from '@tanstack/react-query'

export const updateLetterMutationKey = () => [{ url: '/api-internal/letters/{id}/' }] as const

export type UpdateLetterMutationKey = ReturnType<typeof updateLetterMutationKey>

/**
 * @summary Update Letter
 * {@link /api-internal/letters/:id/}
 */
export function useUpdateLetter(
  options: {
    mutation?: UseMutationOptions<
      UpdateLetterMutationResponse,
      ResponseErrorConfig<UpdateLetter400 | UpdateLetter404 | UpdateLetter422>,
      { id: UpdateLetterPathParams['id']; data?: UpdateLetterMutationRequest; params?: UpdateLetterQueryParams }
    >
    client?: Partial<RequestConfig<UpdateLetterMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateLetterMutationKey()

  return useMutation<
    UpdateLetterMutationResponse,
    ResponseErrorConfig<UpdateLetter400 | UpdateLetter404 | UpdateLetter422>,
    { id: UpdateLetterPathParams['id']; data?: UpdateLetterMutationRequest; params?: UpdateLetterQueryParams }
  >({
    mutationFn: async ({ id, data, params }) => {
      return updateLetter(id, data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}