import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { projectTenantAcquisitionConfigurationOutSchema } from './projectTenantAcquisitionConfigurationOutSchema.ts'
import { z } from 'zod'

export const removeTenantAcquisitionConfigurationPanelSelectionHelperImagePathParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const removeTenantAcquisitionConfigurationPanelSelectionHelperImage200Schema = z.lazy(() => projectTenantAcquisitionConfigurationOutSchema)

/**
 * @description Not Found
 */
export const removeTenantAcquisitionConfigurationPanelSelectionHelperImage404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const removeTenantAcquisitionConfigurationPanelSelectionHelperImage422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.lazy(() => modelValidationErrorOutSchema),
])

export const removeTenantAcquisitionConfigurationPanelSelectionHelperImageMutationResponseSchema = z.lazy(
  () => removeTenantAcquisitionConfigurationPanelSelectionHelperImage200Schema,
)