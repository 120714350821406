// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetPlanChangeDocumentPdfsAsZipQueryResponse,
  GetPlanChangeDocumentPdfsAsZipQueryParams,
  GetPlanChangeDocumentPdfsAsZip400,
  GetPlanChangeDocumentPdfsAsZip404,
  GetPlanChangeDocumentPdfsAsZip422,
} from '../types/GetPlanChangeDocumentPdfsAsZip.ts'

export function getGetPlanChangeDocumentPdfsAsZipUrl() {
  return `/api-internal/plan_change_documents/pdfs` as const
}

/**
 * @summary Get Plan Change Document Pdfs As Zip
 * {@link /api-internal/plan_change_documents/pdfs}
 */
export async function getPlanChangeDocumentPdfsAsZip(
  params: GetPlanChangeDocumentPdfsAsZipQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetPlanChangeDocumentPdfsAsZipQueryResponse,
    ResponseErrorConfig<GetPlanChangeDocumentPdfsAsZip400 | GetPlanChangeDocumentPdfsAsZip404 | GetPlanChangeDocumentPdfsAsZip422>,
    unknown
  >({ method: 'GET', url: getGetPlanChangeDocumentPdfsAsZipUrl().toString(), params, ...requestConfig })
  return res.data
}