// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  RenderContractMutationRequest,
  RenderContractMutationResponse,
  RenderContract400,
  RenderContract404,
  RenderContract422,
} from '../types/RenderContract.ts'

export function getRenderContractUrl() {
  return `/api-internal/contracts/render` as const
}

/**
 * @summary Render Contract
 * {@link /api-internal/contracts/render}
 */
export async function renderContract(
  data: RenderContractMutationRequest,
  config: Partial<RequestConfig<RenderContractMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    RenderContractMutationResponse,
    ResponseErrorConfig<RenderContract400 | RenderContract404 | RenderContract422>,
    RenderContractMutationRequest
  >({ method: 'POST', url: getRenderContractUrl().toString(), data, ...requestConfig })
  return res.data
}