import { AccountingData } from '../../../Accounting/Accounting.Data';

type Props = {
  customerId: number;
};
export const CustomerDetailsAccounting = ({ customerId }: Props) => {
  /*
   * Placeholder Component to have consistent architecture and the possibility to add funcitonality to the Details Tab
   * */

  return <AccountingData height={'calc(100vh - 550px)'} customerId={customerId} />;
};
