import { envVars } from '@mg/ui/src/envVars';
import { Menu, MenuItem, Typography } from '@mui/material';
import { LogOut, UserRound, UserRoundCheck } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLogout } from '../../../helpers/logout';
import { useUserInfo } from '../../../helpers/userInfo';
import { useTranslation } from '../../../i18n';

export const SidebarSettingsMenu = ({ open, onToggle }) => {
  const [userInfo] = useUserInfo();
  const { t } = useTranslation('sidebar');
  const logout = useLogout();

  return (
    <Menu
      open={open}
      onClose={onToggle}
      onClick={onToggle}
      anchorEl={document.getElementById('settings-menu-anchor')}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{ marginLeft: '20px' }}
      slotProps={{
        paper: {
          sx: {
            minWidth: '200px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: 10,
              left: 0,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateX(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
    >
      <MenuItem component={Link} to={'/profile'}>
        <UserRound size={18} />
        <Typography sx={{ marginLeft: '10px' }}>{t('settings_dropdown_profile')}</Typography>
      </MenuItem>
      {userInfo?.is_staff && (
        <MenuItem component={'a'} href={`${envVars.VITE_BACKEND_HOST}/admin`}>
          <UserRoundCheck size={18} />
          <Typography sx={{ marginLeft: '10px' }}>{t('settings_dropdown_admin')}</Typography>
        </MenuItem>
      )}
      <MenuItem onClick={() => logout()}>
        <LogOut size={18} />
        <Typography sx={{ marginLeft: '10px' }}>{t('settings_dropdown_logout')}</Typography>
      </MenuItem>
    </Menu>
  );
};
