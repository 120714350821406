// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetNewPasswordMutationRequest,
  SetNewPasswordMutationResponse,
  SetNewPassword401,
  SetNewPassword404,
  SetNewPassword422,
} from '../types/SetNewPassword.ts'

export function getSetNewPasswordUrl() {
  return `/api-internal/auth/set_new_password` as const
}

/**
 * @summary Set New Password
 * {@link /api-internal/auth/set_new_password}
 */
export async function setNewPassword(
  data: SetNewPasswordMutationRequest,
  config: Partial<RequestConfig<SetNewPasswordMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    SetNewPasswordMutationResponse,
    ResponseErrorConfig<SetNewPassword401 | SetNewPassword404 | SetNewPassword422>,
    SetNewPasswordMutationRequest
  >({ method: 'POST', url: getSetNewPasswordUrl().toString(), data, ...requestConfig })
  return res.data
}