import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CreateLevyStructureMutationRequest,
  CreateLevyStructureMutationResponse,
  CreateLevyStructure404,
  CreateLevyStructure422,
} from '../types/CreateLevyStructure.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { createLevyStructure } from '../clients/createLevyStructure.ts'
import { useMutation } from '@tanstack/react-query'

export const createLevyStructureMutationKey = () => [{ url: '/api-internal/levy_structures' }] as const

export type CreateLevyStructureMutationKey = ReturnType<typeof createLevyStructureMutationKey>

/**
 * @summary Create Levy Structure
 * {@link /api-internal/levy_structures}
 */
export function useCreateLevyStructure(
  options: {
    mutation?: UseMutationOptions<
      CreateLevyStructureMutationResponse,
      ResponseErrorConfig<CreateLevyStructure404 | CreateLevyStructure422>,
      { data: CreateLevyStructureMutationRequest }
    >
    client?: Partial<RequestConfig<CreateLevyStructureMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createLevyStructureMutationKey()

  return useMutation<
    CreateLevyStructureMutationResponse,
    ResponseErrorConfig<CreateLevyStructure404 | CreateLevyStructure422>,
    { data: CreateLevyStructureMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return createLevyStructure(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}