import { measuringPointPanelSchemaSchema } from './measuringPointPanelSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getMeasuringPointPanelPathParamsSchema = z.object({
  id: z.number().int(),
})

export const getMeasuringPointPanelQueryParamsSchema = z
  .object({
    include_project: z.boolean().default(false),
    include_customer: z.boolean().default(false),
    include_measuring_points: z.boolean().default(false),
    include_current_measuring_point: z.boolean().default(false),
    include_last_reading: z.boolean().default(false),
    include_status: z.boolean().default(false),
    include_subscriptions: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const getMeasuringPointPanel200Schema = z.lazy(() => measuringPointPanelSchemaSchema)

/**
 * @description Not Found
 */
export const getMeasuringPointPanel404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getMeasuringPointPanel422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getMeasuringPointPanelQueryResponseSchema = z.lazy(() => getMeasuringPointPanel200Schema)