import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetTenantAcquisitionConfigurationQueryResponse,
  GetTenantAcquisitionConfigurationPathParams,
  GetTenantAcquisitionConfiguration404,
  GetTenantAcquisitionConfiguration422,
} from '../types/GetTenantAcquisitionConfiguration.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getTenantAcquisitionConfiguration } from '../clients/getTenantAcquisitionConfiguration.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getTenantAcquisitionConfigurationQueryKey = (project_id: GetTenantAcquisitionConfigurationPathParams['project_id']) =>
  [{ url: '/api-internal/projects/:project_id/tenant_acquisition_configuration', params: { project_id: project_id } }] as const

export type GetTenantAcquisitionConfigurationQueryKey = ReturnType<typeof getTenantAcquisitionConfigurationQueryKey>

export function getTenantAcquisitionConfigurationQueryOptions(
  project_id: GetTenantAcquisitionConfigurationPathParams['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getTenantAcquisitionConfigurationQueryKey(project_id)
  return queryOptions<
    GetTenantAcquisitionConfigurationQueryResponse,
    ResponseErrorConfig<GetTenantAcquisitionConfiguration404 | GetTenantAcquisitionConfiguration422>,
    GetTenantAcquisitionConfigurationQueryResponse,
    typeof queryKey
  >({
    enabled: !!project_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getTenantAcquisitionConfiguration(project_id, config)
    },
  })
}

/**
 * @summary Get Tenant Acquisition Configuration
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration}
 */
export function useGetTenantAcquisitionConfiguration<
  TData = GetTenantAcquisitionConfigurationQueryResponse,
  TQueryData = GetTenantAcquisitionConfigurationQueryResponse,
  TQueryKey extends QueryKey = GetTenantAcquisitionConfigurationQueryKey,
>(
  project_id: GetTenantAcquisitionConfigurationPathParams['project_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetTenantAcquisitionConfigurationQueryResponse,
        ResponseErrorConfig<GetTenantAcquisitionConfiguration404 | GetTenantAcquisitionConfiguration422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getTenantAcquisitionConfigurationQueryKey(project_id)

  const query = useQuery({
    ...(getTenantAcquisitionConfigurationQueryOptions(project_id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetTenantAcquisitionConfiguration404 | GetTenantAcquisitionConfiguration422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}