// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ObtainTokenMutationRequest, ObtainTokenMutationResponse, ObtainToken404, ObtainToken422 } from '../types/ObtainToken.ts'

export function getObtainTokenUrl() {
  return `/api-internal/auth/token` as const
}

/**
 * @summary Obtain Token
 * {@link /api-internal/auth/token}
 */
export async function obtainToken(
  data: ObtainTokenMutationRequest,
  config: Partial<RequestConfig<ObtainTokenMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ObtainTokenMutationResponse, ResponseErrorConfig<ObtainToken404 | ObtainToken422>, ObtainTokenMutationRequest>({
    method: 'POST',
    url: getObtainTokenUrl().toString(),
    data,
    ...requestConfig,
  })
  return res.data
}