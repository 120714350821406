// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListInvoicesQueryResponse, ListInvoicesQueryParams, ListInvoices404, ListInvoices422 } from '../types/ListInvoices.ts'

export function getListInvoicesUrl() {
  return `/api-internal/invoices/` as const
}

/**
 * @summary List Invoices
 * {@link /api-internal/invoices/}
 */
export async function listInvoices(params: ListInvoicesQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListInvoicesQueryResponse, ResponseErrorConfig<ListInvoices404 | ListInvoices422>, unknown>({
    method: 'GET',
    url: getListInvoicesUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}