import { levyStructureSchemaSchema } from './levyStructureSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getLevyStructurePathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const getLevyStructure200Schema = z.lazy(() => levyStructureSchemaSchema)

/**
 * @description Not Found
 */
export const getLevyStructure404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getLevyStructure422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getLevyStructureQueryResponseSchema = z.lazy(() => getLevyStructure200Schema)