import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetPlanChangeDocumentPdfQueryResponse,
  GetPlanChangeDocumentPdfPathParams,
  GetPlanChangeDocumentPdf400,
  GetPlanChangeDocumentPdf404,
  GetPlanChangeDocumentPdf422,
} from '../types/GetPlanChangeDocumentPdf.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getPlanChangeDocumentPdf } from '../clients/getPlanChangeDocumentPdf.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getPlanChangeDocumentPdfQueryKey = (id: GetPlanChangeDocumentPdfPathParams['id']) =>
  [{ url: '/api-internal/plan_change_documents/:id/pdf', params: { id: id } }] as const

export type GetPlanChangeDocumentPdfQueryKey = ReturnType<typeof getPlanChangeDocumentPdfQueryKey>

export function getPlanChangeDocumentPdfQueryOptions(
  id: GetPlanChangeDocumentPdfPathParams['id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getPlanChangeDocumentPdfQueryKey(id)
  return queryOptions<
    GetPlanChangeDocumentPdfQueryResponse,
    ResponseErrorConfig<GetPlanChangeDocumentPdf400 | GetPlanChangeDocumentPdf404 | GetPlanChangeDocumentPdf422>,
    GetPlanChangeDocumentPdfQueryResponse,
    typeof queryKey
  >({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getPlanChangeDocumentPdf(id, config)
    },
  })
}

/**
 * @summary Get Plan Change Document Pdf
 * {@link /api-internal/plan_change_documents/:id/pdf}
 */
export function useGetPlanChangeDocumentPdf<
  TData = GetPlanChangeDocumentPdfQueryResponse,
  TQueryData = GetPlanChangeDocumentPdfQueryResponse,
  TQueryKey extends QueryKey = GetPlanChangeDocumentPdfQueryKey,
>(
  id: GetPlanChangeDocumentPdfPathParams['id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetPlanChangeDocumentPdfQueryResponse,
        ResponseErrorConfig<GetPlanChangeDocumentPdf400 | GetPlanChangeDocumentPdf404 | GetPlanChangeDocumentPdf422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getPlanChangeDocumentPdfQueryKey(id)

  const query = useQuery({
    ...(getPlanChangeDocumentPdfQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetPlanChangeDocumentPdf400 | GetPlanChangeDocumentPdf404 | GetPlanChangeDocumentPdf422>> & {
    queryKey: TQueryKey
  }

  query.queryKey = queryKey as TQueryKey

  return query
}