// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetTenantAcquisitionConfigurationQueryResponse,
  GetTenantAcquisitionConfigurationPathParams,
  GetTenantAcquisitionConfiguration404,
  GetTenantAcquisitionConfiguration422,
} from '../types/GetTenantAcquisitionConfiguration.ts'

export function getGetTenantAcquisitionConfigurationUrl(project_id: GetTenantAcquisitionConfigurationPathParams['project_id']) {
  return `/api-internal/projects/${project_id}/tenant_acquisition_configuration` as const
}

/**
 * @summary Get Tenant Acquisition Configuration
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration}
 */
export async function getTenantAcquisitionConfiguration(
  project_id: GetTenantAcquisitionConfigurationPathParams['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetTenantAcquisitionConfigurationQueryResponse,
    ResponseErrorConfig<GetTenantAcquisitionConfiguration404 | GetTenantAcquisitionConfiguration422>,
    unknown
  >({ method: 'GET', url: getGetTenantAcquisitionConfigurationUrl(project_id).toString(), ...requestConfig })
  return res.data
}