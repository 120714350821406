// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ExportMeasuringPointDataQueryResponse,
  ExportMeasuringPointDataQueryParams,
  ExportMeasuringPointData400,
  ExportMeasuringPointData404,
  ExportMeasuringPointData422,
} from '../types/ExportMeasuringPointData.ts'

export function getExportMeasuringPointDataUrl() {
  return `/api-internal/measuring_point_data/export` as const
}

/**
 * @description Export customer data
 * @summary Export Measuring Point Data
 * {@link /api-internal/measuring_point_data/export}
 */
export async function exportMeasuringPointData(params: ExportMeasuringPointDataQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    ExportMeasuringPointDataQueryResponse,
    ResponseErrorConfig<ExportMeasuringPointData400 | ExportMeasuringPointData404 | ExportMeasuringPointData422>,
    unknown
  >({ method: 'GET', url: getExportMeasuringPointDataUrl().toString(), params, ...requestConfig })
  return res.data
}