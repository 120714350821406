// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { BillingMutationRequest, BillingMutationResponse, Billing400, Billing404, Billing422 } from '../types/Billing.ts'

export function getBillingUrl() {
  return `/api-internal/billing/billing` as const
}

/**
 * @summary Billing
 * {@link /api-internal/billing/billing}
 */
export async function billing(data?: BillingMutationRequest, config: Partial<RequestConfig<BillingMutationRequest>> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<BillingMutationResponse, ResponseErrorConfig<Billing400 | Billing404 | Billing422>, BillingMutationRequest>({
    method: 'POST',
    url: getBillingUrl().toString(),
    data,
    ...requestConfig,
  })
  return res.data
}