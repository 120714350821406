import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetTransactionStatisticsQueryResponse,
  GetTransactionStatisticsQueryParams,
  GetTransactionStatistics404,
  GetTransactionStatistics422,
} from '../types/GetTransactionStatistics.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getTransactionStatistics } from '../clients/getTransactionStatistics.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getTransactionStatisticsQueryKey = (params?: GetTransactionStatisticsQueryParams) =>
  [{ url: '/api-internal/transactions/statistics' }, ...(params ? [params] : [])] as const

export type GetTransactionStatisticsQueryKey = ReturnType<typeof getTransactionStatisticsQueryKey>

export function getTransactionStatisticsQueryOptions(
  params?: GetTransactionStatisticsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getTransactionStatisticsQueryKey(params)
  return queryOptions<
    GetTransactionStatisticsQueryResponse,
    ResponseErrorConfig<GetTransactionStatistics404 | GetTransactionStatistics422>,
    GetTransactionStatisticsQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getTransactionStatistics(params, config)
    },
  })
}

/**
 * @summary Get Transaction Statistics
 * {@link /api-internal/transactions/statistics}
 */
export function useGetTransactionStatistics<
  TData = GetTransactionStatisticsQueryResponse,
  TQueryData = GetTransactionStatisticsQueryResponse,
  TQueryKey extends QueryKey = GetTransactionStatisticsQueryKey,
>(
  params?: GetTransactionStatisticsQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetTransactionStatisticsQueryResponse,
        ResponseErrorConfig<GetTransactionStatistics404 | GetTransactionStatistics422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getTransactionStatisticsQueryKey(params)

  const query = useQuery({
    ...(getTransactionStatisticsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetTransactionStatistics404 | GetTransactionStatistics422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}