import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  UpdateMeasuringPointPanelMutationRequest,
  UpdateMeasuringPointPanelMutationResponse,
  UpdateMeasuringPointPanelPathParams,
  UpdateMeasuringPointPanel404,
  UpdateMeasuringPointPanel422,
} from '../types/UpdateMeasuringPointPanel.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { updateMeasuringPointPanel } from '../clients/updateMeasuringPointPanel.ts'
import { useMutation } from '@tanstack/react-query'

export const updateMeasuringPointPanelMutationKey = () => [{ url: '/api-internal/measuring_point_panels/{id}' }] as const

export type UpdateMeasuringPointPanelMutationKey = ReturnType<typeof updateMeasuringPointPanelMutationKey>

/**
 * @summary Update Measuring Point Panel
 * {@link /api-internal/measuring_point_panels/:id}
 */
export function useUpdateMeasuringPointPanel(
  options: {
    mutation?: UseMutationOptions<
      UpdateMeasuringPointPanelMutationResponse,
      ResponseErrorConfig<UpdateMeasuringPointPanel404 | UpdateMeasuringPointPanel422>,
      { id: UpdateMeasuringPointPanelPathParams['id']; data?: UpdateMeasuringPointPanelMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateMeasuringPointPanelMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMeasuringPointPanelMutationKey()

  return useMutation<
    UpdateMeasuringPointPanelMutationResponse,
    ResponseErrorConfig<UpdateMeasuringPointPanel404 | UpdateMeasuringPointPanel422>,
    { id: UpdateMeasuringPointPanelPathParams['id']; data?: UpdateMeasuringPointPanelMutationRequest }
  >({
    mutationFn: async ({ id, data }) => {
      return updateMeasuringPointPanel(id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}