// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { DeletePaymentPlanMutationResponse, DeletePaymentPlanPathParams, DeletePaymentPlan404, DeletePaymentPlan422 } from '../types/DeletePaymentPlan.ts'

export function getDeletePaymentPlanUrl(id: DeletePaymentPlanPathParams['id']) {
  return `/api-internal/payment_plans/${id}` as const
}

/**
 * @summary Delete Payment Plan
 * {@link /api-internal/payment_plans/:id}
 */
export async function deletePaymentPlan(id: DeletePaymentPlanPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<DeletePaymentPlanMutationResponse, ResponseErrorConfig<DeletePaymentPlan404 | DeletePaymentPlan422>, unknown>({
    method: 'DELETE',
    url: getDeletePaymentPlanUrl(id).toString(),
    ...requestConfig,
  })
  return res.data
}