import { letterCreateSchemaSchema } from './letterCreateSchemaSchema.ts'
import { letterSchemaSchema } from './letterSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const createLetterQueryParamsSchema = z
  .object({
    include_customer: z.boolean().default(false),
    include_pdf: z.boolean().default(false),
  })
  .optional()

/**
 * @description Created
 */
export const createLetter201Schema = z.lazy(() => letterSchemaSchema)

/**
 * @description Bad Request
 */
export const createLetter400Schema = z.object({})

/**
 * @description Not Found
 */
export const createLetter404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const createLetter422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const createLetterMutationRequestSchema = z.lazy(() => letterCreateSchemaSchema)

export const createLetterMutationResponseSchema = z.lazy(() => createLetter201Schema)