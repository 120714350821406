// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CancelSepaMandateMutationRequest,
  CancelSepaMandateMutationResponse,
  CancelSepaMandatePathParams,
  CancelSepaMandate404,
  CancelSepaMandate422,
} from '../types/CancelSepaMandate.ts'

export function getCancelSepaMandateUrl(id: CancelSepaMandatePathParams['id']) {
  return `/api-internal/sepa_mandates/${id}/cancel` as const
}

/**
 * @summary Cancel Sepa Mandate
 * {@link /api-internal/sepa_mandates/:id/cancel}
 */
export async function cancelSepaMandate(
  id: CancelSepaMandatePathParams['id'],
  data: CancelSepaMandateMutationRequest,
  config: Partial<RequestConfig<CancelSepaMandateMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CancelSepaMandateMutationResponse,
    ResponseErrorConfig<CancelSepaMandate404 | CancelSepaMandate422>,
    CancelSepaMandateMutationRequest
  >({ method: 'POST', url: getCancelSepaMandateUrl(id).toString(), data, ...requestConfig })
  return res.data
}