import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListPlansQueryResponse, ListPlansQueryParams, ListPlans404, ListPlans422 } from '../types/ListPlans.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { listPlans } from '../clients/listPlans.ts'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listPlansInfiniteQueryKey = (params: ListPlansQueryParams) => [{ url: '/api-internal/plans/' }, ...(params ? [params] : [])] as const

export type ListPlansInfiniteQueryKey = ReturnType<typeof listPlansInfiniteQueryKey>

export function listPlansInfiniteQueryOptions(params: ListPlansQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listPlansInfiniteQueryKey(params)
  return infiniteQueryOptions<ListPlansQueryResponse, ResponseErrorConfig<ListPlans404 | ListPlans422>, ListPlansQueryResponse, typeof queryKey, number>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListPlansQueryParams['page']
      }
      return listPlans(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Plans
 * {@link /api-internal/plans/}
 */
export function useListPlansInfinite<
  TData = InfiniteData<ListPlansQueryResponse>,
  TQueryData = ListPlansQueryResponse,
  TQueryKey extends QueryKey = ListPlansInfiniteQueryKey,
>(
  params: ListPlansQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListPlansQueryResponse, ResponseErrorConfig<ListPlans404 | ListPlans422>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listPlansInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listPlansInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ResponseErrorConfig<ListPlans404 | ListPlans422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}