// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ReopenTransactionsMutationRequest,
  ReopenTransactionsMutationResponse,
  ReopenTransactions404,
  ReopenTransactions422,
} from '../types/ReopenTransactions.ts'

export function getReopenTransactionsUrl() {
  return `/api-internal/transactions/reopen` as const
}

/**
 * @summary Reopen Transactions
 * {@link /api-internal/transactions/reopen}
 */
export async function reopenTransactions(
  data?: ReopenTransactionsMutationRequest,
  config: Partial<RequestConfig<ReopenTransactionsMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    ReopenTransactionsMutationResponse,
    ResponseErrorConfig<ReopenTransactions404 | ReopenTransactions422>,
    ReopenTransactionsMutationRequest
  >({ method: 'POST', url: getReopenTransactionsUrl().toString(), data, ...requestConfig })
  return res.data
}