import { Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { accessTokenAtom } from '../../../jotai/atoms.ts';
import { LoginForm } from './Login.Form';

export const Login = () => {
  const { t } = useTranslation('login');

  const accessToken = useAtomValue(accessTokenAtom);
  const navigate = useNavigate();
  useEffect(() => {
    if (accessToken) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <Stack maxWidth={'900px'} justifyContent={'center'} spacing={5}>
      <Stack spacing={3}>
        <Typography variant={'h1'} fontWeight={'500'}>
          {t('welcome')}
        </Typography>
        <Typography variant={'regular'} fontWeight={'300'}>
          {t('login_now')}
        </Typography>
      </Stack>
      <LoginForm />
    </Stack>
  );
};
