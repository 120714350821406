import { bulkMarkAsSentInSchema } from './bulkMarkAsSentInSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const bulkMarkAsSent200Schema = z.unknown()

/**
 * @description Not Found
 */
export const bulkMarkAsSent404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const bulkMarkAsSent422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const bulkMarkAsSentMutationRequestSchema = z.lazy(() => bulkMarkAsSentInSchema)

export const bulkMarkAsSentMutationResponseSchema = z.lazy(() => bulkMarkAsSent200Schema)