import { useCreateLevyStructure, useGetDefaultLevyYears } from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert';
import { MGFormFieldNumber } from '@mg/ui/src/components/MGForm';
import { addErrorsToFormFields } from '@mg/ui/src/components/MGForm/addErrorsToFormFields.ts';
import { MGForm } from '@mg/ui/src/components/MGForm/MGForm';
import { MGFormFieldRadioGroup } from '@mg/ui/src/components/MGForm/MGForm.Field.RadioGroup';
import { MGFormSubmitButton } from '@mg/ui/src/components/MGForm/MGForm.SubmitButton';
import { MGHelpButton } from '@mg/ui/src/components/MGHelpButton';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { MGTooltip } from '@mg/ui/src/components/MGTooltip';
import { palette } from '@mg/ui/src/styles';
import { Box, Button, Grid2, Typography } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import dayjs from 'dayjs';
import { CircleX, Info, InfoIcon } from 'lucide-react';
import { useState } from 'react';
import { z } from 'zod';
import { invalidateAllQueries } from '../../../../helpers/invalidateAllQueries.ts';
import { useSelectedProjectsFilter } from '../../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../../i18n';

import KonzessionsabgabenListePNG from '../../../../assets/konzessionsabgaben_liste.png';

type LevyStructureErrorMessage = {
  title: string;
  subtitle: string;
};

type Props = {
  handleClose: () => void;
};

export const AddLevyStructureForm = ({ handleClose }: Props) => {
  const [isKonzessionsabgabenListOpen, setIsKonzessionsabgabenListOpen] = useState(false);

  const currentYear = dayjs().year();
  const createLevyStructure = useCreateLevyStructure();
  const levyStructureYears = useGetDefaultLevyYears();
  const projectFilter = useSelectedProjectsFilter();

  const mappedYears = levyStructureYears.data?.years.map((y) => ({ value: String(y), label: String(y) }));
  const { t, tString } = useTranslation('levies');
  const measuringUnitLabel = t('lbl_measuring_unit');

  const levyStructureErrorMessageMapping: Record<string, LevyStructureErrorMessage> = {
    LEVY_STRUCTURE_ALREADY_EXISTS: {
      title: tString('err_levy_structure_already_exists'),
      subtitle: tString('err_levy_structure_already_exists_message', {
        start: createLevyStructure.error?.errors[0].interval_start,
        end: createLevyStructure.error?.errors[0].interval_end,
      }),
    },
    LEVY_STRUCTURE_ALREADY_EXISTS_NO_VALID_TO: {
      title: tString('err_levy_structure_already_exists_no_valid_to_date'),
      subtitle: tString('err_levy_structure_already_exists_no_valid_to_date_message', {
        start: createLevyStructure.error?.errors[0].interval_start,
      }),
    },
    LEVY_STRUCTURE_DATA_FOR_YEAR_NOT_EXISTS: {
      title: tString('err_data_for_levy_structure_not_exists'),
      subtitle: tString('err_data_for_levy_structure_not_exists_message'),
    },
  };

  const levyValidatorSchema = z
    .string()
    .min(1, { message: tString('err_input_too_short') })
    .max(19, { message: tString('err_input_too_long') })
    .regex(/^\d+([.,]\d{1,4})?$/, tString('err_only_numbers_allowed'))
    .transform((val) => Number(val))
    .refine((num) => num >= 0, { message: tString('err_input_must_be_positive') });

  const levyStructureValidatorSchema = z.object({
    project_id: z.number(),
    year: z.number(),
    netzentgelte: levyValidatorSchema,
    konzessionsabgaben: levyValidatorSchema,
    error: z.string(),
  });

  const form = useForm({
    onSubmit: async ({ value }) => {
      await createLevyStructure.mutateAsync(
        { data: value },
        {
          onError: (errOut) => {
            if (errOut.type === 'https://metergrid.de/api-errors/model-validation-error') {
              addErrorsToFormFields({ form, modelValidationError: errOut });
            }
          },
        },
      );
      await invalidateAllQueries();
      handleClose();
    },
    validators: {
      onSubmit: levyStructureValidatorSchema,
    },
    defaultValues: {
      project_id: projectFilter.project_id,
      year: currentYear,
      netzentgelte: '',
      konzessionsabgaben: '',
      error: '',
    },
  });

  return (
    <MGForm form={form}>
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          <Typography variant={'small'}>{t('lbl_create_levy_structure')}</Typography>
        </Grid2>
        <Grid2 container size={12}>
          {mappedYears ? (
            <MGFormFieldRadioGroup
              form={form}
              options={mappedYears}
              defaultOption={{ value: String(currentYear), label: String(currentYear) }}
              name={'year'}
              label={t('lbl_levy_structure_year')}
              showTextOnly
              RadioGroupProps={{
                row: true,
                sx: { justifyContent: 'space-between', gap: '5px' },
              }}
            />
          ) : (
            <MGLoader size={80} />
          )}
        </Grid2>
        <Grid2 size={6}>
          <MGFormFieldNumber
            form={form}
            name={'netzentgelte'}
            label={
              <>
                {t('lbl_netzentgelte') + ` ${measuringUnitLabel}`}
                <MGHelpButton href={'https://www.metergrid.de/help-center/anleitungen#netzentgelte'} />
              </>
            }
            highlight
            step={'0.1'}
            min={0}
            endAdornment={tString('lbl_cent')}
            TextFieldProps={{
              placeholder: tString('lbl_netzentgelte'),
              required: true,
              autoFocus: true,
              autoComplete: 'off',
            }}
          />
        </Grid2>
        <Grid2 size={6}>
          <MGFormFieldNumber
            form={form}
            name={'konzessionsabgaben'}
            label={
              <Box>
                {t('lbl_konzessionsabgaben') + ` ${measuringUnitLabel}`}
                <MGTooltip
                  text={
                    <Box
                      component={'img'}
                      sx={{
                        height: 250,
                        width: 500,
                        maxHeight: { md: 300 },
                        maxWidth: { md: 600 },
                      }}
                      alt={tString('lbl_konzessionsabgaben_list_alt_text')}
                      src={KonzessionsabgabenListePNG}
                    />
                  }
                  open={isKonzessionsabgabenListOpen}
                  slotProps={{ tooltip: { style: { maxWidth: 600, borderRadius: '25px' } } }}
                >
                  <Button
                    sx={{ m: 0, p: 0, minWidth: 0 }}
                    onClick={() => {
                      setIsKonzessionsabgabenListOpen(!isKonzessionsabgabenListOpen);
                    }}
                  >
                    <Info color={palette.primary.dark} style={{ marginLeft: 5 }} />
                  </Button>
                </MGTooltip>
              </Box>
            }
            highlight
            step={'0.1'}
            min={0}
            endAdornment={tString('lbl_cent')}
            TextFieldProps={{
              placeholder: tString('lbl_konzessionsabgaben'),
              required: true,
            }}
          />
        </Grid2>
        <Grid2 size={12}>
          <MGAlert
            icon={<InfoIcon />}
            severity={'info'}
            title={t('tit_levy_structure_info')}
            subtitle={t('lbl_levy_structure_info_subtitle')}
          />
        </Grid2>
        {createLevyStructure.error && (
          <Grid2 size={12}>
            <MGAlert
              icon={<CircleX />}
              severity={'error'}
              title={levyStructureErrorMessageMapping[createLevyStructure.error?.errors[0].code].title}
              subtitle={levyStructureErrorMessageMapping[createLevyStructure.error?.errors[0].code].subtitle}
            />
          </Grid2>
        )}
        <Grid2 container size={12} sx={{ justifyContent: 'space-between', mt: 4 }}>
          <Grid2>
            <Button variant={'text'} onClick={handleClose}>
              {t('btn_cancel_levy_structure_create')}
            </Button>
          </Grid2>
          <Grid2>
            <MGFormSubmitButton form={form} buttonLabel={t('btn_submit_levy_structure')} />
          </Grid2>
        </Grid2>
      </Grid2>
    </MGForm>
  );
};
