// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetProjectQueryResponse, GetProjectPathParams, GetProjectQueryParams, GetProject404, GetProject422 } from '../types/GetProject.ts'

export function getGetProjectUrl(id: GetProjectPathParams['id']) {
  return `/api-internal/projects/${id}` as const
}

/**
 * @summary Get Project
 * {@link /api-internal/projects/:id}
 */
export async function getProject(
  id: GetProjectPathParams['id'],
  params?: GetProjectQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetProjectQueryResponse, ResponseErrorConfig<GetProject404 | GetProject422>, unknown>({
    method: 'GET',
    url: getGetProjectUrl(id).toString(),
    params,
    ...requestConfig,
  })
  return res.data
}