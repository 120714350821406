// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { BulkMarkAsSentMutationRequest, BulkMarkAsSentMutationResponse, BulkMarkAsSent404, BulkMarkAsSent422 } from '../types/BulkMarkAsSent.ts'

export function getBulkMarkAsSentUrl() {
  return `/api-internal/document_email/bulk_mark_as_sent` as const
}

/**
 * @summary Bulk Mark As Sent
 * {@link /api-internal/document_email/bulk_mark_as_sent}
 */
export async function bulkMarkAsSent(
  data: BulkMarkAsSentMutationRequest,
  config: Partial<RequestConfig<BulkMarkAsSentMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<BulkMarkAsSentMutationResponse, ResponseErrorConfig<BulkMarkAsSent404 | BulkMarkAsSent422>, BulkMarkAsSentMutationRequest>({
    method: 'POST',
    url: getBulkMarkAsSentUrl().toString(),
    data,
    ...requestConfig,
  })
  return res.data
}