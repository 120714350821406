import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ChangePasswordMutationRequest, ChangePasswordMutationResponse, ChangePassword404, ChangePassword422 } from '../types/ChangePassword.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { changePassword } from '../clients/changePassword.ts'
import { useMutation } from '@tanstack/react-query'

export const changePasswordMutationKey = () => [{ url: '/api-internal/users/current/change-password' }] as const

export type ChangePasswordMutationKey = ReturnType<typeof changePasswordMutationKey>

/**
 * @summary Change Password
 * {@link /api-internal/users/current/change-password}
 */
export function useChangePassword(
  options: {
    mutation?: UseMutationOptions<
      ChangePasswordMutationResponse,
      ResponseErrorConfig<ChangePassword404 | ChangePassword422>,
      { data: ChangePasswordMutationRequest }
    >
    client?: Partial<RequestConfig<ChangePasswordMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? changePasswordMutationKey()

  return useMutation<ChangePasswordMutationResponse, ResponseErrorConfig<ChangePassword404 | ChangePassword422>, { data: ChangePasswordMutationRequest }>({
    mutationFn: async ({ data }) => {
      return changePassword(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}