import { Box } from '@mui/material';
import {
  CreditCard,
  FilePenLine,
  Files,
  Gauge,
  Layers,
  LayoutDashboard,
  ReceiptEuro,
  ReceiptText,
  UsersRound,
} from 'lucide-react';
import { useUserInfo } from '../../../helpers/userInfo';
import { SidebarItem } from './Sidebar.Item';

export const SidebarMainItems = () => {
  const [userInfo] = useUserInfo();

  const hasTenantAcquisition = userInfo?.flags.tenant_acquisition;

  const items = [
    { path: '/', i18nKey: 'route_dashboard', icon: <LayoutDashboard size={20} /> },
    { path: '/projects', i18nKey: 'route_projects', icon: <Layers size={20} /> },
    { path: '/metering', i18nKey: 'route_metering', icon: <Gauge size={20} /> },
    { path: '/customers', i18nKey: 'route_customers', icon: <UsersRound size={20} /> },
    {
      path: '/contracts',
      i18nKey: 'route_contracts',
      icon: <FilePenLine size={20} />,
      hidden: !hasTenantAcquisition,
    },
    { path: '/levystructures', i18nKey: 'route_levystructures', icon: <ReceiptText size={20} /> },
    { path: '/plans', i18nKey: 'route_plans', icon: <ReceiptEuro size={20} /> },
    { path: '/transactions', i18nKey: 'route_transactions', icon: <CreditCard size={20} /> },
    { path: '/documents', i18nKey: 'route_documents', icon: <Files size={20} /> },
    /*
    * Submenu example
    * {
      i18nKey: 'route_accounting',
      icon: <ReceiptText size={20} />,
      children: [
        { path: '/projects/choose-billing-date/', i18nKey: 'route_billing', icon: <ReceiptText size={20} /> },
        { path: '/sepa/create/', i18nKey: 'route_plans', icon: <ReceiptEuro size={20} /> },
      ]
    },
    * */
  ];

  return (
    <Box>
      {items
        .filter((item) => item.hidden !== true)
        .map((item) => (
          <Box paddingY={'2px'} key={item.i18nKey}>
            <SidebarItem key={item.i18nKey} {...item} />
          </Box>
        ))}
    </Box>
  );
};
