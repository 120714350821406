import { MGFieldText, MGFieldTextProps } from './MGField/MGField.Text';
import { MGFormFieldBaseProps } from './MGForm.Field';

interface Props extends MGFormFieldBaseProps, Pick<MGFieldTextProps, 'highlight'> {}

export const MGFormFieldText = ({ label = '', form, name, FieldProps, highlight, TextFieldProps }: Props) => {
  return (
    <form.Field name={name} {...FieldProps}>
      {(field) => (
        <MGFieldText
          name={field.name}
          value={field.state.value || ''}
          label={label}
          errors={field.state.meta.errors}
          highlight={highlight}
          TextFieldProps={TextFieldProps}
          onChange={(e) => field.handleChange(e.target.value)}
          onBlur={field.handleBlur}
        />
      )}
    </form.Field>
  );
};
