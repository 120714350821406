import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetProjectOnboardingCompletenessQueryResponse,
  GetProjectOnboardingCompletenessPathParams,
  GetProjectOnboardingCompleteness404,
  GetProjectOnboardingCompleteness422,
} from '../types/GetProjectOnboardingCompleteness.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getProjectOnboardingCompleteness } from '../clients/getProjectOnboardingCompleteness.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getProjectOnboardingCompletenessQueryKey = (project_id: GetProjectOnboardingCompletenessPathParams['project_id']) =>
  [{ url: '/api-internal/projects/:project_id/onboarding_completeness', params: { project_id: project_id } }] as const

export type GetProjectOnboardingCompletenessQueryKey = ReturnType<typeof getProjectOnboardingCompletenessQueryKey>

export function getProjectOnboardingCompletenessQueryOptions(
  project_id: GetProjectOnboardingCompletenessPathParams['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getProjectOnboardingCompletenessQueryKey(project_id)
  return queryOptions<
    GetProjectOnboardingCompletenessQueryResponse,
    ResponseErrorConfig<GetProjectOnboardingCompleteness404 | GetProjectOnboardingCompleteness422>,
    GetProjectOnboardingCompletenessQueryResponse,
    typeof queryKey
  >({
    enabled: !!project_id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getProjectOnboardingCompleteness(project_id, config)
    },
  })
}

/**
 * @summary Get Project Onboarding Completeness
 * {@link /api-internal/projects/:project_id/onboarding_completeness}
 */
export function useGetProjectOnboardingCompleteness<
  TData = GetProjectOnboardingCompletenessQueryResponse,
  TQueryData = GetProjectOnboardingCompletenessQueryResponse,
  TQueryKey extends QueryKey = GetProjectOnboardingCompletenessQueryKey,
>(
  project_id: GetProjectOnboardingCompletenessPathParams['project_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetProjectOnboardingCompletenessQueryResponse,
        ResponseErrorConfig<GetProjectOnboardingCompleteness404 | GetProjectOnboardingCompleteness422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getProjectOnboardingCompletenessQueryKey(project_id)

  const query = useQuery({
    ...(getProjectOnboardingCompletenessQueryOptions(project_id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetProjectOnboardingCompleteness404 | GetProjectOnboardingCompleteness422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}