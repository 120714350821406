import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListSubscriptionPlansQueryResponse,
  ListSubscriptionPlansQueryParams,
  ListSubscriptionPlans404,
  ListSubscriptionPlans422,
} from '../types/ListSubscriptionPlans.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listSubscriptionPlans } from '../clients/listSubscriptionPlans.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listSubscriptionPlansQueryKey = (params: ListSubscriptionPlansQueryParams) =>
  [{ url: '/api-internal/subscription_plans/' }, ...(params ? [params] : [])] as const

export type ListSubscriptionPlansQueryKey = ReturnType<typeof listSubscriptionPlansQueryKey>

export function listSubscriptionPlansQueryOptions(params: ListSubscriptionPlansQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listSubscriptionPlansQueryKey(params)
  return queryOptions<
    ListSubscriptionPlansQueryResponse,
    ResponseErrorConfig<ListSubscriptionPlans404 | ListSubscriptionPlans422>,
    ListSubscriptionPlansQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listSubscriptionPlans(params, config)
    },
  })
}

/**
 * @summary List Subscription Plans
 * {@link /api-internal/subscription_plans/}
 */
export function useListSubscriptionPlans<
  TData = ListSubscriptionPlansQueryResponse,
  TQueryData = ListSubscriptionPlansQueryResponse,
  TQueryKey extends QueryKey = ListSubscriptionPlansQueryKey,
>(
  params: ListSubscriptionPlansQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ListSubscriptionPlansQueryResponse,
        ResponseErrorConfig<ListSubscriptionPlans404 | ListSubscriptionPlans422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listSubscriptionPlansQueryKey(params)

  const query = useQuery({
    ...(listSubscriptionPlansQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListSubscriptionPlans404 | ListSubscriptionPlans422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}