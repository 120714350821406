import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { Button } from '@mui/material';
import {
  ChevronDown,
  FileInput,
  FilePenLine,
  FolderDot,
  Plus,
  ReceiptText,
  UserRoundPlus,
  UsersRound,
} from 'lucide-react';
import { useUserInfo } from '../../../helpers/userInfo.ts';
import { useTranslation } from '../../../i18n';

export const CustomersActionMenu = () => {
  const { t } = useTranslation('customers');
  const [userInfo] = useUserInfo();

  const hasTenantAcquisition = userInfo?.flags.tenant_acquisition;

  const actions: MGActionMenuItemProps[] = [
    {
      type: 'header',
      text: t('glob_action_header_customers'),
    },
    {
      text: t('glob_action_create_customer'),
      icon: <UserRoundPlus size={20} />,
      href: `/customers/create/`,
    },
    {
      text: t('glob_action_import_customers'),
      icon: <UsersRound size={20} />,
      href: `/import/customers`,
    },
    {
      text: t('glob_action_settle_all_customers'),
      icon: <ReceiptText size={20} />,
      href: `/customers/billing/intervals`,
    },
    {
      text: t('glob_action_relief_quota'),
      icon: <FolderDot size={20} />,
      href: `/customers/electricity_price_break_quota`,
    },

    {
      text: t('glob_action_contracts'),
      icon: <FilePenLine size={20} />,
      href: `/contracts`,
      hidden: !hasTenantAcquisition,
    },
    {
      type: 'header',
      text: t('glob_action_header_billing'),
    },
    {
      text: t('glob_action_create_sepa'),
      icon: <Plus size={20} />,
      href: `/sepa/create/`,
    },
    {
      text: t('glob_action_import_sepa'),
      icon: <FileInput size={20} />,
      href: `/import/sepa_mandates`,
    },
  ];

  return (
    <>
      <MGActionMenu
        title={t('glob_action_menu_title')}
        menuItems={actions}
        spacing={15}
        buttonComponent={
          <Button
            variant={'contained'}
            size={'large'}
            endIcon={<ChevronDown style={{ marginLeft: '10px' }} />}
          >
            {t('glob_action_menu_button')}
          </Button>
        }
      />
    </>
  );
};
