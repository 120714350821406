import { axiosInstance } from '@mg/api-wrappers/src/client';
import { envVars } from '@mg/ui/src/envVars';
import { logout } from '../helpers/logout';
import { accessTokenAtom, currentProjectIdAtom } from '../jotai/atoms.ts';
import { jotaiStore } from '../jotaiStore.ts';

const logoutInterceptor = (error) => {
  const requestUrl = error.request?.responseURL.split('/api-internal/')[1];
  if ((requestUrl !== 'auth/token' && error?.status === 401) || error?.status === 403) {
    logout(window.location.pathname);
  }
  return Promise.reject(error);
};

const authInterceptor = (request) => {
  const token = jotaiStore.get(accessTokenAtom);
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
};

// TODO: delete once requests to Django views are removed
const projectInterceptor = (request) => {
  const projectId = jotaiStore.get(currentProjectIdAtom);
  if (projectId === null) {
    return request;
  }

  if (request.method === 'get') {
    request.params = {
      ...request.params,
      __project_id__: projectId,
    };
  } else if (request.method === 'post') {
    const url = new URL(request.url, envVars.VITE_BACKEND_HOST);
    url.searchParams.append('__project_id__', projectId.toString());
    request.url = url.toString();
  }
  return request;
};

axiosInstance.interceptors.request.use(authInterceptor);
axiosInstance.interceptors.request.use(projectInterceptor);
axiosInstance.interceptors.response.use(null, logoutInterceptor);
