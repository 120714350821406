import { gatewayStatusSchema } from './gatewayStatusSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getGatewayStatusQueryParamsSchema = z.object({
  last_eui_letters: z.string().regex(/^[0-9a-fA-F]{4,16}$/),
  serial: z.string().regex(/^[0-9]{5,}$/),
})

/**
 * @description OK
 */
export const getGatewayStatus200Schema = z.lazy(() => gatewayStatusSchema)

/**
 * @description Not Found
 */
export const getGatewayStatus404Schema = z.union([z.lazy(() => notFoundErrorOutSchema), z.null()])

/**
 * @description Unprocessable Entity
 */
export const getGatewayStatus422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getGatewayStatusQueryResponseSchema = z.lazy(() => getGatewayStatus200Schema)