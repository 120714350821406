import {
  MeasuringPointPanelCreateSchema,
  MeasuringPointPanelUpdateSchema,
  panelType,
  PanelType,
  useCreateMeasuringPointPanel,
  useUpdateMeasuringPointPanel,
} from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert';
import { addErrorsToFormFields } from '@mg/ui/src/components/MGForm/addErrorsToFormFields.ts';
import { MGForm } from '@mg/ui/src/components/MGForm/MGForm';
import { MGFormFieldSelect } from '@mg/ui/src/components/MGForm/MGForm.Field.Select';
import { MGFormFieldText } from '@mg/ui/src/components/MGForm/MGForm.Field.Text';
import { MGFormSubmitButton } from '@mg/ui/src/components/MGForm/MGForm.SubmitButton';
import { MGHelpButton } from '@mg/ui/src/components/MGHelpButton';
import { Button, Grid2 } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { InfoIcon } from 'lucide-react';
import { z } from 'zod';
import { invalidateAllQueries } from '../../../../helpers/invalidateAllQueries';
import { useTranslation } from '../../../../i18n';

type Props = {
  projectId: number;
  panelId?: number;
  defaultValues?: MeasuringPointPanelCreateSchema | MeasuringPointPanelUpdateSchema;
  handleClose: () => void;
};

export const PanelForm = ({ projectId, panelId, defaultValues, handleClose }: Props) => {
  /**
   * This component renders a form for creating and editing measuring point panels.
   *
   * @param {number} projectId Id of the current project
   * @param {number} [panelId] Id of the current panel, if provided, this form will edit the existing panel with that Id, else it will create a new one.
   * @param {Object} [defaultValues] Object of optional default values, if provided, these will be used in the form. Only should be set if @panelId is provided.
   * @param {function} handleClose Close function passed from the modal
   */
  const createMeasuringPointPanelMutation = useCreateMeasuringPointPanel();
  const updateMeasuringPointPanelMutation = useUpdateMeasuringPointPanel();
  const { t, tString } = useTranslation('metering');

  const panelValidatorSchema = z.object({
    project_id: z.number(),
    name: z
      .string()
      .min(0, { message: tString('err_panel_name_too_short') })
      .max(255, { message: tString('err_panel_name_too_long') }), // longest current name has 125 chars
    type: z
      .nativeEnum(panelType)
      .optional()
      .refine((t) => t !== undefined, { message: tString('err_panel_type_missing') }),
    malo_id: z.string(),
    melo_id: z.string(),
  });

  const form = useForm({
    onSubmit: async ({ value }) => {
      const handleError = (errOut) => {
        if (errOut.type === 'https://metergrid.de/api-errors/model-validation-error') {
          addErrorsToFormFields({ form, modelValidationError: errOut });
        }
      };

      if (panelId) {
        // TODO: should we add a check that only makes an update request if data has changed?
        await updateMeasuringPointPanelMutation.mutateAsync(
          {
            id: panelId,
            data: value,
          },
          {
            onError: handleError,
          },
        );
      } else {
        await createMeasuringPointPanelMutation.mutateAsync(
          { data: value },
          {
            onError: handleError,
          },
        );
      }
      await invalidateAllQueries();
      handleClose();
    },
    validators: {
      onSubmit: panelValidatorSchema,
    },
    defaultValues: {
      project_id: projectId,
      name: defaultValues?.name || '',
      type: defaultValues && 'type' in defaultValues ? (defaultValues['type'] as PanelType) : undefined,
      malo_id: defaultValues?.malo_id || '',
      melo_id: defaultValues?.melo_id || '',
    },
  });

  return (
    <MGForm form={form}>
      <Grid2 container spacing={3}>
        <Grid2 size={6}>
          <MGFormFieldText
            form={form}
            name="name"
            label={t('lbl_panel_name')}
            highlight
            TextFieldProps={{
              placeholder: tString('lbl_panel_name'),
              required: true,
              autoFocus: true,
              autoComplete: 'off',
            }}
          />
        </Grid2>
        <Grid2 size={6}>
          <MGFormFieldSelect
            form={form}
            name="type"
            label={t('lbl_panel_type')}
            highlight
            disabled={!!panelId}
            placeholderText={tString('lbl_panel_type')}
            options={[
              {
                key: panelType.SINK,
                label: t('lbl_panel_type_sink'),
              },
              {
                key: panelType.SOURCE,
                label: t('lbl_panel_type_source'),
              },
              {
                key: panelType.INOUT,
                label: t('lbl_panel_type_in_out'),
              },
            ]}
          />
        </Grid2>
        <Grid2 size={12}>
          <MGAlert
            icon={<InfoIcon />}
            severity="info"
            title={t('lbl_panel_info_title')}
            subtitle={t('lbl_panel_info_subtitle')}
          />
        </Grid2>
        <Grid2 size={6}>
          <MGFormFieldText
            form={form}
            name="malo_id"
            label={
              <>
                {t('lbl_panel_malo_id')} <MGHelpButton href="https://www.metergrid.de/help-center/faq" />
              </>
            }
            TextFieldProps={{
              placeholder: tString('lbl_panel_malo_id'),
            }}
          />
        </Grid2>
        <Grid2 size={6}>
          <MGFormFieldText
            form={form}
            name="melo_id"
            label={
              <>
                {t('lbl_panel_melo_id')} <MGHelpButton href="https://www.metergrid.de/help-center/faq" />
              </>
            }
            TextFieldProps={{
              placeholder: tString('lbl_panel_melo_id'),
            }}
          />
        </Grid2>
        <Grid2 container size={12} sx={{ justifyContent: 'space-between', mt: 4 }}>
          <Grid2>
            <Button variant="text" onClick={handleClose}>
              {t('btn_cancel_panel')}
            </Button>
          </Grid2>
          <Grid2>
            <MGFormSubmitButton
              form={form}
              buttonLabel={!panelId ? t('btn_submit_panel') : t('btn_edit_panel')}
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </MGForm>
  );
};
