// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetInvoicePdfQueryResponse, GetInvoicePdfPathParams, GetInvoicePdf400, GetInvoicePdf404, GetInvoicePdf422 } from '../types/GetInvoicePdf.ts'

export function getGetInvoicePdfUrl(id: GetInvoicePdfPathParams['id']) {
  return `/api-internal/invoices/${id}/pdf` as const
}

/**
 * @summary Get Invoice Pdf
 * {@link /api-internal/invoices/:id/pdf}
 */
export async function getInvoicePdf(id: GetInvoicePdfPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetInvoicePdfQueryResponse, ResponseErrorConfig<GetInvoicePdf400 | GetInvoicePdf404 | GetInvoicePdf422>, unknown>({
    method: 'GET',
    url: getGetInvoicePdfUrl(id).toString(),
    ...requestConfig,
  })
  return res.data
}