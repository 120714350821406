import { createScope, molecule } from 'bunshi';
import { atom } from 'jotai/index';

export const mgActionMenuScope = createScope('global');
export const mgActionMenuMolecule = molecule((mol, scope) => {
  const value = scope(mgActionMenuScope);
  if (value === 'global') throw new Error('mgActionMenuMolecule can only be used within <MGActionMenu>');

  const openAtom = atom<boolean>(false);

  return { openAtom };
});
