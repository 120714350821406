// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetBillingIntervalsQueryResponse,
  GetBillingIntervalsQueryParams,
  GetBillingIntervals404,
  GetBillingIntervals422,
} from '../types/GetBillingIntervals.ts'

export function getGetBillingIntervalsUrl() {
  return `/api-internal/billing/interval` as const
}

/**
 * @summary Get Billing Intervals
 * {@link /api-internal/billing/interval}
 */
export async function getBillingIntervals(params: GetBillingIntervalsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetBillingIntervalsQueryResponse, ResponseErrorConfig<GetBillingIntervals404 | GetBillingIntervals422>, unknown>({
    method: 'GET',
    url: getGetBillingIntervalsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}