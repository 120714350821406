import { z } from 'zod'

export const notificationSchemaSchema = z.object({
  data: z.union([z.object({}).strict(), z.null()]).optional(),
  id: z.union([z.number().int(), z.null()]).optional(),
  is_dismissable: z.boolean(),
  level: z.string().max(7),
  message: z.string(),
  project: z.union([z.number().int(), z.null()]).optional(),
  resolved_at: z.union([z.string().datetime(), z.null()]).optional(),
})