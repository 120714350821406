// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetPaymentPlanPdfQueryResponse,
  GetPaymentPlanPdfPathParams,
  GetPaymentPlanPdf400,
  GetPaymentPlanPdf404,
  GetPaymentPlanPdf422,
} from '../types/GetPaymentPlanPdf.ts'

export function getGetPaymentPlanPdfUrl(id: GetPaymentPlanPdfPathParams['id']) {
  return `/api-internal/payment_plans/${id}/pdf` as const
}

/**
 * @summary Get Payment Plan Pdf
 * {@link /api-internal/payment_plans/:id/pdf}
 */
export async function getPaymentPlanPdf(id: GetPaymentPlanPdfPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetPaymentPlanPdfQueryResponse, ResponseErrorConfig<GetPaymentPlanPdf400 | GetPaymentPlanPdf404 | GetPaymentPlanPdf422>, unknown>({
    method: 'GET',
    url: getGetPaymentPlanPdfUrl(id).toString(),
    ...requestConfig,
  })
  return res.data
}