import { Button, Grid2, Paper, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import backgroundImageDark from './card-background-dark.svg';
import backgroundImageLight from './card-background-light.svg';

type Props = {
  title: string | ReactNode;
  buttonTitle: string | ReactNode;
  color: 'dark' | 'light';
  onButtonClick?: () => void;
  href?: string;
  backgroundImage?: string;
  component?: ReactElement;
};
export const MGCallToActionCard = ({
  title,
  buttonTitle,
  color,
  onButtonClick,
  href,
  backgroundImage,
}: Props) => {
  return (
    <Paper
      sx={{
        height: '115px',
        backgroundColor: color === 'dark' ? '#0032A0' : ' #FFF',
        color: color === 'dark' ? '#FFF' : 'inherit',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src={backgroundImage || (color === 'dark' ? backgroundImageDark : backgroundImageLight)}
        alt={'card-background'}
        style={{ position: 'absolute', top: 0, right: 0, height: '120%' }}
      />

      <Grid2 container height={'100%'} paddingX={'40px'}>
        <Grid2 size={8} sx={{ display: 'flex', alignItems: 'center', zIndex: '1' }}>
          <Typography
            variant={'h4'}
            sx={{
              color: 'inherit',
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
        </Grid2>
        <Grid2 size={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
            component={Link}
            to={href}
            onClick={onButtonClick}
            variant={'contained'}
            color={color === 'dark' ? 'white' : 'primary'}
            sx={{
              height: '55px',
              width: '120px',
              fontSize: '13px',
              padding: '0px',
              color: color === 'dark' ? '#0032A0' : 'none',
              fontWeight: '600',
            }}
          >
            {buttonTitle}
          </Button>
        </Grid2>
      </Grid2>
    </Paper>
  );
};
