import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ReopenTransactionsMutationRequest,
  ReopenTransactionsMutationResponse,
  ReopenTransactions404,
  ReopenTransactions422,
} from '../types/ReopenTransactions.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { reopenTransactions } from '../clients/reopenTransactions.ts'
import { useMutation } from '@tanstack/react-query'

export const reopenTransactionsMutationKey = () => [{ url: '/api-internal/transactions/reopen' }] as const

export type ReopenTransactionsMutationKey = ReturnType<typeof reopenTransactionsMutationKey>

/**
 * @summary Reopen Transactions
 * {@link /api-internal/transactions/reopen}
 */
export function useReopenTransactions(
  options: {
    mutation?: UseMutationOptions<
      ReopenTransactionsMutationResponse,
      ResponseErrorConfig<ReopenTransactions404 | ReopenTransactions422>,
      { data?: ReopenTransactionsMutationRequest }
    >
    client?: Partial<RequestConfig<ReopenTransactionsMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? reopenTransactionsMutationKey()

  return useMutation<
    ReopenTransactionsMutationResponse,
    ResponseErrorConfig<ReopenTransactions404 | ReopenTransactions422>,
    { data?: ReopenTransactionsMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return reopenTransactions(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}