import { MGModal, MGModalTitle } from '@mg/ui/src/components/MGModal';
import {
  Box,
  Button,
  Chip,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Info } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../../../../i18n';
import { presentDate } from '../../../../../presenters/date';
import { presentEuroPerMonth } from '../../../../../presenters/number';
import { SoftIcon } from '../../../../shared/SoftIcon';

export const CustomerDetailsContractsSwitchPaymentPlanModal = ({
  paymentPlans,
  initiallySelectedPaymentPlan,
  onSelect,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedPaymentPlanId, setSelectedPaymentPlanId] = useState(null);
  const { t } = useTranslation('customerDetails');

  useEffect(() => {
    setSelectedPaymentPlanId(initiallySelectedPaymentPlan);
  }, [initiallySelectedPaymentPlan]);

  const handleSubmit = () => {
    onSelect(selectedPaymentPlanId);
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(!open)} color={'white'} size={'small'} variant={'contained'}>
        {t('contracts_card_payment_plan_modal_button')}
      </Button>
      <MGModal
        open={open}
        handleToggle={() => setOpen(!open)}
        width={'800px'}
        PaperStyle={{ padding: '40px' }}
      >
        <MGModalTitle>{t('contracts_card_payment_plan_modal_title')}</MGModalTitle>
        <Stack paddingY={'20px'} height={'100%'} justifyContent={'space-between'} spacing={3}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <SoftIcon size={25}>
              <Info />
            </SoftIcon>
            <Typography variant={'small'}>{t('contracts_card_payment_plan_modal_info_message')}</Typography>
          </Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {paymentPlans?.map((pp) => (
                  <TableRow key={pp.id}>
                    <TableCell sx={{ border: 0 }}>
                      <Radio
                        checked={selectedPaymentPlanId === pp.id}
                        onChange={() => setSelectedPaymentPlanId(pp.id)}
                      />
                    </TableCell>

                    <TableCell sx={{ border: 0 }}>
                      <Typography variant={'small'} fontWeight={'500'}>
                        {`ID-${pp.id}`}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>
                      <Typography variant={'small'} fontWeight={'500'}>
                        {t('contracts_card_payment_plan_modal_start_date')} {presentDate(pp.start_date)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>
                      <Typography variant={'small'}>{presentEuroPerMonth(pp.amount)}</Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }}>
                      <Chip
                        color={pp.is_active ? 'success' : 'gray'}
                        label={pp.is_active ? t('active') : t('inactive')}
                        sx={{ borderRadius: '8px' }}
                        size={'small'}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button variant={'contained'} onClick={handleSubmit}>
              {t('contracts_card_modal_submit')}
            </Button>
          </Box>
        </Stack>
      </MGModal>
    </>
  );
};
