import { CUSTOMERNOACTIVESEPAMANDATESchema } from './CUSTOMERNOACTIVESEPAMANDATESchema.ts'
import { CUSTOMERNOBANKACCOUNTSchema } from './CUSTOMERNOBANKACCOUNTSchema.ts'
import { generateSepaFilesInputSchema } from './generateSepaFilesInputSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const generateSepaFiles200Schema = z.instanceof(File)

/**
 * @description Bad Request
 */
export const generateSepaFiles400Schema = z.string()

/**
 * @description Not Found
 */
export const generateSepaFiles404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const generateSepaFiles422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.array(z.union([z.lazy(() => CUSTOMERNOBANKACCOUNTSchema), z.lazy(() => CUSTOMERNOACTIVESEPAMANDATESchema)])),
])

export const generateSepaFilesMutationRequestSchema = z.lazy(() => generateSepaFilesInputSchema)

export const generateSepaFilesMutationResponseSchema = z.lazy(() => generateSepaFiles200Schema)