import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { pagedPlanChangeDocumentSchemaSchema } from './pagedPlanChangeDocumentSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listPlanChangeDocumentsQueryParamsSchema = z.object({
  include_customer: z.boolean().default(false),
  include_pdf: z.boolean().default(false),
  document_type: z.literal('plan_change').default('plan_change'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  project_id: z.number().int(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  valid_from_from: z.union([z.string().datetime(), z.null()]).optional(),
  valid_from_to: z.union([z.string().datetime(), z.null()]).optional(),
  page: z.union([z.number().int(), z.null()]).default(1),
  page_size: z.union([z.number().int(), z.null()]).default(25),
  order_by: z.union([z.string(), z.null()]).optional(),
  skip_pagination: z.union([z.boolean(), z.null()]).default(false),
  single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const listPlanChangeDocuments200Schema = z.lazy(() => pagedPlanChangeDocumentSchemaSchema)

/**
 * @description Not Found
 */
export const listPlanChangeDocuments404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const listPlanChangeDocuments422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listPlanChangeDocumentsQueryResponseSchema = z.lazy(() => listPlanChangeDocuments200Schema)