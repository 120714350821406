// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CountResidualPowerPlansQueryResponse,
  CountResidualPowerPlansQueryParams,
  CountResidualPowerPlans404,
  CountResidualPowerPlans422,
} from '../types/CountResidualPowerPlans.ts'

export function getCountResidualPowerPlansUrl() {
  return `/api-internal/residual_power_plans/count` as const
}

/**
 * @summary Count Residual Power Plans
 * {@link /api-internal/residual_power_plans/count}
 */
export async function countResidualPowerPlans(params: CountResidualPowerPlansQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CountResidualPowerPlansQueryResponse, ResponseErrorConfig<CountResidualPowerPlans404 | CountResidualPowerPlans422>, unknown>({
    method: 'GET',
    url: getCountResidualPowerPlansUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}