import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListLevyStructuresQueryResponse,
  ListLevyStructuresQueryParams,
  ListLevyStructures404,
  ListLevyStructures422,
} from '../types/ListLevyStructures.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { listLevyStructures } from '../clients/listLevyStructures.ts'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listLevyStructuresInfiniteQueryKey = (params: ListLevyStructuresQueryParams) =>
  [{ url: '/api-internal/levy_structures/' }, ...(params ? [params] : [])] as const

export type ListLevyStructuresInfiniteQueryKey = ReturnType<typeof listLevyStructuresInfiniteQueryKey>

export function listLevyStructuresInfiniteQueryOptions(
  params: ListLevyStructuresQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = listLevyStructuresInfiniteQueryKey(params)
  return infiniteQueryOptions<
    ListLevyStructuresQueryResponse,
    ResponseErrorConfig<ListLevyStructures404 | ListLevyStructures422>,
    ListLevyStructuresQueryResponse,
    typeof queryKey,
    number
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListLevyStructuresQueryParams['page']
      }
      return listLevyStructures(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Levy Structures
 * {@link /api-internal/levy_structures/}
 */
export function useListLevyStructuresInfinite<
  TData = InfiniteData<ListLevyStructuresQueryResponse>,
  TQueryData = ListLevyStructuresQueryResponse,
  TQueryKey extends QueryKey = ListLevyStructuresInfiniteQueryKey,
>(
  params: ListLevyStructuresQueryParams,
  options: {
    query?: Partial<
      InfiniteQueryObserverOptions<
        ListLevyStructuresQueryResponse,
        ResponseErrorConfig<ListLevyStructures404 | ListLevyStructures422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listLevyStructuresInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listLevyStructuresInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ResponseErrorConfig<ListLevyStructures404 | ListLevyStructures422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}