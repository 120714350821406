// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetCustomerQueryResponse, GetCustomerPathParams, GetCustomerQueryParams, GetCustomer404, GetCustomer422 } from '../types/GetCustomer.ts'

export function getGetCustomerUrl(customer_id: GetCustomerPathParams['customer_id']) {
  return `/api-internal/customers/${customer_id}` as const
}

/**
 * @summary Get Customer
 * {@link /api-internal/customers/:customer_id}
 */
export async function getCustomer(
  customer_id: GetCustomerPathParams['customer_id'],
  params?: GetCustomerQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetCustomerQueryResponse, ResponseErrorConfig<GetCustomer404 | GetCustomer422>, unknown>({
    method: 'GET',
    url: getGetCustomerUrl(customer_id).toString(),
    params,
    ...requestConfig,
  })
  return res.data
}