import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const status200Schema = z.unknown()

/**
 * @description Not Found
 */
export const status404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const status422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const statusQueryResponseSchema = z.lazy(() => status200Schema)