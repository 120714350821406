// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListSubscriptionsForPanelQueryResponse,
  ListSubscriptionsForPanelPathParams,
  ListSubscriptionsForPanelQueryParams,
  ListSubscriptionsForPanel404,
  ListSubscriptionsForPanel422,
} from '../types/ListSubscriptionsForPanel.ts'

export function getListSubscriptionsForPanelUrl(id: ListSubscriptionsForPanelPathParams['id']) {
  return `/api-internal/measuring_point_panels/${id}/subscriptions` as const
}

/**
 * @summary List Subscriptions For Panel
 * {@link /api-internal/measuring_point_panels/:id/subscriptions}
 */
export async function listSubscriptionsForPanel(
  id: ListSubscriptionsForPanelPathParams['id'],
  params?: ListSubscriptionsForPanelQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListSubscriptionsForPanelQueryResponse, ResponseErrorConfig<ListSubscriptionsForPanel404 | ListSubscriptionsForPanel422>, unknown>({
    method: 'GET',
    url: getListSubscriptionsForPanelUrl(id).toString(),
    params,
    ...requestConfig,
  })
  return res.data
}