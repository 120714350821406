import {
  BillingPreflightLevyStructureOut,
  MissingLevyStructureForInterval,
  NoLevyStructure,
} from '@mg/api-wrappers/src/api-internal';
import { MGAlert } from '@mg/ui/src/components/MGAlert/MGAlert';
import { MGCollapsibleCard } from '@mg/ui/src/components/MGCollapsibleCard/MGCollapsibleCard';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { MGStatusIndicator } from '@mg/ui/src/components/MGStatusIndicator';
import { presentDate } from '@mg/ui/src/presenters';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../../../i18n';
import { LevyStructureLeviesTable } from '../../../LevyStructureLevies/LevyStructureLevies.Table';
import { CreateLevyStructureModal } from '../../../LevyStructures/CreateLevyStructureModal';

type Props = {
  isLoading?: boolean;
  levyStructureIntervals: BillingPreflightLevyStructureOut[];
  errors?: (NoLevyStructure | MissingLevyStructureForInterval)[];
};
export const BillingDetailsPerCustomerLevyCard = ({ levyStructureIntervals, errors, isLoading }: Props) => {
  const { t } = useTranslation('billing');
  const hasLevies = levyStructureIntervals.length > 0;
  const hasErrors = !!errors?.length || !hasLevies;

  return (
    <MGCollapsibleCard
      defaultOpen={hasErrors}
      collapsedContent={
        <>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <MGStatusIndicator status={hasLevies ? 'success' : 'error'} />
            <Typography variant={'large'} color={'text.secondary'}>
              {t('details_per_customer_levies_card_title')}
            </Typography>
          </Stack>
        </>
      }
    >
      {isLoading && (
        <Box py={6}>
          <MGLoader />
        </Box>
      )}

      {!isLoading && hasErrors && (
        <Stack spacing={3}>
          {errors?.map((error, i) => (
            <MGAlert
              key={i}
              severity={'error'}
              title={t(error.code + '_title')}
              button={<CreateLevyStructureModal buttonColor={'error'} />}
            />
          ))}
        </Stack>
      )}

      {!isLoading && (
        <Stack spacing={4}>
          {levyStructureIntervals.map((ls) => (
            <Stack spacing={2} key={ls.start}>
              <Typography variant={'regular'} fontWeight={500}>
                {t('details_per_customer_levies_card_levy_subtitle', {
                  validFrom: presentDate(ls.start),
                  validTo: presentDate(ls.end),
                })}
              </Typography>
              <LevyStructureLeviesTable
                levyStructure={ls.levy_structure}
                hideActions
                datagridProps={{ rowHeight: 55 }}
              />
            </Stack>
          ))}
        </Stack>
      )}
    </MGCollapsibleCard>
  );
};
