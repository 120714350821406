import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ObtainTokenMutationRequest, ObtainTokenMutationResponse, ObtainToken404, ObtainToken422 } from '../types/ObtainToken.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { obtainToken } from '../clients/obtainToken.ts'
import { useMutation } from '@tanstack/react-query'

export const obtainTokenMutationKey = () => [{ url: '/api-internal/auth/token' }] as const

export type ObtainTokenMutationKey = ReturnType<typeof obtainTokenMutationKey>

/**
 * @summary Obtain Token
 * {@link /api-internal/auth/token}
 */
export function useObtainToken(
  options: {
    mutation?: UseMutationOptions<ObtainTokenMutationResponse, ResponseErrorConfig<ObtainToken404 | ObtainToken422>, { data: ObtainTokenMutationRequest }>
    client?: Partial<RequestConfig<ObtainTokenMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? obtainTokenMutationKey()

  return useMutation<ObtainTokenMutationResponse, ResponseErrorConfig<ObtainToken404 | ObtainToken422>, { data: ObtainTokenMutationRequest }>({
    mutationFn: async ({ data }) => {
      return obtainToken(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}