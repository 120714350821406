import { letterCreateSchemaSchema } from './letterCreateSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const previewLetter200Schema = z.unknown()

/**
 * @description Not Found
 */
export const previewLetter404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const previewLetter422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const previewLetterMutationRequestSchema = z.lazy(() => letterCreateSchemaSchema)

export const previewLetterMutationResponseSchema = z.lazy(() => previewLetter200Schema)