import { Box } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { ReactNode } from 'react';
import { MGLoaderButton } from '../MGLoaderButton';
import { TanstackForm } from './MGForm';

interface Props {
  buttonLabel: ReactNode;
  form: TanstackForm;
  icon?: ReactNode;
}

export const MGFormSubmitButton = ({ buttonLabel, icon = <ArrowRight size={20} />, form }: Props) => {
  return (
    <form.Subscribe
      selector={(state) => [state.canSubmit, state.isSubmitting]}
      children={([canSubmit, isSubmitting]) => (
        <Box display={'flex'} justifyContent={'flex-end'}>
          <MGLoaderButton
            loading={isSubmitting}
            disabled={!canSubmit}
            onClick={async () => {
              await form.validateAllFields('submit');
              await form.handleSubmit();
            }}
            size={'large'}
            endIcon={icon}
            variant={'contained'}
          >
            {buttonLabel}
          </MGLoaderButton>
        </Box>
      )}
    />
  );
};
