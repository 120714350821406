import { MGModal, MGModalBody, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from '../../../i18n.tsx';
import { ContractsDownloadDocumentsCard } from './Contracts.DownloadDocumentsCard.tsx';
import { ContractsGeneralDataCard } from './Contracts.GeneralDataCard.tsx';
import { ContractsPlanDetailsCard } from './Contracts.PlanDetailsCard.tsx';

export const ContractsDetailsModal = ({ contractProposal, modalRef }) => {
  const { t } = useTranslation('contracts');
  return (
    <MGModal width={'800px'} ref={modalRef} buttonComponent={<></>}>
      <MGModalTitle>{t('tit_details_modal')}</MGModalTitle>
      <Typography variant="small">{t('lbl_details_modal_subtitle')}</Typography>
      <MGModalBody>
        <Stack direction={'row'} gap={2.5} marginTop={2} justifyContent={'stretch'}>
          <ContractsGeneralDataCard contractProposal={contractProposal} />
          <ContractsPlanDetailsCard contractProposal={contractProposal} />
        </Stack>

        <ContractsDownloadDocumentsCard contractProposal={contractProposal} />
      </MGModalBody>
    </MGModal>
  );
};
