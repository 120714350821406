// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetProjectToOnboardingCompleteMutationResponse,
  SetProjectToOnboardingCompletePathParams,
  SetProjectToOnboardingCompleteQueryParams,
  SetProjectToOnboardingComplete400,
  SetProjectToOnboardingComplete404,
  SetProjectToOnboardingComplete422,
} from '../types/SetProjectToOnboardingComplete.ts'

export function getSetProjectToOnboardingCompleteUrl(id: SetProjectToOnboardingCompletePathParams['id']) {
  return `/api-internal/projects/${id}/complete_onboarding` as const
}

/**
 * @summary Set Project To Onboarding Complete
 * {@link /api-internal/projects/:id/complete_onboarding}
 */
export async function setProjectToOnboardingComplete(
  id: SetProjectToOnboardingCompletePathParams['id'],
  params?: SetProjectToOnboardingCompleteQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    SetProjectToOnboardingCompleteMutationResponse,
    ResponseErrorConfig<SetProjectToOnboardingComplete400 | SetProjectToOnboardingComplete404 | SetProjectToOnboardingComplete422>,
    unknown
  >({ method: 'POST', url: getSetProjectToOnboardingCompleteUrl(id).toString(), params, ...requestConfig })
  return res.data
}