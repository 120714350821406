import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { projectTenantAcquisitionConfigurationOutSchema } from './projectTenantAcquisitionConfigurationOutSchema.ts'
import { z } from 'zod'

export const setTenantAcquisitionConfigurationPanelSelectionHelperImagePathParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const setTenantAcquisitionConfigurationPanelSelectionHelperImage200Schema = z.lazy(() => projectTenantAcquisitionConfigurationOutSchema)

/**
 * @description Not Found
 */
export const setTenantAcquisitionConfigurationPanelSelectionHelperImage404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const setTenantAcquisitionConfigurationPanelSelectionHelperImage422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.lazy(() => modelValidationErrorOutSchema),
])

export const setTenantAcquisitionConfigurationPanelSelectionHelperImageMutationRequestSchema = z.object({
  panel_selection_helper_image: z.instanceof(File),
})

export const setTenantAcquisitionConfigurationPanelSelectionHelperImageMutationResponseSchema = z.lazy(
  () => setTenantAcquisitionConfigurationPanelSelectionHelperImage200Schema,
)