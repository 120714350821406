import { billingPreflightLevyStructureOutSchema } from './billingPreflightLevyStructureOutSchema.ts'
import { billingPreflightResidualPowerPlanOutSchema } from './billingPreflightResidualPowerPlanOutSchema.ts'
import { billingPreflightSubscriptionOutSchema } from './billingPreflightSubscriptionOutSchema.ts'
import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { ELECTRICPRICEBREAKNEEDSTOBEAPPLIEDSchema } from './ELECTRICPRICEBREAKNEEDSTOBEAPPLIEDSchema.ts'
import { ELECTRICPRICEBREAKQUOTANEEDEDSchema } from './ELECTRICPRICEBREAKQUOTANEEDEDSchema.ts'
import { INFUTUREBILLINGSchema } from './INFUTUREBILLINGSchema.ts'
import { MISSINGDATAFROMMEASURINGPOINTSchema } from './MISSINGDATAFROMMEASURINGPOINTSchema.ts'
import { MISSINGLEVYSTRUCTUREFORINTERVALSchema } from './MISSINGLEVYSTRUCTUREFORINTERVALSchema.ts'
import { MISSINGMEASURINGPOINTDATASchema } from './MISSINGMEASURINGPOINTDATASchema.ts'
import { MISSINGPANELSchema } from './MISSINGPANELSchema.ts'
import { MISSINGPANELTYPESchema } from './MISSINGPANELTYPESchema.ts'
import { MISSINGPLANFORINTERVALSchema } from './MISSINGPLANFORINTERVALSchema.ts'
import { MULTIPLEINOUTPANELSSchema } from './MULTIPLEINOUTPANELSSchema.ts'
import { NOACTIVEPLANSchema } from './NOACTIVEPLANSchema.ts'
import { NOACTIVESUBSCRIPTIONSchema } from './NOACTIVESUBSCRIPTIONSchema.ts'
import { NOLEVYSTRUCTURESchema } from './NOLEVYSTRUCTURESchema.ts'
import { NORESIDUALPOWERPLANSchema } from './NORESIDUALPOWERPLANSchema.ts'
import { NOVALIDBILLINGINTERVALSchema } from './NOVALIDBILLINGINTERVALSchema.ts'
import { SUBSCRIPTIONENDEDSchema } from './SUBSCRIPTIONENDEDSchema.ts'
import { SUBSCRIPTIONNOTACTIVESchema } from './SUBSCRIPTIONNOTACTIVESchema.ts'
import { SUBSCRIPTIONNOTBILLABLESchema } from './SUBSCRIPTIONNOTBILLABLESchema.ts'
import { transactionNestedSchemaSchema } from './transactionNestedSchemaSchema.ts'
import { z } from 'zod'

export const billingPreflightOutSchema = z.object({
  billing_end: z.string().date(),
  billing_start: z.string().date(),
  customer: z.lazy(() => customerNestedSchemaSchema),
  errors: z.array(
    z.union([
      z.lazy(() => INFUTUREBILLINGSchema),
      z.lazy(() => NOACTIVESUBSCRIPTIONSchema),
      z.lazy(() => SUBSCRIPTIONNOTBILLABLESchema),
      z.lazy(() => NOVALIDBILLINGINTERVALSchema),
      z.lazy(() => MISSINGMEASURINGPOINTDATASchema),
      z.lazy(() => MISSINGDATAFROMMEASURINGPOINTSchema),
      z.lazy(() => MISSINGLEVYSTRUCTUREFORINTERVALSchema),
      z.lazy(() => SUBSCRIPTIONNOTACTIVESchema),
      z.lazy(() => SUBSCRIPTIONENDEDSchema),
      z.lazy(() => MISSINGPANELTYPESchema),
      z.lazy(() => MULTIPLEINOUTPANELSSchema),
      z.lazy(() => ELECTRICPRICEBREAKNEEDSTOBEAPPLIEDSchema),
      z.lazy(() => ELECTRICPRICEBREAKQUOTANEEDEDSchema),
      z.lazy(() => MISSINGPANELSchema),
    ]),
  ),
  is_billable: z.boolean(),
  levy_structure_errors: z.array(z.union([z.lazy(() => NOLEVYSTRUCTURESchema), z.lazy(() => MISSINGLEVYSTRUCTUREFORINTERVALSchema)])),
  levy_structure_intervals: z.union([z.array(z.lazy(() => billingPreflightLevyStructureOutSchema)), z.null()]),
  residual_power_plan_errors: z.array(z.lazy(() => NORESIDUALPOWERPLANSchema)),
  residual_power_plan_intervals: z.union([z.array(z.lazy(() => billingPreflightResidualPowerPlanOutSchema)), z.null()]),
  subscription_id: z.number().int(),
  subscription_plan_errors: z.array(z.union([z.lazy(() => NOACTIVEPLANSchema), z.lazy(() => MISSINGPLANFORINTERVALSchema)])),
  subscription_plan_intervals: z.union([z.array(z.lazy(() => billingPreflightSubscriptionOutSchema)), z.null()]),
  transactions: z.array(z.lazy(() => transactionNestedSchemaSchema)),
})