import { CurrentUserSchema, useGetCurrentUser } from '@mg/api-wrappers/src/api-internal';
import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { Sidebar } from '../components/features/Sidebar/Sidebar';
import { useLogout } from '../helpers/logout.ts';
import { userInfoAtom } from '../helpers/userInfo';
import { useSelectedProjectsFilter } from '../hooks/useSelectedProjectsFilter.ts';
import { MainContainer } from './MainContainer';

export const AppLayout = () => {
  // Needs to be kept in sync with the width set in backend/frontend/static/theme/components/react-sidebar.scss
  const sidebarWidth = '280px';
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const logout = useLogout();
  const currentProjectId = useSelectedProjectsFilter().project_id;

  const needsLogin = !userInfo || currentProjectId === -1;

  useEffect(() => {
    if (needsLogin && !userInfoQuery.isLoading) {
      logout();
    }
  }, [needsLogin]);

  // Refetch user info to update feature flags in localstorage
  const userInfoQuery = useGetCurrentUser({ query: { enabled: false } });
  useEffect(() => {
    userInfoQuery.refetch().then(({ data }) => {
      setUserInfo(data as CurrentUserSchema);
    });
  }, []);

  if (needsLogin) return null;

  return (
    <Box maxHeight={'100vh'}>
      <Sidebar width={sidebarWidth} />
      <MainContainer sidebarWidth={sidebarWidth} />
    </Box>
  );
};
