// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListMeasuringPointPanelsQueryResponse,
  ListMeasuringPointPanelsQueryParams,
  ListMeasuringPointPanels404,
  ListMeasuringPointPanels422,
} from '../types/ListMeasuringPointPanels.ts'

export function getListMeasuringPointPanelsUrl() {
  return `/api-internal/measuring_point_panels/` as const
}

/**
 * @summary List Measuring Point Panels
 * {@link /api-internal/measuring_point_panels/}
 */
export async function listMeasuringPointPanels(params: ListMeasuringPointPanelsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListMeasuringPointPanelsQueryResponse, ResponseErrorConfig<ListMeasuringPointPanels404 | ListMeasuringPointPanels422>, unknown>({
    method: 'GET',
    url: getListMeasuringPointPanelsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}