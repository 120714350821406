// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  AcceptSepaMandateMutationRequest,
  AcceptSepaMandateMutationResponse,
  AcceptSepaMandatePathParams,
  AcceptSepaMandate404,
  AcceptSepaMandate422,
} from '../types/AcceptSepaMandate.ts'

export function getAcceptSepaMandateUrl(id: AcceptSepaMandatePathParams['id']) {
  return `/api-internal/sepa_mandates/${id}/accept` as const
}

/**
 * @summary Accept Sepa Mandate
 * {@link /api-internal/sepa_mandates/:id/accept}
 */
export async function acceptSepaMandate(
  id: AcceptSepaMandatePathParams['id'],
  data: AcceptSepaMandateMutationRequest,
  config: Partial<RequestConfig<AcceptSepaMandateMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    AcceptSepaMandateMutationResponse,
    ResponseErrorConfig<AcceptSepaMandate404 | AcceptSepaMandate422>,
    AcceptSepaMandateMutationRequest
  >({ method: 'POST', url: getAcceptSepaMandateUrl(id).toString(), data, ...requestConfig })
  return res.data
}