import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  DismissNotificationMutationResponse,
  DismissNotificationPathParams,
  DismissNotification404,
  DismissNotification422,
} from '../types/DismissNotification.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { dismissNotification } from '../clients/dismissNotification.ts'
import { useMutation } from '@tanstack/react-query'

export const dismissNotificationMutationKey = () => [{ url: '/api-internal/notifications/{id}/dismiss' }] as const

export type DismissNotificationMutationKey = ReturnType<typeof dismissNotificationMutationKey>

/**
 * @summary Dismiss Notification
 * {@link /api-internal/notifications/:id/dismiss}
 */
export function useDismissNotification(
  options: {
    mutation?: UseMutationOptions<
      DismissNotificationMutationResponse,
      ResponseErrorConfig<DismissNotification404 | DismissNotification422>,
      { id: DismissNotificationPathParams['id'] }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? dismissNotificationMutationKey()

  return useMutation<
    DismissNotificationMutationResponse,
    ResponseErrorConfig<DismissNotification404 | DismissNotification422>,
    { id: DismissNotificationPathParams['id'] }
  >({
    mutationFn: async ({ id }) => {
      return dismissNotification(id, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}