import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetLetterPdfsAsZipQueryResponse,
  GetLetterPdfsAsZipQueryParams,
  GetLetterPdfsAsZip400,
  GetLetterPdfsAsZip404,
  GetLetterPdfsAsZip422,
} from '../types/GetLetterPdfsAsZip.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getLetterPdfsAsZip } from '../clients/getLetterPdfsAsZip.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getLetterPdfsAsZipQueryKey = (params: GetLetterPdfsAsZipQueryParams) =>
  [{ url: '/api-internal/letters/pdfs' }, ...(params ? [params] : [])] as const

export type GetLetterPdfsAsZipQueryKey = ReturnType<typeof getLetterPdfsAsZipQueryKey>

export function getLetterPdfsAsZipQueryOptions(params: GetLetterPdfsAsZipQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getLetterPdfsAsZipQueryKey(params)
  return queryOptions<
    GetLetterPdfsAsZipQueryResponse,
    ResponseErrorConfig<GetLetterPdfsAsZip400 | GetLetterPdfsAsZip404 | GetLetterPdfsAsZip422>,
    GetLetterPdfsAsZipQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getLetterPdfsAsZip(params, config)
    },
  })
}

/**
 * @summary Get Letter Pdfs As Zip
 * {@link /api-internal/letters/pdfs}
 */
export function useGetLetterPdfsAsZip<
  TData = GetLetterPdfsAsZipQueryResponse,
  TQueryData = GetLetterPdfsAsZipQueryResponse,
  TQueryKey extends QueryKey = GetLetterPdfsAsZipQueryKey,
>(
  params: GetLetterPdfsAsZipQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetLetterPdfsAsZipQueryResponse,
        ResponseErrorConfig<GetLetterPdfsAsZip400 | GetLetterPdfsAsZip404 | GetLetterPdfsAsZip422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getLetterPdfsAsZipQueryKey(params)

  const query = useQuery({
    ...(getLetterPdfsAsZipQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetLetterPdfsAsZip400 | GetLetterPdfsAsZip404 | GetLetterPdfsAsZip422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}