import {
  deliverableDocumentType,
  PlanChangeDocumentSchema,
  useGetPlanChangeDocumentPdf,
} from '@mg/api-wrappers/src/api-internal';
import { useCommonActionMenuItems } from './useCommonActionMenuItems';

import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { useDocumentContext } from '../../features/documents/document-context/document-context';

export const DocumentsPlanChangeTableRowActionMenu = () => {
  const { document } = useDocumentContext<PlanChangeDocumentSchema>();
  const getPlanChangePdfQuery = useGetPlanChangeDocumentPdf(document.id, { query: { enabled: false } });
  const commonItems = useCommonActionMenuItems({
    documentType: deliverableDocumentType.plan_change,
    downloadPdfQuery: getPlanChangePdfQuery,
  });

  const menuItems: MGActionMenuItemProps[] = [...commonItems];

  return <MGActionMenu menuItems={menuItems} />;
};
