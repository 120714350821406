import { Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

export interface MGModalSubtitleProps {
  children: string | ReactNode;
  variant?: TypographyProps['variant'];
}

export const MGModalSubtitle = ({ children, variant = 'small' }: MGModalSubtitleProps) => {
  return <Typography variant={variant}>{children}</Typography>;
};
