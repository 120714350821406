import { Box, Stack, Typography } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ValidationError } from '@tanstack/react-form';
import { Dayjs } from 'dayjs';
import { MGFieldError } from './MGField.Error.tsx';

export type MGFieldDateProps = {
  value: Dayjs;
  label?: string | null;
  errors: ValidationError[];
  onChange: (value: Dayjs | null) => void;
  onBlur: InputBaseProps['onBlur'];
  DatePickerProps?: DatePickerProps<Dayjs>;
};
export const MGFieldDate = ({ value, label, errors, onChange, DatePickerProps }: MGFieldDateProps) => {
  return (
    <Stack spacing={1}>
      {label && (
        <Typography variant={'small'} fontWeight={300}>
          {label}
        </Typography>
      )}
      <Stack>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          <DatePicker
            value={value}
            onChange={onChange}
            sx={{
              '.MuiInputBase-root': { borderRadius: '10px!important' },
              ...DatePickerProps?.sx,
            }}
            {...DatePickerProps}
          />
        </LocalizationProvider>
        <Box height={'12px'}>
          <MGFieldError errors={errors} />
        </Box>
      </Stack>
    </Stack>
  );
};
