import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const countInvoicesQueryParamsSchema = z.object({
  document_type: z.literal('invoice').default('invoice'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  project_id: z.number().int(),
  category: z.union([z.enum(['regular invoice', 'cancellation invoice']), z.null()]).optional(),
  state: z.union([z.enum(['issued', 'canceled']), z.null()]).optional(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  issue_date_from: z.union([z.string().datetime(), z.null()]).optional(),
  issue_date_to: z.union([z.string().datetime(), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const countInvoices200Schema = z.number().int()

/**
 * @description Not Found
 */
export const countInvoices404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const countInvoices422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const countInvoicesQueryResponseSchema = z.lazy(() => countInvoices200Schema)