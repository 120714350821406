import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListNotificationsQueryResponse, ListNotificationsQueryParams, ListNotifications404, ListNotifications422 } from '../types/ListNotifications.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listNotifications } from '../clients/listNotifications.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listNotificationsQueryKey = (params: ListNotificationsQueryParams) =>
  [{ url: '/api-internal/notifications/' }, ...(params ? [params] : [])] as const

export type ListNotificationsQueryKey = ReturnType<typeof listNotificationsQueryKey>

export function listNotificationsQueryOptions(params: ListNotificationsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listNotificationsQueryKey(params)
  return queryOptions<
    ListNotificationsQueryResponse,
    ResponseErrorConfig<ListNotifications404 | ListNotifications422>,
    ListNotificationsQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listNotifications(params, config)
    },
  })
}

/**
 * @summary List Notifications
 * {@link /api-internal/notifications/}
 */
export function useListNotifications<
  TData = ListNotificationsQueryResponse,
  TQueryData = ListNotificationsQueryResponse,
  TQueryKey extends QueryKey = ListNotificationsQueryKey,
>(
  params: ListNotificationsQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<ListNotificationsQueryResponse, ResponseErrorConfig<ListNotifications404 | ListNotifications422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listNotificationsQueryKey(params)

  const query = useQuery({
    ...(listNotificationsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListNotifications404 | ListNotifications422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}