import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const currentProjectIdAtom = atomWithStorage<number | null>('currentProjectId', null, undefined, {
  getOnInit: true,
});

export const refreshMessagesAtom = atom(0);

export const accessTokenAtom = atomWithStorage<string | null>('accessToken', null, undefined, {
  getOnInit: true,
});
