import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { pagedSepaMandateSchemaSchema } from './pagedSepaMandateSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { SEPAMandateStateSchema } from './SEPAMandateStateSchema.ts'
import { z } from 'zod'

export const listSepaMandatesQueryParamsSchema = z.object({
  include_customer: z.boolean().default(false),
  include_pdf: z.boolean().default(false),
  document_type: z.literal('sepa_mandate').default('sepa_mandate'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  project_id: z.number().int(),
  state: z.union([z.lazy(() => SEPAMandateStateSchema), z.null()]).optional(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  page: z.union([z.number().int(), z.null()]).default(1),
  page_size: z.union([z.number().int(), z.null()]).default(25),
  order_by: z.union([z.string(), z.null()]).optional(),
  skip_pagination: z.union([z.boolean(), z.null()]).default(false),
  single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const listSepaMandates200Schema = z.lazy(() => pagedSepaMandateSchemaSchema)

/**
 * @description Not Found
 */
export const listSepaMandates404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const listSepaMandates422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listSepaMandatesQueryResponseSchema = z.lazy(() => listSepaMandates200Schema)