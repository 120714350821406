import { Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

export interface MGModalTitleProps {
  children: ReactNode;
  variant?: TypographyProps['variant'];
}

export const MGModalTitle = ({ children, variant = 'h4' }: MGModalTitleProps) => {
  return <Typography variant={variant}>{children}</Typography>;
};
