import { contractProposalStateSchema } from './contractProposalStateSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { pagedContractProposalOutSchema } from './pagedContractProposalOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listContractProposalsQueryParamsSchema = z.object({
  project_id: z.number().int(),
  state: z.union([z.lazy(() => contractProposalStateSchema), z.null()]).optional(),
  page: z.union([z.number().int(), z.null()]).default(1),
  page_size: z.union([z.number().int(), z.null()]).default(25),
  order_by: z.union([z.string(), z.null()]).optional(),
  skip_pagination: z.union([z.boolean(), z.null()]).default(false),
  single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const listContractProposals200Schema = z.lazy(() => pagedContractProposalOutSchema)

/**
 * @description Not Found
 */
export const listContractProposals404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const listContractProposals422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listContractProposalsQueryResponseSchema = z.lazy(() => listContractProposals200Schema)