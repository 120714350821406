// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListContractProposalsQueryResponse,
  ListContractProposalsQueryParams,
  ListContractProposals404,
  ListContractProposals422,
} from '../types/ListContractProposals.ts'

export function getListContractProposalsUrl() {
  return `/api-internal/contract_proposals/` as const
}

/**
 * @summary List Contract Proposals
 * {@link /api-internal/contract_proposals/}
 */
export async function listContractProposals(params: ListContractProposalsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListContractProposalsQueryResponse, ResponseErrorConfig<ListContractProposals404 | ListContractProposals422>, unknown>({
    method: 'GET',
    url: getListContractProposalsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}