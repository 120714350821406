import { axiosInstance } from '@mg/api-wrappers/src/client.ts';
import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { palette } from '@mg/ui/src/styles';
import { useAtom } from 'jotai';
import { ListPlus, Pencil, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter.ts';
import { useTranslation } from '../../../i18n';
import { refreshMessagesAtom } from '../../../jotai/atoms';
import { DjangoFormModal } from '../../modals/DjangoFormModal';
import { fireSweetalert } from '../../modals/SweetalertModal';

export const MeteringDetailsMeasuringPointListActionMenu = ({ meterId }) => {
  const projectFilter = useSelectedProjectsFilter();
  const [addDataModalOpen, setAddDataModalOpen] = useState(false);
  const [editMeterModalOpen, setEditMeterDataModalOpen] = useState(false);
  const [messageRefreshCounter, setMessageRefreshCounter] = useAtom(refreshMessagesAtom);
  const { t } = useTranslation('meteringDetails');

  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('add_data'),
      icon: <ListPlus size={'18'} />,
      onClick: () => setAddDataModalOpen(!addDataModalOpen),
    },
    {
      text: t('meter_edit'),
      icon: <Pencil size={18} />,
      onClick: () => setEditMeterDataModalOpen(!editMeterModalOpen),
    },
    {
      text: t('meter_list_action_menu_delete_meter'),
      icon: <Trash2 size={'18'} color={palette.error.main} />,
      disabledHint: t('meter_list_action_menu_delete_tooltip'),
      onClick: () => {
        fireSweetalert({
          title: t('meter_list_action_menu_delete_meter_modal_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('meter_list_action_menu_delete_meter_modal_text'),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            axiosInstance.get(`/metering/meters/${meterId}/delete`).then(() => {
              invalidateAllQueries();
              setMessageRefreshCounter(messageRefreshCounter + 1);
            });
          }
        });
      },
    },
  ];

  return (
    <>
      <MGActionMenu menuItems={menuItems} />
      <DjangoFormModal
        pathToDjangoForm={`/metering/data/create?measuring_point_id=${meterId}`}
        title={t('add_data')}
        open={addDataModalOpen}
        handleToggle={() => {
          setAddDataModalOpen(!addDataModalOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setAddDataModalOpen(false);
        }}
      />
      <DjangoFormModal
        pathToDjangoForm={`/metering/meters/${meterId}/edit`}
        title={t('meter_edit')}
        open={editMeterModalOpen}
        handleToggle={() => {
          setEditMeterDataModalOpen(!editMeterModalOpen);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setEditMeterDataModalOpen(false);
        }}
      />
    </>
  );
};
