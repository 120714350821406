import { Box, MenuItem as MUIMenuItem, Select, SelectProps, Stack, Typography } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { ValidationError } from '@tanstack/react-form';
import { ReactNode } from 'react';
import { palette } from '../../../styles';
import { MGFieldError } from './MGField.Error';
import { getHighlightOutlineInputSxForColor } from './MGField.HighlightOutlinedInputSx';

export type MGFieldSelectProps = {
  name: string;
  value: string;
  options: { key: string; label: ReactNode }[];
  errors: ValidationError[];
  highlight?: boolean;
  disabled?: boolean;
  label?: string | null;
  placeholderText?: string;
  SelectProps?: SelectProps;
  onChange: SelectProps['onChange'];
  onBlur: InputBaseProps['onBlur'];
};

export const MGFieldSelect = ({
  name,
  label,
  errors = [],
  value,
  options,
  highlight,
  disabled,
  placeholderText,
  SelectProps,
  onChange,
  onBlur,
}: MGFieldSelectProps) => {
  return (
    <Stack spacing={1}>
      {label && (
        <Typography variant={'small'} fontWeight={300}>
          {label}
        </Typography>
      )}
      <Stack sx={highlight ? getHighlightOutlineInputSxForColor(palette.primary) : undefined}>
        <Select
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          error={errors.length > 0}
          displayEmpty
          {...SelectProps}
        >
          {placeholderText && (
            <MUIMenuItem disabled value={''}>
              <Box color={palette.gray.main}>{placeholderText}</Box>
            </MUIMenuItem>
          )}
          {options.map(({ key, label }) => (
            <MUIMenuItem key={key} value={key}>
              {label}
            </MUIMenuItem>
          ))}
        </Select>

        <Box minHeight={'12px'}>
          <MGFieldError errors={errors} />
        </Box>
      </Stack>
    </Stack>
  );
};
