import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CreateMeasuringPointDataMutationRequest,
  CreateMeasuringPointDataMutationResponse,
  CreateMeasuringPointData404,
  CreateMeasuringPointData422,
} from '../types/CreateMeasuringPointData.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { createMeasuringPointData } from '../clients/createMeasuringPointData.ts'
import { useMutation } from '@tanstack/react-query'

export const createMeasuringPointDataMutationKey = () => [{ url: '/api-internal/measuring_point_data/' }] as const

export type CreateMeasuringPointDataMutationKey = ReturnType<typeof createMeasuringPointDataMutationKey>

/**
 * @summary Create Measuring Point Data
 * {@link /api-internal/measuring_point_data/}
 */
export function useCreateMeasuringPointData(
  options: {
    mutation?: UseMutationOptions<
      CreateMeasuringPointDataMutationResponse,
      ResponseErrorConfig<CreateMeasuringPointData404 | CreateMeasuringPointData422>,
      { data: CreateMeasuringPointDataMutationRequest }
    >
    client?: Partial<RequestConfig<CreateMeasuringPointDataMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createMeasuringPointDataMutationKey()

  return useMutation<
    CreateMeasuringPointDataMutationResponse,
    ResponseErrorConfig<CreateMeasuringPointData404 | CreateMeasuringPointData422>,
    { data: CreateMeasuringPointDataMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return createMeasuringPointData(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}