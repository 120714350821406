import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { envVars } from '@mg/ui/src/envVars';
import { queryClient } from '@mg/ui/src/queryClient.ts';
import { theme } from '@mg/ui/src/styles/theme';
import { ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { TolgeeProvider } from '@tolgee/react';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { Provider as JotaiProvider } from 'jotai';
import { NuqsAdapter } from 'nuqs/adapters/react';
import { PostHogProvider } from 'posthog-js/react';
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import './App.scss';
import { FlagsProvider } from './feature-flags';
import { tolgee } from './i18n';
import { jotaiStore } from './jotaiStore.ts';
import { AppLayout } from './layout/AppLayout';
import { UnprotectedAppLayout } from './layout/UnprotectedAppLayout.tsx';

export const App = () => {
  dayjs.locale('de-DE');

  LicenseInfo.setLicenseKey(
    '2bc0df1bc1b6094f86aaf2f792ba5ce5Tz05MzE2MyxFPTE3NTA5NTM2MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  );

  Sentry.init({
    dsn: 'https://e38e93d7a7c78fd381ae46079fb7953a@o4507826961448960.ingest.de.sentry.io/4507855558869072',
    integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
    environment: envVars.VITE_ENVIRONMENT,
    enabled: envVars.VITE_ENVIRONMENT !== 'local-dev',
    release: envVars.VITE_RELEASE || 'unknown',
  });

  useEffect(() => {
    if (envVars.VITE_ENVIRONMENT !== 'local-dev') {
      datadogRum.init({
        applicationId: 'f1adc337-9f96-4f3c-aa3a-b2bf8ac361c4',
        clientToken: 'pubf2edce4c861bc7ccf955d8d63b5d1192',
        site: 'datadoghq.eu',
        service: 'provider-portal',
        env: envVars.VITE_ENVIRONMENT,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        defaultPrivacyLevel: 'allow',
        allowedTracingUrls: [/https:\/\/.*\.metergrid\.de/],
      });

      datadogLogs.init({
        clientToken: 'pubf2edce4c861bc7ccf955d8d63b5d1192',
        site: 'datadoghq.eu',
        service: 'provider-portal',
        env: envVars.VITE_ENVIRONMENT,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['error', 'warn'],
      });
    }
  }, []);

  return (
    <Suspense>
      <ThemeProvider theme={theme}>
        <JotaiProvider store={jotaiStore}>
          <NuqsAdapter>
            <QueryClientProvider client={queryClient}>
              <TolgeeProvider tolgee={tolgee} fallback="." options={{ useSuspense: true }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
                  <PosthogProvider>
                    <ToastContainer position="bottom-right" newestOnTop transition={Flip} />
                    <FlagsProvider>
                      <BrowserRouter>
                        <Routes>
                          {/* Non protected routes */}
                          <Route path={'/login/*'} element={<UnprotectedAppLayout />} />
                          {/*Protected routes */}
                          <Route path={'/*'} element={<AppLayout />} />
                        </Routes>
                      </BrowserRouter>
                    </FlagsProvider>
                  </PosthogProvider>
                </LocalizationProvider>
              </TolgeeProvider>
            </QueryClientProvider>
          </NuqsAdapter>
        </JotaiProvider>
      </ThemeProvider>
    </Suspense>
  );
};

const PosthogProvider = ({ children }) => {
  if (!envVars.VITE_POSTHOG_KEY) {
    return children;
  }
  return (
    <PostHogProvider apiKey={envVars.VITE_POSTHOG_KEY} options={{ api_host: 'https://eu.i.posthog.com' }}>
      {children}
    </PostHogProvider>
  );
};
