// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListSepaMandatesQueryResponse, ListSepaMandatesQueryParams, ListSepaMandates404, ListSepaMandates422 } from '../types/ListSepaMandates.ts'

export function getListSepaMandatesUrl() {
  return `/api-internal/sepa_mandates/` as const
}

/**
 * @summary List Sepa Mandates
 * {@link /api-internal/sepa_mandates/}
 */
export async function listSepaMandates(params: ListSepaMandatesQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListSepaMandatesQueryResponse, ResponseErrorConfig<ListSepaMandates404 | ListSepaMandates422>, unknown>({
    method: 'GET',
    url: getListSepaMandatesUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}