import {
  setTenantAcquisitionConfigurationLogo,
  setTenantAcquisitionConfigurationPanelSelectionHelperImage,
  useGetTenantAcquisitionConfiguration,
  useRemoveTenantAcquisitionConfigurationLogo,
  useRemoveTenantAcquisitionConfigurationPanelSelectionHelperImage,
  useSetTenantAcquisitionConfiguration,
} from '@mg/api-wrappers/src/api-internal';
import { MGForm, MGFormFieldText, MGFormSubmitButton } from '@mg/ui/src/components/MGForm';
import { addErrorsToFormFields } from '@mg/ui/src/components/MGForm/addErrorsToFormFields.ts';
import { MGFieldFileUpload } from '@mg/ui/src/components/MGForm/MGField/MGField.FileUpload.tsx';
import { MGModal, MGModalBody, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { defaultErrorHandler } from '@mg/ui/src/helpers/errorHandling.ts';
import { Grid2, IconButton, Stack, Typography } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { Settings } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter.ts';
import { useTranslation } from '../../../i18n.tsx';

export const ContractsSettingsModal = () => {
  const { t, tString } = useTranslation('contracts');
  const projectFilter = useSelectedProjectsFilter();
  const getSettingsQuery = useGetTenantAcquisitionConfiguration(projectFilter.project_id);
  const removeLogoMutation = useRemoveTenantAcquisitionConfigurationLogo();
  const removeHelperImageMutation = useRemoveTenantAcquisitionConfigurationPanelSelectionHelperImage();
  const patchSettingsMutation = useSetTenantAcquisitionConfiguration();

  const [open, setOpen] = useState(false);
  const form = useForm({
    defaultValues: {
      energy_mix_direct_consumption:
        (Number(getSettingsQuery.data?.energy_mix_direct_consumption) || 0) * 100,
      payment_plan_amount_minimum: getSettingsQuery.data?.payment_plan_amount_minimum || 0,
      payment_plan_amount_maximum: getSettingsQuery.data?.payment_plan_amount_maximum || 0,
    },
    onSubmit: async (data) => {
      await patchSettingsMutation.mutateAsync(
        {
          project_id: projectFilter.project_id,
          data: {
            energy_mix_direct_consumption: data.value.energy_mix_direct_consumption / 100,
            payment_plan_amount_minimum: data.value.payment_plan_amount_minimum,
            payment_plan_amount_maximum: data.value.payment_plan_amount_maximum,
          },
        },
        {
          onSuccess: () => {
            toast.success('Settings updated');
            setOpen(false);
          },
          onError: (errOut) => {
            if (errOut.type === 'https://metergrid.de/api-errors/model-validation-error') {
              addErrorsToFormFields({ form, modelValidationError: errOut });
            } else {
              defaultErrorHandler(errOut);
            }
          },
        },
      );
    },
  });

  return (
    <>
      <IconButton onClick={() => setOpen(!open)}>
        <Settings />
      </IconButton>
      <MGModal showCloseButton width={'800px'} open={open} handleToggle={() => setOpen(!open)}>
        <MGModalTitle>{t('settings_modal_title')}</MGModalTitle>
        <Typography variant={'extraSmall'}>{t('settings_modal_subtitle')}</Typography>
        <MGModalBody>
          <Stack spacing={5}>
            <MGForm form={form}>
              <Stack spacing={3}>
                <Grid2 container spacing={2}>
                  <Grid2 size={12}>
                    <Grid2 size={6}>
                      <MGFormFieldText
                        name={'energy_mix_direct_consumption'}
                        form={form}
                        label={t('lbl_direct_consumption')}
                        TextFieldProps={{ type: 'number' }}
                      />
                    </Grid2>
                  </Grid2>
                  <Grid2 size={6}>
                    <MGFormFieldText
                      name={'payment_plan_amount_minimum'}
                      form={form}
                      label={t('lbl_min_payment_plan_amount')}
                      TextFieldProps={{ type: 'number' }}
                    />
                  </Grid2>
                  <Grid2 size={6}>
                    <MGFormFieldText
                      name={'payment_plan_amount_maximum'}
                      form={form}
                      label={t('lbl_max_payment_plan_amount')}
                      TextFieldProps={{ type: 'number' }}
                    />
                  </Grid2>
                  <Grid2 size={12} justifyContent={'flex-end'}>
                    <MGFormSubmitButton icon={<></>} buttonLabel={t('btn_save_settings')} form={form} />
                  </Grid2>
                </Grid2>
              </Stack>
            </MGForm>
            <Stack spacing={2}>
              <Typography variant={'h4'}>{t('setting_modal_upload_title')}</Typography>
              <Typography variant={'extraSmall'}>{t('setting_modal_upload_subtitle')}</Typography>
              <Grid2 container spacing={2}>
                <Grid2 size={6}>
                  <MGFieldFileUpload
                    uploadTitle={t('upload_logo_title')}
                    uploadFileInfo={t('png_max_size')}
                    handleDelete={async () => {
                      await removeLogoMutation.mutateAsync({ project_id: projectFilter.project_id });
                      await getSettingsQuery.refetch();
                    }}
                    handleUpload={async (file, onUploadProgress, onSuccess, onError) => {
                      try {
                        await setTenantAcquisitionConfigurationLogo(
                          projectFilter.project_id,
                          { logo: file },
                          { onUploadProgress },
                        );
                        await getSettingsQuery.refetch();
                        onSuccess();
                      } catch (e) {
                        onError(e);
                      }
                    }}
                    accept={{ 'image/png': [] }}
                    value={getSettingsQuery.data?.logo}
                    label={tString('upload_logo_title')}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <MGFieldFileUpload
                    uploadTitle={t('upload_helper_image_title')}
                    label={tString('upload_helper_image_title')}
                    uploadFileInfo={t('png_max_size')}
                    handleDelete={async () => {
                      await removeHelperImageMutation.mutateAsync({ project_id: projectFilter.project_id });
                      await getSettingsQuery.refetch();
                    }}
                    handleUpload={async (file, onUploadProgress, onSuccess, onError) => {
                      try {
                        await setTenantAcquisitionConfigurationPanelSelectionHelperImage(
                          projectFilter.project_id,
                          { panel_selection_helper_image: file },
                          { onUploadProgress },
                        );
                        await getSettingsQuery.refetch();
                        onSuccess();
                      } catch (e) {
                        onError(e);
                      }
                    }}
                    accept={{ 'image/png': [] }}
                    value={getSettingsQuery.data?.panel_selection_helper_image}
                  />
                </Grid2>
              </Grid2>
            </Stack>
          </Stack>
        </MGModalBody>
      </MGModal>
    </>
  );
};
