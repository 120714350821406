import { BILLINGINFUTURESchema } from './BILLINGINFUTURESchema.ts'
import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { measuringPointSubscriptionNestedSchemaSchema } from './measuringPointSubscriptionNestedSchemaSchema.ts'
import { STARTDATEAFTERENDDATESchema } from './STARTDATEAFTERENDDATESchema.ts'
import { SUBSCRIPTIONALREADYENDEDSchema } from './SUBSCRIPTIONALREADYENDEDSchema.ts'
import { SUBSCRIPTIONNOTSTARTEDYETSchema } from './SUBSCRIPTIONNOTSTARTEDYETSchema.ts'
import { WASBILLEDALREADYSchema } from './WASBILLEDALREADYSchema.ts'
import { z } from 'zod'

export const billingIntervalSchemaOutputSchema = z.object({
  customer: z.lazy(() => customerNestedSchemaSchema),
  end_date: z.union([z.string().date(), z.null()]),
  errors: z.union([
    z.array(
      z.union([
        z.lazy(() => WASBILLEDALREADYSchema),
        z.lazy(() => SUBSCRIPTIONNOTSTARTEDYETSchema),
        z.lazy(() => SUBSCRIPTIONALREADYENDEDSchema),
        z.lazy(() => STARTDATEAFTERENDDATESchema),
        z.lazy(() => BILLINGINFUTURESchema),
      ]),
    ),
    z.null(),
  ]),
  start_date: z.union([z.string().date(), z.null()]),
  subscription: z.lazy(() => measuringPointSubscriptionNestedSchemaSchema),
})