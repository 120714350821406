import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  UpdateCustomerNotesMutationRequest,
  UpdateCustomerNotesMutationResponse,
  UpdateCustomerNotesPathParams,
  UpdateCustomerNotes404,
  UpdateCustomerNotes422,
} from '../types/UpdateCustomerNotes.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { updateCustomerNotes } from '../clients/updateCustomerNotes.ts'
import { useMutation } from '@tanstack/react-query'

export const updateCustomerNotesMutationKey = () => [{ url: '/api-internal/customers/{customer_id}/notes' }] as const

export type UpdateCustomerNotesMutationKey = ReturnType<typeof updateCustomerNotesMutationKey>

/**
 * @summary Update Customer Notes
 * {@link /api-internal/customers/:customer_id/notes}
 */
export function useUpdateCustomerNotes(
  options: {
    mutation?: UseMutationOptions<
      UpdateCustomerNotesMutationResponse,
      ResponseErrorConfig<UpdateCustomerNotes404 | UpdateCustomerNotes422>,
      { customer_id: UpdateCustomerNotesPathParams['customer_id']; data: UpdateCustomerNotesMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateCustomerNotesMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateCustomerNotesMutationKey()

  return useMutation<
    UpdateCustomerNotesMutationResponse,
    ResponseErrorConfig<UpdateCustomerNotes404 | UpdateCustomerNotes422>,
    { customer_id: UpdateCustomerNotesPathParams['customer_id']; data: UpdateCustomerNotesMutationRequest }
  >({
    mutationFn: async ({ customer_id, data }) => {
      return updateCustomerNotes(customer_id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}