import { Box, Card, Stack, Typography } from '@mui/material';

import SolarPanelSVG from '../../../../assets/solar_panels.svg';
import { useTranslation } from '../../../../i18n';
import { ProgressChart } from '../../../shared/Charts/ProgressChart';

export const OnboardingProjectStatusCard = ({ percentage }) => {
  const { t } = useTranslation('onboarding');
  return (
    <Card sx={{ height: '150px' }}>
      <Stack direction={'row'} height={'100%'} padding={2} spacing={1}>
        <Box flex={1}>
          <ProgressChart value={percentage} label={'Fortschritt'} />
        </Box>
        <Box flex={4}>
          <Stack height={'100%'} justifyContent={'center'} spacing={1}>
            <Typography variant={'h5'} color={'text.secondary'}>
              {t('onboarding_status_card_title')}
            </Typography>
            <Typography variant={'small'}>
              {percentage === 100
                ? t('onboarding_status_card_text_complete')
                : t('onboarding_status_card_text_missing_data')}
            </Typography>
          </Stack>
        </Box>
        <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
          <img
            style={{ width: '160px', transform: 'translateY(5px)' }}
            src={SolarPanelSVG}
            alt={'solar_panels'}
          />
        </Box>
      </Stack>
    </Card>
  );
};
