import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetLetterPdfQueryResponse, GetLetterPdfPathParams, GetLetterPdf400, GetLetterPdf404, GetLetterPdf422 } from '../types/GetLetterPdf.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getLetterPdf } from '../clients/getLetterPdf.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getLetterPdfQueryKey = (id: GetLetterPdfPathParams['id']) => [{ url: '/api-internal/letters/:id/pdf', params: { id: id } }] as const

export type GetLetterPdfQueryKey = ReturnType<typeof getLetterPdfQueryKey>

export function getLetterPdfQueryOptions(id: GetLetterPdfPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getLetterPdfQueryKey(id)
  return queryOptions<
    GetLetterPdfQueryResponse,
    ResponseErrorConfig<GetLetterPdf400 | GetLetterPdf404 | GetLetterPdf422>,
    GetLetterPdfQueryResponse,
    typeof queryKey
  >({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getLetterPdf(id, config)
    },
  })
}

/**
 * @summary Get Letter Pdf
 * {@link /api-internal/letters/:id/pdf}
 */
export function useGetLetterPdf<TData = GetLetterPdfQueryResponse, TQueryData = GetLetterPdfQueryResponse, TQueryKey extends QueryKey = GetLetterPdfQueryKey>(
  id: GetLetterPdfPathParams['id'],
  options: {
    query?: Partial<
      QueryObserverOptions<GetLetterPdfQueryResponse, ResponseErrorConfig<GetLetterPdf400 | GetLetterPdf404 | GetLetterPdf422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getLetterPdfQueryKey(id)

  const query = useQuery({
    ...(getLetterPdfQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetLetterPdf400 | GetLetterPdf404 | GetLetterPdf422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}