import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetBillingIntervalsQueryResponse,
  GetBillingIntervalsQueryParams,
  GetBillingIntervals404,
  GetBillingIntervals422,
} from '../types/GetBillingIntervals.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getBillingIntervals } from '../clients/getBillingIntervals.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getBillingIntervalsQueryKey = (params: GetBillingIntervalsQueryParams) =>
  [{ url: '/api-internal/billing/interval' }, ...(params ? [params] : [])] as const

export type GetBillingIntervalsQueryKey = ReturnType<typeof getBillingIntervalsQueryKey>

export function getBillingIntervalsQueryOptions(params: GetBillingIntervalsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getBillingIntervalsQueryKey(params)
  return queryOptions<
    GetBillingIntervalsQueryResponse,
    ResponseErrorConfig<GetBillingIntervals404 | GetBillingIntervals422>,
    GetBillingIntervalsQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getBillingIntervals(params, config)
    },
  })
}

/**
 * @summary Get Billing Intervals
 * {@link /api-internal/billing/interval}
 */
export function useGetBillingIntervals<
  TData = GetBillingIntervalsQueryResponse,
  TQueryData = GetBillingIntervalsQueryResponse,
  TQueryKey extends QueryKey = GetBillingIntervalsQueryKey,
>(
  params: GetBillingIntervalsQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<GetBillingIntervalsQueryResponse, ResponseErrorConfig<GetBillingIntervals404 | GetBillingIntervals422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getBillingIntervalsQueryKey(params)

  const query = useQuery({
    ...(getBillingIntervalsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetBillingIntervals404 | GetBillingIntervals422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}