import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SendDocumentByEmailMutationResponse,
  SendDocumentByEmailQueryParams,
  SendDocumentByEmail404,
  SendDocumentByEmail422,
} from '../types/SendDocumentByEmail.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { sendDocumentByEmail } from '../clients/sendDocumentByEmail.ts'
import { useMutation } from '@tanstack/react-query'

export const sendDocumentByEmailMutationKey = () => [{ url: '/api-internal/document_email/send_email' }] as const

export type SendDocumentByEmailMutationKey = ReturnType<typeof sendDocumentByEmailMutationKey>

/**
 * @summary Send Document By Email
 * {@link /api-internal/document_email/send_email}
 */
export function useSendDocumentByEmail(
  options: {
    mutation?: UseMutationOptions<
      SendDocumentByEmailMutationResponse,
      ResponseErrorConfig<SendDocumentByEmail404 | SendDocumentByEmail422>,
      { params: SendDocumentByEmailQueryParams }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? sendDocumentByEmailMutationKey()

  return useMutation<
    SendDocumentByEmailMutationResponse,
    ResponseErrorConfig<SendDocumentByEmail404 | SendDocumentByEmail422>,
    { params: SendDocumentByEmailQueryParams }
  >({
    mutationFn: async ({ params }) => {
      return sendDocumentByEmail(params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}