import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { projectTenantAcquisitionConfigurationOutSchema } from './projectTenantAcquisitionConfigurationOutSchema.ts'
import { tenantAcquisitionConfigurationInSchema } from './tenantAcquisitionConfigurationInSchema.ts'
import { z } from 'zod'

export const setTenantAcquisitionConfigurationPathParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const setTenantAcquisitionConfiguration200Schema = z.lazy(() => projectTenantAcquisitionConfigurationOutSchema)

/**
 * @description Not Found
 */
export const setTenantAcquisitionConfiguration404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const setTenantAcquisitionConfiguration422Schema = z.union([z.lazy(() => paramValidationErrorOutSchema), z.lazy(() => modelValidationErrorOutSchema)])

export const setTenantAcquisitionConfigurationMutationRequestSchema = z.lazy(() => tenantAcquisitionConfigurationInSchema)

export const setTenantAcquisitionConfigurationMutationResponseSchema = z.lazy(() => setTenantAcquisitionConfiguration200Schema)