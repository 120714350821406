import { ContractProposalOut } from '@mg/api-wrappers/src/api-internal/index.ts';
import { MGCardHeader } from '@mg/ui/src/components/MGCard/MGCard.Header.tsx';
import { MGCardListLayoutItem } from '@mg/ui/src/components/MGCard/MGCard.ListLayout.Item.tsx';
import { MGCardListLayout } from '@mg/ui/src/components/MGCard/MGCard.ListLayout.tsx';
import { MGCard } from '@mg/ui/src/components/MGCard/MGCard.tsx';
import { presentEuro, presentEuroCentPerKilowattHour } from '@mg/ui/src/presenters/index.ts';
import { palette } from '@mg/ui/src/styles/palette.ts';
import { Typography } from '@mui/material';
import { useTranslation } from '../../../i18n.tsx';

interface ContractsPlanDetailsCardProps {
  contractProposal: ContractProposalOut;
}

export const ContractsPlanDetailsCard = ({ contractProposal }: ContractsPlanDetailsCardProps) => {
  const { t } = useTranslation('contracts');

  return (
    <MGCard sx={{ flex: 1, padding: '20px' }}>
      <MGCardHeader
        leftHeader={
          <Typography color={palette.common.black} fontWeight={500}>
            Tarifdetails
          </Typography>
        }
      />
      <MGCardListLayout>
        <MGCardListLayoutItem label={t('lbl_confirm_sign_modal_plan_card_name')}>
          <Typography variant={'extraSmall'}>{contractProposal.plan?.name}</Typography>
        </MGCardListLayoutItem>
        <MGCardListLayoutItem label={t('lbl_confirm_sign_modal_plan_card_base_price')}>
          <Typography variant={'extraSmall'}>
            {presentEuro(contractProposal.plan?.price_base, { fractionDigits: 4 })}
          </Typography>
        </MGCardListLayoutItem>
        {contractProposal.plan?.plan_components.map((planComponent) => {
          return (
            <MGCardListLayoutItem
              label={t(
                'lbl_confirm_sign_modal_plan_card_price_component_' +
                  planComponent.calculation_basis?.toLocaleLowerCase(),
              )}
            >
              <Typography variant={'extraSmall'}>
                {presentEuroCentPerKilowattHour(planComponent.price_per_kwh)}
              </Typography>
            </MGCardListLayoutItem>
          );
        })}
        <MGCardListLayoutItem label={t('lbl_confirm_sign_modal_plan_card_payment')}>
          <Typography variant={'extraSmall'}>
            {presentEuro(contractProposal.payment_plan_amount_gross)}
          </Typography>
        </MGCardListLayoutItem>
      </MGCardListLayout>
    </MGCard>
  );
};
