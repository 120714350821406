import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getSepaMandatePdfPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const getSepaMandatePdf200Schema = z.unknown()

/**
 * @description Bad Request
 */
export const getSepaMandatePdf400Schema = z.string()

/**
 * @description Not Found
 */
export const getSepaMandatePdf404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getSepaMandatePdf422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getSepaMandatePdfQueryResponseSchema = z.lazy(() => getSepaMandatePdf200Schema)