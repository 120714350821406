import { acceptSepaMandateSchemaSchema } from './acceptSepaMandateSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { sepaMandateSchemaSchema } from './sepaMandateSchemaSchema.ts'
import { z } from 'zod'

export const acceptSepaMandatePathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const acceptSepaMandate200Schema = z.lazy(() => sepaMandateSchemaSchema)

/**
 * @description Not Found
 */
export const acceptSepaMandate404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const acceptSepaMandate422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const acceptSepaMandateMutationRequestSchema = z.lazy(() => acceptSepaMandateSchemaSchema)

export const acceptSepaMandateMutationResponseSchema = z.lazy(() => acceptSepaMandate200Schema)