import { MeasuringPointSchema } from '@mg/api-wrappers/src/api-internal';
import { palette } from '@mg/ui/src/styles/palette';
import { Box, Chip, Grid2, Stack, Typography } from '@mui/material';
import { generateColorFromId } from '../../../helpers/generateColor';
import { useTranslation } from '../../../i18n';
import { MeteringHistoryFilterModal } from './MeteringHistory.FilterModal';

type Props = {
  selectedMeasuringPointIds: number[];
  onChange: (ids: number[]) => void;
  measuringPoints?: MeasuringPointSchema[];
  onHover: (id: number | null) => void;
  hoveredItem: number | null;
};
export const MeteringHistoryGraphLegend = ({
  selectedMeasuringPointIds,
  onChange,
  measuringPoints,
  onHover,
  hoveredItem,
}: Props) => {
  const selectedItemsCount = selectedMeasuringPointIds.length;
  const totalItemsCount = measuringPoints?.length;
  const { t } = useTranslation('meteringHistory');
  return (
    <>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography>{t('legend_title')}</Typography>
        <Chip size={'small'} color={'primary'} label={`${selectedItemsCount} / ${totalItemsCount}`} />
        <MeteringHistoryFilterModal
          selectedMeasuringPointIds={selectedMeasuringPointIds}
          measuringPoints={measuringPoints}
          onSubmit={onChange}
        />
      </Stack>

      <Grid2 container spacing={2} columns={5}>
        {(measuringPoints || [])
          .filter((mp) => selectedMeasuringPointIds.includes(mp.id!))
          .map((measuringPoint) => {
            const isHovered = hoveredItem === measuringPoint.id;
            return (
              <Grid2 size={1} key={`legend_item_${measuringPoint.id}`}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={1}
                  height={'50px'}
                  sx={{
                    border: `2px solid ${palette.gray.light}`,
                    borderRadius: '10px',
                    padding: '10px',
                    opacity: isHovered || !hoveredItem ? 1 : 0.2,
                    transition: 'opacity 0.2s linear',
                    cursor: 'default',
                  }}
                  onMouseEnter={() => onHover(measuringPoint?.id || null)}
                  onMouseLeave={() => onHover(null)}
                >
                  <Box>
                    <GraphLineIcon color={generateColorFromId(measuringPoint?.panel_id || 0)} />
                  </Box>
                  <Stack sx={{ textOverflow: 'hidden', overflow: 'hidden' }}>
                    <Typography
                      variant={'extraSmall'}
                      color={'text.secondary'}
                      fontWeight={400}
                      textAlign={'center'}
                      noWrap
                    >
                      {measuringPoint?.measuring_point_panel?.name}
                    </Typography>
                    <Typography noWrap variant={'extraSmall'} textAlign={'center'}>
                      ({measuringPoint?.serial})
                    </Typography>
                  </Stack>
                </Stack>
              </Grid2>
            );
          })}
      </Grid2>
    </>
  );
};

const GraphLineIcon = ({ color }) => {
  return (
    <svg width="24" height="9" viewBox="0 0 24 9">
      <path
        d="M19.7545 8.75518C18.6264 8.75518 17.5443 8.30708 16.7465 7.50943C15.9486 6.71178 15.5003 5.62989 15.5 4.5017C15.4997 3.37351 15.9476 2.2914 16.745 1.49335C17.5425 0.695299 18.6242 0.246658 19.7524 0.246094C20.8806 0.24553 21.9628 0.693088 22.7611 1.49034C23.5593 2.28759 24.0082 3.36925 24.0091 4.49744C24.0099 5.62563 23.5626 6.70797 22.7656 7.50642C21.9685 8.30486 20.887 8.75404 19.7588 8.75517L19.7545 8.75518Z"
        fill={color}
      />
      <path d="M0.5 4.24609L19.5 4.2461" stroke={color} strokeWidth="2" />
    </svg>
  );
};
