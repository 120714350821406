import { z } from 'zod'

export const addressSchemaSchema = z.object({
  address_2: z.union([z.string(), z.null()]).optional(),
  city: z.string().max(128),
  country: z.union([z.string(), z.null()]).default('DE'),
  extra: z.union([z.string(), z.null()]).describe('Extra information to display on the invoice (html formatted).').optional(),
  id: z.union([z.number().int(), z.null()]).optional(),
  phone: z.union([z.string(), z.null()]).optional(),
  street: z.string().max(255),
  street_number: z.string().max(32),
  zip_code: z.string().max(32),
})