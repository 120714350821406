import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { projectTenantAcquisitionConfigurationOutSchema } from './projectTenantAcquisitionConfigurationOutSchema.ts'
import { z } from 'zod'

export const getTenantAcquisitionConfigurationPathParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const getTenantAcquisitionConfiguration200Schema = z.lazy(() => projectTenantAcquisitionConfigurationOutSchema)

/**
 * @description Not Found
 */
export const getTenantAcquisitionConfiguration404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getTenantAcquisitionConfiguration422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getTenantAcquisitionConfigurationQueryResponseSchema = z.lazy(() => getTenantAcquisitionConfiguration200Schema)