import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';

import { useTranslation } from '../../../i18n';
import { FormModal } from '../../modals/FormModal';
import { AddLevyStructureForm } from './LevyStructuresForms/LevyStructures.AddLevyForm';

type Props = {
  buttonColor?: 'primary' | 'success' | 'error' | 'warning' | 'inherit' | 'secondary' | 'info' | 'white';
};

export const CreateLevyStructureModal = ({ buttonColor = 'primary' }: Props) => {
  const [isLevyFormOpen, setIsLevyFormOpen] = useState(false);
  const { t } = useTranslation('levies');

  return (
    <>
      <Button
        size={'large'}
        color={buttonColor}
        variant={'contained'}
        onClick={() => setIsLevyFormOpen(true)}
        endIcon={<Add />}
      >
        {t('btn_create_levies_structure')}
      </Button>

      <FormModal
        open={isLevyFormOpen}
        handleToggle={() => {
          setIsLevyFormOpen(false);
        }}
        title={t('tit_create_levies_structure')}
        width="760px"
      >
        <AddLevyStructureForm
          handleClose={() => {
            setIsLevyFormOpen(false);
          }}
        />
      </FormModal>
    </>
  );
};
