import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { CountProjectsQueryResponse, CountProjects404, CountProjects422 } from '../types/CountProjects.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { countProjects } from '../clients/countProjects.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countProjectsQueryKey = () => [{ url: '/api-internal/projects/count' }] as const

export type CountProjectsQueryKey = ReturnType<typeof countProjectsQueryKey>

export function countProjectsQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = countProjectsQueryKey()
  return queryOptions<CountProjectsQueryResponse, ResponseErrorConfig<CountProjects404 | CountProjects422>, CountProjectsQueryResponse, typeof queryKey>({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countProjects(config)
    },
  })
}

/**
 * @summary Count Projects
 * {@link /api-internal/projects/count}
 */
export function useCountProjects<
  TData = CountProjectsQueryResponse,
  TQueryData = CountProjectsQueryResponse,
  TQueryKey extends QueryKey = CountProjectsQueryKey,
>(
  options: {
    query?: Partial<QueryObserverOptions<CountProjectsQueryResponse, ResponseErrorConfig<CountProjects404 | CountProjects422>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countProjectsQueryKey()

  const query = useQuery({
    ...(countProjectsQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<CountProjects404 | CountProjects422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}