import { Box, Grid2, SxProps, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { MGLoader } from '../MGLoader';

type Props = {
  children: ReactNode | ReactNode[];
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  center?: boolean;
  rightSection?: ReactNode | ReactNode[];
  stickyFooter?: ReactNode | ReactNode[];
  loading?: boolean;
  sx?: SxProps;
};
export const MGPageLayout = ({
  children,
  title,
  subtitle,
  center,
  rightSection,
  stickyFooter,
  loading,
  sx,
}: Props) => {
  return (
    <Grid2 container justifyContent={'center'} sx={{ height: 'calc(100vh - 30px)', width: '100%', ...sx }}>
      <Grid2
        container
        sx={{
          maxWidth: '1100px',
          width: '100%',
          alignContent: 'flex-start',
          paddingX: '20px',
        }}
      >
        {title && (
          <>
            <Grid2
              size={12}
              container
              sx={{
                marginBottom: '0px',
                height: subtitle ? '150px' : '100px',
                marginTop: '40px',
              }}
              id={'page-title'}
            >
              <Grid2
                size={rightSection ? 6 : 12}
                sx={
                  center
                    ? { display: 'flex', justifyContent: 'center' }
                    : { display: 'flex', alignItems: 'center' }
                }
              >
                <Typography variant={'h1'} sx={{ marginBottom: '0px' }}>
                  {title}
                </Typography>
              </Grid2>
              {rightSection && !center && (
                <Grid2
                  size={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {rightSection}
                </Grid2>
              )}

              {subtitle && (
                <Grid2
                  size={12}
                  sx={center ? { display: 'flex', justifyContent: 'center', textAlign: 'center' } : {}}
                >
                  <Typography color={'gray.dark'}>{subtitle}</Typography>
                </Grid2>
              )}
            </Grid2>
          </>
        )}

        {/*Page Content*/}
        <Grid2 size={12} sx={{}}>
          {loading ? (
            <Box height={'calc(100vh - 330px)'}>
              <MGLoader />
            </Box>
          ) : (
            children
          )}
        </Grid2>
      </Grid2>

      {stickyFooter && !loading && (
        <Box
          sx={{
            backgroundColor: '#FFF',
            position: 'sticky',
            bottom: '0px',
            width: '100%',
            boxShadow: '-5px 0 21px -7px #888',
            zIndex: 2,
          }}
        >
          <motion.div animate={{ height: loading ? '0px' : '70px' }}>{stickyFooter}</motion.div>
        </Box>
      )}
    </Grid2>
  );
};
