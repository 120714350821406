// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetContractHtmlQueryResponse,
  GetContractHtmlQueryParams,
  GetContractHtml400,
  GetContractHtml404,
  GetContractHtml422,
} from '../types/GetContractHtml.ts'

export function getGetContractHtmlUrl() {
  return `/api-internal/contracts/html` as const
}

/**
 * @summary Get Contract Html
 * {@link /api-internal/contracts/html}
 */
export async function getContractHtml(params: GetContractHtmlQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetContractHtmlQueryResponse, ResponseErrorConfig<GetContractHtml400 | GetContractHtml404 | GetContractHtml422>, unknown>({
    method: 'GET',
    url: getGetContractHtmlUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}