import { useGetCustomer } from '@mg/api-wrappers/src/api-internal';
import { Box, Stack, Typography } from '@mui/material';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from '../../../../i18n';
import { DataGridApiProvider } from '../../../shared/DataGridCard/useGridApi';
import { NavigateWithSearch } from '../../Billing/NavigateWithSearch.tsx';
import { CustomerDetailsAccounting } from './Accounting/CustomerDetails.Accounting';
import { CustomerDetailsContracts } from './Contracts/CustomerDetails.Contracts';
import { CustomerDetailsData } from './Data/CustomerDetails.Data';
import { CustomerDetailsDocuments } from './Documents/CustomerDetails.Documents';
import { CustomerDetailsMetering } from './Metering/CustomerDetails.Metering';

export const CustomerDetailsPages = () => {
  const { t } = useTranslation('customerDetails');

  const params = useParams();

  const { isLoading, data } = useGetCustomer(parseInt(params.id!), {
    include_address: true,
    include_postal_address: true,
    include_bank_account: true,
    include_measuring_point_panel: true,
    include_total_open_amount: true,
    include_subscription: true,
    include_total_settled_amount: true,
    include_current_plan: true,
    include_active_sepa_mandate_id: true,
  });

  const location = useLocation();

  return (
    <DataGridApiProvider>
      <Box maxHeight={'calc(100vh - 320px)'}>
        <Stack spacing={3}>
          <Typography variant={'h2'}>{t(`tabs_${location.pathname.split('/').pop()}`)}</Typography>
          <Routes>
            <Route path={'/'} element={<NavigateWithSearch replace to="data" />} />
            <Route path={'/data'} element={<CustomerDetailsData isLoading={isLoading} customer={data} />} />
            <Route path={'/contract'} element={<CustomerDetailsContracts customer={data} />} />
            <Route
              path={'/metering'}
              element={
                <CustomerDetailsMetering
                  panelId={data?.measuring_point_panel!.id as number}
                  subscription={data?.subscription}
                />
              }
            />
            <Route
              path={'/documents'}
              element={<CustomerDetailsDocuments customerId={data?.id as number} />}
            />
            <Route
              path={'/transactions'}
              element={<CustomerDetailsAccounting customerId={data?.id as number} />}
            />
          </Routes>
        </Stack>
      </Box>
    </DataGridApiProvider>
  );
};
