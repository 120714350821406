import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const countPaymentPlansQueryParamsSchema = z.object({
  document_type: z.literal('payment_plan').default('payment_plan'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  project_id: z.number().int(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  start_date_from: z.union([z.string(), z.null()]).optional(),
  start_date_to: z.union([z.string(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const countPaymentPlans200Schema = z.number().int()

/**
 * @description Not Found
 */
export const countPaymentPlans404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const countPaymentPlans422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const countPaymentPlansQueryResponseSchema = z.lazy(() => countPaymentPlans200Schema)