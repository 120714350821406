import { MGModal } from '@mg/ui/src/components/MGModal';
import { Box, Grid2, Typography } from '@mui/material';
import { InlineWidget } from 'react-calendly';
import { useUserInfo } from '../../helpers/userInfo';
import { useTranslation } from '../../i18n';
import { Loader } from '../shared/Loader/Loader';

type Props = {
  calendlyLink: string;
  open: boolean;
  handleToggle: () => void;
  translationPrefix: string;
};

export const CalendlyModal = ({ calendlyLink, open, handleToggle, translationPrefix }: Props) => {
  const { t } = useTranslation('dashboard');
  const [user] = useUserInfo();

  const prefill = user
    ? {
        email: user.email || user.username,
        name: `${user.first_name} ${user.last_name}`,
      }
    : {};

  return (
    <MGModal
      open={open}
      handleToggle={handleToggle}
      height={'720px'}
      width={'710px'}
      position={{ anchorTop: 'center' }}
      showCloseButton
      PaperStyle={{ overflow: 'hidden' }}
      BodyStyle={{ overflow: 'hidden' }}
    >
      <Grid2 container paddingX={'20px'}>
        <Grid2 size={12} textAlign={'center'} paddingTop={'50px'}>
          <Typography variant={'h2'} fontWeight={'bold'}>
            {t(`${translationPrefix}_modal_title`)}
          </Typography>
        </Grid2>
        <Grid2 size={12} textAlign={'center'} paddingY={'40px'}>
          <Typography variant={'regular'}>{t(`${translationPrefix}_modal_subtitle`)}</Typography>
        </Grid2>
        <Grid2 size={12} display={'flex'} justifyContent={'center'}>
          <Box position={'absolute'} top={'120px'} sx={{ scale: '0.7' }}>
            <InlineWidget
              LoadingSpinner={() => (
                <Box height={'657px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Loader />
                </Box>
              )}
              utm={{ utmSource: 'portal' }}
              prefill={prefill}
              url={calendlyLink}
              styles={{ minWidth: '1000px', height: '657px' }}
            />
          </Box>
        </Grid2>
      </Grid2>
    </MGModal>
  );
};
