import { userFeatureFlagsSchema } from './userFeatureFlagsSchema.ts'
import { z } from 'zod'

export const currentUserSchemaSchema = z.object({
  accessible_project_ids: z.array(z.number().int()),
  email: z.union([z.string(), z.null()]).optional(),
  first_name: z.union([z.string(), z.null()]).optional(),
  flags: z.lazy(() => userFeatureFlagsSchema),
  id: z.union([z.number().int(), z.null()]).optional(),
  is_active: z.boolean().default(true).describe('Legt fest, ob dieser Benutzer aktiv ist. Kann deaktiviert werden, anstatt Benutzer zu löschen.'),
  is_staff: z.boolean().default(false).describe('Legt fest, ob sich der Benutzer an der Administrationsseite anmelden kann.'),
  last_name: z.union([z.string(), z.null()]).optional(),
  username: z.string().max(150).describe('Erforderlich. 150 Zeichen oder weniger. Nur Buchstaben, Ziffern und @/./+/-/_.'),
})