import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout';
import { Stack } from '@mui/material';
import { ContractsData } from './Contracts.Data.tsx';
import { ContractsFlowChart } from './Contracts.FlowChart.tsx';
import { ContractsSettingsModal } from './Contracts.SettingsModal.tsx';
import { ContractsShareLinkModal } from './Contracts.ShareLinkModal.tsx';
export const Contracts = () => {
  return (
    <MGPageLayout
      title="Mieterstromverträge"
      rightSection={
        <Stack direction={'row'} spacing={1}>
          <ContractsShareLinkModal />
          <ContractsSettingsModal />
        </Stack>
      }
    >
      <Stack spacing={8}>
        <ContractsFlowChart />
        <ContractsData />
      </Stack>
    </MGPageLayout>
  );
};
