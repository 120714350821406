import { Box, Grid2, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { Check, File as FileIcon, TriangleAlert, Upload, X } from 'lucide-react';
import { ReactNode, useMemo, useState } from 'react';
import Dropzone, { Accept, FileRejection } from 'react-dropzone';
import { palette } from '../../../styles';
import { MGIcon } from '../../MGIcon';
import { MGImageWithFallback } from '../../MGImageWithFallback';

type Props = {
  uploadTitle: ReactNode;
  uploadFileInfo: ReactNode;
  label?: string;
  value?: { file_name: string; url: string } | null;
  accept: Accept;
  handleUpload: (
    file: File,
    onUploadProgress: (AxiosProgressEvent) => void,
    onSuccess: () => void,
    onError: (error) => void,
  ) => void;
  handleDelete: () => void;
};

export const MGFieldFileUpload = ({
  uploadTitle,
  uploadFileInfo,
  label,
  handleUpload,
  handleDelete,
  value,
  accept,
}: Props) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const currentFile = useMemo(
    () =>
      file
        ? {
            name: file.name,
            url: URL.createObjectURL(file),
          }
        : value
          ? {
              name: value.file_name,
              url: value.url,
            }
          : null,
    [file, value],
  );

  const handleDrop = (files: File[]) => {
    setFile(files[0]);
    handleUpload(
      files[0],
      (progressEvent) => {
        setUploadProgress(Math.floor(progressEvent.progress * 100));
      },
      () => {
        setIsSuccess(true);
        setError(false);
      },
      (error) => {
        setError(error);
      },
    );
  };

  const handleDropReject = (files: FileRejection[]) => {
    /* Workaround to display filename and error message if file type doesnt match the accept criteria */
    setFile(files[0].file);
    setError(true);
  };

  const handleRemove = async () => {
    await handleDelete();
    setFile(null);
  };

  const isUploading = uploadProgress !== 0 && uploadProgress <= 100;

  return (
    <Stack spacing={1} height={'100%'}>
      {label && (
        <Typography variant={'small'} fontWeight={300}>
          {label}
        </Typography>
      )}
      <Dropzone onDropAccepted={handleDrop} onDropRejected={handleDropReject} accept={accept}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <Box
            {...getRootProps()}
            sx={{
              height: '120px',
              borderWidth: '1px',
              borderColor: error ? 'error.main' : 'primary.main',
              borderStyle: isUploading ? 'solid' : 'dashed',
              borderRadius: 1,
              backgroundColor: isDragActive ? 'primary.light' : 'none',
              transition: 'background-color 0.3s',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
              position: 'relative',
            }}
          >
            {currentFile ? (
              <Grid2
                container
                direction={'row'}
                alignItems={'center'}
                spacing={1}
                height={'100%'}
                padding={2}
              >
                <Grid2
                  size={3}
                  height={'100%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <MGImageWithFallback
                    style={{
                      width: '100%',
                      borderRadius: 5,
                      objectFit: 'cover',
                      aspectRatio: '1',
                    }}
                    src={currentFile.url}
                    alt={currentFile.name}
                    fallback={
                      <MGIcon variant={'soft'} color={'error'}>
                        <FileIcon />
                      </MGIcon>
                    }
                  />
                </Grid2>
                <Grid2 size={9}>
                  <Stack spacing={0.7}>
                    <Box width={'100%'}>
                      <Typography
                        noWrap
                        variant={'small'}
                        sx={{ display: 'block', textOverflow: 'ellipsis' }}
                      >
                        {currentFile.name || ''}
                      </Typography>
                    </Box>
                    {!!uploadProgress && uploadProgress < 100 ? (
                      <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Box flex={1}>
                          <LinearProgress variant="determinate" value={uploadProgress} />
                        </Box>
                        <Typography variant={'small'}>{`${uploadProgress}%`}</Typography>
                      </Stack>
                    ) : error ? (
                      <Stack direction={'row'} spacing={0.5}>
                        <Typography variant={'extraSmall'}>Upload fehlgeschlagen</Typography>
                        <MGIcon variant={'soft'} color={'error'} size={18}>
                          <TriangleAlert size={12} />
                        </MGIcon>
                      </Stack>
                    ) : (
                      isSuccess && (
                        <Stack direction={'row'} spacing={0.5}>
                          <Typography variant={'extraSmall'}>Upload abgeschlossen</Typography>
                          <MGIcon variant={'soft'} size={18}>
                            <Check size={12} />
                          </MGIcon>
                        </Stack>
                      )
                    )}
                  </Stack>
                </Grid2>
              </Grid2>
            ) : (
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                <input {...getInputProps()} />
                <Stack alignItems={'center'} spacing={0} justifyContent={'space-between'}>
                  <Upload size={24} color={palette.primary.main} />
                  <Typography variant={'small'} color={'primary.main'} textAlign={'center'}>
                    {uploadTitle}
                  </Typography>
                  <Typography variant={'extraSmall'}>{uploadFileInfo}</Typography>
                </Stack>
              </Box>
            )}
            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
              <IconButton onClick={handleRemove}>
                <X />
              </IconButton>
            </Box>
          </Box>
        )}
      </Dropzone>
    </Stack>
  );
};
