import { usePreflightBillingQueries } from '@mg/api-wrappers/src/custom_wrapper/usePreflightBillingQueries';
import { MGTooltip } from '@mg/ui/src/components/MGTooltip';
import { presentDate } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles';
import { Avatar, Box, Button, Card, Chip, Stack, Typography } from '@mui/material';
import { useMolecule } from 'bunshi/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { CircleHelp } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlags } from '../../../../../feature-flags';
import { useTranslation } from '../../../../../i18n';
import { billingMolecule } from '../../Billing.Atoms';
import { useRequireBillingInitialized } from '../../useRequireBillingInitialized';
import { BillingDetailsPerCustomerAlert } from './Billing.DetailsPerCustomer.Alert';
import { BillingDetailsPerCustomerCustomerCard } from './Billing.DetailsPerCustomer.CustomerCard';
import { BillingDetailsPerCustomerFinishBillingModal } from './Billing.DetailsPerCustomer.FinishBillingModal';
import { BillingDetailsPerCustomerLevyCard } from './Billing.DetailsPerCustomer.LevyCard';
import { BillingDetailsPerCustomerLoading } from './Billing.DetailsPerCustomer.Loading';
import { BillingDetailsPerCustomerPlansCard } from './Billing.DetailsPerCustomer.PlansCard';
import { BillingDetailsPerCustomerSidebar } from './Billing.DetailsPerCustomer.Sidebar';
import { BillingDetailsPerCustomerTransactionsCard } from './Billing.DetailsPerCustomer.TransactionsCard';

export const BillingDetailsPerCustomer = () => {
  useRequireBillingInitialized();

  const { billingDebug: isDebug } = useFlags();
  const { t } = useTranslation('billing');
  const navigate = useNavigate();

  const { billingCustomersAtom, verifiedTransactionsAtom } = useMolecule(billingMolecule);
  const billingCustomers = useAtomValue(billingCustomersAtom);
  const preflightBillingQueries = usePreflightBillingQueries(
    billingCustomers.map((billingCustomer) => ({
      subscription_id: billingCustomer.subscription!.id as number,
      start_date: billingCustomer.start_date,
      end_date: billingCustomer.end_date,
    })),
  );

  const setVerifiedTransactions = useSetAtom(verifiedTransactionsAtom);

  useEffect(() => {
    preflightBillingQueries.forEach((entry) => {
      if (!entry.data) return;

      setVerifiedTransactions((prev) => {
        if (!entry.data?.customer.id) return prev;

        return {
          ...prev,
          [entry.data.customer.id]:
            prev[entry.data.customer.id] ||
            !entry.data.transactions.some((transaction) =>
              ['due', 'overdue'].includes(transaction.granular_state),
            ),
        };
      });
    });
  }, [preflightBillingQueries, setVerifiedTransactions]);

  const [selectedCustomer, setSelectedCustomer] = useState(billingCustomers[0]?.customer);

  if (preflightBillingQueries.some((entry) => entry.isLoading)) {
    return <BillingDetailsPerCustomerLoading data={preflightBillingQueries as any} />;
  }

  /* If there are no customers available for billing or all requests returned a 500 because of unrelated issues, show a error message.*/
  if (!billingCustomers.length || !preflightBillingQueries.length) {
    return <Stack>Keine Abrechnenbaren Kunden gefunden</Stack>;
  }

  const hasError = preflightBillingQueries.some(
    (entry) =>
      entry.error ||
      (entry.data?.errors?.length ?? 0) > 0 ||
      (entry.data?.levy_structure_errors?.length ?? 0) > 0 ||
      (entry.data?.residual_power_plan_errors?.length ?? 0) > 0,
  );

  const selectedCustomerPreflightDataQuery = preflightBillingQueries.find(
    (entry) => entry?.data?.customer?.id === selectedCustomer?.id,
  );

  return (
    <Stack spacing={5}>
      <Stack direction={'row'} spacing={3}>
        <Box flex={2} sx={{ maxWidth: '250px' }}>
          <BillingDetailsPerCustomerSidebar
            queries={preflightBillingQueries as any}
            onChange={setSelectedCustomer}
            selectedCustomer={selectedCustomer}
          />
        </Box>
        <Box flex={6}>
          <Card sx={{ padding: 5 }}>
            {selectedCustomerPreflightDataQuery?.isError && (
              <Stack spacing={2}>
                <Typography variant={'small'} color={'error'}>
                  {t('details_per_customer_error_text')}
                </Typography>
              </Stack>
            )}
            {selectedCustomerPreflightDataQuery?.isSuccess && (
              <Stack spacing={2}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <Avatar sx={{ bgcolor: 'primary.light', color: 'primary.main' }} />
                    <Typography variant={'large'} fontWeight={'500'} color={'text.secondary'}>
                      {selectedCustomer?.display_name}
                    </Typography>
                  </Stack>

                  <MGTooltip
                    title={t('billing_individual_period_tooltip_title')}
                    text={t('billing_individual_period_tooltip_text')}
                  >
                    <Stack direction={'row'} gap={0.5}>
                      <Chip
                        color="primary"
                        variant={'soft'}
                        label={`${presentDate(selectedCustomerPreflightDataQuery.data.billing_start)} - ${presentDate(selectedCustomerPreflightDataQuery.data.billing_end)}`}
                      />
                      <Box>
                        <CircleHelp size={18} color={palette.gray.main} />
                      </Box>
                    </Stack>
                  </MGTooltip>
                </Box>

                <BillingDetailsPerCustomerAlert
                  isBillable={selectedCustomerPreflightDataQuery.data.is_billable}
                  errors={selectedCustomerPreflightDataQuery.data.errors}
                />
                <BillingDetailsPerCustomerLevyCard
                  isLoading={
                    selectedCustomerPreflightDataQuery.isLoading ||
                    selectedCustomerPreflightDataQuery.isRefetching
                  }
                  levyStructureIntervals={
                    selectedCustomerPreflightDataQuery.data.levy_structure_intervals || []
                  }
                  errors={selectedCustomerPreflightDataQuery.data.levy_structure_errors}
                />
                <BillingDetailsPerCustomerPlansCard
                  subscriptionPlanIntervals={
                    selectedCustomerPreflightDataQuery.data.subscription_plan_intervals || []
                  }
                  residualPowerPlanIntervals={
                    selectedCustomerPreflightDataQuery.data.residual_power_plan_intervals || []
                  }
                  subscriptionPlanErrors={selectedCustomerPreflightDataQuery.data.subscription_plan_errors}
                  residualPowerPlanErrors={selectedCustomerPreflightDataQuery.data.residual_power_plan_errors}
                />
                <BillingDetailsPerCustomerTransactionsCard
                  customerId={selectedCustomer.id!}
                  startDate={selectedCustomerPreflightDataQuery.data.billing_start}
                  endDate={selectedCustomerPreflightDataQuery.data.billing_end}
                />
                <BillingDetailsPerCustomerCustomerCard customerId={selectedCustomer.id as number} />
              </Stack>
            )}
          </Card>
        </Box>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant={'contained'} color={'gray'} onClick={() => navigate(-1)}>
          {t('details_per_customer_back_button')}
        </Button>
        <BillingDetailsPerCustomerFinishBillingModal disableToggle={!isDebug && hasError} />
      </Box>
    </Stack>
  );
};
