// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetPaymentPlanPdfsAsZipQueryResponse,
  GetPaymentPlanPdfsAsZipQueryParams,
  GetPaymentPlanPdfsAsZip400,
  GetPaymentPlanPdfsAsZip404,
  GetPaymentPlanPdfsAsZip422,
} from '../types/GetPaymentPlanPdfsAsZip.ts'

export function getGetPaymentPlanPdfsAsZipUrl() {
  return `/api-internal/payment_plans/pdfs` as const
}

/**
 * @summary Get Payment Plan Pdfs As Zip
 * {@link /api-internal/payment_plans/pdfs}
 */
export async function getPaymentPlanPdfsAsZip(params: GetPaymentPlanPdfsAsZipQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetPaymentPlanPdfsAsZipQueryResponse,
    ResponseErrorConfig<GetPaymentPlanPdfsAsZip400 | GetPaymentPlanPdfsAsZip404 | GetPaymentPlanPdfsAsZip422>,
    unknown
  >({ method: 'GET', url: getGetPaymentPlanPdfsAsZipUrl().toString(), params, ...requestConfig })
  return res.data
}