import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetProjectRemoteReadabilityStatusQueryResponse,
  GetProjectRemoteReadabilityStatusPathParams,
  GetProjectRemoteReadabilityStatus404,
  GetProjectRemoteReadabilityStatus422,
} from '../types/GetProjectRemoteReadabilityStatus.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getProjectRemoteReadabilityStatus } from '../clients/getProjectRemoteReadabilityStatus.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getProjectRemoteReadabilityStatusQueryKey = (id: GetProjectRemoteReadabilityStatusPathParams['id']) =>
  [{ url: '/api-internal/projects/:id/remote_readability_status', params: { id: id } }] as const

export type GetProjectRemoteReadabilityStatusQueryKey = ReturnType<typeof getProjectRemoteReadabilityStatusQueryKey>

export function getProjectRemoteReadabilityStatusQueryOptions(
  id: GetProjectRemoteReadabilityStatusPathParams['id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getProjectRemoteReadabilityStatusQueryKey(id)
  return queryOptions<
    GetProjectRemoteReadabilityStatusQueryResponse,
    ResponseErrorConfig<GetProjectRemoteReadabilityStatus404 | GetProjectRemoteReadabilityStatus422>,
    GetProjectRemoteReadabilityStatusQueryResponse,
    typeof queryKey
  >({
    enabled: !!id,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getProjectRemoteReadabilityStatus(id, config)
    },
  })
}

/**
 * @summary Get Project Remote Readability Status
 * {@link /api-internal/projects/:id/remote_readability_status}
 */
export function useGetProjectRemoteReadabilityStatus<
  TData = GetProjectRemoteReadabilityStatusQueryResponse,
  TQueryData = GetProjectRemoteReadabilityStatusQueryResponse,
  TQueryKey extends QueryKey = GetProjectRemoteReadabilityStatusQueryKey,
>(
  id: GetProjectRemoteReadabilityStatusPathParams['id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetProjectRemoteReadabilityStatusQueryResponse,
        ResponseErrorConfig<GetProjectRemoteReadabilityStatus404 | GetProjectRemoteReadabilityStatus422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getProjectRemoteReadabilityStatusQueryKey(id)

  const query = useQuery({
    ...(getProjectRemoteReadabilityStatusQueryOptions(id, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetProjectRemoteReadabilityStatus404 | GetProjectRemoteReadabilityStatus422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}