import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const exportMeasuringPointDataQueryParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const exportMeasuringPointData200Schema = z.unknown()

/**
 * @description Bad Request
 */
export const exportMeasuringPointData400Schema = z.string()

/**
 * @description Not Found
 */
export const exportMeasuringPointData404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const exportMeasuringPointData422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const exportMeasuringPointDataQueryResponseSchema = z.lazy(() => exportMeasuringPointData200Schema)