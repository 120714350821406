import { z } from 'zod'

/**
 * @description Returned when an object is not found
 */
export const notFoundErrorOutSchema = z
  .object({
    title: z.string().optional(),
    type: z.literal('https://metergrid.de/api-errors/not-found').default('https://metergrid.de/api-errors/not-found'),
  })
  .describe('Returned when an object is not found')