import { measuringPointPanelNestedSchemaSchema } from './measuringPointPanelNestedSchemaSchema.ts'
import { measuringPointPanelUpdateSchemaSchema } from './measuringPointPanelUpdateSchemaSchema.ts'
import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const updateMeasuringPointPanelPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const updateMeasuringPointPanel200Schema = z.lazy(() => measuringPointPanelNestedSchemaSchema)

/**
 * @description Not Found
 */
export const updateMeasuringPointPanel404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const updateMeasuringPointPanel422Schema = z.union([z.lazy(() => paramValidationErrorOutSchema), z.lazy(() => modelValidationErrorOutSchema)])

export const updateMeasuringPointPanelMutationRequestSchema = z.lazy(() => measuringPointPanelUpdateSchemaSchema)

export const updateMeasuringPointPanelMutationResponseSchema = z.lazy(() => updateMeasuringPointPanel200Schema)