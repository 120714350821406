// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListSepaTransactionsQueryResponse,
  ListSepaTransactionsQueryParams,
  ListSepaTransactions404,
  ListSepaTransactions422,
} from '../types/ListSepaTransactions.ts'

export function getListSepaTransactionsUrl() {
  return `/api-internal/transactions/sepa_transactions` as const
}

/**
 * @summary List Sepa Transactions
 * {@link /api-internal/transactions/sepa_transactions}
 */
export async function listSepaTransactions(params?: ListSepaTransactionsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListSepaTransactionsQueryResponse, ResponseErrorConfig<ListSepaTransactions404 | ListSepaTransactions422>, unknown>({
    method: 'GET',
    url: getListSepaTransactionsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}