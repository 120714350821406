import { useListPlans } from '@mg/api-wrappers/src/api-internal';
import { MGModal, MGModalBody, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { Box, Button, Stack } from '@mui/material';
import { ExternalLink } from 'lucide-react';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter.ts';
import { useTranslation } from '../../../i18n';
import { ContractsShareLinkModalPlanCard } from './Contracts.ShareLinkModal.PlanCard.tsx';
export const ContractsShareLinkModal = () => {
  const { t } = useTranslation('contracts');
  const projectFilter = useSelectedProjectsFilter();
  const plansQuery = useListPlans({ ...projectFilter, skip_pagination: true });

  return (
    <MGModal
      width={'900px'}
      buttonComponent={
        <Button variant={'contained'} endIcon={<ExternalLink />}>
          Bewohnerlink teilen
        </Button>
      }
    >
      <MGModalTitle>{t('share_link_modal_title')}</MGModalTitle>
      <MGModalBody>
        <Stack spacing={3}>
          <Box>{t('share_link_modal_subtitle')}</Box>
          <Box maxHeight={'80vh'} overflow={'auto'}>
            <Stack spacing={2}>
              {plansQuery.data?.items.map((plan) => <ContractsShareLinkModalPlanCard plan={plan} />)}
            </Stack>
          </Box>
        </Stack>
      </MGModalBody>
    </MGModal>
  );
};
