// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListNotificationsQueryResponse, ListNotificationsQueryParams, ListNotifications404, ListNotifications422 } from '../types/ListNotifications.ts'

export function getListNotificationsUrl() {
  return `/api-internal/notifications/` as const
}

/**
 * @summary List Notifications
 * {@link /api-internal/notifications/}
 */
export async function listNotifications(params: ListNotificationsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListNotificationsQueryResponse, ResponseErrorConfig<ListNotifications404 | ListNotifications422>, unknown>({
    method: 'GET',
    url: getListNotificationsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}