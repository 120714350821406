import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { CountCustomersQueryResponse, CountCustomersQueryParams, CountCustomers404, CountCustomers422 } from '../types/CountCustomers.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { countCustomers } from '../clients/countCustomers.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countCustomersQueryKey = (params?: CountCustomersQueryParams) => [{ url: '/api-internal/customers/count' }, ...(params ? [params] : [])] as const

export type CountCustomersQueryKey = ReturnType<typeof countCustomersQueryKey>

export function countCustomersQueryOptions(params?: CountCustomersQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = countCustomersQueryKey(params)
  return queryOptions<CountCustomersQueryResponse, ResponseErrorConfig<CountCustomers404 | CountCustomers422>, CountCustomersQueryResponse, typeof queryKey>({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countCustomers(params, config)
    },
  })
}

/**
 * @summary Count Customers
 * {@link /api-internal/customers/count}
 */
export function useCountCustomers<
  TData = CountCustomersQueryResponse,
  TQueryData = CountCustomersQueryResponse,
  TQueryKey extends QueryKey = CountCustomersQueryKey,
>(
  params?: CountCustomersQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<CountCustomersQueryResponse, ResponseErrorConfig<CountCustomers404 | CountCustomers422>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countCustomersQueryKey(params)

  const query = useQuery({
    ...(countCustomersQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<CountCustomers404 | CountCustomers422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}