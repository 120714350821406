import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CreateMeasuringPointPanelMutationRequest,
  CreateMeasuringPointPanelMutationResponse,
  CreateMeasuringPointPanel404,
  CreateMeasuringPointPanel422,
} from '../types/CreateMeasuringPointPanel.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { createMeasuringPointPanel } from '../clients/createMeasuringPointPanel.ts'
import { useMutation } from '@tanstack/react-query'

export const createMeasuringPointPanelMutationKey = () => [{ url: '/api-internal/measuring_point_panels' }] as const

export type CreateMeasuringPointPanelMutationKey = ReturnType<typeof createMeasuringPointPanelMutationKey>

/**
 * @summary Create Measuring Point Panel
 * {@link /api-internal/measuring_point_panels}
 */
export function useCreateMeasuringPointPanel(
  options: {
    mutation?: UseMutationOptions<
      CreateMeasuringPointPanelMutationResponse,
      ResponseErrorConfig<CreateMeasuringPointPanel404 | CreateMeasuringPointPanel422>,
      { data: CreateMeasuringPointPanelMutationRequest }
    >
    client?: Partial<RequestConfig<CreateMeasuringPointPanelMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createMeasuringPointPanelMutationKey()

  return useMutation<
    CreateMeasuringPointPanelMutationResponse,
    ResponseErrorConfig<CreateMeasuringPointPanel404 | CreateMeasuringPointPanel422>,
    { data: CreateMeasuringPointPanelMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return createMeasuringPointPanel(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}