import { MGCard, MGCardContent, MGCardHeader, MGCardTitle } from '@mg/ui/src/components/MGCard';
import { palette } from '@mg/ui/src/styles';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { ArrowRight, ReceiptEuro } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { CreateLetterModal } from '../../modals/CreateLetterModal/CreateLetterModal';

export const DocumentsCards = () => {
  const { t } = useTranslation('documents');

  return (
    <Stack direction={'row'} id={'test'} spacing={2}>
      <MGCard sx={{ width: '100%', height: 'auto' }}>
        <MGCardHeader leftHeader={<ReceiptEuro size={20} color={palette.primary.main} />} />
        <MGCardTitle>
          <Typography variant={'regular'} fontWeight={'600'}>
            {t('create_invoice_title')}
          </Typography>
        </MGCardTitle>
        <MGCardContent>
          <Typography variant={'small'} color={palette.gray.dark}>
            {t('create_ínvoice_text')}
          </Typography>
          <Box mt={2}>
            <Button
              variant={'contained'}
              to={'/customers'}
              component={Link}
              endIcon={<ArrowRight size={18} />}
              size={'medium'}
              color={'white'}
            >
              {t('create_invoice_button')}
            </Button>
          </Box>
        </MGCardContent>
      </MGCard>

      <MGCard sx={{ width: '100%', height: 'auto' }}>
        <MGCardHeader leftHeader={<ReceiptEuro size={20} color={palette.primary.main} />} />
        <MGCardTitle>
          <Typography variant={'regular'} fontWeight={'600'}>
            {t('create_letter_title')}
          </Typography>
        </MGCardTitle>
        <MGCardContent>
          <Typography variant={'small'} color={palette.gray.dark}>
            {t('create_letter_text')}
          </Typography>
          <Box mt={2}>
            <CreateLetterModal
              toggler={
                <Button
                  variant={'contained'}
                  endIcon={<ArrowRight size={18} />}
                  size={'medium'}
                  color={'white'}
                >
                  {t('create_letter_button')}
                </Button>
              }
            />
          </Box>
        </MGCardContent>
      </MGCard>

      <MGCard sx={{ width: '100%', height: 'auto' }}>
        <MGCardHeader
          leftHeader={<ReceiptEuro size={20} color={palette.primary.main} />}
          rightHeader={
            <Chip
              size={'small'}
              sx={{ fontSize: '12px', borderRadius: '8px' }}
              variant={'soft'}
              color={'primary'}
              label={t('soon_available')}
            />
          }
        />
        <MGCardTitle>
          <Typography variant={'regular'} fontWeight={'600'}>
            {t('upload_document_title')}
          </Typography>
        </MGCardTitle>
        <MGCardContent>
          <Typography variant={'small'} color={palette.gray.dark}>
            {t('upload_document_text')}
          </Typography>
        </MGCardContent>
      </MGCard>
    </Stack>
  );
};
