// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListPlansQueryResponse, ListPlansQueryParams, ListPlans404, ListPlans422 } from '../types/ListPlans.ts'

export function getListPlansUrl() {
  return `/api-internal/plans/` as const
}

/**
 * @summary List Plans
 * {@link /api-internal/plans/}
 */
export async function listPlans(params: ListPlansQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListPlansQueryResponse, ResponseErrorConfig<ListPlans404 | ListPlans422>, unknown>({
    method: 'GET',
    url: getListPlansUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}