// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetLetterPdfQueryResponse, GetLetterPdfPathParams, GetLetterPdf400, GetLetterPdf404, GetLetterPdf422 } from '../types/GetLetterPdf.ts'

export function getGetLetterPdfUrl(id: GetLetterPdfPathParams['id']) {
  return `/api-internal/letters/${id}/pdf` as const
}

/**
 * @summary Get Letter Pdf
 * {@link /api-internal/letters/:id/pdf}
 */
export async function getLetterPdf(id: GetLetterPdfPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetLetterPdfQueryResponse, ResponseErrorConfig<GetLetterPdf400 | GetLetterPdf404 | GetLetterPdf422>, unknown>({
    method: 'GET',
    url: getGetLetterPdfUrl(id).toString(),
    ...requestConfig,
  })
  return res.data
}