import { InputAdornment } from '@mui/material';
import { ReactNode } from 'react';
import { MGFieldText, MGFieldTextProps } from './MGField/MGField.Text';
import { MGFormFieldBaseProps } from './MGForm.Field';

interface Props extends MGFormFieldBaseProps, Pick<MGFieldTextProps, 'highlight'> {
  step?: string;
  min?: number;
  max?: number;
  endAdornment?: ReactNode;
}

export const MGFormFieldNumber = ({
  label = '',
  form,
  name,
  FieldProps,
  highlight,
  step = '1',
  min,
  max,
  endAdornment,
  TextFieldProps,
}: Props) => {
  return (
    <form.Field name={name} {...FieldProps}>
      {(field) => (
        <MGFieldText
          name={field.name}
          value={field.state.value || ''}
          label={label}
          errors={field.state.meta.errors}
          highlight={highlight}
          TextFieldProps={{
            type: 'number',
            slotProps: {
              input: {
                endAdornment: endAdornment && (
                  <InputAdornment position={'end'}> {endAdornment}</InputAdornment>
                ),
              },
            },
            inputProps: { step, min, max },
            ...TextFieldProps,
          }}
          onChange={(e) => field.handleChange(e.target.value)}
          onBlur={field.handleBlur}
        />
      )}
    </form.Field>
  );
};
