// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SettleTransactionsMutationRequest,
  SettleTransactionsMutationResponse,
  SettleTransactions404,
  SettleTransactions422,
} from '../types/SettleTransactions.ts'

export function getSettleTransactionsUrl() {
  return `/api-internal/transactions/settle` as const
}

/**
 * @summary Settle Transactions
 * {@link /api-internal/transactions/settle}
 */
export async function settleTransactions(
  data?: SettleTransactionsMutationRequest,
  config: Partial<RequestConfig<SettleTransactionsMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    SettleTransactionsMutationResponse,
    ResponseErrorConfig<SettleTransactions404 | SettleTransactions422>,
    SettleTransactionsMutationRequest
  >({ method: 'POST', url: getSettleTransactionsUrl().toString(), data, ...requestConfig })
  return res.data
}