import { useListSubscriptionsForPanel } from '@mg/api-wrappers/src/api-internal';
import { MGCard } from '@mg/ui/src/components/MGCard';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from '../../../i18n';
import { MeteringDetailsCustomerListActionMenu } from './MeteringDetails.CustomerList.ActionMenu';

export const MeteringDetailsCustomerList = ({ panelId }) => {
  const { data, isLoading, isError } = useListSubscriptionsForPanel(panelId, {
    include_customer: true,
  });
  const { t, tString } = useTranslation('meteringDetails');

  const columns = [
    {
      field: 'customer.name',
      headerName: tString('customer_list_name'),
      flex: 2,
      renderCell: (params) => <MGCustomerWithAvatar customer={params.row.customer} />,
    },
    {
      field: 'state',
      headerName: tString('customer_list_status'),
      valueGetter: (value) => t(`state_${value}`),
      flex: 1,
    },
    {
      field: 'id',
      headerName: tString('customer_list_contract_number'),
      flex: 1,
    },
    {
      field: 'customer.email',
      headerName: tString('customer_list_email'),
      flex: 1,
      valueGetter: (_value, row) => {
        return row.customer?.email || '-';
      },
    },
    {
      field: 'customer.phone_number',
      headerName: tString('customer_list_phone'),
      flex: 1,
      valueGetter: (_value, row) => {
        return row.customer?.phone_number || '-';
      },
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.1,
      sortable: false,
      renderCell: (params) => <MeteringDetailsCustomerListActionMenu customerId={params.row.customer.id} />,
    },
  ];
  return (
    <Stack spacing={2}>
      <Typography variant={'h3'} color={'text.primary'}>
        {t('customer_list_title') + ':'}
      </Typography>
      <MGCard isLoading={isLoading} isError={isError} noData={!data?.length} sx={{ height: '300px' }}>
        <DataGrid
          rows={data || []}
          sx={{
            minHeight: '300px',
            '.MuiDataGrid-row': { paddingY: '0px' },
            '.MuiDataGrid-filler': { height: '0px' },
          }}
          columns={columns}
        />
      </MGCard>
    </Stack>
  );
};
