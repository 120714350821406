import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListTransactionsQueryResponse, ListTransactionsQueryParams, ListTransactions404, ListTransactions422 } from '../types/ListTransactions.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { listTransactions } from '../clients/listTransactions.ts'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listTransactionsInfiniteQueryKey = (params?: ListTransactionsQueryParams) =>
  [{ url: '/api-internal/transactions/' }, ...(params ? [params] : [])] as const

export type ListTransactionsInfiniteQueryKey = ReturnType<typeof listTransactionsInfiniteQueryKey>

export function listTransactionsInfiniteQueryOptions(params?: ListTransactionsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listTransactionsInfiniteQueryKey(params)
  return infiniteQueryOptions<
    ListTransactionsQueryResponse,
    ResponseErrorConfig<ListTransactions404 | ListTransactions422>,
    ListTransactionsQueryResponse,
    typeof queryKey,
    number
  >({
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListTransactionsQueryParams['page']
      }
      return listTransactions(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Transactions
 * {@link /api-internal/transactions/}
 */
export function useListTransactionsInfinite<
  TData = InfiniteData<ListTransactionsQueryResponse>,
  TQueryData = ListTransactionsQueryResponse,
  TQueryKey extends QueryKey = ListTransactionsInfiniteQueryKey,
>(
  params?: ListTransactionsQueryParams,
  options: {
    query?: Partial<
      InfiniteQueryObserverOptions<ListTransactionsQueryResponse, ResponseErrorConfig<ListTransactions404 | ListTransactions422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listTransactionsInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listTransactionsInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ResponseErrorConfig<ListTransactions404 | ListTransactions422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}