// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetInvoicePdfsAsZipQueryResponse,
  GetInvoicePdfsAsZipQueryParams,
  GetInvoicePdfsAsZip400,
  GetInvoicePdfsAsZip404,
  GetInvoicePdfsAsZip422,
} from '../types/GetInvoicePdfsAsZip.ts'

export function getGetInvoicePdfsAsZipUrl() {
  return `/api-internal/invoices/pdfs` as const
}

/**
 * @summary Get Invoice Pdfs As Zip
 * {@link /api-internal/invoices/pdfs}
 */
export async function getInvoicePdfsAsZip(params: GetInvoicePdfsAsZipQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetInvoicePdfsAsZipQueryResponse,
    ResponseErrorConfig<GetInvoicePdfsAsZip400 | GetInvoicePdfsAsZip404 | GetInvoicePdfsAsZip422>,
    unknown
  >({ method: 'GET', url: getGetInvoicePdfsAsZipUrl().toString(), params, ...requestConfig })
  return res.data
}