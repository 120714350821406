import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetDefaultLevyYearsQueryResponse, GetDefaultLevyYears404, GetDefaultLevyYears422 } from '../types/GetDefaultLevyYears.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getDefaultLevyYears } from '../clients/getDefaultLevyYears.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getDefaultLevyYearsQueryKey = () => [{ url: '/api-internal/levy_structures/available_default_levy_years' }] as const

export type GetDefaultLevyYearsQueryKey = ReturnType<typeof getDefaultLevyYearsQueryKey>

export function getDefaultLevyYearsQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getDefaultLevyYearsQueryKey()
  return queryOptions<
    GetDefaultLevyYearsQueryResponse,
    ResponseErrorConfig<GetDefaultLevyYears404 | GetDefaultLevyYears422>,
    GetDefaultLevyYearsQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getDefaultLevyYears(config)
    },
  })
}

/**
 * @summary Get Default Levy Years
 * {@link /api-internal/levy_structures/available_default_levy_years}
 */
export function useGetDefaultLevyYears<
  TData = GetDefaultLevyYearsQueryResponse,
  TQueryData = GetDefaultLevyYearsQueryResponse,
  TQueryKey extends QueryKey = GetDefaultLevyYearsQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<GetDefaultLevyYearsQueryResponse, ResponseErrorConfig<GetDefaultLevyYears404 | GetDefaultLevyYears422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getDefaultLevyYearsQueryKey()

  const query = useQuery({
    ...(getDefaultLevyYearsQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetDefaultLevyYears404 | GetDefaultLevyYears422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}