import {
  ContractProposalOut,
  ContractProposalState,
  contractProposalState,
  estimatedStartDateType,
  ListContractProposalsQueryParams,
  type MeasuringPointPanelNestedSchema,
  useDeleteContractProposal,
  useGetContractProposalCountsByState,
  useListContractProposalsInfinite,
} from '@mg/api-wrappers/src/api-internal';
import { MGActionMenu, MGActionMenuItem } from '@mg/ui/src/components/MGActionMenu';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { MGModalHandle } from '@mg/ui/src/components/MGModal/index.ts';
import { presentDate } from '@mg/ui/src/presenters';
import { palette } from '@mg/ui/src/styles';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Calendar, Pencil, Trash2 } from 'lucide-react';
import { createParser, useQueryState } from 'nuqs';
import { useRef, useState } from 'react';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries.ts';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter.ts';
import { useTranslation } from '../../../i18n.tsx';
import { fireSweetalert } from '../../modals/SweetalertModal.tsx';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard.tsx';
import { MetergridTabs, MGTabProps } from '../../shared/MetergridTabs.tsx';
import { ContractsConfirmAndSignModal } from './Contracts.ConfirmAndSignModal.tsx';
import { ContractsCreateCustomerModal } from './Contracts.CreateCustomerModal.tsx';
import { ContractsDetailsModal } from './Contracts.DetailsModal.tsx';

const tabParser = createParser({
  parse(queryValue: string): ContractProposalState {
    return contractProposalState[queryValue] ?? contractProposalState.signed_by_provider;
  },
  serialize(value) {
    return value;
  },
});

export const ContractsData = () => {
  const { t } = useTranslation('contracts');
  const projectFilter = useSelectedProjectsFilter();
  const counts = useGetContractProposalCountsByState(projectFilter);

  const deleteContractProposalMutation = useDeleteContractProposal();

  const tabs: MGTabProps[] = [
    { label: 'create', value: contractProposalState.signed_by_provider },
    { label: 'check_and_confirm', value: contractProposalState.signed_by_tenant },
    { label: 'customer_enter_data', value: contractProposalState.open },
    { label: 'rejected', value: contractProposalState.rejected },
    { label: 'archive', value: contractProposalState.archived },
  ];
  const [tab] = useQueryState('tab', {
    ...tabParser,
    defaultValue: contractProposalState.signed_by_provider,
    clearOnDefault: true,
  });

  /* Map translations to tabs to avoid duplication of translations*/
  const formattedTabs = tabs.map((tab) => ({
    ...tab,
    label: t(tab.label as string),
    count: counts.isSuccess ? (counts.data[tab.value] ?? 0) : undefined,
  }));

  const columns: GridColDef<ContractProposalOut>[] = [
    {
      flex: 0.5,
      field: 'measuring_point_panel',
      valueGetter: (value: MeasuringPointPanelNestedSchema | null) => {
        return value?.name ?? null;
      },
      renderCell: (params) => (
        <Box paddingX={1}>
          <Typography variant={'small'} color={'text.secondary'}>
            {params.value ?? '-'}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'name',
      flex: 1,
      renderCell: (params) => (
        <MGCustomerWithAvatar
          customer={{
            first_name: params.row.address_first_name,
            last_name: params.row.address_last_name,
            company: params.row.address_company,
          }}
        />
      ),
    },
    {
      field: 'plan',
      flex: 1,
      renderCell: (params) => (
        <Chip
          sx={{ padding: 1 }}
          variant={'soft'}
          size={'small'}
          color={'purple'}
          label={params.row.plan?.name}
        />
      ),
    },
    {
      field: 'specific_estimated_start_date',
      flex: 1,
      valueGetter: (value) => (value ? presentDate(value) : '-'),
      renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'} height={'100%'} spacing={1}>
          <Calendar size={15} />
          <Typography variant={'small'} color={'text.secondary'}>
            {params.row.estimated_start_date_type === estimatedStartDateType.EARLIEST_POSSIBLE
              ? t('earliest_possible')
              : params.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      align: 'right',
      width:
        tab === contractProposalState.signed_by_tenant || contractProposalState.signed_by_provider
          ? 176
          : undefined,
      renderCell: (params) =>
        params.row.state === contractProposalState.open ? (
          <Box paddingX={1}>
            <MGActionMenu>
              <MGActionMenuItem
                text={t('edit')}
                icon={<Pencil />}
                onClick={() => window.open(params.row.edit_link)}
              />
              <MGActionMenuItem
                text={t('contract_proposal_delete')}
                icon={<Trash2 />}
                onClick={() => {
                  fireSweetalert({
                    title: t('alter_title_contract_proposal_delete'),
                    confirmButtonColor: 'error',
                    icon: 'warning',
                    iconColor: palette.error.main,
                    text: t('alter_text_contract_proposal_delete'),
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      deleteContractProposalMutation.mutate(
                        { id: params!.row!.id! },
                        {
                          onSuccess: () => {
                            invalidateAllQueries();
                          },
                        },
                      );
                    }
                  });
                }}
              />
            </MGActionMenu>
          </Box>
        ) : params.row.state === contractProposalState.signed_by_tenant ? (
          <Box>
            <ContractsConfirmAndSignModal contractProposal={params.row} />
          </Box>
        ) : params.row.state === contractProposalState.signed_by_provider ? (
          <Box>
            <ContractsCreateCustomerModal contractProposal={params.row} />
          </Box>
        ) : null,
    },
  ];

  const [clickedContractProposal, setClickedContractProposal] = useState(null);
  const modalHandle = useRef<MGModalHandle>(null);

  return (
    <>
      <Stack spacing={3}>
        <MetergridTabs tabs={formattedTabs} onChange={console.log} />
        <Stack spacing={1}>
          <Typography variant={'h4'}>{t(`${tabs.find((t) => t.value === tab)?.label}`)}</Typography>
          <Typography variant={'small'} color={'text.primary'}>
            {t(`${tabs.find((t) => t.value === tab)?.label}_subtitle`)}
          </Typography>
          <DataGridCard
            height={'calc(100vh - 520px)'}
            reactQueryHook={useListContractProposalsInfinite}
            reactQueryHookParams={
              {
                ...projectFilter,
                state: tab,
              } satisfies ListContractProposalsQueryParams
            }
            columns={columns}
            datagridProps={{
              columnHeaderHeight: 0,
              rowHeight: 80,
              sx: {
                '.MuiDataGrid-columnHeaders': { display: 'none' },
                '.MuiDataGrid-row': {
                  backgroundColor: 'white !important',
                  '&:hover': { boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)' },
                },
              },
              onRowClick: (params) => {
                modalHandle.current?.open();
                setClickedContractProposal(params.row);
              },
            }}
            CardProps={{
              sx: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: 'none !important',
                padding: 0,
              },
            }}
          />
        </Stack>
      </Stack>
      {clickedContractProposal && (
        <ContractsDetailsModal modalRef={modalHandle} contractProposal={clickedContractProposal} />
      )}
    </>
  );
};
