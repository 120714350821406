import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const exportTransactions200Schema = z.unknown()

/**
 * @description Bad Request
 */
export const exportTransactions400Schema = z.string()

/**
 * @description Not Found
 */
export const exportTransactions404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const exportTransactions422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const exportTransactionsQueryResponseSchema = z.lazy(() => exportTransactions200Schema)