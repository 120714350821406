// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetProjectToOnboardingV1CompleteMutationResponse,
  SetProjectToOnboardingV1CompletePathParams,
  SetProjectToOnboardingV1CompleteQueryParams,
  SetProjectToOnboardingV1Complete400,
  SetProjectToOnboardingV1Complete404,
  SetProjectToOnboardingV1Complete422,
} from '../types/SetProjectToOnboardingV1Complete.ts'

export function getSetProjectToOnboardingV1CompleteUrl(id: SetProjectToOnboardingV1CompletePathParams['id']) {
  return `/api-internal/projects/${id}/complete_onboarding_v1` as const
}

/**
 * @summary Set Project To Onboarding V1 Complete
 * {@link /api-internal/projects/:id/complete_onboarding_v1}
 */
export async function setProjectToOnboardingV1Complete(
  id: SetProjectToOnboardingV1CompletePathParams['id'],
  params?: SetProjectToOnboardingV1CompleteQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    SetProjectToOnboardingV1CompleteMutationResponse,
    ResponseErrorConfig<SetProjectToOnboardingV1Complete400 | SetProjectToOnboardingV1Complete404 | SetProjectToOnboardingV1Complete422>,
    unknown
  >({ method: 'POST', url: getSetProjectToOnboardingV1CompleteUrl(id).toString(), params, ...requestConfig })
  return res.data
}