// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListSubscriptionPlansQueryResponse,
  ListSubscriptionPlansQueryParams,
  ListSubscriptionPlans404,
  ListSubscriptionPlans422,
} from '../types/ListSubscriptionPlans.ts'

export function getListSubscriptionPlansUrl() {
  return `/api-internal/subscription_plans/` as const
}

/**
 * @summary List Subscription Plans
 * {@link /api-internal/subscription_plans/}
 */
export async function listSubscriptionPlans(params: ListSubscriptionPlansQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListSubscriptionPlansQueryResponse, ResponseErrorConfig<ListSubscriptionPlans404 | ListSubscriptionPlans422>, unknown>({
    method: 'GET',
    url: getListSubscriptionPlansUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}