import { MGModal, MGModalSubtitle, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { MGModalProps } from '@mg/ui/src/components/MGModal/MGModal';
import { JSX, ReactNode } from 'react';

type Props = MGModalProps & {
  title: ReactNode;
  subtitle?: ReactNode;
  open: boolean;
};

export const FormModal = ({ title, subtitle, children, ...props }: Props): JSX.Element => {
  return (
    <MGModal
      {...props}
      ModalProps={{ disableRestoreFocus: true }}
      showCloseButton
      PaperStyle={{ overflow: 'auto' }}
    >
      <MGModalTitle>{title}</MGModalTitle>
      {subtitle && <MGModalSubtitle>{subtitle}</MGModalSubtitle>}
      {children}
    </MGModal>
  );
};
