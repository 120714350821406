import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CountPlanChangeDocumentsQueryResponse,
  CountPlanChangeDocumentsQueryParams,
  CountPlanChangeDocuments404,
  CountPlanChangeDocuments422,
} from '../types/CountPlanChangeDocuments.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { countPlanChangeDocuments } from '../clients/countPlanChangeDocuments.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countPlanChangeDocumentsQueryKey = (params: CountPlanChangeDocumentsQueryParams) =>
  [{ url: '/api-internal/plan_change_documents/count' }, ...(params ? [params] : [])] as const

export type CountPlanChangeDocumentsQueryKey = ReturnType<typeof countPlanChangeDocumentsQueryKey>

export function countPlanChangeDocumentsQueryOptions(
  params: CountPlanChangeDocumentsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = countPlanChangeDocumentsQueryKey(params)
  return queryOptions<
    CountPlanChangeDocumentsQueryResponse,
    ResponseErrorConfig<CountPlanChangeDocuments404 | CountPlanChangeDocuments422>,
    CountPlanChangeDocumentsQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countPlanChangeDocuments(params, config)
    },
  })
}

/**
 * @summary Count Plan Change Documents
 * {@link /api-internal/plan_change_documents/count}
 */
export function useCountPlanChangeDocuments<
  TData = CountPlanChangeDocumentsQueryResponse,
  TQueryData = CountPlanChangeDocumentsQueryResponse,
  TQueryKey extends QueryKey = CountPlanChangeDocumentsQueryKey,
>(
  params: CountPlanChangeDocumentsQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        CountPlanChangeDocumentsQueryResponse,
        ResponseErrorConfig<CountPlanChangeDocuments404 | CountPlanChangeDocuments422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countPlanChangeDocumentsQueryKey(params)

  const query = useQuery({
    ...(countPlanChangeDocumentsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<CountPlanChangeDocuments404 | CountPlanChangeDocuments422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}