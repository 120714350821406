import { multiplePublicErrorsOutUnionHASPROCESSEDTRANSACTIONSNOTLATESTPAYMENTPLANSchema } from './multiplePublicErrorsOutUnionHASPROCESSEDTRANSACTIONSNOTLATESTPAYMENTPLANSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const deletePaymentPlanPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description No Content
 */
export const deletePaymentPlan204Schema = z.unknown()

/**
 * @description Not Found
 */
export const deletePaymentPlan404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const deletePaymentPlan422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.lazy(() => multiplePublicErrorsOutUnionHASPROCESSEDTRANSACTIONSNOTLATESTPAYMENTPLANSchema),
])

export const deletePaymentPlanMutationResponseSchema = z.lazy(() => deletePaymentPlan204Schema)