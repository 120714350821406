// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CreateMeasuringPointPanelMutationRequest,
  CreateMeasuringPointPanelMutationResponse,
  CreateMeasuringPointPanel404,
  CreateMeasuringPointPanel422,
} from '../types/CreateMeasuringPointPanel.ts'

export function getCreateMeasuringPointPanelUrl() {
  return `/api-internal/measuring_point_panels` as const
}

/**
 * @summary Create Measuring Point Panel
 * {@link /api-internal/measuring_point_panels}
 */
export async function createMeasuringPointPanel(
  data: CreateMeasuringPointPanelMutationRequest,
  config: Partial<RequestConfig<CreateMeasuringPointPanelMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateMeasuringPointPanelMutationResponse,
    ResponseErrorConfig<CreateMeasuringPointPanel404 | CreateMeasuringPointPanel422>,
    CreateMeasuringPointPanelMutationRequest
  >({ method: 'POST', url: getCreateMeasuringPointPanelUrl().toString(), data, ...requestConfig })
  return res.data
}