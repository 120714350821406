// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListMessagesQueryResponse, ListMessages404, ListMessages422 } from '../types/ListMessages.ts'

export function getListMessagesUrl() {
  return `/api-internal/messages/` as const
}

/**
 * @summary List Messages
 * {@link /api-internal/messages/}
 */
export async function listMessages(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListMessagesQueryResponse, ResponseErrorConfig<ListMessages404 | ListMessages422>, unknown>({
    method: 'GET',
    url: getListMessagesUrl().toString(),
    ...requestConfig,
  })
  return res.data
}