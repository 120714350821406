import { measuringPointPanelCreateSchemaSchema } from './measuringPointPanelCreateSchemaSchema.ts'
import { measuringPointPanelNestedSchemaSchema } from './measuringPointPanelNestedSchemaSchema.ts'
import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const createMeasuringPointPanel200Schema = z.lazy(() => measuringPointPanelNestedSchemaSchema)

/**
 * @description Not Found
 */
export const createMeasuringPointPanel404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const createMeasuringPointPanel422Schema = z.union([z.lazy(() => paramValidationErrorOutSchema), z.lazy(() => modelValidationErrorOutSchema)])

export const createMeasuringPointPanelMutationRequestSchema = z.lazy(() => measuringPointPanelCreateSchemaSchema)

export const createMeasuringPointPanelMutationResponseSchema = z.lazy(() => createMeasuringPointPanel200Schema)