import { palette } from '@mg/ui/src/styles';
import { Box, Card, Grid2, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { ReactNode } from 'react';

type Props = {
  rows: Array<{ label: string | ReactNode; value: string | ReactNode }>;
  title: ReactNode;
  alertTitle: ReactNode;
  alertButtonClick: () => void;
  icon: ReactNode;
  actionMenu: ReactNode;
  isLoading: boolean;
};
export const CustomerDetailsContractsCard = ({
  title,
  icon,
  actionMenu,
  rows,
  alertTitle,
  alertButtonClick,
  isLoading,
}: Props) => {
  return (
    <Card sx={{ padding: '40px', height: '100%' }}>
      <Stack height={'100%'}>
        <Stack spacing={0.1} alignItems={'space-between'} height={'100%'}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            {icon}
            {actionMenu}
          </Stack>
          {isLoading ? <Skeleton width={'200px'} /> : title}
          <Grid2 container rowSpacing={4}>
            {rows.map((row) => (
              <Grid2 size={6}>
                <Stack spacing={0.5}>
                  {isLoading ? (
                    <Skeleton width={'80px'} />
                  ) : (
                    <Typography variant={'small'} color={'text.secondary'} fontWeight={'medium'}>
                      {row.value}
                    </Typography>
                  )}
                  <Typography variant={'small'} fontWeight={'light'}>
                    {row.label}
                  </Typography>
                </Stack>
              </Grid2>
            ))}
          </Grid2>
        </Stack>
        {!isLoading && (
          <Box
            sx={{
              border: `1px solid ${palette.primary.main}`,
              backgroundColor: palette.primary.light,
              borderRadius: '10px',
              paddingY: '15px',
              paddingX: '25px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: palette.text.secondary,
            }}
          >
            <Typography color={'text.secondary'}>{alertTitle}</Typography>
            <IconButton
              onClick={alertButtonClick}
              sx={{
                color: palette.primary.contrastText,
                backgroundColor: palette.primary.main,
                ':hover': { backgroundColor: palette.primary.dark },
              }}
            >
              <ArrowRight size={18} />
            </IconButton>
          </Box>
        )}
      </Stack>
    </Card>
  );
};
