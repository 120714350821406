import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { pagedInvoiceSchemaSchema } from './pagedInvoiceSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listInvoicesQueryParamsSchema = z.object({
  include_customer: z.boolean().default(false),
  include_pdf: z.boolean().default(false),
  document_type: z.literal('invoice').default('invoice'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  project_id: z.number().int(),
  category: z.union([z.enum(['regular invoice', 'cancellation invoice']), z.null()]).optional(),
  state: z.union([z.enum(['issued', 'canceled']), z.null()]).optional(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  issue_date_from: z.union([z.string().datetime(), z.null()]).optional(),
  issue_date_to: z.union([z.string().datetime(), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  page: z.union([z.number().int(), z.null()]).default(1),
  page_size: z.union([z.number().int(), z.null()]).default(25),
  order_by: z.union([z.string(), z.null()]).optional(),
  skip_pagination: z.union([z.boolean(), z.null()]).default(false),
  single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const listInvoices200Schema = z.lazy(() => pagedInvoiceSchemaSchema)

/**
 * @description Not Found
 */
export const listInvoices404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const listInvoices422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listInvoicesQueryResponseSchema = z.lazy(() => listInvoices200Schema)