import { customerSchemaSchema } from './customerSchemaSchema.ts'
import { pdfNestedSchemaSchema } from './pdfNestedSchemaSchema.ts'
import { SEPAMandateStateSchema } from './SEPAMandateStateSchema.ts'
import { trackedTaskNestedSchemaSchema } from './trackedTaskNestedSchemaSchema.ts'
import { z } from 'zod'

export const sepaMandateSchemaSchema = z.object({
  accepted_date: z.union([z.string().date(), z.null()]).optional(),
  cancelled_date: z.union([z.string().date(), z.null()]).optional(),
  customer: z.union([z.lazy(() => customerSchemaSchema), z.null()]).optional(),
  deliver_to_customer_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  delivered_to_customer: z.boolean(),
  delivery_in_progress: z.boolean(),
  generate_pdf_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  id: z.number().int(),
  pdf: z.union([z.lazy(() => pdfNestedSchemaSchema), z.null()]).optional(),
  project: z.union([z.number().int(), z.null()]).optional(),
  reference: z
    .union([z.string(), z.null()])
    .describe('Reference for the SEPA mandate, if none is set gets a default reference. \n Default: {project-id}-{customer-id}-{increasing number}')
    .optional(),
  state: z.union([z.lazy(() => SEPAMandateStateSchema), z.null()]).optional(),
  type: z.string().max(32).default('SSD_CORE').describe('Represents the type of the SEPA mandate'),
  use: z.string().max(32).default('MULTI_USE').describe('Represents permissions on how to use the mandate.'),
})