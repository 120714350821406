import { customerSchemaSchema } from './customerSchemaSchema.ts'
import { pdfNestedSchemaSchema } from './pdfNestedSchemaSchema.ts'
import { trackedTaskNestedSchemaSchema } from './trackedTaskNestedSchemaSchema.ts'
import { z } from 'zod'

export const paymentPlanSchemaSchema = z.object({
  amount: z.union([z.string(), z.number()]),
  created_at: z.union([z.string().datetime(), z.null()]).optional(),
  customer: z.union([z.lazy(() => customerSchemaSchema), z.null()]).optional(),
  deliver_to_customer_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  delivered_to_customer: z.boolean(),
  delivery_in_progress: z.boolean(),
  generate_pdf_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  id: z.number().int(),
  interval: z.string().max(12).default('month').describe('The frequency with which the payment plan is executed.'),
  is_active: z.boolean(),
  is_deletable: z.boolean().default(false),
  pdf: z.union([z.lazy(() => pdfNestedSchemaSchema), z.null()]).optional(),
  project: z.union([z.number().int(), z.null()]).optional(),
  start_date: z.string().date().describe('The date of the first advance payment and start of the payment plan.').optional(),
  subscription: z.union([z.number().int(), z.null()]).describe('The subscription this payment plan is paying into.').optional(),
})