import { Add } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { cloneElement, ReactElement, useState } from 'react';
import { invalidateAllQueries } from '../../helpers/invalidateAllQueries';
import { useTranslation } from '../../i18n';
import { DjangoFormModal } from './DjangoFormModal';

type CreateMeasuringPointModalProps = {
  panelId: string | number;
  toggleButtonComponent?: ReactElement<ButtonProps>;
};

export const CreateMeasuringPointModal = ({
  panelId,
  toggleButtonComponent,
}: CreateMeasuringPointModalProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('meteringDetails');

  return (
    <>
      {toggleButtonComponent ? (
        cloneElement(toggleButtonComponent, {
          onClick: (event) => {
            event?.preventDefault();
            setOpen(true);
            event.stopPropagation();
          },
        })
      ) : (
        <Button
          size={'large'}
          variant={'outlined'}
          onClick={() => setOpen(true)}
          sx={{ marginLeft: '20px' }}
          endIcon={<Add sx={{ marginLeft: '10px' }} />}
        >
          {t('switch_meter')}
        </Button>
      )}

      <DjangoFormModal
        pathToDjangoForm={`/metering/panels/${panelId}/measuring_point_switch`}
        title={t('meter_switch')}
        open={open}
        handleToggle={() => {
          setOpen(!open);
        }}
        onSuccess={() => {
          invalidateAllQueries();
          setOpen(false);
        }}
      />
    </>
  );
};
