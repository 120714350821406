import { MeasuringPointSubscriptionSchema } from '@mg/api-wrappers/src/api-internal';
import { Box } from '@mui/material';
import { MeteringDetailsOverview } from '../../../MeteringDetails/MeteringDetails.Overview';

type Props = {
  panelId: number;
  subscription?: MeasuringPointSubscriptionSchema | null;
};
export const CustomerDetailsMetering = ({ panelId, subscription }: Props) => {
  /*
   * Placeholder Component to have consistent architecture and the possibility to add funcitonality to the Details Tab
   * */
  return (
    <Box paddingBottom={'20px'}>
      <MeteringDetailsOverview panelId={panelId} subscription={subscription} />
    </Box>
  );
};
