import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListCustomersQueryResponse, ListCustomersQueryParams, ListCustomers404, ListCustomers422 } from '../types/ListCustomers.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listCustomers } from '../clients/listCustomers.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listCustomersQueryKey = (params?: ListCustomersQueryParams) => [{ url: '/api-internal/customers/' }, ...(params ? [params] : [])] as const

export type ListCustomersQueryKey = ReturnType<typeof listCustomersQueryKey>

export function listCustomersQueryOptions(params?: ListCustomersQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listCustomersQueryKey(params)
  return queryOptions<ListCustomersQueryResponse, ResponseErrorConfig<ListCustomers404 | ListCustomers422>, ListCustomersQueryResponse, typeof queryKey>({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listCustomers(params, config)
    },
  })
}

/**
 * @summary List Customers
 * {@link /api-internal/customers/}
 */
export function useListCustomers<
  TData = ListCustomersQueryResponse,
  TQueryData = ListCustomersQueryResponse,
  TQueryKey extends QueryKey = ListCustomersQueryKey,
>(
  params?: ListCustomersQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<ListCustomersQueryResponse, ResponseErrorConfig<ListCustomers404 | ListCustomers422>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listCustomersQueryKey(params)

  const query = useQuery({
    ...(listCustomersQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListCustomers404 | ListCustomers422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}