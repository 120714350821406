import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SettleTransactionsMutationRequest,
  SettleTransactionsMutationResponse,
  SettleTransactions404,
  SettleTransactions422,
} from '../types/SettleTransactions.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { settleTransactions } from '../clients/settleTransactions.ts'
import { useMutation } from '@tanstack/react-query'

export const settleTransactionsMutationKey = () => [{ url: '/api-internal/transactions/settle' }] as const

export type SettleTransactionsMutationKey = ReturnType<typeof settleTransactionsMutationKey>

/**
 * @summary Settle Transactions
 * {@link /api-internal/transactions/settle}
 */
export function useSettleTransactions(
  options: {
    mutation?: UseMutationOptions<
      SettleTransactionsMutationResponse,
      ResponseErrorConfig<SettleTransactions404 | SettleTransactions422>,
      { data?: SettleTransactionsMutationRequest }
    >
    client?: Partial<RequestConfig<SettleTransactionsMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? settleTransactionsMutationKey()

  return useMutation<
    SettleTransactionsMutationResponse,
    ResponseErrorConfig<SettleTransactions404 | SettleTransactions422>,
    { data?: SettleTransactionsMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return settleTransactions(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}