import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { pagedCustomerSchemaSchema } from './pagedCustomerSchemaSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const listCustomersQueryParamsSchema = z
  .object({
    include_measuring_point_panel: z.boolean().default(false),
    include_total_open_amount: z.boolean().default(false),
    include_total_settled_amount: z.boolean().default(false),
    include_last_billing_date: z.boolean().default(false),
    include_bank_account: z.boolean().default(false),
    include_address: z.boolean().default(false),
    include_postal_address: z.boolean().default(false),
    include_subscription: z.boolean().default(false),
    include_current_plan: z.boolean().default(false),
    include_plans: z.boolean().default(false),
    include_active_sepa_mandate_id: z.boolean().default(false),
    project_id: z.union([z.number().int(), z.null()]).optional(),
    is_active: z.union([z.boolean(), z.null()]).optional(),
    search: z.union([z.string(), z.null()]).optional(),
    page: z.union([z.number().int(), z.null()]).default(1),
    page_size: z.union([z.number().int(), z.null()]).default(25),
    order_by: z.union([z.string(), z.null()]).optional(),
    skip_pagination: z.union([z.boolean(), z.null()]).default(false),
    single_page_up_to: z.union([z.number().int(), z.null()]).optional(),
  })
  .optional()

/**
 * @description OK
 */
export const listCustomers200Schema = z.lazy(() => pagedCustomerSchemaSchema)

/**
 * @description Not Found
 */
export const listCustomers404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const listCustomers422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const listCustomersQueryResponseSchema = z.lazy(() => listCustomers200Schema)