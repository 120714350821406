// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListProjectsQueryResponse, ListProjectsQueryParams, ListProjects404, ListProjects422 } from '../types/ListProjects.ts'

export function getListProjectsUrl() {
  return `/api-internal/projects/` as const
}

/**
 * @summary List Projects
 * {@link /api-internal/projects/}
 */
export async function listProjects(params?: ListProjectsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListProjectsQueryResponse, ResponseErrorConfig<ListProjects404 | ListProjects422>, unknown>({
    method: 'GET',
    url: getListProjectsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}