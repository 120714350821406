// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CreateLevyStructureMutationRequest,
  CreateLevyStructureMutationResponse,
  CreateLevyStructure404,
  CreateLevyStructure422,
} from '../types/CreateLevyStructure.ts'

export function getCreateLevyStructureUrl() {
  return `/api-internal/levy_structures` as const
}

/**
 * @summary Create Levy Structure
 * {@link /api-internal/levy_structures}
 */
export async function createLevyStructure(
  data: CreateLevyStructureMutationRequest,
  config: Partial<RequestConfig<CreateLevyStructureMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateLevyStructureMutationResponse,
    ResponseErrorConfig<CreateLevyStructure404 | CreateLevyStructure422>,
    CreateLevyStructureMutationRequest
  >({ method: 'POST', url: getCreateLevyStructureUrl().toString(), data, ...requestConfig })
  return res.data
}