import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { EditOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';

type Props = {
  projectId: number;
};
export const ProjectsRowActionsMenu = ({ projectId }: Props) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { t } = useTranslation('projects');

  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('edit_project'),
      icon: <EditOutlined />,
      onClick: () => setEditModalOpen(!editModalOpen),
    },
  ];

  return (
    <>
      <MGActionMenu menuItems={menuItems} />
      <DjangoFormModal
        pathToDjangoForm={`/projects/${projectId}/edit/`}
        title={t('edit_project') as string}
        open={editModalOpen}
        handleToggle={() => {
          setEditModalOpen(!editModalOpen);
        }}
        onSuccess={() => {
          setEditModalOpen(false);
        }}
      />
    </>
  );
};
