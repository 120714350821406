import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
} from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { ValidationError } from '@tanstack/react-form';
import { CircleHelp } from 'lucide-react';
import { useState } from 'react';
import { palette } from '../../../styles';
import { MGFieldError } from './MGField.Error';

type OptionType = { value: string; label: React.ReactNode };

export type MGFieldRadioGroupProps = {
  name: string;
  value: string;
  label?: string | null;
  errors: ValidationError[];
  helpLink?: string;
  options: OptionType[];
  defaultOption?: OptionType;
  showTextOnly?: boolean;
  onChange: InputBaseProps['onChange'];
  onBlur: RadioGroupProps['onBlur'];
  RadioGroupProps?: RadioGroupProps;
};

export const MGFieldRadioGroup = ({
  name,
  value,
  label,
  errors = [],
  helpLink,
  options,
  defaultOption,
  showTextOnly,
  onChange,
  onBlur,
  RadioGroupProps,
}: MGFieldRadioGroupProps) => {
  const [selectedOptions, setSelectedOption] = useState(defaultOption?.value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Stack spacing={1} width={1}>
      <FormControl>
        <FormLabel>
          {label}
          {helpLink && (
            <a href={helpLink} tabIndex={-1} style={{ marginLeft: 5 }}>
              <CircleHelp />
            </a>
          )}
        </FormLabel>
        <RadioGroup
          name={name}
          value={value}
          defaultValue={defaultOption}
          onChange={handleChange}
          onBlur={onBlur}
          aria-required
          aria-labelledby={name}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            gap: 1,
          }}
          {...RadioGroupProps}
        >
          {options.map((option) => {
            return (
              <FormControlLabel
                sx={{
                  margin: '0px',
                  border: `1px solid ${option.value === selectedOptions ? palette.primary.main : palette.border.main}`,
                  backgroundColor: `${option.value === selectedOptions ? palette.primary.light : palette.white}`,
                  color: `${option.value === selectedOptions && palette.text.primary}`,
                  borderRadius: '10px',
                  height: '60px',
                  justifyContent: showTextOnly ? 'center' : 'flex-start',
                  flexGrow: 1,
                }}
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': showTextOnly ? { visibility: 'hidden', width: 0, height: 0 } : {},
                      '&.MuiRadio-root': showTextOnly ? { padding: 0 } : {},
                    }}
                  />
                }
                label={option.label}
                aria-label={String(option.label)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      <Box minHeight={'12px'}>
        <MGFieldError errors={errors} />
      </Box>
    </Stack>
  );
};
