import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getContractProposalCountsByStateQueryParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const getContractProposalCountsByState200Schema = z.object({}).catchall(z.number().int())

/**
 * @description Not Found
 */
export const getContractProposalCountsByState404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getContractProposalCountsByState422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getContractProposalCountsByStateQueryResponseSchema = z.lazy(() => getContractProposalCountsByState200Schema)