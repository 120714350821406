import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetSepaMandatePdfsAsZipQueryResponse,
  GetSepaMandatePdfsAsZipQueryParams,
  GetSepaMandatePdfsAsZip400,
  GetSepaMandatePdfsAsZip404,
  GetSepaMandatePdfsAsZip422,
} from '../types/GetSepaMandatePdfsAsZip.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getSepaMandatePdfsAsZip } from '../clients/getSepaMandatePdfsAsZip.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSepaMandatePdfsAsZipQueryKey = (params: GetSepaMandatePdfsAsZipQueryParams) =>
  [{ url: '/api-internal/sepa_mandates/pdfs' }, ...(params ? [params] : [])] as const

export type GetSepaMandatePdfsAsZipQueryKey = ReturnType<typeof getSepaMandatePdfsAsZipQueryKey>

export function getSepaMandatePdfsAsZipQueryOptions(
  params: GetSepaMandatePdfsAsZipQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getSepaMandatePdfsAsZipQueryKey(params)
  return queryOptions<
    GetSepaMandatePdfsAsZipQueryResponse,
    ResponseErrorConfig<GetSepaMandatePdfsAsZip400 | GetSepaMandatePdfsAsZip404 | GetSepaMandatePdfsAsZip422>,
    GetSepaMandatePdfsAsZipQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSepaMandatePdfsAsZip(params, config)
    },
  })
}

/**
 * @summary Get Sepa Mandate Pdfs As Zip
 * {@link /api-internal/sepa_mandates/pdfs}
 */
export function useGetSepaMandatePdfsAsZip<
  TData = GetSepaMandatePdfsAsZipQueryResponse,
  TQueryData = GetSepaMandatePdfsAsZipQueryResponse,
  TQueryKey extends QueryKey = GetSepaMandatePdfsAsZipQueryKey,
>(
  params: GetSepaMandatePdfsAsZipQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetSepaMandatePdfsAsZipQueryResponse,
        ResponseErrorConfig<GetSepaMandatePdfsAsZip400 | GetSepaMandatePdfsAsZip404 | GetSepaMandatePdfsAsZip422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSepaMandatePdfsAsZipQueryKey(params)

  const query = useQuery({
    ...(getSepaMandatePdfsAsZipQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetSepaMandatePdfsAsZip400 | GetSepaMandatePdfsAsZip404 | GetSepaMandatePdfsAsZip422>> & {
    queryKey: TQueryKey
  }

  query.queryKey = queryKey as TQueryKey

  return query
}