import {
  ContractProposalOut,
  getGetContractDocumentsZipUrl,
} from '@mg/api-wrappers/src/api-internal/index.ts';
import client from '@mg/api-wrappers/src/client.ts';
import { MGCard } from '@mg/ui/src/components/MGCard/MGCard.tsx';
import { palette } from '@mg/ui/src/styles/palette.ts';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Download, FileText } from 'lucide-react';
import { downloadFileContent } from '../../../helpers/downloadFile.ts';
import { useTranslation } from '../../../i18n.tsx';

interface ContractsDownloadDocumentsCardProps {
  contractProposal: ContractProposalOut;
}

export const ContractsDownloadDocumentsCard = ({ contractProposal }: ContractsDownloadDocumentsCardProps) => {
  const { t } = useTranslation('contracts');

  const downloadDocumentsZip = async (url: string) => {
    const response = await client({
      method: 'GET',
      url,
      params: {
        id: (contractProposal.id && +contractProposal.id) || 0,
        download: true,
      },
      responseType: 'blob',
    });

    // Get filename from content-disposition header
    const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
    downloadFileContent(response.data as BlobPart, fileName);
  };
  const downloadContractProposalPdfZip = useMutation({
    mutationFn: () => downloadDocumentsZip(getGetContractDocumentsZipUrl()),
  });

  return (
    <MGCard
      sx={{
        padding: '20px',
        marginTop: 4,
      }}
    >
      <Stack direction={'row'} gap={2.5}>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '40px',
            height: '40px',
            backgroundColor: palette.primary.light,
            color: palette.primary.dark,
            borderRadius: 1,
          }}
        >
          <FileText size={24} />
        </Stack>
        <Stack flex={1} justifyContent={'space-between'}>
          <Typography fontWeight={500} color={palette.common.black} lineHeight={1.2}>
            Vertragsunterlagen
          </Typography>
          <Typography variant={'extraSmall'}>
            {t('lbl_confirm_sign_modal_contract_document_download')}
          </Typography>
        </Stack>
        <Box>
          <Button
            onClick={() => {
              downloadContractProposalPdfZip.mutate();
            }}
            loading={downloadContractProposalPdfZip.isPending}
            variant={'contained'}
            color={'primary'}
            sx={{ padding: 0, width: '40px', height: '40px', minWidth: 0 }}
          >
            <Download size={24} />
          </Button>
        </Box>
      </Stack>
    </MGCard>
  );
};
