'use client';

import { Box, Card, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import { CSSProperties, ReactNode } from 'react';
import error from '../../assets/lottie/error.json';
import loader from '../../assets/lottie/loader.json';
import noDataLottie from '../../assets/lottie/no-data.json';
import '../../styles/theme'; // Needed for module augmentation

export interface MGCardProps {
  children: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  noData?: boolean;
  sx?: CSSProperties;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  stretch?: boolean;
}
export const MGCard = ({
  children,
  isLoading = false,
  isError = false,
  noData = false,
  stretch = false,
  sx,
}: MGCardProps) => {
  const renderCardContent = () => {
    if (isLoading) {
      return (
        <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Box sx={{ height: '50px', width: '50px' }}>
            <Lottie animationData={loader} loop={true} />
          </Box>
        </Box>
      );
    }
    if (isError) {
      return (
        <Box
          height={'100%'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Box sx={{ height: '50px', width: '50px' }}>
            <Lottie animationData={error} loop={false} />
          </Box>
          <Typography variant={'extraSmall'}>Hoppla! Da ist wohl etwas schiefgelaufen. </Typography>
        </Box>
      );
    }

    if (noData) {
      return (
        <Box
          height={'100%'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Box sx={{ height: '100px', width: '100px' }}>
            <Lottie animationData={noDataLottie} loop={false} />
          </Box>
          <Typography variant={'extraSmall'}>Keine Daten verfügbar</Typography>
        </Box>
      );
    }

    return (
      <motion.div
        style={{ height: '100%' }}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.2,
          delay: 0,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Stack spacing={1} sx={{ height: '100%' }}>
          <Stack
            spacing={1}
            height={stretch ? '100%' : undefined}
            justifyContent={stretch ? 'space-between' : undefined}
          >
            {children}
          </Stack>
        </Stack>
      </motion.div>
    );
  };

  return <Card sx={{ padding: '30px', height: '100%', ...sx }}>{renderCardContent()}</Card>;
};
