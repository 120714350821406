// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { CountLettersQueryResponse, CountLettersQueryParams, CountLetters404, CountLetters422 } from '../types/CountLetters.ts'

export function getCountLettersUrl() {
  return `/api-internal/letters/count` as const
}

/**
 * @summary Count Letters
 * {@link /api-internal/letters/count}
 */
export async function countLetters(params: CountLettersQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CountLettersQueryResponse, ResponseErrorConfig<CountLetters404 | CountLetters422>, unknown>({
    method: 'GET',
    url: getCountLettersUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}