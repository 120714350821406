import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { DeletePaymentPlanMutationResponse, DeletePaymentPlanPathParams, DeletePaymentPlan404, DeletePaymentPlan422 } from '../types/DeletePaymentPlan.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { deletePaymentPlan } from '../clients/deletePaymentPlan.ts'
import { useMutation } from '@tanstack/react-query'

export const deletePaymentPlanMutationKey = () => [{ url: '/api-internal/payment_plans/{id}' }] as const

export type DeletePaymentPlanMutationKey = ReturnType<typeof deletePaymentPlanMutationKey>

/**
 * @summary Delete Payment Plan
 * {@link /api-internal/payment_plans/:id}
 */
export function useDeletePaymentPlan(
  options: {
    mutation?: UseMutationOptions<
      DeletePaymentPlanMutationResponse,
      ResponseErrorConfig<DeletePaymentPlan404 | DeletePaymentPlan422>,
      { id: DeletePaymentPlanPathParams['id'] }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deletePaymentPlanMutationKey()

  return useMutation<
    DeletePaymentPlanMutationResponse,
    ResponseErrorConfig<DeletePaymentPlan404 | DeletePaymentPlan422>,
    { id: DeletePaymentPlanPathParams['id'] }
  >({
    mutationFn: async ({ id }) => {
      return deletePaymentPlan(id, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}