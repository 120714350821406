import { envVars } from '@mg/ui/src/envVars';
import {
  BackendFetch,
  CombinedOptions,
  DefaultParamType,
  DevTools,
  FormatSimple,
  Tolgee,
  useTranslate,
} from '@tolgee/react';
import { ReactElement } from 'react';

const enableDevTools = !!envVars.VITE_TOLGEE_API_URL && !!envVars.VITE_TOLGEE_API_TOKEN;

export const tolgee = Tolgee()
  .use(FormatSimple())
  .use(enableDevTools ? DevTools() : BackendFetch({ prefix: '/i18n' }))
  .init({
    /* These will only be set in development mode so the interactive translation editing is enabled */
    apiUrl: envVars.VITE_TOLGEE_API_URL || undefined,
    apiKey: envVars.VITE_TOLGEE_API_TOKEN || undefined,
    language: 'de-DE',
    defaultNs: 'shared',
    // Namespaces are prefetched so flickering is prevented
    ns: [
      // TODO: Merge all namespaces except "backend" into provider-portal and add the single namespace here.
      'actionBar',
      'backend',
      'billing',
      'contract',
      'createLetter',
      'customerDetails',
      'customers',
      'dashboard',
      'documents',
      'errors',
      'latestReadings',
      'levies',
      'login',
      'metergridPlus',
      'metering',
      'meteringDetails',
      'meteringHistory',
      'onboarding',
      'plans',
      'projects',
      'sepaGeneration',
      'shared',
      'sidebar',
      'supportPage',
      'tenant-acquisition',
      'transactions',
      'user-profile',
    ],
  });

// Use dynamic translations only in development and review mod
if (enableDevTools) {
  import('@tolgee/web/tools').then((module) => {
    tolgee.addPlugin(module.InContextTools());
  });
}

/**
 *  Transation function used outside react components
 *  Use useTranslation() inside react components!
 *  */
export const hooklessT = (namespace: string, key: string, values?: CombinedOptions<DefaultParamType>) => {
  if (values) {
    // Replace undefined values with empty string
    Object.entries(values).forEach(([key, value]) => {
      if (value === undefined) {
        values[key] = '';
      }
    });
  }

  return tolgee.t(key, key, { ns: namespace, ...values });
};

export const useTranslation = (namespace: string) => {
  const { t } = useTranslate(namespace);
  const tolgeeT = (key: string, values?: CombinedOptions<DefaultParamType>) => {
    if (values) {
      // Replace undefined values with empty string
      Object.entries(values).forEach(([key, value]) => {
        if (value === undefined) {
          values[key] = '';
        }
      });
    }
    const translated = t(key, values);
    if (translated === key) {
      //return '';
    }
    return translated;
  };

  return {
    tString: tolgeeT,
    t: (key: string, values?: CombinedOptions<DefaultParamType>): string | ReactElement => {
      const translation = tolgeeT(key, values);
      const translationWithLinebreaks = translation.replace(/\n/g, '<br />');
      const hasHtml = /<\/?[a-z][\s\S]*>/i.test(translationWithLinebreaks);

      if (!hasHtml) return translationWithLinebreaks;

      return <div dangerouslySetInnerHTML={{ __html: translationWithLinebreaks }} />;
    },
  };
};
