import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  DeleteContractProposalMutationResponse,
  DeleteContractProposalPathParams,
  DeleteContractProposal404,
  DeleteContractProposal422,
} from '../types/DeleteContractProposal.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { deleteContractProposal } from '../clients/deleteContractProposal.ts'
import { useMutation } from '@tanstack/react-query'

export const deleteContractProposalMutationKey = () => [{ url: '/api-internal/contract_proposals/{id}/' }] as const

export type DeleteContractProposalMutationKey = ReturnType<typeof deleteContractProposalMutationKey>

/**
 * @summary Delete Contract Proposal
 * {@link /api-internal/contract_proposals/:id/}
 */
export function useDeleteContractProposal(
  options: {
    mutation?: UseMutationOptions<
      DeleteContractProposalMutationResponse,
      ResponseErrorConfig<DeleteContractProposal404 | DeleteContractProposal422>,
      { id: DeleteContractProposalPathParams['id'] }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteContractProposalMutationKey()

  return useMutation<
    DeleteContractProposalMutationResponse,
    ResponseErrorConfig<DeleteContractProposal404 | DeleteContractProposal422>,
    { id: DeleteContractProposalPathParams['id'] }
  >({
    mutationFn: async ({ id }) => {
      return deleteContractProposal(id, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}