/* eslint-disable @typescript-eslint/no-explicit-any */

import { Box, CardProps, Stack, Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { DataGridPro, DataGridProProps, GridApiPro, GridColDef, GridSortItem } from '@mui/x-data-grid-pro';
import { QueryOptions } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai/index';
import { MutableRefObject, ReactNode, useEffect, useMemo, useState } from 'react';
import { EverythingSelectedAtom, filterValueAtom } from '../../../jotai/actionBar';
import { MetergridCard } from '../MetergridCard';
import { MetergridTabs, MGTabProps } from '../MetergridTabs';
import { DataGridCardActionBar } from './DataGridCard.ActionBar';
import { DataGridCardLoadingOverlay } from './DataGridCard.LoadingOverlay';
import { DataGridCardNoRowsOverlay } from './DataGridCard.NoRowsOverlay';
import { DataGridCardSearch } from './DataGridCard.Search';
import { apiAtom, DataGridApiProvider, useGridApi } from './useGridApi';

export type DataGridCardProps = {
  reactQueryHook: any;
  reactQueryHookParams: any;
  reactQueryHookQueryOptions?: QueryOptions;
  columns: GridColDef[];
  showSearch?: boolean;
  searchPlaceholder?: string;
  rowHeight?: number;
  datagridProps?: Omit<DataGridProProps, 'columns'>;
  tabs?: MGTabProps[];
  reactQueryFilter?: { [queryParam: string]: string | number };
  selectable?: boolean;
  onTabChange?: (tab: MGTabProps['value']) => void;
  showFilter?: boolean;
  isFiltered?: boolean;
  filterModalContent?: ReactNode;
  height?: string | number;
  defaultSort?: string | null;
  onSelectionChange?: (selectedItems: GridRowSelectionModel) => void;
  onSelectionChangeGetSelectedRowItems?: (dgcSelectionModel: {
    everythingSelected: boolean;
    selectedIds: GridRowSelectionModel;
    selectedData: unknown[];
  }) => void;
  CardProps?: CardProps;
};

export const DataGridCard = (props: DataGridCardProps) => {
  const api = useAtomValue(apiAtom);

  if (api) {
    return <DataGridCardInner {...props} />;
  } else {
    return (
      <DataGridApiProvider>
        <DataGridCardInner {...props} />
      </DataGridApiProvider>
    );
  }
};

const DataGridCardInner = ({
  reactQueryHook,
  columns,
  showSearch,
  searchPlaceholder,
  reactQueryHookParams,
  reactQueryHookQueryOptions,
  rowHeight = 52,
  datagridProps = {},
  tabs,
  reactQueryFilter,
  selectable,
  onTabChange = () => {},
  filterModalContent,
  height,
  defaultSort,
  onSelectionChange,
  onSelectionChangeGetSelectedRowItems,
  CardProps,
}: DataGridCardProps) => {
  const api = useAtomValue(apiAtom);
  if (!api) throw 'DataGridApiProvider not found';

  const [sortBy, setSortBy] = useState(defaultSort === null ? null : defaultSort || columns[0].field);
  const [sortModel, setSortModel] = useState<GridSortItem[]>(
    defaultSort === null
      ? []
      : [
          {
            field: defaultSort?.includes('-') ? defaultSort?.split('-')[1] : defaultSort || columns[0].field,
            sort: defaultSort?.includes('-') ? 'desc' : 'asc',
          },
        ],
  );
  const [selectedItems, setSelectedItems] = useState<GridRowSelectionModel>([]);
  const [everythingSelected, setEverythingSelected] = useAtom(EverythingSelectedAtom);
  const [filterValue] = useAtom(filterValueAtom);

  const { isLoading, data, fetchNextPage, isFetchingNextPage, queryKey } = reactQueryHook(
    {
      page_size: 25,
      order_by: sortBy,
      ...reactQueryFilter,
      ...reactQueryHookParams,
      search: filterValue.search,
    },
    {
      query: {
        getNextPageParam: (x) => {
          if (x?.current_page === x?.total_pages) {
            return undefined;
          }
          return x?.current_page + 1;
        },
        select: (data) => {
          return {
            pages: [...data.pages].map((page) => page.items),
            pageParams: data.pageParams,
            totalItems: data.pages[data.pages.length - 1].total_items || 0,
          };
        },
        ...reactQueryHookQueryOptions,
      },
    },
  );

  const handleSortChange = (model) => {
    setSortModel(model);

    if (!model[0]) {
      setSortBy(columns[0].field);
      return;
    }

    // @ts-expect-error - sortKey is not a default property of GridColDef
    const sortKeyOverride = columns.find((column) => column.field === model[0].field)?.sortKey;

    const djangoSortDirection = model[0].sort === 'asc' ? '' : '-';
    const djangoSortString = djangoSortDirection + (sortKeyOverride || model[0].field);

    setSortBy(djangoSortString);
  };

  const { dataGridApiRef } = useGridApi();

  /*
   * DataGrid Api ref functions
   * Can be accessed by parent if passing the ref object
   * */
  if (dataGridApiRef.current) {
    dataGridApiRef.current.apiLoaded = true;

    // @ts-expect-error TS2339
    dataGridApiRef.current.allItemsSelected = () => {
      return everythingSelected;
    };

    // @ts-expect-error TS2339
    dataGridApiRef.current.getReactQueryFilter = () => {
      return {
        ...reactQueryFilter,
        ...reactQueryHookParams,
        ...filterValue,
      };
    };

    // @ts-expect-error TS2339
    dataGridApiRef.current.getQueryKey = () => {
      return queryKey;
    };
  }

  const rows = useMemo(() => data?.pages?.flatMap((x) => x) || [], [data]);

  useEffect(() => {
    let currentSelectedIds = selectedItems;

    if (everythingSelected) {
      const unselectedRowIds = rows.filter((row) => !selectedItems.includes(row.id)).map((row) => row.id);

      if (unselectedRowIds.length > 0) {
        currentSelectedIds = [...selectedItems, ...unselectedRowIds];
        setSelectedItems(currentSelectedIds);
      }
    }

    if (onSelectionChangeGetSelectedRowItems) {
      const selectedIds = currentSelectedIds;
      const selectedData = rows.filter((row) => currentSelectedIds.includes(row.id));
      onSelectionChangeGetSelectedRowItems({ everythingSelected, selectedIds, selectedData });
    }
  }, [selectedItems, everythingSelected, rows, onSelectionChangeGetSelectedRowItems]);

  /*
   * Scroll to top when the first row changes when a filter or search is applied
   * */
  const firstRowId = rows[0]?.id;
  useEffect(() => {
    if (!dataGridApiRef.current?.apiLoaded) return;

    try {
      // Prevent error when there is no index to scroll to
      dataGridApiRef.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 });

      // Reset selected items when the first row changes
      setSelectedItems([]);
      setEverythingSelected(false);
    } catch (_error) {
      /* do nothing */
    }
  }, [dataGridApiRef.current, firstRowId, setEverythingSelected]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={3}>
        {tabs && (
          <MetergridTabs
            tabs={tabs}
            onChange={(tab) => {
              onTabChange(tab);
            }}
          />
        )}
        {showSearch && <DataGridCardSearch width={tabs ? '50%' : '100%'} placeholder={searchPlaceholder} />}
      </Stack>

      <MetergridCard
        {...CardProps}
        sx={{
          height: height || `calc(100vh - 430px)`,
          padding: '20px',
          paddingBottom: 0,
          ...CardProps?.sx,
        }}
      >
        <DataGridPro
          apiRef={dataGridApiRef as MutableRefObject<GridApiPro>}
          rowHeight={rowHeight}
          disableColumnMenu
          disableColumnResize
          isCellEditable={() => false}
          onSortModelChange={handleSortChange}
          sortModel={sortModel}
          isRowSelectable={() => !!selectable}
          onRowSelectionModelChange={(row) => {
            if (everythingSelected) {
              setEverythingSelected(false);
            }
            setSelectedItems(row);
            onSelectionChange && onSelectionChange(row);
          }}
          disableRowSelectionOnClick
          rowSelectionModel={selectedItems}
          checkboxSelection={!!selectable}
          onRowsScrollEnd={() => fetchNextPage()}
          sortingMode={'server'}
          hideFooter
          rows={rows}
          loading={isLoading || isFetchingNextPage}
          columns={columns.map((column) => {
            if (!column.renderCell) {
              column.renderCell = (params) => (
                <Box display={'flex'} alignItems={'center'} height={'100%'}>
                  <Typography variant={'small'}>{params.value}</Typography>
                </Box>
              );
            }
            return column;
          })}
          slots={{
            noRowsOverlay: () => <DataGridCardNoRowsOverlay />,
            loadingOverlay: () => (
              <DataGridCardLoadingOverlay showLinearProgress={isFetchingNextPage} showSpinner={isLoading} />
            ),
          }}
          getRowSpacing={() => ({ top: 5, bottom: 5 })}
          {...datagridProps}
          sx={{
            '.MuiDataGrid-row': { paddingY: '0px' },
            '.MuiDataGrid-filler': { height: '0px' },
            '.MuiDataGrid-main': { marginBottom: '20px' },
            '.MuiDataGrid-cell': { cursor: datagridProps.onRowClick ? 'pointer' : 'default' },
            ...datagridProps.sx,
          }}
        />
      </MetergridCard>

      <DataGridCardActionBar
        selectedItemCount={everythingSelected ? data?.totalItems : selectedItems.length}
        onSelectionCancel={() => {
          setSelectedItems([]);
          setEverythingSelected(false);
        }}
        onSelectAll={() => {
          setSelectedItems(rows.map((item) => item.id));
          setEverythingSelected(true);
        }}
        filteredItemCount={data?.totalItems}
        filterModalContent={filterModalContent}
      />
    </>
  );
};
