// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListMissingDataMutationRequest, ListMissingDataMutationResponse, ListMissingData404, ListMissingData422 } from '../types/ListMissingData.ts'

export function getListMissingDataUrl() {
  return `/api-internal/billing/missing_data` as const
}

/**
 * @summary List Missing Data
 * {@link /api-internal/billing/missing_data}
 */
export async function listMissingData(
  data?: ListMissingDataMutationRequest,
  config: Partial<RequestConfig<ListMissingDataMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListMissingDataMutationResponse, ResponseErrorConfig<ListMissingData404 | ListMissingData422>, ListMissingDataMutationRequest>({
    method: 'POST',
    url: getListMissingDataUrl().toString(),
    data,
    ...requestConfig,
  })
  return res.data
}