// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetTenantAcquisitionConfigurationLogoMutationRequest,
  SetTenantAcquisitionConfigurationLogoMutationResponse,
  SetTenantAcquisitionConfigurationLogoPathParams,
  SetTenantAcquisitionConfigurationLogo404,
  SetTenantAcquisitionConfigurationLogo422,
} from '../types/SetTenantAcquisitionConfigurationLogo.ts'

export function getSetTenantAcquisitionConfigurationLogoUrl(project_id: SetTenantAcquisitionConfigurationLogoPathParams['project_id']) {
  return `/api-internal/projects/${project_id}/tenant_acquisition_configuration/logo` as const
}

/**
 * @summary Set Tenant Acquisition Configuration Logo
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration/logo}
 */
export async function setTenantAcquisitionConfigurationLogo(
  project_id: SetTenantAcquisitionConfigurationLogoPathParams['project_id'],
  data: SetTenantAcquisitionConfigurationLogoMutationRequest,
  config: Partial<RequestConfig<SetTenantAcquisitionConfigurationLogoMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const formData = new FormData()
  if (data) {
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof typeof data]
      if (typeof key === 'string' && (typeof value === 'string' || value instanceof Blob)) {
        formData.append(key, value)
      }
    })
  }
  const res = await request<
    SetTenantAcquisitionConfigurationLogoMutationResponse,
    ResponseErrorConfig<SetTenantAcquisitionConfigurationLogo404 | SetTenantAcquisitionConfigurationLogo422>,
    SetTenantAcquisitionConfigurationLogoMutationRequest
  >({
    method: 'POST',
    url: getSetTenantAcquisitionConfigurationLogoUrl(project_id).toString(),
    data: formData,
    ...requestConfig,
    headers: { 'Content-Type': 'multipart/form-data', ...requestConfig.headers },
  })
  return res.data
}