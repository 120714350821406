import { MGModal, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { MGModalProps } from '@mg/ui/src/components/MGModal/MGModal.tsx';
import { useMultiStepPanelContext } from '../MultiStepPanel/MultiStepPanel.Context';

export const MultiStepModalBaseModal = ({ children, ...muiBaseModalProps }: MGModalProps) => {
  const { currentStep } = useMultiStepPanelContext();

  return (
    <MGModal
      height={'auto'}
      width={'910px'}
      position={{ anchorTop: 'center' }}
      PaperStyle={{ overflow: 'hidden', padding: '40px', paddingRight: '20px', paddingBottom: '20px' }}
      BodyStyle={{ overflow: 'hidden', paddingRight: '20px' }}
      showCloseButton
      {...muiBaseModalProps}
    >
      {currentStep.title && <MGModalTitle>{currentStep.title}</MGModalTitle>}
      {children}
    </MGModal>
  );
};
