import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getContractDocumentsZipQueryParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const getContractDocumentsZip200Schema = z.unknown()

/**
 * @description Not Found
 */
export const getContractDocumentsZip404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getContractDocumentsZip422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getContractDocumentsZipQueryResponseSchema = z.lazy(() => getContractDocumentsZip200Schema)