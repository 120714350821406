import { axiosInstance } from '@mg/ui/src/kubbClient.ts';
import { toast } from 'react-toastify';
import { hooklessT } from '../i18n.tsx';

export const downloadFile = (reactQueryObject, params = {}, clientOptions = {}) => {
  const url = reactQueryObject.queryKey[0].url;
  const queryParams = reactQueryObject.queryKey[0].params;
  const urlWithParams = url.replace(/:(\w+)/g, (_, key: string) => queryParams[key]);
  return toast.promise(
    async () => {
      const response = await axiosInstance.request({
        method: 'GET',
        url: urlWithParams,
        params,
        responseType: 'blob',
        ...clientOptions,
      });

      const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
      downloadFileContent(response.data, fileName);
    },
    {
      pending: hooklessT('shared', 'downloading_files'),
      error: hooklessT('shared', 'file_download_error_message'),
    },
  );
};

export const downloadFileContent = (content: BlobPart, fileName: string) => {
  const objectUrl = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // Clean up by removing the link and revoking the object URL
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(objectUrl);
};
