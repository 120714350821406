// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetCustomerDataExportQueryResponse,
  GetCustomerDataExport400,
  GetCustomerDataExport404,
  GetCustomerDataExport422,
} from '../types/GetCustomerDataExport.ts'

export function getGetCustomerDataExportUrl() {
  return `/api-internal/customers/export` as const
}

/**
 * @summary Get Customer Data Export
 * {@link /api-internal/customers/export}
 */
export async function getCustomerDataExport(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetCustomerDataExportQueryResponse,
    ResponseErrorConfig<GetCustomerDataExport400 | GetCustomerDataExport404 | GetCustomerDataExport422>,
    unknown
  >({ method: 'GET', url: getGetCustomerDataExportUrl().toString(), ...requestConfig })
  return res.data
}