import { multiplePublicErrorsOutUnionALREADYSIGNEDBYPROVIDERNOTSIGNEDBYTENANTCREDITORIDENTFIERNEEDEDFORSEPAMEASURINGPOINTMISSINGSchema } from './multiplePublicErrorsOutUnionALREADYSIGNEDBYPROVIDERNOTSIGNEDBYTENANTCREDITORIDENTFIERNEEDEDFORSEPAMEASURINGPOINTMISSINGSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const signContractProposalByProviderPathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description No Content
 */
export const signContractProposalByProvider204Schema = z.unknown()

/**
 * @description Not Found
 */
export const signContractProposalByProvider404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const signContractProposalByProvider422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.lazy(() => multiplePublicErrorsOutUnionALREADYSIGNEDBYPROVIDERNOTSIGNEDBYTENANTCREDITORIDENTFIERNEEDEDFORSEPAMEASURINGPOINTMISSINGSchema),
])

export const signContractProposalByProviderMutationResponseSchema = z.lazy(() => signContractProposalByProvider204Schema)