// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CreateMeasuringPointDataMutationRequest,
  CreateMeasuringPointDataMutationResponse,
  CreateMeasuringPointData404,
  CreateMeasuringPointData422,
} from '../types/CreateMeasuringPointData.ts'

export function getCreateMeasuringPointDataUrl() {
  return `/api-internal/measuring_point_data/` as const
}

/**
 * @summary Create Measuring Point Data
 * {@link /api-internal/measuring_point_data/}
 */
export async function createMeasuringPointData(
  data: CreateMeasuringPointDataMutationRequest,
  config: Partial<RequestConfig<CreateMeasuringPointDataMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CreateMeasuringPointDataMutationResponse,
    ResponseErrorConfig<CreateMeasuringPointData404 | CreateMeasuringPointData422>,
    CreateMeasuringPointDataMutationRequest
  >({ method: 'POST', url: getCreateMeasuringPointDataUrl().toString(), data, ...requestConfig })
  return res.data
}