import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { CountSepaMandatesQueryResponse, CountSepaMandatesQueryParams, CountSepaMandates404, CountSepaMandates422 } from '../types/CountSepaMandates.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { countSepaMandates } from '../clients/countSepaMandates.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const countSepaMandatesQueryKey = (params: CountSepaMandatesQueryParams) =>
  [{ url: '/api-internal/sepa_mandates/count' }, ...(params ? [params] : [])] as const

export type CountSepaMandatesQueryKey = ReturnType<typeof countSepaMandatesQueryKey>

export function countSepaMandatesQueryOptions(params: CountSepaMandatesQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = countSepaMandatesQueryKey(params)
  return queryOptions<
    CountSepaMandatesQueryResponse,
    ResponseErrorConfig<CountSepaMandates404 | CountSepaMandates422>,
    CountSepaMandatesQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return countSepaMandates(params, config)
    },
  })
}

/**
 * @summary Count Sepa Mandates
 * {@link /api-internal/sepa_mandates/count}
 */
export function useCountSepaMandates<
  TData = CountSepaMandatesQueryResponse,
  TQueryData = CountSepaMandatesQueryResponse,
  TQueryKey extends QueryKey = CountSepaMandatesQueryKey,
>(
  params: CountSepaMandatesQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<CountSepaMandatesQueryResponse, ResponseErrorConfig<CountSepaMandates404 | CountSepaMandates422>, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? countSepaMandatesQueryKey(params)

  const query = useQuery({
    ...(countSepaMandatesQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<CountSepaMandates404 | CountSepaMandates422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}