import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { projectNotCompleteErrorOutSchema } from './projectNotCompleteErrorOutSchema.ts'
import { projectSchemaSchema } from './projectSchemaSchema.ts'
import { z } from 'zod'

export const setProjectToOnboardingCompletePathParamsSchema = z.object({
  id: z.number().int(),
})

export const setProjectToOnboardingCompleteQueryParamsSchema = z
  .object({
    include_provider: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const setProjectToOnboardingComplete200Schema = z.lazy(() => projectSchemaSchema)

/**
 * @description Bad Request
 */
export const setProjectToOnboardingComplete400Schema = z.lazy(() => projectNotCompleteErrorOutSchema)

/**
 * @description Not Found
 */
export const setProjectToOnboardingComplete404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const setProjectToOnboardingComplete422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const setProjectToOnboardingCompleteMutationResponseSchema = z.lazy(() => setProjectToOnboardingComplete200Schema)