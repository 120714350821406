import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListResidualPowerPlansQueryResponse,
  ListResidualPowerPlansQueryParams,
  ListResidualPowerPlans404,
  ListResidualPowerPlans422,
} from '../types/ListResidualPowerPlans.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { listResidualPowerPlans } from '../clients/listResidualPowerPlans.ts'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listResidualPowerPlansInfiniteQueryKey = (params: ListResidualPowerPlansQueryParams) =>
  [{ url: '/api-internal/residual_power_plans/' }, ...(params ? [params] : [])] as const

export type ListResidualPowerPlansInfiniteQueryKey = ReturnType<typeof listResidualPowerPlansInfiniteQueryKey>

export function listResidualPowerPlansInfiniteQueryOptions(
  params: ListResidualPowerPlansQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = listResidualPowerPlansInfiniteQueryKey(params)
  return infiniteQueryOptions<
    ListResidualPowerPlansQueryResponse,
    ResponseErrorConfig<ListResidualPowerPlans404 | ListResidualPowerPlans422>,
    ListResidualPowerPlansQueryResponse,
    typeof queryKey,
    number
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListResidualPowerPlansQueryParams['page']
      }
      return listResidualPowerPlans(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Residual Power Plans
 * {@link /api-internal/residual_power_plans/}
 */
export function useListResidualPowerPlansInfinite<
  TData = InfiniteData<ListResidualPowerPlansQueryResponse>,
  TQueryData = ListResidualPowerPlansQueryResponse,
  TQueryKey extends QueryKey = ListResidualPowerPlansInfiniteQueryKey,
>(
  params: ListResidualPowerPlansQueryParams,
  options: {
    query?: Partial<
      InfiniteQueryObserverOptions<
        ListResidualPowerPlansQueryResponse,
        ResponseErrorConfig<ListResidualPowerPlans404 | ListResidualPowerPlans422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listResidualPowerPlansInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listResidualPowerPlansInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ResponseErrorConfig<ListResidualPowerPlans404 | ListResidualPowerPlans422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}