import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { currentProjectIdAtom } from '../jotai/atoms';

export const useSwitchProjectHook = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentProjectId, setCurrentProjectId] = useAtom(currentProjectIdAtom);

  return (projectId: number, options: { redirectTo?: string } = {}) => {
    if (currentProjectId != projectId) {
      setCurrentProjectId(projectId);
      queryClient.getQueryCache().clear();
      toast.success('Projekt erfolgreich gewechselt.');
    }

    const redirectTo = options.redirectTo ?? `/${location.pathname.split('/')[1]}`;
    navigate(redirectTo, { replace: true });
  };
};
