import { useDeletePaymentPlan, useGetPaymentPlanPdf } from '@mg/api-wrappers/src/api-internal';
import { MGActionMenu } from '@mg/ui/src/components/MGActionMenu';
import { palette } from '@mg/ui/src/styles';
import { Download, Plus, Trash2 } from 'lucide-react';
import { downloadFile } from '../../../../../helpers/downloadFile';
import { invalidateAllQueries } from '../../../../../helpers/invalidateAllQueries.ts';
import { useTranslation } from '../../../../../i18n';
import { fireSweetalert } from '../../../../modals/SweetalertModal.tsx';

export const CustomerDetailsContractsPaymentPlanActionMenu = ({ customerId, paymentPlan }) => {
  const getPaymantPlanPdfQuery = useGetPaymentPlanPdf(paymentPlan?.id, { query: { enabled: false } });
  const { t } = useTranslation('customerDetails');
  const deletePaymentPlanMutation = useDeletePaymentPlan();

  const menuItems = [
    {
      text: t('payment_plans_action_menu_change_plan'),
      icon: <Plus size={18} />,
      href: `/customers/${customerId}/paymentplan/edit/`,
    },
    {
      text: t('payment_plan_action_menu_delete_plan'),
      icon: <Trash2 size={18} color={palette.error.main} />,
      disabled: !paymentPlan?.is_deletable,
      disabledHint: t('payment_plan_action_menu_delete_plan_disabled_hint'),
      onClick: () => {
        fireSweetalert({
          title: t('payment_plan_delete_modal_title'),
          confirmButtonColor: 'error',
          icon: 'warning',
          iconColor: palette.error.main,
          text: t('payment_plan_delete_modal_text'),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            deletePaymentPlanMutation.mutate(
              { id: paymentPlan.id },
              {
                onSuccess: () => {
                  invalidateAllQueries();
                },
              },
            );
          }
        });
      },
    },
    {
      text: t('payment_plans_action_menu_download_plan'),
      icon: <Download size={18} />,
      onClick: () => downloadFile(getPaymantPlanPdfQuery),
    },
  ];

  return <MGActionMenu menuItems={menuItems} />;
};
