// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetPlanChangeDocumentPdfQueryResponse,
  GetPlanChangeDocumentPdfPathParams,
  GetPlanChangeDocumentPdf400,
  GetPlanChangeDocumentPdf404,
  GetPlanChangeDocumentPdf422,
} from '../types/GetPlanChangeDocumentPdf.ts'

export function getGetPlanChangeDocumentPdfUrl(id: GetPlanChangeDocumentPdfPathParams['id']) {
  return `/api-internal/plan_change_documents/${id}/pdf` as const
}

/**
 * @summary Get Plan Change Document Pdf
 * {@link /api-internal/plan_change_documents/:id/pdf}
 */
export async function getPlanChangeDocumentPdf(id: GetPlanChangeDocumentPdfPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetPlanChangeDocumentPdfQueryResponse,
    ResponseErrorConfig<GetPlanChangeDocumentPdf400 | GetPlanChangeDocumentPdf404 | GetPlanChangeDocumentPdf422>,
    unknown
  >({ method: 'GET', url: getGetPlanChangeDocumentPdfUrl(id).toString(), ...requestConfig })
  return res.data
}