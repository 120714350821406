import {
  MeasuringPointPanelSchema,
  useListMeasuringPointPanels,
  useUpdateMeasuringPointPanel,
} from '@mg/api-wrappers/src/api-internal';
import { MGLoader } from '@mg/ui/src/components/MGLoader';
import { MGModal, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { Box, Button, Card, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Check, CircleGauge } from 'lucide-react';
import { useEffect, useState } from 'react';
import { invalidateAllQueries } from '../../helpers/invalidateAllQueries';
import { useSelectedProjectsFilter } from '../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../i18n';

export const RenamePanelsModal = ({ buttonVariant, buttonText }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('onboarding');
  const projectFilter = useSelectedProjectsFilter();

  const panelQuery = useListMeasuringPointPanels({
    ...projectFilter,
    skip_pagination: true,
  });

  useEffect(() => {
    if (!open) {
      panelQuery.refetch();
      invalidateAllQueries();
    }
  }, [open]);

  return (
    <>
      <Button variant={buttonVariant} onClick={() => setOpen(true)}>
        {buttonText}
      </Button>
      <MGModal
        open={open}
        handleToggle={() => setOpen(!open)}
        height={'72vh'}
        width={'60vw'}
        position={{ anchorTop: 'center' }}
        ModalStyle={{ maxWidth: '1100px' }}
      >
        <MGModalTitle>{t('onboarding_tile_rename_panels_title')}</MGModalTitle>
        <Box sx={{ overflow: 'hidden' }}>
          <Box marginTop={'20px'}>
            <Typography variant={'small'} fontWeight={'300'}>
              {t('rename_panels_modal_subtitle')}
            </Typography>
          </Box>
          <Box sx={{ height: 'calc(70vh - 210px)', overflow: 'auto' }}>
            {panelQuery.isLoading && (
              <Box height={'100%'}>
                <MGLoader />
              </Box>
            )}
            {panelQuery.data && (
              <Stack spacing={1} marginTop={'30px'}>
                {panelQuery.data.items.map((panel) => (
                  <InputCard key={panel.id + panel.name} panel={panel} />
                ))}
              </Stack>
            )}
          </Box>
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} marginTop={'20px'}>
            <Button variant={'contained'} onClick={() => setOpen(false)}>
              {t('rename_panels_done_button')}
            </Button>
          </Box>
        </Box>
      </MGModal>
    </>
  );
};

const InputCard = ({ panel }: { panel: MeasuringPointPanelSchema }) => {
  if (!panel.id) throw new Error('Panel id is missing');

  const { t, tString } = useTranslation('onboarding');
  const updatePanel = useUpdateMeasuringPointPanel();
  const [editing, setEditing] = useState<boolean>(panel.should_be_renamed || false);
  const [label, setLabel] = useState<string>(panel.name);
  const [name, setName] = useState<string>(panel.should_be_renamed ? '' : panel.name);
  const [focus, setFocus] = useState<boolean>(false);

  const inputRef = (element: HTMLInputElement) => {
    if (element && focus) {
      element.focus();
      setFocus(false);
    }
  };

  const edit = () => {
    setFocus(true);
    setEditing(true);
  };

  const save = () => {
    const trimmed = name.trim();
    if (trimmed.length == 0 || trimmed == panel.name) return;

    updatePanel.mutate(
      {
        id: panel.id!,
        data: {
          name: trimmed,
        },
      },
      {
        onSuccess: () => {
          setLabel(trimmed);
        },
      },
    );
  };

  return (
    <Card
      sx={{
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        paddingX: '20px',
      }}
    >
      <Stack direction="row" width="100%" spacing={3} alignItems="center">
        <CircleGauge />
        <Box sx={{ flex: 5 }}>{label}</Box>
        {editing ? (
          <TextField
            sx={{ flex: 7 }}
            placeholder={tString('rename_panels_placeholder')}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={save}
            inputRef={inputRef}
            InputProps={{
              endAdornment: updatePanel.isPending ? (
                <InputAdornment position="end">
                  <MGLoader size="30px" />
                </InputAdornment>
              ) : updatePanel.isSuccess ? (
                <InputAdornment position="end">
                  <Check color="green" />
                </InputAdornment>
              ) : null,
            }}
          />
        ) : (
          <Button onClick={edit} variant="outlined" color="primary">
            {t('rename_panels_rename_button')}
          </Button>
        )}
      </Stack>
    </Card>
  );
};
