import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CancelSepaMandateMutationRequest,
  CancelSepaMandateMutationResponse,
  CancelSepaMandatePathParams,
  CancelSepaMandate404,
  CancelSepaMandate422,
} from '../types/CancelSepaMandate.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { cancelSepaMandate } from '../clients/cancelSepaMandate.ts'
import { useMutation } from '@tanstack/react-query'

export const cancelSepaMandateMutationKey = () => [{ url: '/api-internal/sepa_mandates/{id}/cancel' }] as const

export type CancelSepaMandateMutationKey = ReturnType<typeof cancelSepaMandateMutationKey>

/**
 * @summary Cancel Sepa Mandate
 * {@link /api-internal/sepa_mandates/:id/cancel}
 */
export function useCancelSepaMandate(
  options: {
    mutation?: UseMutationOptions<
      CancelSepaMandateMutationResponse,
      ResponseErrorConfig<CancelSepaMandate404 | CancelSepaMandate422>,
      { id: CancelSepaMandatePathParams['id']; data: CancelSepaMandateMutationRequest }
    >
    client?: Partial<RequestConfig<CancelSepaMandateMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? cancelSepaMandateMutationKey()

  return useMutation<
    CancelSepaMandateMutationResponse,
    ResponseErrorConfig<CancelSepaMandate404 | CancelSepaMandate422>,
    { id: CancelSepaMandatePathParams['id']; data: CancelSepaMandateMutationRequest }
  >({
    mutationFn: async ({ id, data }) => {
      return cancelSepaMandate(id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}