import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetNewPasswordMutationRequest,
  SetNewPasswordMutationResponse,
  SetNewPassword401,
  SetNewPassword404,
  SetNewPassword422,
} from '../types/SetNewPassword.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { setNewPassword } from '../clients/setNewPassword.ts'
import { useMutation } from '@tanstack/react-query'

export const setNewPasswordMutationKey = () => [{ url: '/api-internal/auth/set_new_password' }] as const

export type SetNewPasswordMutationKey = ReturnType<typeof setNewPasswordMutationKey>

/**
 * @summary Set New Password
 * {@link /api-internal/auth/set_new_password}
 */
export function useSetNewPassword(
  options: {
    mutation?: UseMutationOptions<
      SetNewPasswordMutationResponse,
      ResponseErrorConfig<SetNewPassword401 | SetNewPassword404 | SetNewPassword422>,
      { data: SetNewPasswordMutationRequest }
    >
    client?: Partial<RequestConfig<SetNewPasswordMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? setNewPasswordMutationKey()

  return useMutation<
    SetNewPasswordMutationResponse,
    ResponseErrorConfig<SetNewPassword401 | SetNewPassword404 | SetNewPassword422>,
    { data: SetNewPasswordMutationRequest }
  >({
    mutationFn: async ({ data }) => {
      return setNewPassword(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}