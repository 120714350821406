import { levyStructureCreateInSchema } from './levyStructureCreateInSchema.ts'
import { levyStructureSchemaSchema } from './levyStructureSchemaSchema.ts'
import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { multiplePublicErrorsOutSchema } from './multiplePublicErrorsOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const createLevyStructure200Schema = z.array(z.lazy(() => levyStructureSchemaSchema))

/**
 * @description Not Found
 */
export const createLevyStructure404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const createLevyStructure422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.lazy(() => modelValidationErrorOutSchema),
  z.lazy(() => multiplePublicErrorsOutSchema),
])

export const createLevyStructureMutationRequestSchema = z.lazy(() => levyStructureCreateInSchema)

export const createLevyStructureMutationResponseSchema = z.lazy(() => createLevyStructure200Schema)