import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListLettersQueryResponse, ListLettersQueryParams, ListLetters404, ListLetters422 } from '../types/ListLetters.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listLetters } from '../clients/listLetters.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listLettersQueryKey = (params: ListLettersQueryParams) => [{ url: '/api-internal/letters/' }, ...(params ? [params] : [])] as const

export type ListLettersQueryKey = ReturnType<typeof listLettersQueryKey>

export function listLettersQueryOptions(params: ListLettersQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listLettersQueryKey(params)
  return queryOptions<ListLettersQueryResponse, ResponseErrorConfig<ListLetters404 | ListLetters422>, ListLettersQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listLetters(params, config)
    },
  })
}

/**
 * @summary List Letters
 * {@link /api-internal/letters/}
 */
export function useListLetters<TData = ListLettersQueryResponse, TQueryData = ListLettersQueryResponse, TQueryKey extends QueryKey = ListLettersQueryKey>(
  params: ListLettersQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<ListLettersQueryResponse, ResponseErrorConfig<ListLetters404 | ListLetters422>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listLettersQueryKey(params)

  const query = useQuery({
    ...(listLettersQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListLetters404 | ListLetters422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}