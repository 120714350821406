import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListMeasuringPointPanelsQueryResponse,
  ListMeasuringPointPanelsQueryParams,
  ListMeasuringPointPanels404,
  ListMeasuringPointPanels422,
} from '../types/ListMeasuringPointPanels.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listMeasuringPointPanels } from '../clients/listMeasuringPointPanels.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listMeasuringPointPanelsQueryKey = (params: ListMeasuringPointPanelsQueryParams) =>
  [{ url: '/api-internal/measuring_point_panels/' }, ...(params ? [params] : [])] as const

export type ListMeasuringPointPanelsQueryKey = ReturnType<typeof listMeasuringPointPanelsQueryKey>

export function listMeasuringPointPanelsQueryOptions(
  params: ListMeasuringPointPanelsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = listMeasuringPointPanelsQueryKey(params)
  return queryOptions<
    ListMeasuringPointPanelsQueryResponse,
    ResponseErrorConfig<ListMeasuringPointPanels404 | ListMeasuringPointPanels422>,
    ListMeasuringPointPanelsQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listMeasuringPointPanels(params, config)
    },
  })
}

/**
 * @summary List Measuring Point Panels
 * {@link /api-internal/measuring_point_panels/}
 */
export function useListMeasuringPointPanels<
  TData = ListMeasuringPointPanelsQueryResponse,
  TQueryData = ListMeasuringPointPanelsQueryResponse,
  TQueryKey extends QueryKey = ListMeasuringPointPanelsQueryKey,
>(
  params: ListMeasuringPointPanelsQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        ListMeasuringPointPanelsQueryResponse,
        ResponseErrorConfig<ListMeasuringPointPanels404 | ListMeasuringPointPanels422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listMeasuringPointPanelsQueryKey(params)

  const query = useQuery({
    ...(listMeasuringPointPanelsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListMeasuringPointPanels404 | ListMeasuringPointPanels422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}