import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const sendDocumentByEmailQueryParamsSchema = z.object({
  id: z.number().int(),
  is_test_mail: z.boolean(),
  document_type: z.enum(['invoice', 'payment_plan', 'plan_change', 'sepa_mandate', 'letter']),
})

/**
 * @description OK
 */
export const sendDocumentByEmail200Schema = z.unknown()

/**
 * @description Not Found
 */
export const sendDocumentByEmail404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const sendDocumentByEmail422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const sendDocumentByEmailMutationResponseSchema = z.lazy(() => sendDocumentByEmail200Schema)