// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { CountSepaMandatesQueryResponse, CountSepaMandatesQueryParams, CountSepaMandates404, CountSepaMandates422 } from '../types/CountSepaMandates.ts'

export function getCountSepaMandatesUrl() {
  return `/api-internal/sepa_mandates/count` as const
}

/**
 * @summary Count Sepa Mandates
 * {@link /api-internal/sepa_mandates/count}
 */
export async function countSepaMandates(params: CountSepaMandatesQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CountSepaMandatesQueryResponse, ResponseErrorConfig<CountSepaMandates404 | CountSepaMandates422>, unknown>({
    method: 'GET',
    url: getCountSepaMandatesUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}