// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SignContractProposalByProviderMutationResponse,
  SignContractProposalByProviderPathParams,
  SignContractProposalByProvider404,
  SignContractProposalByProvider422,
} from '../types/SignContractProposalByProvider.ts'

export function getSignContractProposalByProviderUrl(id: SignContractProposalByProviderPathParams['id']) {
  return `/api-internal/contract_proposals/${id}/sign_by_provider` as const
}

/**
 * @summary Sign Contract Proposal By Provider
 * {@link /api-internal/contract_proposals/:id/sign_by_provider}
 */
export async function signContractProposalByProvider(
  id: SignContractProposalByProviderPathParams['id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    SignContractProposalByProviderMutationResponse,
    ResponseErrorConfig<SignContractProposalByProvider404 | SignContractProposalByProvider422>,
    unknown
  >({ method: 'POST', url: getSignContractProposalByProviderUrl(id).toString(), ...requestConfig })
  return res.data
}