import { ProjectSchema, useListProjects } from '@mg/api-wrappers/src/api-internal';
import { Search } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  debounce,
  Divider,
  Fade,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useSwitchProjectHook } from '../../../hooks/useSwitchProject';
import { useTranslation } from '../../../i18n';
import { Loader } from '../../shared/Loader/Loader';

type Props = {
  open: boolean;
  setOpen: (boolean) => void;
  options?: ProjectSchema[];
};

export const SidebarProjectSelectorSearch = ({ open, setOpen }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const projectQuery = useListProjects({ search: searchValue, order_by: 'id', page_size: 5 }, {});
  const switchProject = useSwitchProjectHook();

  const textInputRef = useRef<HTMLInputElement>(null);
  const firstMenuItemRef = useRef<HTMLAnchorElement>(null);

  const { t, tString } = useTranslation('sidebar');

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      textInputRef.current?.blur();
      firstMenuItemRef.current?.focus();
    }
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  return (
    <Popper
      open={open}
      anchorEl={document.getElementById('project-select-main-div')}
      placement="bottom-start"
      sx={{ inset: '15px auto auto 0px !important' }}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper
            sx={{
              position: 'relative',
              width: '300px',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 110,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            }}
          >
            <Divider
              sx={{
                position: 'absolute',
                top: '50px',
                width: '100%',
                borderColor: '#ccd6ec',
              }}
              id={'divider-test'}
            />
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Box>
                <Box sx={{ paddingX: '20px', paddingY: '10px' }}>
                  <TextField
                    ref={textInputRef}
                    onKeyDown={handleKeyDown}
                    onChange={debounce((e) => setSearchValue(e.target.value), 200)}
                    autoFocus
                    fullWidth
                    placeholder={tString('project_search_placeholder')}
                    variant={'standard'}
                    InputProps={{
                      autoComplete: 'off',
                      endAdornment: (
                        <InputAdornment position="end">
                          {projectQuery.isLoading ? <Loader size={'25px'} /> : <Search />}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  maxHeight={'250px'}
                  overflow={'scroll'}
                  sx={{
                    '::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                  }}
                >
                  {projectQuery.isError && (
                    <Box
                      sx={{
                        marginY: '5px',
                        padding: '10px',
                        paddingLeft: '20px',
                        borderRadius: '.625rem',
                      }}
                    >
                      <Typography variant={'small'} color={'error'}>
                        {t('project_search_unexpected_error_message')}
                      </Typography>
                    </Box>
                  )}
                  {projectQuery.isSuccess && (
                    <MenuList>
                      {projectQuery.data.items.map((option, index) => (
                        <MenuItem
                          key={option.id}
                          onKeyDown={(e) => e.key === 'Escape' && setOpen(false)}
                          ref={index === 0 ? firstMenuItemRef : null}
                          onClick={() => {
                            switchProject(option.id as number);
                            setOpen(false);
                          }}
                          component={'a'}
                          sx={{
                            marginY: '5px',
                            padding: '10px',
                            paddingX: '20px',
                            borderRadius: '.625rem',
                            overflow: 'hidden',
                          }}
                        >
                          <Typography variant={'small'} noWrap>
                            {option.name || option.location}
                          </Typography>
                        </MenuItem>
                      ))}
                      {!!searchValue.length && !projectQuery.data.items.length && (
                        <Box
                          sx={{
                            marginY: '5px',
                            padding: '10px',
                            paddingLeft: '20px',
                            borderRadius: '.625rem',
                          }}
                        >
                          <Typography variant={'small'}>{t('project_search_no_results')}</Typography>
                        </Box>
                      )}
                    </MenuList>
                  )}
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
