// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ExportTransactionsQueryResponse, ExportTransactions400, ExportTransactions404, ExportTransactions422 } from '../types/ExportTransactions.ts'

export function getExportTransactionsUrl() {
  return `/api-internal/transactions/export` as const
}

/**
 * @description Export transaction data
 * @summary Export Transactions
 * {@link /api-internal/transactions/export}
 */
export async function exportTransactions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    ExportTransactionsQueryResponse,
    ResponseErrorConfig<ExportTransactions400 | ExportTransactions404 | ExportTransactions422>,
    unknown
  >({ method: 'GET', url: getExportTransactionsUrl().toString(), ...requestConfig })
  return res.data
}