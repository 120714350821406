import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Modal,
  ModalProps,
  Paper,
  Stack,
  SxProps,
} from '@mui/material';
import {
  cloneElement,
  ReactElement,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

export interface MGModalHandle {
  close: () => void;
  open: () => void;
}

interface MGModalBaseProps {
  ref?: RefObject<MGModalHandle | null>;
  showCloseButton?: boolean;
  width?: string;
  height?: string;
  position?: {
    top?: number | string;
    left?: number | string;
    anchorTop?: 'center' | 'top';
    anchorLeft?: 'center' | 'left';
  };

  /**
   * Prevent closing the modal on actions which are not controlled and built into the modal ('backdrop click', 'escape key down' and 'close button click').
   */
  preventAutoClose?: boolean;
  ModalStyle?: SxProps;
  PaperStyle?: SxProps;
  BodyStyle?: SxProps;
  ModalProps?: Omit<ModalProps, 'children' | 'open'>;
  children?: ReactNode | ReactNode[];
}

type ToggleReason = 'closeButtonClick' | 'escapeKeyDown' | 'backdropClick' | 'modalHandleCall';

interface MGModalControlled {
  open: boolean;
  handleToggle: (e: SyntheticEvent | undefined, reason: ToggleReason, open: boolean) => void;
  buttonText?: undefined; // buttonText should be absent
  buttonComponent?: undefined; // buttonComponent should be absent
}

interface MGModalWithDefaultButton {
  buttonText: string;
  handleToggle?: (e: SyntheticEvent | undefined, reason: ToggleReason, open: boolean) => void;
  open?: undefined; // open should be absent
  buttonComponent?: undefined; // buttonComponent should be absent
}

interface MGModalWithButtonComponent {
  buttonComponent: ReactElement<ButtonProps>;
  handleToggle?: (e: SyntheticEvent | undefined, reason: ToggleReason, open: boolean) => void;
  open?: undefined; // open should be absent
  buttonText?: undefined; // buttonText should be absent
}

// Define the final type as a union of the three
export type MGModalProps = MGModalBaseProps &
  (MGModalControlled | MGModalWithDefaultButton | MGModalWithButtonComponent);
export const MGModal = ({
  ref,
  open,
  handleToggle,
  buttonText,
  buttonComponent,
  children,
  width,
  height,
  position = { anchorTop: 'center', anchorLeft: 'left' },
  showCloseButton,
  ModalStyle,
  PaperStyle,
  ModalProps,
  preventAutoClose,
}: MGModalProps) => {
  // Positioning
  const style: SxProps = {
    width: width || 'auto',
    minWidth: '300px',
    maxWidth: '100vw',
    height: height,
    maxHeight: '100vh !important',
    top: position?.top || '50% !important',
    left: position?.left || '50% !important',
    transform: `translate(${position?.anchorLeft === 'center' ? '0%' : '-50%'}, ${
      position.anchorTop && position?.anchorTop === 'center' ? '-50%' : '0%'
    }) !important`,
    position: 'absolute',
    ...ModalStyle,
  };

  // Background
  const paperStyle: SxProps = {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: ' 20px !important',
    overflow: 'hidden',
    paddingY: '46px',
    paddingX: '40px',
    ...PaperStyle,
  };

  const modalSx: SxProps = { zIndex: 800 };
  if (ModalProps?.sx) Object.assign(modalSx, ModalProps?.sx);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(open || false);
  }, [open]);

  const closeModal = useCallback(
    (reason: ToggleReason, e?: SyntheticEvent) => {
      if (
        preventAutoClose &&
        (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason === 'closeButtonClick')
      )
        return;

      setIsOpen(false);
      handleToggle?.(e, reason, false);
    },
    [handleToggle],
  );

  useImperativeHandle(
    ref,
    () => ({
      close: () => closeModal('modalHandleCall'),
      open: () => {
        setIsOpen(true);
        handleToggle?.(undefined, 'modalHandleCall', true);
      },
    }),
    [handleToggle, closeModal],
  );

  return (
    <>
      {buttonComponent
        ? cloneElement(buttonComponent, { onClick: () => setIsOpen(!open) })
        : buttonText && (
            <Button variant={'contained'} onClick={() => setIsOpen(!open)}>
              {buttonText}
            </Button>
          )}

      <Modal
        open={isOpen}
        onClose={(e: SyntheticEvent, reason) => closeModal(reason, e)}
        {...ModalProps}
        sx={modalSx}
      >
        <Box sx={style}>
          <Paper sx={paperStyle}>
            {showCloseButton && (
              <IconButton
                sx={{ position: 'absolute', right: 5, top: 5 }}
                onClick={(e) => closeModal('closeButtonClick', e)}
              >
                <Close />
              </IconButton>
            )}
            <Stack display={'flex'} height={'100%'} spacing={2}>
              {children}
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};
