import { accessTokenOutSchema } from './accessTokenOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { obtainTokenInSchema } from './obtainTokenInSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description OK
 */
export const obtainToken200Schema = z.lazy(() => accessTokenOutSchema)

/**
 * @description Not Found
 */
export const obtainToken404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const obtainToken422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const obtainTokenMutationRequestSchema = z.lazy(() => obtainTokenInSchema)

export const obtainTokenMutationResponseSchema = z.lazy(() => obtainToken200Schema)