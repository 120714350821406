import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListMessagesQueryResponse, ListMessages404, ListMessages422 } from '../types/ListMessages.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { listMessages } from '../clients/listMessages.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listMessagesQueryKey = () => [{ url: '/api-internal/messages/' }] as const

export type ListMessagesQueryKey = ReturnType<typeof listMessagesQueryKey>

export function listMessagesQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = listMessagesQueryKey()
  return queryOptions<ListMessagesQueryResponse, ResponseErrorConfig<ListMessages404 | ListMessages422>, ListMessagesQueryResponse, typeof queryKey>({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listMessages(config)
    },
  })
}

/**
 * @summary List Messages
 * {@link /api-internal/messages/}
 */
export function useListMessages<TData = ListMessagesQueryResponse, TQueryData = ListMessagesQueryResponse, TQueryKey extends QueryKey = ListMessagesQueryKey>(
  options: {
    query?: Partial<QueryObserverOptions<ListMessagesQueryResponse, ResponseErrorConfig<ListMessages404 | ListMessages422>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listMessagesQueryKey()

  const query = useQuery({
    ...(listMessagesQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<ListMessages404 | ListMessages422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}