// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  UpdateCustomerNotesMutationRequest,
  UpdateCustomerNotesMutationResponse,
  UpdateCustomerNotesPathParams,
  UpdateCustomerNotes404,
  UpdateCustomerNotes422,
} from '../types/UpdateCustomerNotes.ts'

export function getUpdateCustomerNotesUrl(customer_id: UpdateCustomerNotesPathParams['customer_id']) {
  return `/api-internal/customers/${customer_id}/notes` as const
}

/**
 * @summary Update Customer Notes
 * {@link /api-internal/customers/:customer_id/notes}
 */
export async function updateCustomerNotes(
  customer_id: UpdateCustomerNotesPathParams['customer_id'],
  data: UpdateCustomerNotesMutationRequest,
  config: Partial<RequestConfig<UpdateCustomerNotesMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateCustomerNotesMutationResponse,
    ResponseErrorConfig<UpdateCustomerNotes404 | UpdateCustomerNotes422>,
    UpdateCustomerNotesMutationRequest
  >({ method: 'PATCH', url: getUpdateCustomerNotesUrl(customer_id).toString(), data, ...requestConfig })
  return res.data
}