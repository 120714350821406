// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetCurrentUserQueryResponse, GetCurrentUser404, GetCurrentUser422 } from '../types/GetCurrentUser.ts'

export function getGetCurrentUserUrl() {
  return `/api-internal/users/current` as const
}

/**
 * @summary Get Current User
 * {@link /api-internal/users/current}
 */
export async function getCurrentUser(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetCurrentUserQueryResponse, ResponseErrorConfig<GetCurrentUser404 | GetCurrentUser422>, unknown>({
    method: 'GET',
    url: getGetCurrentUserUrl().toString(),
    ...requestConfig,
  })
  return res.data
}