// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  RemoveTenantAcquisitionConfigurationLogoMutationResponse,
  RemoveTenantAcquisitionConfigurationLogoPathParams,
  RemoveTenantAcquisitionConfigurationLogo404,
  RemoveTenantAcquisitionConfigurationLogo422,
} from '../types/RemoveTenantAcquisitionConfigurationLogo.ts'

export function getRemoveTenantAcquisitionConfigurationLogoUrl(project_id: RemoveTenantAcquisitionConfigurationLogoPathParams['project_id']) {
  return `/api-internal/projects/${project_id}/tenant_acquisition_configuration/logo/remove` as const
}

/**
 * @summary Remove Tenant Acquisition Configuration Logo
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration/logo/remove}
 */
export async function removeTenantAcquisitionConfigurationLogo(
  project_id: RemoveTenantAcquisitionConfigurationLogoPathParams['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    RemoveTenantAcquisitionConfigurationLogoMutationResponse,
    ResponseErrorConfig<RemoveTenantAcquisitionConfigurationLogo404 | RemoveTenantAcquisitionConfigurationLogo422>,
    unknown
  >({ method: 'POST', url: getRemoveTenantAcquisitionConfigurationLogoUrl(project_id).toString(), ...requestConfig })
  return res.data
}