// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  UpdateMeasuringPointPanelMutationRequest,
  UpdateMeasuringPointPanelMutationResponse,
  UpdateMeasuringPointPanelPathParams,
  UpdateMeasuringPointPanel404,
  UpdateMeasuringPointPanel422,
} from '../types/UpdateMeasuringPointPanel.ts'

export function getUpdateMeasuringPointPanelUrl(id: UpdateMeasuringPointPanelPathParams['id']) {
  return `/api-internal/measuring_point_panels/${id}` as const
}

/**
 * @summary Update Measuring Point Panel
 * {@link /api-internal/measuring_point_panels/:id}
 */
export async function updateMeasuringPointPanel(
  id: UpdateMeasuringPointPanelPathParams['id'],
  data?: UpdateMeasuringPointPanelMutationRequest,
  config: Partial<RequestConfig<UpdateMeasuringPointPanelMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UpdateMeasuringPointPanelMutationResponse,
    ResponseErrorConfig<UpdateMeasuringPointPanel404 | UpdateMeasuringPointPanel422>,
    UpdateMeasuringPointPanelMutationRequest
  >({ method: 'PATCH', url: getUpdateMeasuringPointPanelUrl(id).toString(), data, ...requestConfig })
  return res.data
}