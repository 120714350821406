import { MissingDataOut } from '@mg/api-wrappers/src/api-internal';
import { MGCollapsibleCard } from '@mg/ui/src/components/MGCollapsibleCard/MGCollapsibleCard';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { MGStatusIndicator } from '@mg/ui/src/components/MGStatusIndicator';
import { Box, Card, Stack, Typography } from '@mui/material';
import { CircleCheck, TriangleAlert } from 'lucide-react';
import { useTranslation } from '../../../../../i18n';
import { BillingMeteringDataCardTable } from './Billing.MeteringData.Card.Table';

export const BillingMeteringDataCard = ({ meteringData }: { meteringData: MissingDataOut[] }) => {
  const isError = meteringData.some((data) => !!data.errors?.length);
  const { t } = useTranslation('billing');
  const { t: tErrors } = useTranslation('errors');

  return (
    <MGCollapsibleCard
      defaultOpen={isError}
      collapsedContent={
        <Stack direction={'row'} width={'100%'} spacing={10} alignItems={'center'}>
          <Box flex={0.2}>
            <MGStatusIndicator status={isError ? 'error' : 'success'} />
          </Box>
          <Box flex={2}>
            <MGCustomerWithAvatar
              customer={meteringData[0].customer}
              noCustomerDisplayText={
                meteringData[0].measuring_point_panel?.type
                  ? t(`${meteringData[0].measuring_point_panel.type}_PANEL`, {
                      panel_name: meteringData[0].measuring_point_panel?.name,
                    })
                  : t(meteringData[0].errors![0]?.code.split('_MISSING')[0], {
                      panel_name: meteringData[0].measuring_point_panel?.name,
                    })
              }
            />
          </Box>
          <Box flex={2}>
            <Typography noWrap variant={'small'}>
              {meteringData[0].measuring_point_panel?.name}
            </Typography>
          </Box>
          <Box flex={4}>
            {isError ? (
              <Typography
                color={'error.main'}
                variant={'small'}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <TriangleAlert size={18} style={{ marginRight: '5px' }} />
                {t('missing_data')}
              </Typography>
            ) : (
              <Typography color={'success.main'} variant={'small'}>
                <CircleCheck size={18} style={{ marginRight: '5px' }} />
                {t('data_completed')}
              </Typography>
            )}
          </Box>
        </Stack>
      }
    >
      <Card sx={{ padding: '20px' }}>
        <BillingMeteringDataCardTable meteringData={meteringData} />
      </Card>
    </MGCollapsibleCard>
  );
};
