// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetProjectRemoteReadabilityStatusQueryResponse,
  GetProjectRemoteReadabilityStatusPathParams,
  GetProjectRemoteReadabilityStatus404,
  GetProjectRemoteReadabilityStatus422,
} from '../types/GetProjectRemoteReadabilityStatus.ts'

export function getGetProjectRemoteReadabilityStatusUrl(id: GetProjectRemoteReadabilityStatusPathParams['id']) {
  return `/api-internal/projects/${id}/remote_readability_status` as const
}

/**
 * @summary Get Project Remote Readability Status
 * {@link /api-internal/projects/:id/remote_readability_status}
 */
export async function getProjectRemoteReadabilityStatus(
  id: GetProjectRemoteReadabilityStatusPathParams['id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetProjectRemoteReadabilityStatusQueryResponse,
    ResponseErrorConfig<GetProjectRemoteReadabilityStatus404 | GetProjectRemoteReadabilityStatus422>,
    unknown
  >({ method: 'GET', url: getGetProjectRemoteReadabilityStatusUrl(id).toString(), ...requestConfig })
  return res.data
}