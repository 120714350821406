// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListTransactionsQueryResponse, ListTransactionsQueryParams, ListTransactions404, ListTransactions422 } from '../types/ListTransactions.ts'

export function getListTransactionsUrl() {
  return `/api-internal/transactions/` as const
}

/**
 * @summary List Transactions
 * {@link /api-internal/transactions/}
 */
export async function listTransactions(params?: ListTransactionsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListTransactionsQueryResponse, ResponseErrorConfig<ListTransactions404 | ListTransactions422>, unknown>({
    method: 'GET',
    url: getListTransactionsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}