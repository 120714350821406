import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { INOUTPANELMISSINGSchema } from './INOUTPANELMISSINGSchema.ts'
import { measuringPointDataSchemaSchema } from './measuringPointDataSchemaSchema.ts'
import { MEASURINGPOINTMISSINGSchema } from './MEASURINGPOINTMISSINGSchema.ts'
import { measuringPointNestedSchemaSchema } from './measuringPointNestedSchemaSchema.ts'
import { measuringPointPanelNestedSchemaSchema } from './measuringPointPanelNestedSchemaSchema.ts'
import { measuringPointSubscriptionNestedSchemaSchema } from './measuringPointSubscriptionNestedSchemaSchema.ts'
import { MISSINGENDDATASchema } from './MISSINGENDDATASchema.ts'
import { MISSINGSTARTDATASchema } from './MISSINGSTARTDATASchema.ts'
import { OBISCODESSchema } from './OBISCODESSchema.ts'
import { SOURCEPANELMISSINGSchema } from './SOURCEPANELMISSINGSchema.ts'
import { z } from 'zod'

export const missingDataOutSchema = z.object({
  customer: z.union([z.lazy(() => customerNestedSchemaSchema), z.null()]).optional(),
  end_data: z.union([z.lazy(() => measuringPointDataSchemaSchema), z.null()]).optional(),
  end_datetime: z.string().datetime(),
  errors: z.union([
    z.array(
      z.union([
        z.lazy(() => INOUTPANELMISSINGSchema),
        z.lazy(() => SOURCEPANELMISSINGSchema),
        z.lazy(() => MEASURINGPOINTMISSINGSchema),
        z.lazy(() => MISSINGSTARTDATASchema),
        z.lazy(() => MISSINGENDDATASchema),
      ]),
    ),
    z.null(),
  ]),
  measuring_point: z.union([z.lazy(() => measuringPointNestedSchemaSchema), z.null()]).optional(),
  measuring_point_panel: z.union([z.lazy(() => measuringPointPanelNestedSchemaSchema), z.null()]).optional(),
  obis_code: z.union([z.lazy(() => OBISCODESSchema), z.null()]).optional(),
  start_data: z.union([z.lazy(() => measuringPointDataSchemaSchema), z.null()]).optional(),
  start_datetime: z.string().datetime(),
  subscription: z.union([z.lazy(() => measuringPointSubscriptionNestedSchemaSchema), z.null()]).optional(),
})