import { useListProjectsInfinite } from '@mg/api-wrappers/src/api-internal';
import { Add } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { useSwitchProjectHook } from '../../../hooks/useSwitchProject';
import { useTranslation } from '../../../i18n';
import { filterValueAtom } from '../../../jotai/actionBar';
import { CalendlyModal } from '../../modals/CalendlyModal';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { FilterProvider } from '../../shared/DataGridCard/DataGridCard.FilterProvider';
import { PageLayout } from '../../shared/PageLayout';
import { ProjectsActionsMenu } from './Projects.ActionsMenu';
import { ProjectsCurrentProjectCard } from './Projects.CurrentProjectCard';
import { ProjectsRowActionsMenu } from './Projects.RowActionsMenu';

export const Projects = () => {
  const { t, tString } = useTranslation('projects');
  const [modalOpen, setModalOpen] = useState(false);
  const currentProjectId = useCurrentProject();
  const switchProject = useSwitchProjectHook();
  const setFilterValue = useSetAtom(filterValueAtom);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: tString('id') as string,
      flex: 0.2,
    },
    {
      field: 'location',
      headerName: tString('address') as string,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>
      ),
    },
    {
      field: 'name',
      headerName: tString('name') as string,
      flex: 1,
      valueGetter: (value) => value || '-',
      renderCell: ({ value }) => (
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      width: 20,
      renderCell: (params) => {
        return (
          <Box display={'flex'} alignItems={'center'} height={'100%'}>
            <ProjectsRowActionsMenu projectId={params.row.id} />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <PageLayout
        title={t('page_title')}
        subtitle={t('page_subtitle')}
        rightSection={
          <>
            <ProjectsActionsMenu />
            <Button
              size={'large'}
              color={'primary'}
              variant={'contained'}
              onClick={() => setModalOpen(!modalOpen)}
              sx={{ marginLeft: '20px' }}
            >
              {'Projekt anfragen'} <Add sx={{ marginLeft: '10px' }} />
            </Button>
            <CalendlyModal
              calendlyLink={tString('url_calendly_new_project')}
              open={modalOpen}
              handleToggle={() => setModalOpen(!modalOpen)}
              translationPrefix={'book_appointment'}
            />
          </>
        }
      >
        <Stack spacing={2}>
          <ProjectsCurrentProjectCard />
          <FilterProvider>
            <DataGridCard
              height={'calc(100vh - 380px)'}
              datagridProps={{
                onRowClick: (row) => {
                  switchProject(row.id as number);
                  setFilterValue({});
                },
              }}
              rowHeight={70}
              showSearch={true}
              searchPlaceholder={tString('search_placeholder') as string}
              columns={columns}
              reactQueryHook={useListProjectsInfinite}
              reactQueryHookParams={{
                current_project_id: currentProjectId,
                exclude_current_project: true,
              }}
              defaultSort={'id'}
            />
          </FilterProvider>
        </Stack>
      </PageLayout>
    </>
  );
};
