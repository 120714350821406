import { palette } from '@mg/ui/src/styles';
import { Card, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';

export const CustomerDetailsTabs = ({ tabs }) => {
  const { t } = useTranslation('customerDetails');

  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split('/');
  const currentTab = path.pop();
  return (
    <Card sx={{ height: '64px' }}>
      <Tabs
        value={currentTab}
        onChange={(value, tab) => navigate(`${path.join('/')}/${tab}`)}
        sx={{
          height: '100%',
          '.MuiTabs-flexContainer': {
            height: '100%',
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab}
            value={tab}
            label={t(`tabs_${tab}`)}
            sx={{
              color: palette.text.disabled,
              fontWeight: 400,
              '&.Mui-selected': {
                color: palette.text.secondary,
                fontWeight: 400,
              },
            }}
          />
        ))}
      </Tabs>
    </Card>
  );
};
