import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetCustomerDataExportQueryResponse,
  GetCustomerDataExport400,
  GetCustomerDataExport404,
  GetCustomerDataExport422,
} from '../types/GetCustomerDataExport.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getCustomerDataExport } from '../clients/getCustomerDataExport.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCustomerDataExportQueryKey = () => [{ url: '/api-internal/customers/export' }] as const

export type GetCustomerDataExportQueryKey = ReturnType<typeof getCustomerDataExportQueryKey>

export function getCustomerDataExportQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getCustomerDataExportQueryKey()
  return queryOptions<
    GetCustomerDataExportQueryResponse,
    ResponseErrorConfig<GetCustomerDataExport400 | GetCustomerDataExport404 | GetCustomerDataExport422>,
    GetCustomerDataExportQueryResponse,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCustomerDataExport(config)
    },
  })
}

/**
 * @summary Get Customer Data Export
 * {@link /api-internal/customers/export}
 */
export function useGetCustomerDataExport<
  TData = GetCustomerDataExportQueryResponse,
  TQueryData = GetCustomerDataExportQueryResponse,
  TQueryKey extends QueryKey = GetCustomerDataExportQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetCustomerDataExportQueryResponse,
        ResponseErrorConfig<GetCustomerDataExport400 | GetCustomerDataExport404 | GetCustomerDataExport422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCustomerDataExportQueryKey()

  const query = useQuery({
    ...(getCustomerDataExportQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetCustomerDataExport400 | GetCustomerDataExport404 | GetCustomerDataExport422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}