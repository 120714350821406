import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetBillingPreflightDataQueryResponse,
  GetBillingPreflightDataQueryParams,
  GetBillingPreflightData400,
  GetBillingPreflightData404,
  GetBillingPreflightData422,
} from '../types/GetBillingPreflightData.ts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { getBillingPreflightData } from '../clients/getBillingPreflightData.ts'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getBillingPreflightDataQueryKey = (params: GetBillingPreflightDataQueryParams) =>
  [{ url: '/api-internal/billing/preflight_data' }, ...(params ? [params] : [])] as const

export type GetBillingPreflightDataQueryKey = ReturnType<typeof getBillingPreflightDataQueryKey>

export function getBillingPreflightDataQueryOptions(
  params: GetBillingPreflightDataQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = getBillingPreflightDataQueryKey(params)
  return queryOptions<
    GetBillingPreflightDataQueryResponse,
    ResponseErrorConfig<GetBillingPreflightData400 | GetBillingPreflightData404 | GetBillingPreflightData422>,
    GetBillingPreflightDataQueryResponse,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getBillingPreflightData(params, config)
    },
  })
}

/**
 * @summary Get Billing Preflight Data
 * {@link /api-internal/billing/preflight_data}
 */
export function useGetBillingPreflightData<
  TData = GetBillingPreflightDataQueryResponse,
  TQueryData = GetBillingPreflightDataQueryResponse,
  TQueryKey extends QueryKey = GetBillingPreflightDataQueryKey,
>(
  params: GetBillingPreflightDataQueryParams,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetBillingPreflightDataQueryResponse,
        ResponseErrorConfig<GetBillingPreflightData400 | GetBillingPreflightData404 | GetBillingPreflightData422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getBillingPreflightDataQueryKey(params)

  const query = useQuery({
    ...(getBillingPreflightDataQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetBillingPreflightData400 | GetBillingPreflightData404 | GetBillingPreflightData422>> & {
    queryKey: TQueryKey
  }

  query.queryKey = queryKey as TQueryKey

  return query
}