import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListContractProposalsQueryResponse,
  ListContractProposalsQueryParams,
  ListContractProposals404,
  ListContractProposals422,
} from '../types/ListContractProposals.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { listContractProposals } from '../clients/listContractProposals.ts'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listContractProposalsInfiniteQueryKey = (params: ListContractProposalsQueryParams) =>
  [{ url: '/api-internal/contract_proposals/' }, ...(params ? [params] : [])] as const

export type ListContractProposalsInfiniteQueryKey = ReturnType<typeof listContractProposalsInfiniteQueryKey>

export function listContractProposalsInfiniteQueryOptions(
  params: ListContractProposalsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const queryKey = listContractProposalsInfiniteQueryKey(params)
  return infiniteQueryOptions<
    ListContractProposalsQueryResponse,
    ResponseErrorConfig<ListContractProposals404 | ListContractProposals422>,
    ListContractProposalsQueryResponse,
    typeof queryKey,
    number
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListContractProposalsQueryParams['page']
      }
      return listContractProposals(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Contract Proposals
 * {@link /api-internal/contract_proposals/}
 */
export function useListContractProposalsInfinite<
  TData = InfiniteData<ListContractProposalsQueryResponse>,
  TQueryData = ListContractProposalsQueryResponse,
  TQueryKey extends QueryKey = ListContractProposalsInfiniteQueryKey,
>(
  params: ListContractProposalsQueryParams,
  options: {
    query?: Partial<
      InfiniteQueryObserverOptions<
        ListContractProposalsQueryResponse,
        ResponseErrorConfig<ListContractProposals404 | ListContractProposals422>,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listContractProposalsInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listContractProposalsInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ResponseErrorConfig<ListContractProposals404 | ListContractProposals422>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}