// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetMeasuringPointPanelQueryResponse,
  GetMeasuringPointPanelPathParams,
  GetMeasuringPointPanelQueryParams,
  GetMeasuringPointPanel404,
  GetMeasuringPointPanel422,
} from '../types/GetMeasuringPointPanel.ts'

export function getGetMeasuringPointPanelUrl(id: GetMeasuringPointPanelPathParams['id']) {
  return `/api-internal/measuring_point_panels/${id}` as const
}

/**
 * @summary Get Measuring Point Panel
 * {@link /api-internal/measuring_point_panels/:id}
 */
export async function getMeasuringPointPanel(
  id: GetMeasuringPointPanelPathParams['id'],
  params?: GetMeasuringPointPanelQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetMeasuringPointPanelQueryResponse, ResponseErrorConfig<GetMeasuringPointPanel404 | GetMeasuringPointPanel422>, unknown>({
    method: 'GET',
    url: getGetMeasuringPointPanelUrl(id).toString(),
    params,
    ...requestConfig,
  })
  return res.data
}