import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { projectTenantAcquisitionConfigurationOutSchema } from './projectTenantAcquisitionConfigurationOutSchema.ts'
import { z } from 'zod'

export const setTenantAcquisitionConfigurationLogoPathParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const setTenantAcquisitionConfigurationLogo200Schema = z.lazy(() => projectTenantAcquisitionConfigurationOutSchema)

/**
 * @description Not Found
 */
export const setTenantAcquisitionConfigurationLogo404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const setTenantAcquisitionConfigurationLogo422Schema = z.union([
  z.lazy(() => paramValidationErrorOutSchema),
  z.lazy(() => modelValidationErrorOutSchema),
])

export const setTenantAcquisitionConfigurationLogoMutationRequestSchema = z.object({
  logo: z.instanceof(File),
})

export const setTenantAcquisitionConfigurationLogoMutationResponseSchema = z.lazy(() => setTenantAcquisitionConfigurationLogo200Schema)