import { ImgHTMLAttributes, ReactElement, useState } from 'react';

export interface MGImageWithFallbackProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallback: ReactElement;
}

export const MGImageWithFallback = ({ fallback, ...imageProps }: MGImageWithFallbackProps) => {
  const [error, setError] = useState(false);

  if (error) {
    return fallback;
  }
  return <img onError={() => setError(true)} {...imageProps} />;
};
