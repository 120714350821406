import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  CreateLetterMutationRequest,
  CreateLetterMutationResponse,
  CreateLetterQueryParams,
  CreateLetter400,
  CreateLetter404,
  CreateLetter422,
} from '../types/CreateLetter.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { createLetter } from '../clients/createLetter.ts'
import { useMutation } from '@tanstack/react-query'

export const createLetterMutationKey = () => [{ url: '/api-internal/letters/' }] as const

export type CreateLetterMutationKey = ReturnType<typeof createLetterMutationKey>

/**
 * @summary Create Letter
 * {@link /api-internal/letters/}
 */
export function useCreateLetter(
  options: {
    mutation?: UseMutationOptions<
      CreateLetterMutationResponse,
      ResponseErrorConfig<CreateLetter400 | CreateLetter404 | CreateLetter422>,
      { data: CreateLetterMutationRequest; params?: CreateLetterQueryParams }
    >
    client?: Partial<RequestConfig<CreateLetterMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createLetterMutationKey()

  return useMutation<
    CreateLetterMutationResponse,
    ResponseErrorConfig<CreateLetter400 | CreateLetter404 | CreateLetter422>,
    { data: CreateLetterMutationRequest; params?: CreateLetterQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return createLetter(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}