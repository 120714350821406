// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetProjectOnboardingCompletenessQueryResponse,
  GetProjectOnboardingCompletenessPathParams,
  GetProjectOnboardingCompleteness404,
  GetProjectOnboardingCompleteness422,
} from '../types/GetProjectOnboardingCompleteness.ts'

export function getGetProjectOnboardingCompletenessUrl(project_id: GetProjectOnboardingCompletenessPathParams['project_id']) {
  return `/api-internal/projects/${project_id}/onboarding_completeness` as const
}

/**
 * @summary Get Project Onboarding Completeness
 * {@link /api-internal/projects/:project_id/onboarding_completeness}
 */
export async function getProjectOnboardingCompleteness(
  project_id: GetProjectOnboardingCompletenessPathParams['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetProjectOnboardingCompletenessQueryResponse,
    ResponseErrorConfig<GetProjectOnboardingCompleteness404 | GetProjectOnboardingCompleteness422>,
    unknown
  >({ method: 'GET', url: getGetProjectOnboardingCompletenessUrl(project_id).toString(), ...requestConfig })
  return res.data
}