import { MGFieldDate, MGFieldDateProps } from './MGField/MGField.Date.tsx';
import { MGFormFieldBaseProps } from './MGForm.Field';

interface Props extends Omit<MGFormFieldBaseProps, 'TextFieldProps'> {
  DatePickerProps?: MGFieldDateProps['DatePickerProps'];
}

export const MGFormFieldDate = ({ label = '', form, name, FieldProps, DatePickerProps }: Props) => {
  return (
    <form.Field name={name} {...FieldProps}>
      {(field) => (
        <MGFieldDate
          label={label}
          value={field.state.value}
          onChange={(value) => field.handleChange(value)}
          onBlur={field.handleBlur}
          errors={field.state.meta.errors}
          DatePickerProps={DatePickerProps}
        />
      )}
    </form.Field>
  );
};
