import { palette } from '@mg/ui/src/styles';
import { Box, Card, Grid, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from '../../../../i18n';
import { Loader } from '../../../shared/Loader/Loader';

export type OnboardingCardProps = {
  title: ReactNode;
  subtitle: ReactNode;
  icon: ReactNode;
  status: 'done' | 'todo' | 'coming_soon' | 'blocked';
  button?: ReactNode;
  loading?: boolean;
  sx?: SxProps;
  key?: string;
  onClick?: () => void;
  href?: string;
};
export const OnboardingCard = ({
  title,
  subtitle,
  icon,
  status,
  button,
  loading,
  sx = {},
}: OnboardingCardProps) => {
  const { t } = useTranslation('onboarding');
  const isDone = status === 'done';
  return (
    <Card sx={{ paddingY: '20px', paddingX: '20px', minHeight: '120px', ...sx }}>
      {loading ? (
        <Box height={'120px'} alignItems={'center'}>
          <Loader />
        </Box>
      ) : (
        <Grid container rowGap={3} height={'100%'}>
          <Grid item xs={8} color={palette.primary.main}>
            {icon}
          </Grid>
          <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
            <Box
              sx={{
                width: '100%',
                height: '36px',
                backgroundColor: isDone ? palette.success.light : palette.gray.light,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderRadius: '8px',
              }}
            >
              <Typography color={isDone ? palette.success.main : palette.gray.dark}>
                {t(`status_${status}`)}
              </Typography>
            </Box>
          </Grid>

          <Grid item container xs={12}>
            <Grid xs={12} item>
              <Typography variant={'regular'} fontWeight={500} color={'text.secondary'}>
                {title}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography variant={'small'} marginTop={'5px'} fontWeight={'300'}>
                {subtitle}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} display={'flex'} alignItems={'flex-end'}>
            {button}
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
