import { MGFieldRadioGroup, MGFieldRadioGroupProps } from './MGField/MGField.RadioGroup';
import { MGFormFieldBaseProps } from './MGForm.Field';

type Props = MGFormFieldBaseProps &
  Pick<MGFieldRadioGroupProps, 'RadioGroupProps' | 'helpLink' | 'options' | 'defaultOption' | 'showTextOnly'>;

export const MGFormFieldRadioGroup = ({
  label = '',
  form,
  name,
  FieldProps,
  options,
  defaultOption,
  showTextOnly,
  RadioGroupProps,
  helpLink,
}: Props) => {
  return (
    <form.Field name={name} {...FieldProps}>
      {(field) => (
        <MGFieldRadioGroup
          name={field.name}
          value={field.state.value || ''}
          label={label}
          errors={field.state.meta.errors}
          helpLink={helpLink}
          options={options}
          defaultOption={defaultOption}
          showTextOnly={showTextOnly}
          RadioGroupProps={RadioGroupProps}
          onChange={(e) => field.handleChange(e.target.value)}
          onBlur={field.handleBlur}
        />
      )}
    </form.Field>
  );
};
