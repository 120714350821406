import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, currentProjectIdAtom } from '../jotai/atoms.ts';
import { jotaiStore } from '../jotaiStore.ts';
import { userInfoAtom } from './userInfo';

const clearAtoms = () => {
  jotaiStore.set(userInfoAtom, null);
  jotaiStore.set(accessTokenAtom, null);
  jotaiStore.set(currentProjectIdAtom, null);
};

export const logout = (next?: string) => {
  clearAtoms();
  if (!window.location.pathname.startsWith('/login')) {
    window.location.href = next ? `/login?next=${next}` : '/login';
  }
};

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = (next?: string) => {
    clearAtoms();
    navigate(next ? `/login?next=${next}` : '/login', { replace: true });
  };

  return logout;
};
