import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout';
import { Stack } from '@mui/material';
import { CustomerDetailsTabs } from './CustomerDetails.Tabs';
import { CustomerDetailsTitleCard } from './CustomerDetails.TitleCard';
import { CustomerDetailsPages } from './Pages/CustomerDetails.Pages';

export const CustomerDetails = () => {
  const tabs = ['data', 'contract', 'metering', 'documents', 'transactions'];

  return (
    <MGPageLayout>
      <Stack paddingTop={'50px'} spacing={5} height={'100%'} width={'100%'}>
        <CustomerDetailsTitleCard />
        <CustomerDetailsTabs tabs={tabs} />
        <CustomerDetailsPages />
      </Stack>
    </MGPageLayout>
  );
};
