import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getLetterPdfsAsZipQueryParamsSchema = z.object({
  document_type: z.literal('letter').default('letter'),
  search: z.union([z.string(), z.null()]).optional(),
  ids: z.union([z.array(z.number().int()), z.null()]).optional(),
  customer_id: z.union([z.number().int(), z.null()]).optional(),
  project_id: z.number().int(),
  customer: z.union([z.number().int(), z.null()]).optional(),
  issue_date_from: z.union([z.string(), z.null()]).optional(),
  issue_date_to: z.union([z.string(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const getLetterPdfsAsZip200Schema = z.unknown()

/**
 * @description Bad Request
 */
export const getLetterPdfsAsZip400Schema = z.string()

/**
 * @description Not Found
 */
export const getLetterPdfsAsZip404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getLetterPdfsAsZip422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getLetterPdfsAsZipQueryResponseSchema = z.lazy(() => getLetterPdfsAsZip200Schema)