import { ContractProposalOut } from '@mg/api-wrappers/src/api-internal/index.ts';
import { MGCardHeader } from '@mg/ui/src/components/MGCard/MGCard.Header.tsx';
import { MGCardListLayoutItem } from '@mg/ui/src/components/MGCard/MGCard.ListLayout.Item.tsx';
import { MGCardListLayout } from '@mg/ui/src/components/MGCard/MGCard.ListLayout.tsx';
import { MGCard } from '@mg/ui/src/components/MGCard/MGCard.tsx';
import { customerFullName } from '@mg/ui/src/presenters/customer.ts';
import { palette } from '@mg/ui/src/styles/palette.ts';
import { Typography } from '@mui/material';
import { useTranslation } from '../../../i18n.tsx';

interface ContractsGeneralDataCardProps {
  contractProposal: ContractProposalOut;
}

export const ContractsGeneralDataCard = ({ contractProposal }: ContractsGeneralDataCardProps) => {
  const { t } = useTranslation('contracts');

  return (
    <MGCard
      sx={{
        flex: 1,
        padding: '20px',
      }}
    >
      <MGCardHeader
        leftHeader={
          <Typography color={palette.common.black} fontWeight={500}>
            Allgemeine Angaben
          </Typography>
        }
      />
      <MGCardListLayout>
        <MGCardListLayoutItem label={t('lbl_confirm_sign_modal_core_card_name')}>
          <Typography variant={'extraSmall'}>
            {customerFullName({
              first_name: contractProposal.address_first_name,
              last_name: contractProposal.address_last_name,
            })}
          </Typography>
        </MGCardListLayoutItem>
        <MGCardListLayoutItem label={t('lbl_confirm_sign_modal_core_card_contact')}>
          <Typography variant={'extraSmall'}>
            {contractProposal.email || contractProposal.phone_number}
          </Typography>
        </MGCardListLayoutItem>
        <MGCardListLayoutItem label={t('lbl_confirm_sign_modal_core_card_object')}>
          <Typography variant={'extraSmall'}>{contractProposal.measuring_point_panel?.name}</Typography>
        </MGCardListLayoutItem>
        <MGCardListLayoutItem label={t('lbl_confirm_sign_modal_core_card_measuring_point')}>
          <Typography variant={'extraSmall'}>{contractProposal.measuring_point_serial}</Typography>
        </MGCardListLayoutItem>
        <MGCardListLayoutItem label={t('lbl_confirm_sign_modal_core_card_start_date')}>
          <Typography variant={'extraSmall'}>
            {contractProposal.estimated_start_date_type === 'SPECIFIC_DATE'
              ? contractProposal.specific_estimated_start_date
              : t('lbl_confirm_sign_modal_core_card_start_date_soon')}
          </Typography>
        </MGCardListLayoutItem>
      </MGCardListLayout>
    </MGCard>
  );
};
