import { useGetProject } from '@mg/api-wrappers/src/api-internal';
import { palette } from '@mg/ui/src/styles';
import { Box, Card, Chip, Stack, Typography } from '@mui/material';
import { MapPin } from 'lucide-react';
import iconHouse from '../../../assets/icon_house.svg';
import { useCurrentProject } from '../../../hooks/useCurrentProject';
import { useTranslation } from '../../../i18n';
import { ProjectsRowActionsMenu } from './Projects.RowActionsMenu';

export const ProjectsCurrentProjectCard = ({ hideInput = false }) => {
  const currentProjectId = useCurrentProject();
  const projectQuery = useGetProject(currentProjectId);
  const { t } = useTranslation('projects');

  return (
    <Card sx={{ paddingY: '10px', paddingX: '15px' }}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Box alignItems={'center'} display={'flex'} sx={{ width: '100%' }}>
          <img style={{ borderRadius: '10px' }} src={iconHouse} />

          <Typography sx={{ marginLeft: '20px' }}>{`ID ${currentProjectId}`}</Typography>

          <Typography variant={'small'} sx={{ marginLeft: '50px' }}>
            <MapPin size={16} style={{ marginRight: '5px', color: palette.primary.main }} />
            {projectQuery.data?.location}
          </Typography>
        </Box>

        <Chip
          variant={'soft'}
          color={'primary'}
          sx={{ borderRadius: '8px', marginRight: '20px' }}
          label={t('current_project')}
          size={'small'}
        />
        {!hideInput && <ProjectsRowActionsMenu projectId={currentProjectId} />}
      </Stack>
    </Card>
  );
};
