import { letterSchemaSchema } from './letterSchemaSchema.ts'
import { letterUpdateSchemaSchema } from './letterUpdateSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const updateLetterPathParamsSchema = z.object({
  id: z.number().int(),
})

export const updateLetterQueryParamsSchema = z
  .object({
    include_customer: z.boolean().default(false),
    include_pdf: z.boolean().default(false),
  })
  .optional()

/**
 * @description OK
 */
export const updateLetter200Schema = z.lazy(() => letterSchemaSchema)

/**
 * @description Bad Request
 */
export const updateLetter400Schema = z.object({})

/**
 * @description Not Found
 */
export const updateLetter404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const updateLetter422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const updateLetterMutationRequestSchema = z.lazy(() => letterUpdateSchemaSchema)

export const updateLetterMutationResponseSchema = z.lazy(() => updateLetter200Schema)