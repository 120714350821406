// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  RemoveTenantAcquisitionConfigurationPanelSelectionHelperImageMutationResponse,
  RemoveTenantAcquisitionConfigurationPanelSelectionHelperImagePathParams,
  RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage404,
  RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage422,
} from '../types/RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage.ts'

export function getRemoveTenantAcquisitionConfigurationPanelSelectionHelperImageUrl(
  project_id: RemoveTenantAcquisitionConfigurationPanelSelectionHelperImagePathParams['project_id'],
) {
  return `/api-internal/projects/${project_id}/tenant_acquisition_configuration/panel_selection_helper_image/remove` as const
}

/**
 * @summary Remove Tenant Acquisition Configuration Panel Selection Helper Image
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration/panel_selection_helper_image/remove}
 */
export async function removeTenantAcquisitionConfigurationPanelSelectionHelperImage(
  project_id: RemoveTenantAcquisitionConfigurationPanelSelectionHelperImagePathParams['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    RemoveTenantAcquisitionConfigurationPanelSelectionHelperImageMutationResponse,
    ResponseErrorConfig<RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage404 | RemoveTenantAcquisitionConfigurationPanelSelectionHelperImage422>,
    unknown
  >({ method: 'POST', url: getRemoveTenantAcquisitionConfigurationPanelSelectionHelperImageUrl(project_id).toString(), ...requestConfig })
  return res.data
}