// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListMeasuringPointsQueryResponse,
  ListMeasuringPointsQueryParams,
  ListMeasuringPoints404,
  ListMeasuringPoints422,
} from '../types/ListMeasuringPoints.ts'

export function getListMeasuringPointsUrl() {
  return `/api-internal/measuring_points/` as const
}

/**
 * @summary List Measuring Points
 * {@link /api-internal/measuring_points/}
 */
export async function listMeasuringPoints(params: ListMeasuringPointsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListMeasuringPointsQueryResponse, ResponseErrorConfig<ListMeasuringPoints404 | ListMeasuringPoints422>, unknown>({
    method: 'GET',
    url: getListMeasuringPointsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}