// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetTenantAcquisitionConfigurationMutationRequest,
  SetTenantAcquisitionConfigurationMutationResponse,
  SetTenantAcquisitionConfigurationPathParams,
  SetTenantAcquisitionConfiguration404,
  SetTenantAcquisitionConfiguration422,
} from '../types/SetTenantAcquisitionConfiguration.ts'

export function getSetTenantAcquisitionConfigurationUrl(project_id: SetTenantAcquisitionConfigurationPathParams['project_id']) {
  return `/api-internal/projects/${project_id}/tenant_acquisition_configuration` as const
}

/**
 * @summary Set Tenant Acquisition Configuration
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration}
 */
export async function setTenantAcquisitionConfiguration(
  project_id: SetTenantAcquisitionConfigurationPathParams['project_id'],
  data?: SetTenantAcquisitionConfigurationMutationRequest,
  config: Partial<RequestConfig<SetTenantAcquisitionConfigurationMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    SetTenantAcquisitionConfigurationMutationResponse,
    ResponseErrorConfig<SetTenantAcquisitionConfiguration404 | SetTenantAcquisitionConfiguration422>,
    SetTenantAcquisitionConfigurationMutationRequest
  >({ method: 'PATCH', url: getSetTenantAcquisitionConfigurationUrl(project_id).toString(), data, ...requestConfig })
  return res.data
}