import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { BulkEmailSendMutationRequest, BulkEmailSendMutationResponse, BulkEmailSend404, BulkEmailSend422 } from '../types/BulkEmailSend.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { bulkEmailSend } from '../clients/bulkEmailSend.ts'
import { useMutation } from '@tanstack/react-query'

export const bulkEmailSendMutationKey = () => [{ url: '/api-internal/document_email/bulk_send' }] as const

export type BulkEmailSendMutationKey = ReturnType<typeof bulkEmailSendMutationKey>

/**
 * @summary Bulk Email Send
 * {@link /api-internal/document_email/bulk_send}
 */
export function useBulkEmailSend(
  options: {
    mutation?: UseMutationOptions<
      BulkEmailSendMutationResponse,
      ResponseErrorConfig<BulkEmailSend404 | BulkEmailSend422>,
      { data: BulkEmailSendMutationRequest }
    >
    client?: Partial<RequestConfig<BulkEmailSendMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkEmailSendMutationKey()

  return useMutation<BulkEmailSendMutationResponse, ResponseErrorConfig<BulkEmailSend404 | BulkEmailSend422>, { data: BulkEmailSendMutationRequest }>({
    mutationFn: async ({ data }) => {
      return bulkEmailSend(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}