import {
  listTransactionsInfiniteQueryKey,
  useGetTransactionStatistics,
} from '@mg/api-wrappers/src/api-internal';
import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout';
import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { FilterProvider } from '../../shared/DataGridCard/DataGridCard.FilterProvider';
import { DataGridApiProvider } from '../../shared/DataGridCard/useGridApi';
import { AccountingActionMenu } from './Accounting.ActionMenu';
import { AccountingData } from './Accounting.Data';
import { AccountingStatistics } from './Accounting.Statistics';

const AccountingPage = () => {
  const queryClient = useQueryClient();
  const projectFilter = useSelectedProjectsFilter();
  const statisticsQuery = useGetTransactionStatistics({ ...projectFilter });

  const { t } = useTranslation('transactions');

  const invalidateQueries = useCallback(() => {
    return Promise.all([
      queryClient.invalidateQueries({ queryKey: statisticsQuery.queryKey }),
      queryClient.invalidateQueries({
        queryKey: listTransactionsInfiniteQueryKey({ ...projectFilter }),
      }),
    ]);
  }, [queryClient, statisticsQuery.queryKey, projectFilter]);

  return (
    <>
      <MGPageLayout
        title={t('page_title')}
        rightSection={
          <AccountingActionMenu
            onCreateTransactionSuccess={() => {
              invalidateQueries();
            }}
          />
        }
      >
        <Stack spacing={4}>
          <AccountingStatistics statisticsQueryResult={statisticsQuery} />
          <DataGridApiProvider>
            <AccountingData />
          </DataGridApiProvider>
        </Stack>
      </MGPageLayout>
    </>
  );
};

export const Accounting = () => {
  return (
    <FilterProvider>
      <AccountingPage />
    </FilterProvider>
  );
};
