import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  SetProjectToOnboardingV1CompleteMutationResponse,
  SetProjectToOnboardingV1CompletePathParams,
  SetProjectToOnboardingV1CompleteQueryParams,
  SetProjectToOnboardingV1Complete400,
  SetProjectToOnboardingV1Complete404,
  SetProjectToOnboardingV1Complete422,
} from '../types/SetProjectToOnboardingV1Complete.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { setProjectToOnboardingV1Complete } from '../clients/setProjectToOnboardingV1Complete.ts'
import { useMutation } from '@tanstack/react-query'

export const setProjectToOnboardingV1CompleteMutationKey = () => [{ url: '/api-internal/projects/{id}/complete_onboarding_v1' }] as const

export type SetProjectToOnboardingV1CompleteMutationKey = ReturnType<typeof setProjectToOnboardingV1CompleteMutationKey>

/**
 * @summary Set Project To Onboarding V1 Complete
 * {@link /api-internal/projects/:id/complete_onboarding_v1}
 */
export function useSetProjectToOnboardingV1Complete(
  options: {
    mutation?: UseMutationOptions<
      SetProjectToOnboardingV1CompleteMutationResponse,
      ResponseErrorConfig<SetProjectToOnboardingV1Complete400 | SetProjectToOnboardingV1Complete404 | SetProjectToOnboardingV1Complete422>,
      { id: SetProjectToOnboardingV1CompletePathParams['id']; params?: SetProjectToOnboardingV1CompleteQueryParams }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? setProjectToOnboardingV1CompleteMutationKey()

  return useMutation<
    SetProjectToOnboardingV1CompleteMutationResponse,
    ResponseErrorConfig<SetProjectToOnboardingV1Complete400 | SetProjectToOnboardingV1Complete404 | SetProjectToOnboardingV1Complete422>,
    { id: SetProjectToOnboardingV1CompletePathParams['id']; params?: SetProjectToOnboardingV1CompleteQueryParams }
  >({
    mutationFn: async ({ id, params }) => {
      return setProjectToOnboardingV1Complete(id, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}