import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getContractProposalSepaPdfQueryParamsSchema = z.object({
  id: z.number().int(),
  download: z.boolean().default(false),
})

/**
 * @description OK
 */
export const getContractProposalSepaPdf200Schema = z.unknown()

/**
 * @description Not Found
 */
export const getContractProposalSepaPdf404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getContractProposalSepaPdf422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getContractProposalSepaPdfQueryResponseSchema = z.lazy(() => getContractProposalSepaPdf200Schema)