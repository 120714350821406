import {
  deliverableDocumentType,
  PaymentPlanSchema,
  useGetPaymentPlanPdf,
} from '@mg/api-wrappers/src/api-internal';
import { useCommonActionMenuItems } from './useCommonActionMenuItems';

import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { useDocumentContext } from '../../features/documents/document-context/document-context';

export const DocumentsPaymentPlansTableRowActionMenu = () => {
  const { document } = useDocumentContext<PaymentPlanSchema>();
  const getPaymantPlanPdfQuery = useGetPaymentPlanPdf(document.id, { query: { enabled: false } });
  const commonItems = useCommonActionMenuItems({
    documentType: deliverableDocumentType.payment_plan,
    downloadPdfQuery: getPaymantPlanPdfQuery,
  });

  const menuItems: MGActionMenuItemProps[] = [...commonItems];

  return (
    <>
      <MGActionMenu menuItems={menuItems} />
    </>
  );
};
