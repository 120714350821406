// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  RequestPasswordResetMutationRequest,
  RequestPasswordResetMutationResponse,
  RequestPasswordReset404,
  RequestPasswordReset422,
} from '../types/RequestPasswordReset.ts'

export function getRequestPasswordResetUrl() {
  return `/api-internal/auth/request_password_reset` as const
}

/**
 * @summary Request Password Reset
 * {@link /api-internal/auth/request_password_reset}
 */
export async function requestPasswordReset(
  data: RequestPasswordResetMutationRequest,
  config: Partial<RequestConfig<RequestPasswordResetMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    RequestPasswordResetMutationResponse,
    ResponseErrorConfig<RequestPasswordReset404 | RequestPasswordReset422>,
    RequestPasswordResetMutationRequest
  >({ method: 'POST', url: getRequestPasswordResetUrl().toString(), data, ...requestConfig })
  return res.data
}