import { MGBadge } from '@mg/ui/src/components/MGBadge/MGBadge';
import { MGCard } from '@mg/ui/src/components/MGCard';
import { Box, Chip, Grid2, Stack, Typography } from '@mui/material';
import arrowBottomUp from '../../../assets/arrow_bottom_up.svg';
import arrowTopDown from '../../../assets/arrow_top_down.svg';
import { useTranslation } from '../../../i18n.tsx';

export const ContractsFlowChart = () => {
  const { t } = useTranslation('contracts');
  const cards = [
    {
      title: t('start_title'),
      subtitle: t('start_subtitle'),
      chipLabel: t('start_chip'),
      color: 'warning' as const,
    },
    {
      title: t('enter_data_title'),
      subtitle: t('enter_data_subtitle'),
      chipLabel: t('enter_data_chip'),
      color: 'gray' as const,
    },
    {
      title: t('confirmation_title'),
      subtitle: t('confirmation_subtitle'),
      chipLabel: t('confirmation_chip'),
      color: 'primary' as const,
    },
    {
      title: t('create_customer_title'),
      subtitle: t('create_customer_subtitle'),
      chipLabel: t('create_customer_chip'),
      color: 'success' as const,
    },
  ];
  return (
    <Grid2 container spacing={1}>
      {cards.map((card, index) => (
        <Grid2 key={index} size={3} position={'relative'}>
          <Box
            sx={{
              position: 'absolute',
              right: -45,
              zIndex: 1,
              top: index === 1 ? -50 : undefined,
              bottom: index === 1 ? undefined : -50,
            }}
          >
            {(index === 0 || index === 2) && <img src={arrowBottomUp} alt={''} />}
            {index === 1 && <img src={arrowTopDown} alt={''} />}
          </Box>
          <MGCard stretch sx={{ justifyContent: 'space-between', height: '100%' }}>
            <Stack spacing={2}>
              <Stack direction={'row'} id={'test2'} spacing={1}>
                <Box>
                  <MGBadge>{index + 1}</MGBadge>
                </Box>
                <Typography>{card.title}</Typography>
              </Stack>
              <Box>
                <Typography variant={'extraSmall'}>{card.subtitle}</Typography>
              </Box>
              <Box>
                <Chip
                  variant={'soft'}
                  size={'small'}
                  sx={{ borderRadius: '5px' }}
                  label={card.chipLabel}
                  color={card.color}
                />
              </Box>
            </Stack>
          </MGCard>
        </Grid2>
      ))}
    </Grid2>
  );
};
