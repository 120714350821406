import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  RealizeContractProposalMutationRequest,
  RealizeContractProposalMutationResponse,
  RealizeContractProposalPathParams,
  RealizeContractProposal404,
  RealizeContractProposal422,
} from '../types/RealizeContractProposal.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { realizeContractProposal } from '../clients/realizeContractProposal.ts'
import { useMutation } from '@tanstack/react-query'

export const realizeContractProposalMutationKey = () => [{ url: '/api-internal/contract_proposals/{id}/realize' }] as const

export type RealizeContractProposalMutationKey = ReturnType<typeof realizeContractProposalMutationKey>

/**
 * @summary Realize Contract Proposal
 * {@link /api-internal/contract_proposals/:id/realize}
 */
export function useRealizeContractProposal(
  options: {
    mutation?: UseMutationOptions<
      RealizeContractProposalMutationResponse,
      ResponseErrorConfig<RealizeContractProposal404 | RealizeContractProposal422>,
      { id: RealizeContractProposalPathParams['id']; data: RealizeContractProposalMutationRequest }
    >
    client?: Partial<RequestConfig<RealizeContractProposalMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? realizeContractProposalMutationKey()

  return useMutation<
    RealizeContractProposalMutationResponse,
    ResponseErrorConfig<RealizeContractProposal404 | RealizeContractProposal422>,
    { id: RealizeContractProposalPathParams['id']; data: RealizeContractProposalMutationRequest }
  >({
    mutationFn: async ({ id, data }) => {
      return realizeContractProposal(id, data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}