import { useGetMeasuringPointPanel } from '@mg/api-wrappers/src/api-internal';
import { MGEllipsizedValue } from '@mg/ui/src/components/MGEllipsizedValue';
import { Grid, Typography, useTheme } from '@mui/material';
import { Info } from 'lucide-react';
import { useTranslation } from '../../../i18n';
import { MetergridCard } from '../../shared/MetergridCard';

export const MeteringDetailsMeasuringPointPanel = ({ panelId }) => {
  const { data, isLoading, isError } = useGetMeasuringPointPanel(panelId, {});
  const { t } = useTranslation('meteringDetails');

  const theme = useTheme();
  return (
    <MetergridCard isLoading={isLoading} isError={isError}>
      <Grid container>
        <Grid item xs={12}>
          <Info color={theme.palette.primary.main} />
        </Grid>
        <Grid item xs={12} marginTop={'10px'}>
          <Typography>{t('general_card_title')}</Typography>
        </Grid>

        <Grid item container xs={12} marginTop={'15px'} rowSpacing={'10px'}>
          <Grid item container xs={6}>
            <Grid item xs={12}>
              <Typography variant={'extraSmall'}>{t('general_card_name')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <MGEllipsizedValue>{data?.name || '-'}</MGEllipsizedValue>
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={12}>
              <Typography variant={'extraSmall'}>{t('general_card_type')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{data?.type ? t(data.type) : '-'}</Typography>
            </Grid>
          </Grid>

          <Grid item container xs={6}>
            <Grid item xs={12}>
              <Typography variant={'extraSmall'}>{t('general_card_malo_id')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{data?.malo_id || '-'}</Typography>
            </Grid>
          </Grid>

          <Grid item container xs={6}>
            <Grid item xs={12}>
              <Typography variant={'extraSmall'}>{t('general_card_melo_id')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{data?.melo_id || '-'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MetergridCard>
  );
};
