import { planComponentSchemaSchema } from './planComponentSchemaSchema.ts'
import { z } from 'zod'

export const planSchemaSchema = z.object({
  active_subscriptions: z.union([z.number().int(), z.null()]).optional(),
  cancel_period: z.string().max(128).default('MONTHS_3_TO_TERM_END').describe('The terms to cancel the subscription'),
  duration: z.string().max(128).default('MONTHS_12').describe('The duration of the plan the customer has to agree to'),
  expired_subscriptions: z.union([z.number().int(), z.null()]).optional(),
  id: z.union([z.number().int(), z.null()]).optional(),
  interval: z.string().max(12).default('year').describe('The frequency with which it should be billed.'),
  interval_count: z.number().int().default(1).describe('The number of intervals between each billing'),
  is_deletable: z.boolean(),
  is_editable: z.boolean(),
  name: z.string().max(255).default('Mieterstrom').describe('Display name of the plan.'),
  plan_components: z.array(z.lazy(() => planComponentSchemaSchema)),
  planned_subscriptions: z.union([z.number().int(), z.null()]).optional(),
  price_base: z.union([z.string(), z.number()]).describe('The price in EUR per year for supplying the customer (before tax).'),
  tenant_acquisition_link: z.string(),
})