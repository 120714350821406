import { PlanSchema } from '@mg/api-wrappers/src/api-internal';
import { MGCard } from '@mg/ui/src/components/MGCard';
import { presentEuroPerYear } from '@mg/ui/src/presenters';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import { Link, QrCode } from 'lucide-react';
import QRCode from 'qrcode';
import { toast } from 'react-toastify';
import { useTranslation } from '../../../i18n.tsx';
import { ComponentPrices } from '../../shared/ComponentPrices/ComponentPrices.tsx';

type Props = {
  plan: PlanSchema;
};
export const ContractsShareLinkModalPlanCard = ({ plan }: Props) => {
  const { tString } = useTranslation('contracts');

  const downloadQr = () => {
    QRCode.toDataURL(plan.tenant_acquisition_link, function (error, qrcode) {
      if (error) console.error('ERROR', error);
      const element = document.createElement('a');
      element.href = qrcode;
      element.download = 'Mieteransprache QR-Code.png';
      element.click();
    });
  };

  const copyLinkToClipboard = () => {
    toast.promise(navigator.clipboard.writeText(plan.tenant_acquisition_link), {
      success: tString('copy_to_clipboard_success'),
    });
  };

  return (
    <MGCard stretch sx={{ backgroundColor: '#FDFDFC' }}>
      <Stack direction={'row'} alignItems={'center'}>
        <Typography flex={1.5}>{plan.name}</Typography>
        <Box flex={1}>
          <Chip
            color={plan.plan_components.length === 1 ? 'purple' : 'primary'}
            variant={'soft'}
            label={plan.plan_components.length === 1 ? 'Mischpreis' : '2-Komponenten'}
            size={'small'}
          />
        </Box>
        <Typography flex={1} variant={'small'}>
          {presentEuroPerYear(plan.price_base)}
        </Typography>
        <Box flex={1}>
          <ComponentPrices planComponents={plan.plan_components} />
        </Box>
        <Stack direction={'row'}>
          <IconButton onClick={downloadQr}>
            <QrCode />
          </IconButton>
          <IconButton onClick={copyLinkToClipboard}>
            <Link />
          </IconButton>
        </Stack>
      </Stack>
    </MGCard>
  );
};
