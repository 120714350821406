import { useGetProjectOnboardingCompleteness } from '@mg/api-wrappers/src/api-internal';
import { Button, Grid, Stack } from '@mui/material';
import { Signature, UsersRound, UtilityPole, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useUserInfo } from '../../../../helpers/userInfo.ts';
import { useCurrentProject } from '../../../../hooks/useCurrentProject';
import { useTranslation } from '../../../../i18n';
import { OnboardingCard, OnboardingCardProps } from './Onboarding.Card';

export const OnboardingStepPlansAndLevies = () => {
  const { t } = useTranslation('onboarding');
  const projectId = useCurrentProject();
  const onboardingQuery = useGetProjectOnboardingCompleteness(projectId);
  const { created_plan, created_residual_power_plan, created_levies, setup_customers } =
    onboardingQuery.data || {};

  const [userInfo] = useUserInfo();

  const hasTenantAcquisition = userInfo?.flags.tenant_acquisition;

  const tiles: Omit<OnboardingCardProps, 'title' | 'subtitle'>[] = [
    {
      key: 'plans',
      icon: <UtilityPole />,
      status:
        onboardingQuery.data?.created_plan && onboardingQuery.data?.created_residual_power_plan
          ? 'done'
          : 'todo',
      href: '/plans',
    },
    {
      key: 'levies',
      icon: <Zap />,
      status: onboardingQuery.data?.created_levies ? 'done' : 'todo',
      href: '/levystructures',
    },
    {
      key: 'customers',
      icon: <UsersRound />,
      status: onboardingQuery.data?.setup_customers ? 'done' : 'todo',
      href: '/customers',
    },
    {
      key: 'generate_contracts',
      icon: <Signature />,
      status: !hasTenantAcquisition
        ? 'coming_soon'
        : created_plan && created_residual_power_plan && created_levies && setup_customers
          ? 'todo'
          : 'blocked',
      href: '/contracts',
    },
  ];
  return (
    <Stack gap={2}>
      <Grid container spacing={3}>
        {tiles.map((tile) => (
          <Grid key={tile.key} item xs={12} md={6}>
            <OnboardingCard
              sx={{
                height: '100%',
              }}
              loading={onboardingQuery.isLoading}
              title={t(`onboarding_tile_${tile.key}_title`)}
              subtitle={t(`onboarding_tile_${tile.key}_subtitle`)}
              icon={tile.icon}
              status={tile.status}
              button={
                <Button
                  component={Link}
                  to={tile.href}
                  onClick={tile.onClick}
                  variant={tile.status === 'done' ? 'outlined' : 'contained'}
                  disabled={tile.status === 'coming_soon' || tile.status === 'blocked'}
                >
                  {t(
                    tile.status === 'done'
                      ? `onboarding_tile_${tile.key}_edit_button`
                      : `onboarding_tile_${tile.key}_button`,
                  )}
                </Button>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
