import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  RemoveTenantAcquisitionConfigurationLogoMutationResponse,
  RemoveTenantAcquisitionConfigurationLogoPathParams,
  RemoveTenantAcquisitionConfigurationLogo404,
  RemoveTenantAcquisitionConfigurationLogo422,
} from '../types/RemoveTenantAcquisitionConfigurationLogo.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { removeTenantAcquisitionConfigurationLogo } from '../clients/removeTenantAcquisitionConfigurationLogo.ts'
import { useMutation } from '@tanstack/react-query'

export const removeTenantAcquisitionConfigurationLogoMutationKey = () =>
  [{ url: '/api-internal/projects/{project_id}/tenant_acquisition_configuration/logo/remove' }] as const

export type RemoveTenantAcquisitionConfigurationLogoMutationKey = ReturnType<typeof removeTenantAcquisitionConfigurationLogoMutationKey>

/**
 * @summary Remove Tenant Acquisition Configuration Logo
 * {@link /api-internal/projects/:project_id/tenant_acquisition_configuration/logo/remove}
 */
export function useRemoveTenantAcquisitionConfigurationLogo(
  options: {
    mutation?: UseMutationOptions<
      RemoveTenantAcquisitionConfigurationLogoMutationResponse,
      ResponseErrorConfig<RemoveTenantAcquisitionConfigurationLogo404 | RemoveTenantAcquisitionConfigurationLogo422>,
      { project_id: RemoveTenantAcquisitionConfigurationLogoPathParams['project_id'] }
    >
    client?: Partial<RequestConfig> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? removeTenantAcquisitionConfigurationLogoMutationKey()

  return useMutation<
    RemoveTenantAcquisitionConfigurationLogoMutationResponse,
    ResponseErrorConfig<RemoveTenantAcquisitionConfigurationLogo404 | RemoveTenantAcquisitionConfigurationLogo422>,
    { project_id: RemoveTenantAcquisitionConfigurationLogoPathParams['project_id'] }
  >({
    mutationFn: async ({ project_id }) => {
      return removeTenantAcquisitionConfigurationLogo(project_id, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}