import { billingPreflightOutSchema } from './billingPreflightOutSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const getBillingPreflightDataQueryParamsSchema = z.object({
  subscription_id: z.number().int(),
  start_date: z.union([z.string().date(), z.null()]).optional(),
  end_date: z.union([z.string().date(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const getBillingPreflightData200Schema = z.lazy(() => billingPreflightOutSchema)

/**
 * @description Bad Request
 */
export const getBillingPreflightData400Schema = z.string()

/**
 * @description Not Found
 */
export const getBillingPreflightData404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getBillingPreflightData422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getBillingPreflightDataQueryResponseSchema = z.lazy(() => getBillingPreflightData200Schema)