import { contractHTMLSchemaSchema } from './contractHTMLSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { renderContractMissingDataErrorOutSchema } from './renderContractMissingDataErrorOutSchema.ts'
import { z } from 'zod'

export const getContractHtmlQueryParamsSchema = z.object({
  plan_id: z.number().int(),
  start_date: z.union([z.string().date(), z.null()]).optional(),
})

/**
 * @description OK
 */
export const getContractHtml200Schema = z.lazy(() => contractHTMLSchemaSchema)

/**
 * @description Bad Request
 */
export const getContractHtml400Schema = z.lazy(() => renderContractMissingDataErrorOutSchema)

/**
 * @description Not Found
 */
export const getContractHtml404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const getContractHtml422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const getContractHtmlQueryResponseSchema = z.lazy(() => getContractHtml200Schema)