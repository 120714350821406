import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

export const countResidualPowerPlansQueryParamsSchema = z.object({
  project_id: z.number().int(),
})

/**
 * @description OK
 */
export const countResidualPowerPlans200Schema = z.number().int()

/**
 * @description Not Found
 */
export const countResidualPowerPlans404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const countResidualPowerPlans422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const countResidualPowerPlansQueryResponseSchema = z.lazy(() => countResidualPowerPlans200Schema)