import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { ListMissingDataMutationRequest, ListMissingDataMutationResponse, ListMissingData404, ListMissingData422 } from '../types/ListMissingData.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { listMissingData } from '../clients/listMissingData.ts'
import { useMutation } from '@tanstack/react-query'

export const listMissingDataMutationKey = () => [{ url: '/api-internal/billing/missing_data' }] as const

export type ListMissingDataMutationKey = ReturnType<typeof listMissingDataMutationKey>

/**
 * @summary List Missing Data
 * {@link /api-internal/billing/missing_data}
 */
export function useListMissingData(
  options: {
    mutation?: UseMutationOptions<
      ListMissingDataMutationResponse,
      ResponseErrorConfig<ListMissingData404 | ListMissingData422>,
      { data?: ListMissingDataMutationRequest }
    >
    client?: Partial<RequestConfig<ListMissingDataMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? listMissingDataMutationKey()

  return useMutation<ListMissingDataMutationResponse, ResponseErrorConfig<ListMissingData404 | ListMissingData422>, { data?: ListMissingDataMutationRequest }>({
    mutationFn: async ({ data }) => {
      return listMissingData(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}