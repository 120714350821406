import {
  ContractProposalOut,
  useSignContractProposalByProvider,
} from '@mg/api-wrappers/src/api-internal/index.ts';
import { MGModal, MGModalBody, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { MGModalHandle } from '@mg/ui/src/components/MGModal/MGModal.tsx';
import { defaultErrorHandler } from '@mg/ui/src/helpers/errorHandling.ts';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries.ts';
import { useTranslation } from '../../../i18n.tsx';
import { ContractsDownloadDocumentsCard } from './Contracts.DownloadDocumentsCard.tsx';
import { ContractsGeneralDataCard } from './Contracts.GeneralDataCard.tsx';
import { ContractsPlanDetailsCard } from './Contracts.PlanDetailsCard.tsx';

interface ContractsConfirmAndSignModalProps {
  contractProposal: ContractProposalOut;
}

export const ContractsConfirmAndSignModal = ({ contractProposal }: ContractsConfirmAndSignModalProps) => {
  const { t } = useTranslation('contracts');
  const modalHandle = useRef<MGModalHandle>(null);

  const signContractProposal = useSignContractProposalByProvider({
    mutation: {
      async onSuccess() {
        await invalidateAllQueries();
        modalHandle.current?.close();
        toast.success(t('toast_confirm_sign_modal_success'));
      },
      onError: defaultErrorHandler,
    },
  });

  return (
    <MGModal
      ref={modalHandle}
      width={'800px'}
      buttonComponent={
        <Button variant={'contained'} color={'primary'}>
          {t('btn_confirm_sign_modal_open')}
        </Button>
      }
    >
      <MGModalTitle>{t('tit_confirm_sign_modal')}</MGModalTitle>
      <Typography variant="small">{t('lbl_confirm_sign_modal_subtitle')}</Typography>
      <MGModalBody>
        <Stack direction={'row'} gap={2.5} marginTop={2} justifyContent={'stretch'}>
          <ContractsGeneralDataCard contractProposal={contractProposal} />
          <ContractsPlanDetailsCard contractProposal={contractProposal} />
        </Stack>

        <ContractsDownloadDocumentsCard contractProposal={contractProposal} />

        {/* Modal Actions with Acknowledgements */}
        <Stack direction={'row'} gap={2} marginTop={4}>
          <Typography component={'div'} fontSize={12} fontWeight={400} lineHeight={1.5}>
            {/* This is a bit of a hack to get the links to work in the desired layout / as one paragraph */}
            {t('lbl_confirm_sign_modal_acknowledgements')}
          </Typography>
          <Box>
            <Button variant="contained" color="white" onClick={() => modalHandle.current?.close()}>
              {t('btn_confirm_sign_modal_cancel')}
            </Button>
          </Box>
          <Box>
            <Button
              variant={'contained'}
              color={'primary'}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => signContractProposal.mutate({ id: contractProposal.id })}
              loading={signContractProposal.isPending}
            >
              <span>{t('btn_confirm_sign_modal_confirm')}</span>
            </Button>
          </Box>
        </Stack>
      </MGModalBody>
    </MGModal>
  );
};
