// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetSepaMandatePdfQueryResponse,
  GetSepaMandatePdfPathParams,
  GetSepaMandatePdf400,
  GetSepaMandatePdf404,
  GetSepaMandatePdf422,
} from '../types/GetSepaMandatePdf.ts'

export function getGetSepaMandatePdfUrl(id: GetSepaMandatePdfPathParams['id']) {
  return `/api-internal/sepa_mandates/${id}/pdf` as const
}

/**
 * @summary Get Sepa Mandate Pdf
 * {@link /api-internal/sepa_mandates/:id/pdf}
 */
export async function getSepaMandatePdf(id: GetSepaMandatePdfPathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetSepaMandatePdfQueryResponse, ResponseErrorConfig<GetSepaMandatePdf400 | GetSepaMandatePdf404 | GetSepaMandatePdf422>, unknown>({
    method: 'GET',
    url: getGetSepaMandatePdfUrl(id).toString(),
    ...requestConfig,
  })
  return res.data
}