import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  BulkEmailPreflightMutationRequest,
  BulkEmailPreflightMutationResponse,
  BulkEmailPreflightQueryParams,
  BulkEmailPreflight404,
  BulkEmailPreflight422,
} from '../types/BulkEmailPreflight.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { bulkEmailPreflight } from '../clients/bulkEmailPreflight.ts'
import { useMutation } from '@tanstack/react-query'

export const bulkEmailPreflightMutationKey = () => [{ url: '/api-internal/document_email/bulk_preflight' }] as const

export type BulkEmailPreflightMutationKey = ReturnType<typeof bulkEmailPreflightMutationKey>

/**
 * @summary Bulk Email Preflight
 * {@link /api-internal/document_email/bulk_preflight}
 */
export function useBulkEmailPreflight(
  options: {
    mutation?: UseMutationOptions<
      BulkEmailPreflightMutationResponse,
      ResponseErrorConfig<BulkEmailPreflight404 | BulkEmailPreflight422>,
      { data?: BulkEmailPreflightMutationRequest; params?: BulkEmailPreflightQueryParams }
    >
    client?: Partial<RequestConfig<BulkEmailPreflightMutationRequest>> & { client?: typeof client }
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkEmailPreflightMutationKey()

  return useMutation<
    BulkEmailPreflightMutationResponse,
    ResponseErrorConfig<BulkEmailPreflight404 | BulkEmailPreflight422>,
    { data?: BulkEmailPreflightMutationRequest; params?: BulkEmailPreflightQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return bulkEmailPreflight(data, params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}