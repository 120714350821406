import { MGModal, MGModalTitle } from '@mg/ui/src/components/MGModal';
import { AxiosError } from 'axios';
import { ReactNode, Suspense } from 'react';
import { DjangoForm } from '../shared/DjangoForm';
import('../../styles/old_styles/style.scss');

type Props = {
  open: boolean;
  handleToggle: () => void;
  title: ReactNode;
  pathToDjangoForm: string;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
  prefill?: { id: string; value: string | number }[];
  BodyStyle?: React.CSSProperties;
  contentBefore?: React.ReactNode;
};
export const DjangoFormModalWithoutSuspense = ({
  open,
  handleToggle,
  title,
  pathToDjangoForm,
  onSuccess,
  onError,
  prefill,
  BodyStyle,
  contentBefore = null,
}: Props) => {
  if (!open) return null;

  return (
    <MGModal
      showCloseButton
      open={open}
      handleToggle={handleToggle}
      height={'750px'}
      width={'1000px'}
      PaperStyle={{ padding: '20px', overflow: 'auto' }}
      BodyStyle={{ paddingRight: '20px', ...BodyStyle }}
    >
      <MGModalTitle>{title}</MGModalTitle>
      {contentBefore}
      <DjangoForm
        hideTitle
        pathToDjangoForm={pathToDjangoForm}
        onSuccess={onSuccess}
        onError={onError}
        prefill={prefill}
      />
    </MGModal>
  );
};

export const DjangoFormModal = (props) => {
  return (
    <Suspense>
      <DjangoFormModalWithoutSuspense {...props} />
    </Suspense>
  );
};
