// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  ListLevyStructuresQueryResponse,
  ListLevyStructuresQueryParams,
  ListLevyStructures404,
  ListLevyStructures422,
} from '../types/ListLevyStructures.ts'

export function getListLevyStructuresUrl() {
  return `/api-internal/levy_structures/` as const
}

/**
 * @summary List Levy Structures
 * {@link /api-internal/levy_structures/}
 */
export async function listLevyStructures(params: ListLevyStructuresQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListLevyStructuresQueryResponse, ResponseErrorConfig<ListLevyStructures404 | ListLevyStructures422>, unknown>({
    method: 'GET',
    url: getListLevyStructuresUrl().toString(),
    params,
    ...requestConfig,
  })
  return res.data
}