// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type {
  GetContractProposalCountsByStateQueryResponse,
  GetContractProposalCountsByStateQueryParams,
  GetContractProposalCountsByState404,
  GetContractProposalCountsByState422,
} from '../types/GetContractProposalCountsByState.ts'

export function getGetContractProposalCountsByStateUrl() {
  return `/api-internal/contract_proposals/counts_by_state` as const
}

/**
 * @summary Get Contract Proposal Counts By State
 * {@link /api-internal/contract_proposals/counts_by_state}
 */
export async function getContractProposalCountsByState(
  params: GetContractProposalCountsByStateQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GetContractProposalCountsByStateQueryResponse,
    ResponseErrorConfig<GetContractProposalCountsByState404 | GetContractProposalCountsByState422>,
    unknown
  >({ method: 'GET', url: getGetContractProposalCountsByStateUrl().toString(), params, ...requestConfig })
  return res.data
}