// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { CountProjectsQueryResponse, CountProjects404, CountProjects422 } from '../types/CountProjects.ts'

export function getCountProjectsUrl() {
  return `/api-internal/projects/count` as const
}

/**
 * @summary Count Projects
 * {@link /api-internal/projects/count}
 */
export async function countProjects(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CountProjectsQueryResponse, ResponseErrorConfig<CountProjects404 | CountProjects422>, unknown>({
    method: 'GET',
    url: getCountProjectsUrl().toString(),
    ...requestConfig,
  })
  return res.data
}