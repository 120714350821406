// @ts-nocheck
import client from '../../client.ts'
import type { RequestConfig, ResponseErrorConfig } from '../../client.ts'
import type { GetLevyStructureQueryResponse, GetLevyStructurePathParams, GetLevyStructure404, GetLevyStructure422 } from '../types/GetLevyStructure.ts'

export function getGetLevyStructureUrl(id: GetLevyStructurePathParams['id']) {
  return `/api-internal/levy_structures/${id}` as const
}

/**
 * @summary Get Levy Structure
 * {@link /api-internal/levy_structures/:id}
 */
export async function getLevyStructure(id: GetLevyStructurePathParams['id'], config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GetLevyStructureQueryResponse, ResponseErrorConfig<GetLevyStructure404 | GetLevyStructure422>, unknown>({
    method: 'GET',
    url: getGetLevyStructureUrl(id).toString(),
    ...requestConfig,
  })
  return res.data
}