import { cancelSepaMandateSchemaSchema } from './cancelSepaMandateSchemaSchema.ts'
import { notFoundErrorOutSchema } from './notFoundErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { sepaMandateSchemaSchema } from './sepaMandateSchemaSchema.ts'
import { z } from 'zod'

export const cancelSepaMandatePathParamsSchema = z.object({
  id: z.number().int(),
})

/**
 * @description OK
 */
export const cancelSepaMandate200Schema = z.lazy(() => sepaMandateSchemaSchema)

/**
 * @description Not Found
 */
export const cancelSepaMandate404Schema = z.lazy(() => notFoundErrorOutSchema)

/**
 * @description Unprocessable Entity
 */
export const cancelSepaMandate422Schema = z.lazy(() => paramValidationErrorOutSchema)

export const cancelSepaMandateMutationRequestSchema = z.lazy(() => cancelSepaMandateSchemaSchema)

export const cancelSepaMandateMutationResponseSchema = z.lazy(() => cancelSepaMandate200Schema)