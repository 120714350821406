import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { VisibilityOutlined } from '@mui/icons-material';
import { useTranslation } from '../../../i18n';

export const MeteringDetailsCustomerListActionMenu = ({ customerId }) => {
  const { t } = useTranslation('meteringDetails');

  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('customer_list_action_menu_show_customer'),
      icon: <VisibilityOutlined />,
      href: `/customers/${customerId}`,
    },
  ];

  return <MGActionMenu menuItems={menuItems} />;
};
