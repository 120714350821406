import { Box } from '@mui/material';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { DataGridCardSearch } from '../../shared/DataGridCard/DataGridCard.Search';
import { MetergridTabs, MGTabProps } from '../../shared/MetergridTabs';
import { DocumentsInvoiceTable } from './Documents.InvoiceTable';
import { DocumentsLetterTable } from './Documents.LetterTable';
import { DocumentsPaymentPlansTable } from './Documents.PaymentPlansTable';
import { DocumentsPlanChangeTable } from './Documents.PlanChangeTable';
import { DocumentsSepaTable } from './Documents.SepaTable';

import {
  deliverableDocumentType,
  useCountInvoices,
  useCountLetters,
  useCountPaymentPlans,
  useCountPlanChangeDocuments,
  useCountSepaMandates,
} from '@mg/api-wrappers/src/api-internal';
import { useAtom } from 'jotai/index';
import { useQueryState } from 'nuqs';
import { filterValueAtom } from '../../../jotai/actionBar';
import { BulkEmailDocumentsModal } from '../../features/documents/bulk-email/BulkEmailDocumentsModal';

type Props = {
  height?: string;
  customerId?: number | null;
};
export const DocumentsData = ({ height, customerId }: Props) => {
  const { tString } = useTranslation('documents');

  const [currentTab, setCurrentTab] = useQueryState('tab', { defaultValue: 'invoice' });

  const projectFilter = useSelectedProjectsFilter();
  const countInvoiceQuery = useCountInvoices({
    ...projectFilter,
    customer_id: customerId,
    document_type: deliverableDocumentType.invoice,
  });
  const countPaymentPlanQuery = useCountPaymentPlans({
    ...projectFilter,
    customer_id: customerId,
    document_type: deliverableDocumentType.payment_plan,
  });
  const countSepaQuery = useCountSepaMandates({
    ...projectFilter,
    customer_id: customerId,
    document_type: deliverableDocumentType.sepa_mandate,
  });
  const countPlanChangeQuery = useCountPlanChangeDocuments({
    ...projectFilter,
    customer_id: customerId,
    document_type: deliverableDocumentType.plan_change,
  });
  const countLetterQuery = useCountLetters({
    ...projectFilter,
    customer_id: customerId,
    document_type: deliverableDocumentType.letter,
  });
  const [filterValue, setFilterValue] = useAtom(filterValueAtom);

  const tabs: MGTabProps[] = [
    {
      label: tString('tab_invoice'),
      value: 'invoice',
      count: countInvoiceQuery.data,
      selected: currentTab === 'invoice',
    },
    {
      label: tString('tab_payment_plan'),
      value: 'payment_plan',
      count: countPaymentPlanQuery.data,
      selected: currentTab === 'payment_plan',
    },
    {
      label: tString('tab_sepa'),
      value: 'sepa',
      count: countSepaQuery.data,
      selected: currentTab === 'sepa',
    },
    {
      label: tString('tab_plan_change_document'),
      value: 'plan_change_document',
      count: countPlanChangeQuery.data,
      selected: currentTab === 'plan_change_document',
    },
    {
      label: tString('tab_letter'),
      value: 'letter',
      count: countLetterQuery.data,
      selected: currentTab === 'letter',
    },
  ];

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'}>
          <MetergridTabs
            tabs={tabs}
            onChange={async (tab) => {
              if (filterValue.search) {
                setFilterValue({ search: filterValue.search });
              } else {
                setFilterValue({});
              }

              await setCurrentTab(tab);
            }}
          />
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <DataGridCardSearch width={'100%'} placeholder={tString(`search_placeholder_${currentTab}`)} />
        </Box>
      </Box>
      <Box id="datagrid-wrapper" height={height || 'calc(100vh - 480px)'} margin={'0px !important'}>
        <BulkEmailDocumentsModal />
        <SelectedDataGrid currentTab={currentTab} customerId={customerId} />
      </Box>
    </>
  );
};

const SelectedDataGrid = ({ currentTab, customerId }) => {
  switch (currentTab) {
    case 'invoice':
      return <DocumentsInvoiceTable customerId={customerId} />;
    case 'payment_plan':
      return <DocumentsPaymentPlansTable customerId={customerId} />;
    case 'sepa':
      return <DocumentsSepaTable customerId={customerId} />;
    case 'plan_change_document':
      return <DocumentsPlanChangeTable customerId={customerId} />;
    case 'letter':
      return <DocumentsLetterTable customerId={customerId} />;
  }
};
